import { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";

export default function useAuthToken() {
  const navigate = useNavigate();

  const authContext = useContext(AuthContext);
  const [token, setToken] = useState(authContext.currentToken);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }

    setToken(authContext.currentToken);
  }, [authContext.currentToken]);

  return token;
}
