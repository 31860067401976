import { buildHttpClient } from "../services/api";
import axios from "axios";

class EnvioRepository {
  constructor({ token }) {
    this.http = buildHttpClient(token);

    this.http.interceptors.request.use(
      function (config) {
        if (!config.headers.token) {
          throw new axios.Cancel("");
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  consultarTransportadoras(params) {
    return this.http.post("/envios/consultar-transportadoras", params);
  }

  estimarCusto(params) {
    return this.http.post("/envios/estimar-custo", params);
  }

  novoEnvio(params) {
    return this.http.post("/envios/novo-envio", params);
  }

  consultarEnvio(params) {
    return this.http.post("/envios/consultar-envio", params);
  }

  cancelarEnvio(params) {
    return this.http.post("/envios/cancelar-envio", params);
  }

  verEtiqueta(params) {
    return this.http.post("/envios/ver-etiqueta", params);
  }

  statusProntoParaEnvio(params) {
    return this.http.post("/envios/status-pronto-para-envio", params);
  }

  statusEnviado(params) {
    return this.http.post("/envios/status-enviado", params);
  }
}

export default EnvioRepository;
