import { Button, Checkbox, Flex, Text } from "@chakra-ui/react";
import Formatters from "../../../utils/formatters";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

export default function TabCuponsLembreteCarrinho({
  allCupons,
  selectedCupons,
  removeOne,
}) {
  return (
    <Flex direction={"column"}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Código</Th>
              <Th>Tipo</Th>
              <Th>Promoção</Th>
              <Th>Quantidade</Th>
              <Th>Utilizados</Th>
              <Th>Período</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {allCupons.map((cupom) => (
              <Tr>
                <Td>
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        selectedCupons.push(cupom);
                      } else {
                        selectedCupons.splice(selectedCupons.indexOf(cupom), 1);
                      }
                    }}
                  />
                </Td>
                <Td>
                  <Text
                    fontSize={"sm"}
                    whiteSpace={"normal"}
                    noOfLines={2}
                    w="250"
                  >
                    {cupom.codigo}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize={"sm"}
                    whiteSpace={"normal"}
                    noOfLines={2}
                    w="250"
                  >
                    {cupom.tipo}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize={"sm"}
                    whiteSpace={"normal"}
                    noOfLines={2}
                    w="250"
                  >
                    {cupom.nome}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize={"sm"}
                    whiteSpace={"normal"}
                    noOfLines={2}
                    w="250"
                  >
                    {cupom.quantidade}
                  </Text>
                </Td>
                <Td>
                  <Text
                    fontSize={"sm"}
                    whiteSpace={"normal"}
                    noOfLines={2}
                    w="250"
                  >
                    {cupom.pedidos_utilizados?.length}
                  </Text>
                </Td>
                <Td>
                  <Flex direction={"column"}>
                    <Text fontSize={"sm"}>
                      {Formatters.date(new Date(cupom.data_inicio))}
                    </Text>
                    <Text fontSize={"sm"}>
                      {Formatters.date(new Date(cupom.data_fim))}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  {/* <Button
                    onClick={() => {
                      removeOne(cupom);
                    }}
                  >
                    Excluir
                  </Button> */}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
