import { Flex, Text, Button, Image } from "@chakra-ui/react";

import { Tr, Td } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../../hooks/useAuthToken";
import PromocaoRepository from "../../../repositories/promocao_repository";
import Formatters from "../../../utils/formatters";

export default function ProdutosTr({ data, onClose }) {
  const navigate = useNavigate();
  const token = useAuthToken();

  const [desconto] = data.descontos;

  console.log(desconto);

  return (
    <>
      <Tr key={data._id}>
        <Td>
          <Text fontSize="xs">{data.nome}</Text>
        </Td>
        <Td>
          <Image src={data.imagem} w="90px" />
        </Td>
        <Td>
          {desconto.desconto_porcentagem > 0 && (
            <Text fontSize="xs" fontWeight={700}>
              {desconto.desconto_porcentagem}%
            </Text>
          )}

          {desconto.desconto_reais > 0 && (
            <Text fontSize="xs" fontWeight={700}>
              {Formatters.money(desconto.desconto_reais)}
            </Text>
          )}
        </Td>
        <Td>
          <Text fontSize="xs">{desconto.vendas}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">{desconto.cliques}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">{data.ativo ? "Ativo" : "Inativo"}</Text>
        </Td>

        <Td>
          <Flex direction="column">
            <Text fontSize="xs">
              {new Date(data.data_inicio.replace("Z", "")).toLocaleDateString()}
            </Text>
            <Text fontSize="xs">
              {new Date(data.data_fim.replace("Z", "")).toLocaleDateString()}
            </Text>
          </Flex>
        </Td>
        <Td>
          <Flex direction="column">
            <Button
              disabled={!data.ativo}
              variant={"customOutline"}
              onClick={() => {
                navigate(`/promocao/${data._id}`);
              }}
              size="sm"
              mb="0.5"
            >
              Editar
            </Button>
            {data.ativo && (
              <Button
                variant={"customOutline"}
                onClick={async () => {
                  onClose();
                }}
                size="sm"
                mt="0.5"
              >
                Encerrar
              </Button>
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
