import React from "react";
import {
  Flex,
  Box,
  Heading,
  Input,
  FormControl,
  Button,
  Text,
  Link,
  Image,
  Divider,
  CircularProgress,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";

import LoginBackground from "./../../assets/loginvendedordeshopnise.png";
import logo from "./../../assets/logo.png";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import RecuperarSenhaDialog from "../../components/RecuperarSenhaDialog";

export default function LoginPage() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();

  const [isPageReady, setIsPageReady] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  const [recoverySended, setRecoverySended] = useState(false);

  const onLoginCompleted = async (data) => {
    if (data?.user?.uid) window.location.href = "/";
  };

  useEffect(() => {
    // if (context.authState === "no_auth") {
    //   setIsPageReady(true);
    // } else if (context.authState === "auth_ok") {
    //   context.firebaseUser.getIdToken().then((idToken) => {
    //     // api.defaults.headers.common["token"] = idToken;
    //     navigate("/");
    //   });
    // }

    context.signOut("LoginPage");
  }, []);

  if (!isPageReady) {
    return <CircularProgress />;
  }

  return (
    <>
      <Flex direction="row" h="100vh" alignItems="center">
        <Flex
          w="20%"
          minW={["100%", "550px"]}
          maxW="700px"
          direction="column"
          px={["0", "5"]}
        >
          <Box p={8} minW="200px" w="full" h="full" alignItems="start">
            <Box mb="10" w="full">
              <Image
                src={logo}
                style={{ filter: "invert(1) grayscale(1)" }}
                maxWidth="200px"
                objectFit="contain"
                alt="Deshopnise logo"
                alignSelf="center"
                display="inline-flex"
              />
              <Text size="xl" fontWeight="medium">
                Seja bem vindo a <b>Central do Vendedor.</b>
                <br />
                Crie seus anúncios de forma fácil e prática.
              </Text>
            </Box>

            <Box>
              <form>
                <FormControl isRequired>
                  <Text fontWeight="medium">Email</Text>
                  <Input
                    variant={"filled"}
                    placeholder="Digite seu e-mail"
                    size="lg"
                    mb="2"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>

                <FormControl isRequired>
                  <Text fontWeight="medium">Senha</Text>
                  <InputGroup size="md">
                    <Input
                      variant={"filled"}
                      type={show ? "text" : "password"}
                      placeholder="Informe a senha"
                      size="lg"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement
                      pt="5px"
                      onClick={() => setShow(!show)}
                      children={show ? <MdVisibility /> : <MdVisibilityOff />}
                    />
                  </InputGroup>
                </FormControl>
                <Flex
                  mt="5"
                  direction="row"
                  fontSize="sm"
                  fontWeight="500"
                  align="center"
                  justifyContent="space-between"
                >
                  <Text textAlign="center" mr="2px">
                    Esqueceu a senha?{" "}
                  </Text>
                  <Link
                    color="primary.400"
                    href="#"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <Text decoration="underline" fontWeight="700">
                      Clique aqui
                    </Text>
                  </Link>
                </Flex>
                <Button
                  width="full"
                  colorScheme="purple"
                  backgroundColor={"primary.400"}
                  mt="2"
                  size="lg"
                  onClick={async () => {
                    setLoading(true);

                    const user = await context.signInEmailAndPassword({
                      email,
                      password,
                    });

                    setLoading(false);

                    onLoginCompleted(user);

                    // setTimeout(() => {
                    //   onLoginCompleted(user);
                    // }, 1500);
                  }}
                >
                  {loading ? <CircularProgress isIndeterminate /> : "Entrar"}
                </Button>
                <Button
                  width="full"
                  colorScheme="purple"
                  backgroundColor={"#009E45"}
                  mt="2"
                  size="lg"
                  onClick={async () => {
                    window.location.href = "/cadastro";
                  }}
                >
                  Cadastre-se
                </Button>

                {/* <Flex align="center">
                <Divider />
                <Text padding="2">ou</Text>
                <Divider />
              </Flex>
              <Button
                width="full"
                colorScheme="purple"
                variant="outline"
                size="lg"
                onClick={() => navigate("/cadastro")}
              >
                Cadastre-se
              </Button> */}
              </form>
            </Box>
          </Box>
        </Flex>
        <Image
          display={{ base: "none", md: "block" }}
          w="80%"
          h="full"
          fit="cover"
          src={LoginBackground}
        />
      </Flex>
      <RecuperarSenhaDialog
        isOpen={open}
        onClose={() => setOpen(false)}
        send={recoverySended}
        onSend={(e) => {
          context.sendResetPasswordMail({ email: e }).then((v) => {
            if (v) setRecoverySended(true);
          });
        }}
      />
    </>
  );
}
