import { Box, Flex, Heading, Image, Switch, Text } from "@chakra-ui/react";
import {
  MdChevronRight,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdLocalShipping,
} from "react-icons/md";
import PageScaffold from "../../components/PageScaffold";
import { Link, useNavigate } from "react-router-dom";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import { useEffect, useState, useContext } from "react";
import EnvioRepository from "../../repositories/envio_repository";
import useAuthToken from "../../hooks/useAuthToken";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import { AuthContext } from "../../contexts/authContext";
import enviosImg from "../../assets/envios.svg";
import jadLogImg from "../../assets/jadlog.png";

export default function ConfiguracoesEnviosPage() {
  const navigate = useNavigate();
  const loja = useEstabelecimento();
  const token = useAuthToken();
  const authContext = useContext(AuthContext);

  const [transportadoras, setTransportadoras] = useState([]);
  const [metodos, setMetodos] = useState([]);

  useEffect(() => {
    const repo = new EnvioRepository({ token });

    repo.consultarTransportadoras().then((res) => {
      if (res.status === 200) {
        setTransportadoras(res.data);
      }
    });

    setMetodos(loja.metodos_envio);
  }, []);

  return (
    <PageScaffold showSideBar={true}>
      <Box
        paddingTop={"12px"}
        paddingBottom={"4px"}
        fontWeight={"700"}
        fontSize={"22px"}
      >
        {/* <span>Meus Envios</span> */}
        <Flex direction="row" my="5">
          <Link to="/meus-envios">
            <Box
              px="5"
              py="2"
              borderRadius="32"
              backgroundColor="transparent"
              mr="30px"
              color="grey"
              fontSize="lg"
            >
              <Text>Meus Envios</Text>
            </Box>
          </Link>
          <Link to="/envio-em-massa">
            <Box
              px="5"
              py="2"
              borderRadius="32"
              backgroundColor="transparent"
              mr="30px"
              color="grey"
              fontSize="lg"
            >
              <Text>Enviar em massa</Text>
            </Box>
          </Link>
          <Link to="/config-de-envio">
            <Box
              px="5"
              py="2"
              borderRadius="32"
              backgroundColor="primary.400"
              mr="30px"
              color="white"
              fontSize="lg"
            >
              <Text>Configurações</Text>
            </Box>
          </Link>
        </Flex>
      </Box>
      <Flex w="full" direction="column" pb="0" marginTop={"5px"}>
        <Box
          boxShadow="sm"
          w="full"
          borderRadius="8px"
          p="5"
          bgColor="white"
          pl="40px"
        >
          <Flex direction="row" align="start">
            <Image src={enviosImg} boxSize="60px" />
            <Flex direction="column" ml="40px">
              <Heading fontSize="xl">Logística</Heading>
              <Text
                fontSize="xs"
                pt={"10px"}
                noOfLines="4"
                fontWeight="500"
                w="650px"
              >
                Aproveite nossa logística rápida e confiável, com parceiros de
                envio na nossa plataforma. Lembre-se de ter uma impressora para
                imprimir as etiquetas de envio.
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column" mt="5" ml="100px">
            <Text fontWeight="semibold" fontSize="lg">
              Habilite as opções disponíveis na sua região
            </Text>
            <Box
              borderRadius="8px"
              p="5"
              mt="5"
              bgColor="#F5F5F5"
              w="max-content"
            >
              <Flex direction="row" align="center">
                <Text fontSize="md" fontWeight={600}>
                  {loja.endereco &&
                    loja.endereco.rua +
                      ", " +
                      loja.endereco.numero +
                      ", " +
                      loja.endereco.bairro +
                      ", " +
                      loja.endereco.cidade +
                      " - " +
                      loja.endereco.estado +
                      " - " +
                      loja.endereco.cep}
                </Text>
                <Text
                  fontSize="xs"
                  ml="2"
                  color="primary.400"
                  cursor="pointer"
                  fontWeight="700"
                  onClick={() => navigate("/meus-enderecos")}
                >
                  Trocar endereço
                </Text>
                <MdChevronRight color="#693FF3" />
              </Flex>
            </Box>

            {transportadoras
              .filter((t) => t.delivery_method_type !== "RETURN")
              .reduce((acc, cur) => {
                if (cur.length === 0) {
                  return [acc];
                }

                const exists = acc.find(
                  (a) => a.logistics_provider_id === cur.logistics_provider_id
                );

                if (!exists) {
                  acc.push(cur);
                }

                return acc;
              }, [])
              .map((transportadora) => {
                return (
                  <Box
                    boxShadow="sm"
                    w="full"
                    borderRadius="8px"
                    p="5"
                    mt="5"
                    bgColor="#F5F5F5"
                    fontWeight={500}
                    lineHeight={2.5}
                  >
                    <Flex
                      direction="row"
                      w="full"
                      justifyContent="space-between"
                      mb="5"
                    >
                      <Flex
                        direction="row"
                        w="260px"
                        justifyContent="space-between"
                        align="center"
                      >
                        <Switch
                          isChecked={metodos.includes(
                            transportadora.logistics_provider_id
                          )}
                          onChange={(e) => {
                            let met = [];

                            if (e.target.checked) {
                              met = [
                                ...metodos,
                                transportadora.logistics_provider_id,
                              ];
                            } else {
                              met = metodos.filter(
                                (m) =>
                                  m !== transportadora.logistics_provider_id
                              );
                            }

                            setMetodos(met);

                            const repo = new VendedoresRepository({ token });

                            repo
                              .alterar({
                                ...loja,
                                metodos_envio: met,
                              })
                              .then((res) => {
                                authContext.loadEstabelecimento(
                                  loja.firebase_id
                                );
                              });
                          }}
                        />
                        <Image
                          w={"80px"}
                          h={"30px"}
                          objectFit={"contain"}
                          src={
                            transportadora.logistics_provider_name ===
                            "Correios"
                              ? "https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Correios_%282014%29.svg/2560px-Correios_%282014%29.svg.png"
                              : jadLogImg
                          }
                          mx="5"
                        />
                        <Text
                          noOfLines="2"
                          color="grey"
                          fontSize="xs"
                          fontWeight="semibold"
                          lineHeight="1"
                        >
                          {transportadora.logistics_provider_name === "Correios"
                            ? "para envios de até 30KG"
                            : "para envios de até 120KG "}
                        </Text>
                      </Flex>
                      <MdKeyboardArrowUp />
                    </Flex>
                    <Text fontSize="xs">
                      Ativa esta opção para oferecer envios pelos{" "}
                      {transportadora.logistics_provider_name}. Entenda como
                      você pode enviar seus pedidos usando este canal de envio.
                    </Text>
                    <Text fontSize="xs">
                      1. Certifique-se de ter um endereço válido cadastrado.
                    </Text>
                    <Text fontSize="xs">
                      2. Os pesos dos produtos cadastrados serão usados para
                      calcular a estimativa de taxa de frete.
                    </Text>
                    <Text fontSize="xs">
                      3. Peso e tamanho máx dos pacotes:{" "}
                      {transportadora.logistics_provider_name === "Correios"
                        ? "30KG ou 70 cm"
                        : "120KG ou 80x80x80"}{" "}
                      nas dimensões.{" "}
                      <a
                        style={{
                          color: "#693FF3",
                          textDecoration: "underline",
                        }}
                        href={
                          transportadora.logistics_provider_name === "Correios"
                            ? "https://www.correios.com.br/enviar/encomendas/saiba-mais-nacional"
                            : "https://www.central.deshopnise.com.br/dimensoes-maxima-jadlog"
                        }
                        target="_blank"
                      >
                        Mais informações
                      </a>
                    </Text>
                    <Text fontSize="xs" mt="2">
                      Restrições:
                      <br />
                      Peso máximo{" "}
                      {transportadora.logistics_provider_name === "Correios"
                        ? "30KG"
                        : "120KG "}
                    </Text>
                  </Box>
                );
              })}

            {/* <Box
              boxShadow="sm"
              w="full"
              borderRadius="8px"
              p="5"
              mt="5"
              bgColor="#F5F5F5"
            >
              <Flex
                direction="row"
                w="full"
                justifyContent="space-between"
                mb="5"
              >
                <Flex
                  direction="row"
                  w="260px"
                  justifyContent="space-between"
                  align="center"
                >
                  <Switch />
                  <Image
                    w={"80px"}
                    h={"30px"}
                    objectFit={"contain"}
                    src={"https://www.aviacaocomercial.net/azulcargologo2.png"}
                    mx="5"
                  />
                  <Text
                    noOfLines="2"
                    color="grey"
                    fontSize="xs"
                    fontWeight="semibold"
                    lineHeight="1"
                  >
                    para envios de até 60KG
                  </Text>
                </Flex>
                <MdKeyboardArrowDown />
              </Flex>
            </Box> */}
          </Flex>
        </Box>
      </Flex>
    </PageScaffold>
  );
}
