import {
  Box,
  Text,
  Flex,
  Button,
  Input,
  FormControl,
  useToast,
  Grid,
  Select,
} from "@chakra-ui/react";

import OthersRepository from "../../../../repositories/others_repository";
import useAuthToken from "../../../../hooks/useAuthToken";
import VendedoresRepository from "../../../../repositories/vendedores_repositories";
import { useEffect, useRef, useState } from "react";
import useEstabelecimento from "../../../../hooks/useEstabelecimento";
import { Spinner } from "@chakra-ui/react";

const opcoes = [
  "Itaú",
  "Bradesco",
  "Caixa Econômica",
  "Banco do Brasil",
  "Santander",
  "Banrisul",
  "Sicredi",
  "Sicoob",
  "Inter",
  "BRB",
  "Via Credi/Civia Cooperativa",
  "Neon",
  "Votorantim",
  "Banco Letsbank S.A",
  "Nubank",
  "Pagseguro",
  "Banco Original",
  "Safra",
  "Modal",
  "Banestes",
  "Unicred",
  "Money Plus",
  "Mercantil do Brasil",
  "JP Morgan",
  "Gerencianet Pagamentos do Brasil",
  "Banco C6",
  "BS2",
  "Banco Topazio",
  "Uniprime",
  "Stone",
  "Banco Daycoval",
  "Rendimento",
  "Banco do Nordeste",
  "Citibank",
  "PJBank",
  "Cooperativa Central de Credito Noroeste Brasileiro",
  "Uniprime Norte do Paraná",
  "Global SCM",
  "Next",
  "Cora",
  "Mercado Pago",
  "Banco da Amazonia",
  "BNP Paribas Brasil",
  "Juno",
  "Cresol",
  "BRL Trust DTVM",
  "Banco Banese",
  "Banco BTG Pactual",
  "Banco Omni",
  "Acesso Soluções de Pagamento",
  "CCR de São Miguel do Oeste",
  "Polocred",
  "Ótimo",
  "Banco Ribeirão Preto",
  "ASAAS IP",
  "Banco Pan",
  "Neon",
  "VORTX DTVM LTDA",
  "Banco BMG",
  "Pefisa",
  "J17 - SCD S/A",
  "Credisan",
  "Pinbank",
  "XP Investimentos",
  "Crefisa",
  "Singulare",
  "SUMUP SCD S.A.",
  "Banco ABC Brasil",
  "BCO LETSBANK S.A.",
  "HR Digital Sociedade de Crédito Direto S.A",
];

export default function CarteiraComponent({ data, onCompleted }) {
  const estabelecimento = useEstabelecimento();
  const token = useAuthToken();

  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const nomeCompletoRef = useRef();
  const documentoRef = useRef();
  const nomeBancoRef = useRef();
  const numeroAgenciaRef = useRef();
  const numeroContaRef = useRef();
  const nomeResp = useRef(null);
  const cpfResp = useRef(null);

  useEffect(() => {
    if (data?.conta_bancaria_valida) {
      const vendedorRepo = new VendedoresRepository({ token });

      vendedorRepo.consultarContaBancaria().then((response) => {
        if (response.status === 200) {
          nomeCompletoRef.current.value = response.data["nome_legal"];
          documentoRef.current.value = response.data["documento"];
          nomeBancoRef.current.value = response.data["banco"];
          numeroAgenciaRef.current.value = response.data["agencia"];
          numeroContaRef.current.value = response.data["conta"];
          nomeResp.current.value = response.data["nome_responsavel"];
          cpfResp.current.value = response.data["cpf_responsavel"];
        }
      });
    }
  }, []);

  const onSubmit = () => {
    const isAnyEmpty = [
      nomeCompletoRef.current?.value,
      documentoRef.current?.value,
      nomeBancoRef.current?.value,
      numeroAgenciaRef.current?.value,
      numeroContaRef.current?.value,
      cpfResp.current?.value ?? documentoRef.current?.value,
      nomeResp.current?.value ?? nomeCompletoRef.current?.value,
    ].some((value) => !value);

    if (isAnyEmpty) {
      toast({
        title: "Informe o seus dados",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const data = {
      nome_legal: nomeCompletoRef.current.value,
      documento: documentoRef.current.value,
      banco: nomeBancoRef.current.value,
      agencia: numeroAgenciaRef.current.value,
      conta: numeroContaRef.current.value,
      cpf_responsavel: cpfResp.current?.value ?? documentoRef.current.value,
      nome_responsavel:
        nomeResp.current?.value ?? nomeCompletoRef.current.value,
    };

    const repo = new VendedoresRepository({ token });

    setLoading(true);

    repo
      .atualizarContaBancaria(data)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Dados atualizados",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCompleted(data);
        } else if (
          response.status === 400 &&
          response.data["error"] === `Error: \"conta já verificada\"`
        ) {
          toast({
            title: "Dados atualizados",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCompleted(data);
        } else {
          toast({
            title: response.data["error"],
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        toast({
          title: "Ocorreu um erro",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Box
      style={{
        zoom: "1.1",
      }}
      width="full"
      my="5"
      mt="20"
      align={"center"}
    >
      <Box w={"450px"} align="start">
        <Text
          fontSize={"22px"}
          fontWeight={"700"}
          w={"400px"}
          mb="2"
          textAlign={"start"}
        >
          Dados de Conta Bancária
        </Text>
      </Box>
      <Box w={"450px"} align="start">
        <Text
          fontSize={"14px"}
          fontWeight={"500"}
          w={"400px"}
          mb="2"
          textAlign={"start"}
        >
          <b>Atenção:</b> A conta bancária deve estar registrada sob o mesmo CPF
          ou CNPJ utilizado no cadastro de vendedor.
        </Text>
      </Box>
      <Flex
        justifyContent={"center"}
        w={"450px"}
        align="center"
        direction="column"
      >
        <Input
          placeholder="Nome completo"
          variant="filled"
          fontSize={"sm"}
          mt="2"
          ref={nomeCompletoRef}
          _placeholder={{
            fontWeight: "500",
          }}
          fontWeight={"500"}
          size={"lg"}
        />
        <Input
          placeholder={
            estabelecimento.tipo_cadastro === "pessoa_fisica" ? "CPF" : "CNPJ"
          }
          variant="filled"
          fontSize={"sm"}
          mt="2"
          ref={documentoRef}
          _placeholder={{
            fontWeight: "500",
          }}
          fontWeight={"500"}
          size={"lg"}
        />

        <Select
          placeholder="Selecione o banco"
          variant="filled"
          fontSize={"sm"}
          mt="2"
          ref={nomeBancoRef}
          _placeholder={{
            fontWeight: "500",
          }}
          fontWeight={"500"}
          size={"lg"}
        >
          {opcoes.map((opcao) => (
            <option value={opcao}>{opcao}</option>
          ))}
        </Select>
        <Flex w={"450px"} mt={"8px"} mb={"5px"}>
          <Input
            placeholder="Número da Agência"
            variant="filled"
            ref={numeroAgenciaRef}
            fontSize={"sm"}
            size="lg"
            mr={"4px"}
            _placeholder={{
              fontWeight: "500",
            }}
            fontWeight={"500"}
          />
          <Input
            placeholder="Número da Conta"
            variant="filled"
            ref={numeroContaRef}
            fontSize={"sm"}
            size="lg"
            ml={"4px"}
            _placeholder={{
              fontWeight: "500",
            }}
            fontWeight={"500"}
          />
        </Flex>
        {estabelecimento.tipo_cadastro !== "pessoa_fisica" && (
          <Flex w={"450px"}>
            <Input
              placeholder="Nome do Responsável"
              variant="filled"
              ref={nomeResp}
              fontSize={"sm"}
              size="lg"
              mr={"4px"}
              _placeholder={{
                fontWeight: "500",
              }}
              fontWeight={"500"}
            />
            <Input
              placeholder="CPF do Responsável"
              variant="filled"
              ref={cpfResp}
              fontSize={"sm"}
              size="lg"
              ml={"4px"}
              _placeholder={{
                fontWeight: "500",
              }}
              fontWeight={"500"}
            />
          </Flex>
        )}
      </Flex>

      <Button
        onClick={loading ? null : onSubmit}
        bg={"#683FF3"}
        color={"white"}
        mt={"2"}
        w={"450px"}
        borderRadius={"8px"}
        h={"45px"}
        fontWeight={"700"}
        _hover={{
          bg: "#4C378F",
        }}
      >
        {loading ? <Spinner /> : "CONFIRMAR"}
      </Button>
    </Box>
  );
}
