import { ArrowForwardIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Flex,
  Select,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Image,
  chakra,
  Stack,
  Button,
  Spacer,
} from "@chakra-ui/react";
import CampanhasSelectorDialog from "../../../components/CampanhasSelectorDialog";
import { useState } from "react";
import { useEffect } from "react";

const CampanhaPickerList = ({ onPick = () => {} }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pickedCampanhas, setPickedCampanhas] = useState({
    campanha: null,
    items: [],
  });

  return (
    <Flex
      maxW={"90vw"}
      margin={"0 auto"}
      backgroundColor={"white"}
      borderRadius={"6px"}
      shadow={"md"}
      marginTop={"40px"}
      direction={"column"}
      color={"black"}
    >
      <CampanhasSelectorDialog
        isOpen={isDialogOpen}
        blockProducts={[]}
        currentProducts={[]}
        onSelect={(p) => {
          setIsDialogOpen(false);
          setPickedCampanhas(p);
          onPick(p);
        }}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
      <Flex direction={"row"} padding={"30px"}>
        <Flex direction={"column"}>
          <Text fontWeight={"bold"} fontSize={"20px"}>
            Selecione a campanha
          </Text>
          <Box height={"2px"} />
          <Text fontWeight={"500"} fontSize={"14px"}>
            Selecione uma campanha para analisar os métricas abaixo
          </Text>
        </Flex>
        <Spacer />

        <Button
          width={"240px"}
          marginLeft={"15px"}
          backgroundColor="primary.400"
          color={"white"}
          _hover={{ opacity: "0.8" }}
          onClick={() => {
            setIsDialogOpen(true);
          }}
        >
          Selecionar Campanha
          <Spacer />
          <ChevronRightIcon />
        </Button>
      </Flex>
      <TableContainer color={"black"} padding={"10px"}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Nome
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Tipo de Campanha
              </Th>
              {/* <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Nome da promoção
              </Th> */}
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Status
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Periodo
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {pickedCampanhas.campanha && (
              <>
                {pickedCampanhas.items.map((campanha) => (
                  <Tr key={campanha.id}>
                    {/* <Td>
                      <Flex direction={"row"}>
                        <Image
                          src={
                            "https://a-static.mlcdn.com.br/1500x1500/smartphone-samsung-galaxy-s22-ultra-256gb-preto-5g-12gb-ram-68-cam-quadrupla-snapdragon/magazineluiza/234440600/caaba74677c519fff3e87a0c4ac8ef68.jpg"
                          }
                          width={"80px"}
                          height={"80px"}
                        />
                      </Flex>
                    </Td>
                    <Td fontSize={"14px"} fontWeight={"bold"}>
                      <Image
                        src="https://cdn.awsli.com.br/1000x1000/959/959474/produto/151085743bae4a51ba4.jpg"
                        width={"80px"}
                        height={"80px"}
                      />
                    </Td> */}
                    <Td>
                      <Text
                        fontSize={"13px"}
                        fontWeight={"bold"}
                        maxW={"320px"}
                      >
                        {campanha.nome}
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        fontSize={"13px"}
                        fontWeight={"bold"}
                        maxW={"320px"}
                      >
                        {campanha.tipo}
                      </Text>
                    </Td>
                    <Td>
                      <Text
                        color={"#00AA81"}
                        fontSize={"13px"}
                        fontWeight={"bold"}
                        maxW={"320px"}
                      >
                        {campanha.status}
                      </Text>
                    </Td>
                    <Td>
                      <Flex direction={"column"}>
                        <Text
                          fontSize={"13px"}
                          fontWeight={"bold"}
                          maxW={"320px"}
                        >
                          {new Date(campanha.data_inicio).toLocaleDateString()}
                        </Text>
                        <Text
                          fontSize={"13px"}
                          fontWeight={"bold"}
                          maxW={"320px"}
                        >
                          {new Date(campanha.data_fim).toLocaleDateString()}
                        </Text>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </>
            )}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default CampanhaPickerList;
