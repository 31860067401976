import React from "react";

import { Box, Flex, Text, Avatar, Divider } from "@chakra-ui/react";
import { MdChat } from "react-icons/md";

function OrderResume({ data, onOpenChat }) {
  return (
    <Box
      p={"20px"}
      boxShadow="sm"
      w="full"
      borderRadius={"10px"}
      marginTop={"5px"}
      height={"110px"}
      backgroundColor={"white"}
    >
      <Flex dir="row" alignItems={"center"} justifyContent={"space-around"}>
        <Avatar
          margin={"0px 20px"}
          size="md"
          name={data.usuario_nome}
          src={data.usuario_imagem}
        />

        <Text fontSize={"22px"} fontWeight={"700"}>
          {data.usuario_nome}
        </Text>

        <Box margin={"0px 35px"}>
          <MdChat
            size={"25px"}
            onClick={() => {
              onOpenChat();
            }}
          />
        </Box>

        <Divider
          orientation="vertical"
          height={"60px"}
          width={"4px"}
          borderLeft={"1px solid black"}
          opacity={"0.2"}
        />

        <Flex direction={"column"} margin={"0px 35px"}>
          <Text fontSize={"17px"} fontWeight={"700"}>
            ID do pedido
          </Text>
          <Text fontSize={"17px"} fontWeight={"700"}>
            {data.numero_pedido}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default OrderResume;
