import { Flex, Box, Text } from "@chakra-ui/react";

const PageHeader = ({ title, subtitle }) => (
  <Box
    paddingTop={"32px"}
    paddingBottom={"24px"}
    fontWeight={"700"}
    fontSize={"22px"}
  >
    <Flex direction="column">
      <span>{title}</span>

      <Text fontSize="14px" fontWeight={500}>
        {subtitle}
      </Text>
    </Flex>
  </Box>
);

export default PageHeader;
