import { Box, Flex, Text, Image } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import Formatters from "../../../utils/formatters";
import { Link } from "react-router-dom";

function TransacaoListTile({ data }) {
  const statusStrings = {
    pendente: "Pagamento a receber",
    concluido: "Pagamento recebido",
    cancelado: "Pagamento cancelado",
    disponivel: "Pagamento disponível",
  };

  if (!data.pedido) {
    return (
      <>
        <Tr>
          <Td w="170px">
            <Text fontSize={"sm"}>
              {new Date(data.createdAt).toLocaleDateString()}
            </Text>
          </Td>
          <Td w="480px">
            <Flex direction={"column"}>
              <Flex maxW={"400px"}>
                <Box w={"40px"} />
                <Flex direction={"column"}>
                  <Text fontSize={"sm"} whiteSpace={"normal"} noOfLines={2}>
                    {data.descricao}
                  </Text>

                  <Flex pt={"5px"} direction={"row"} fontSize={"12px"}></Flex>
                  {/* 
                {Object.keys(item.propriedades).map((key) => {
                  return (
                    <>
                      <Text key={key} fontSize={"10px"} lineHeight={"11px"}>
                        {key.toUpperCase()}:
                      </Text>
                      <Text fontSize={"12px"} color={"primary.500"}>
                        {item.propriedades[key]}
                      </Text>
                    </>
                  );
                })} */}
                </Flex>
              </Flex>
            </Flex>
          </Td>

          <Td w="350px">
            <Flex direction={"column"}>
              <Flex maxW={"350px"}>
                <Text fontSize={"sm"} whiteSpace={"normal"} noOfLines={2}>
                  {new Date(data.data_prevista) > new Date()
                    ? "Processado"
                    : "Processando"}
                </Text>
              </Flex>
            </Flex>
          </Td>

          <Td maxW={"200px"}>
            <Text fontSize={"12px"}>Saída</Text>
            <Text
              fontSize={"14px"}
              whiteSpace={"normal"}
              noOfLines={5}
              fontWeight={"700"}
            >
              - {Formatters.money(data.amount / 100)}
            </Text>
          </Td>
        </Tr>
      </>
    );
  }

  const [item] = data.pedido.itens;
  const evento = data.pedido.eventos.at(-1);

  return (
    <>
      <Tr>
        <Td w="170px">
          <Text fontSize={"sm"}>
            {new Date(data.createdAt).toLocaleDateString()}
          </Text>
        </Td>
        <Td w="480px">
          <Link to={`/pedido/${data.pedido_id}`}>
            <Flex direction={"column"}>
              <Flex maxW={"400px"}>
                <Image
                  w={"80px"}
                  objectFit={"contain"}
                  src={item.produto_img}
                />
                <Box w={"40px"} />
                <Flex direction={"column"}>
                  <Text fontSize={"sm"} whiteSpace={"normal"} noOfLines={2}>
                    {item.produto_nome}
                  </Text>

                  <Flex pt={"5px"} direction={"row"} fontSize={"12px"}>
                    <Text whiteSpace={"normal"} noOfLines={2}>
                      VARIANTE:{" "}
                    </Text>
                    <Box w={"5px"} />
                    <Text color={"primary.500"}>{item.sku}</Text>
                  </Flex>
                  {/* 
                {Object.keys(item.propriedades).map((key) => {
                  return (
                    <>
                      <Text key={key} fontSize={"10px"} lineHeight={"11px"}>
                        {key.toUpperCase()}:
                      </Text>
                      <Text fontSize={"12px"} color={"primary.500"}>
                        {item.propriedades[key]}
                      </Text>
                    </>
                  );
                })} */}
                </Flex>
              </Flex>
            </Flex>
          </Link>
        </Td>

        <Td w="350px">
          <Flex direction={"column"}>
            <Flex maxW={"350px"}>
              <Text fontSize={"sm"} whiteSpace={"normal"} noOfLines={2}>
                {evento.titulo}
              </Text>
            </Flex>
          </Flex>
        </Td>

        <Td maxW={"200px"}>
          <Text fontSize={"12px"}>{statusStrings[data.status]}</Text>
          <Text
            fontSize={"14px"}
            whiteSpace={"normal"}
            noOfLines={5}
            fontWeight={"700"}
          >
            {Formatters.money(data.amount / 100)}
          </Text>
        </Td>
      </Tr>
    </>
  );
}

export default function TransacoesList({ data }) {
  if (!Array.isArray(data)) {
    return <></>;
  }

  return (
    <Flex direction={"column"}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Data</Th>
              <Th>Produto/Serviço</Th>
              <Th>Status</Th>
              <Th>Valor</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {data.map((e) => (
              <TransacaoListTile key={e._id} data={e} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
