import {
  Avatar,
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Input,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
import React from "react";
import {
  MdChevronRight,
  MdExpand,
  MdFlashOn,
  MdImportContacts,
  MdLightbulb,
} from "react-icons/md";
import PageScaffold from "../../components/PageScaffold";
import BoxAvisosDeshopnise from "./components/BoxAvisosDeshopnise";
import Chat from "../../components/Chat";
import useAuthToken from "../../hooks/useAuthToken";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import { useEffect } from "react";

import VideoPopUp from "./components/VideoPopUp";
import pendentesImg from "../../assets/dashboard/pendentes.png";
import canceladosImg from "../../assets/dashboard/cancelados.png";
import devolucaoImg from "../../assets/dashboard/devolucao.png";
import esgotadosImg from "../../assets/dashboard/esgotados.png";
import pedidoImg from "../../assets/dashboard/pedidos.png";
import banidoImg from "../../assets/dashboard/banidos.png";
import atencaoImg from "../../assets/atencao.svg";
import Cadastro from "../Cadastro";
import { AuthContext } from "../../contexts/authContext";
import { useContext } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import { es } from "date-fns/locale";
import LampComponent from "./components/Lamp";

const denunciasLabels = {
  falsificacao: "Falsificações e direitos autorais",
  item_proibido: "Itens proibidos (Banidos / impórprios)",
  violacao_politicas:
    " Violação das políticas de listagem(ex: Palavras-chaves inadequadas, links externos e similares.",
  item_ofensivo: "Itens ofensivos e/ou potencialmente ofensivos",
  listagem_fraudulenta:
    "Listagens fraudulentas (demandas de vendedores ilegais, etc)",
  propriedade_furtada: "Propriedade furtada",
  outros: "Outros",
};

function StatusTile({ icon, text, value, href }) {
  return (
    <Link to={href}>
      <GridItem>
        <Box
          shadow={"sm"}
          h="20"
          bgColor="white"
          padding={"0px 12px"}
          paddingLeft="20px"
          margin={"0 auto"}
          borderRadius="8"
          alignItems="center"
          cursor="pointer"
        >
          <Flex direction="row" h="100%" align="center">
            {icon}
            <Flex direction="column" ml="5">
              <Text fontWeight="bold" fontSize={"18px"} color={"primary.300"}>
                {value}
              </Text>
              <Text fontSize="12px" fontWeight={500}>
                {text}
              </Text>
            </Flex>
          </Flex>
        </Box>
      </GridItem>
    </Link>
  );
}

function TabItem(children, onClick) {
  return (
    <Tab
      _selected={{
        color: "#693FF3",
        borderBottom: "3px solid #693FF3",
      }}
      onClick={onClick}
      fontWeight={600}
      color={"#777777"}
      padding={"12px 15px"}
      fontSize="xs"
    >
      {children}
    </Tab>
  );
}

export default function HomePage() {
  const token = useAuthToken();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const repo = new VendedoresRepository({ token });
  const { hasPhone } = useContext(AuthContext);
  const estabelecimento = useEstabelecimento();
  const { strikes } = estabelecimento;
  const navigate = useNavigate();

  const [data, setData] = React.useState(null);
  const [strike, setStrike] = React.useState(null);

  useEffect(() => {
    repo.consultarDashboard().then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    });

    strikes.forEach((s) => {
      if (new Date(s.bloqueado_ate) > new Date()) {
        setStrike(s);
      }
    });

    if (localStorage.getItem("videoPopUp") !== "true") {
      onOpen();
      localStorage.setItem("videoPopUp", "true");
    }
  }, []);

  // if (!hasPhone) {
  //   return <Cadastro etapaIndex={5} />;
  // }

  if (estabelecimento.status === "bloqueado") {
    return (
      <PageScaffold showSideBar={true} onOpenVideo={onOpen}>
        <Center h={"90vh"}>
          <Text fontWeight={"600"}>Sua conta foi bloqueada</Text>
        </Center>
      </PageScaffold>
    );
  }

  return (
    <PageScaffold showSideBar={true} onOpenVideo={onOpen}>
      <VideoPopUp isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      <Flex
        mW={"1440px"}
        margin={"0 0"}
        direction={"row"}
        justify="space-between"
      >
        <Box
          boxShadow="sm"
          width={"90%"}
          minHeight={"90vh"}
          borderRadius={"8px"}
          marginTop={"5px"}
          mr="5"
          my="7px"
        >
          {strike && (
            <Box
              shadow={"sm"}
              boxShadow="sm"
              w="full"
              my="7px"
              borderRadius="8px"
              px="5"
              py="4"
              bgColor="white"
              h="fit-content"
              backgroundColor={"red"}
              color={"white"}
            >
              <Flex align="center">
                <Avatar
                  name="!"
                  size="xs"
                  mr="4"
                  backgroundColor={"white"}
                  color="black"
                  fontSize={"24px"}
                  fontWeight={"bold"}
                />
                <Flex direction={"column"} lineHeight="15px">
                  <b>
                    <Text fontSize="15px">
                      Aviso de Strike {strikes.length}/3
                    </Text>
                  </b>
                  <Text fontSize="14px" fontWeight="medium" pt={"2px"}>
                    Seu perfil foi restrito até {"  "}
                    <b>
                      {new Date(strike.bloqueado_ate).toLocaleDateString()}
                      {". "}
                    </b>
                    Motivo:{" "}
                    <b>"{denunciasLabels[strike.motivo ?? "outros"]}"</b>.
                  </Text>
                </Flex>
              </Flex>
            </Box>
          )}

          {!estabelecimento?.pre_cadastro && (
            <Box
              shadow={"sm"}
              boxShadow="sm"
              w="full"
              my="7px"
              borderRadius="8px"
              px="5"
              py="4"
              bgColor="white"
              h="fit-content"
            >
              <Flex align="center">
                <Avatar name="Aguardando" size="xs" mr="4" src={atencaoImg} />
                <Flex direction={"column"} lineHeight="15px">
                  <b>
                    <Text fontSize="15px">Atenção</Text>
                  </b>
                  <Text fontSize="14px" fontWeight="medium" pt={"2px"}>
                    Entre na central do vendedor e veja as novidades que
                    lançamos para vocês.
                  </Text>
                </Flex>
              </Flex>
            </Box>
          )}
          {estabelecimento?.pre_cadastro && (
            <Box
              onClick={() => {
                navigate("/completar-cadastro");
              }}
              shadow={"sm"}
              boxShadow="sm"
              w="full"
              my="7px"
              borderRadius="8px"
              px="5"
              py="4"
              bgColor="#7C5EFA"
              color={"white"}
              h="fit-content"
              className="pulse"
              cursor={"pointer"}
            >
              <Flex align="center">
                {/* <MdLightbulb color="white" size={"35px"} /> */}
                <LampComponent />
                <Flex direction={"column"} lineHeight="15px" ml={"4"}>
                  <b>
                    <Text fontSize="15px">Vamos validar o seu cadastro?</Text>
                  </b>
                  <Text fontSize="14px" fontWeight="medium" pt={"2px"}>
                    falta pouco para começar a criar seus primeiros anúncios!
                    Clique aqui e preencha as informações da sua loja para
                    começar.
                  </Text>
                </Flex>
                <Spacer />
                <MdChevronRight color="white" size={"35px"} />
              </Flex>
            </Box>
          )}

          <Grid templateColumns="repeat(4, 1fr)" gap="3" mt="4" mb="4">
            <StatusTile
              icon={<Image src={pedidoImg} w={"35px"} />}
              text={"Pedidos"}
              value={data?.pedidos ?? 0}
              href={"/meus-envios"}
            />

            <StatusTile
              icon={<Image src={pendentesImg} w={"35px"} />}
              text={"Envios Pendentes"}
              value={data?.envios_pendentes ?? 0}
              href={"/meus-envios?target=a_enviar"}
            />

            <StatusTile
              icon={<Image src={canceladosImg} w={"35px"} />}
              text={"Pedidos Cancelados"}
              value={data?.cancelados ?? 0}
              href={"/meus-envios?target=cancelados"}
            />

            <StatusTile
              icon={<Image src={devolucaoImg} w={"35px"} />}
              text={"Devoluções / Reembolso"}
              value={data?.devolucoes ?? 0}
              href={"/meus-envios?target=devolucoes"}
            />

            <StatusTile
              icon={<Image src={banidoImg} w={"35px"} />}
              text={"Produtos denunciados"}
              value={data?.banidos ?? 0}
              href={"/meus-produtos?target=denunciados"}
            />

            <StatusTile
              icon={<Image src={esgotadosImg} w={"35px"} />}
              text={"Produtos esgotados"}
              value={data?.esgotados ?? 0}
              href={"/meus-produtos?target=esgotados"}
            />
          </Grid>
          <Box
            shadow={"sm"}
            boxShadow="sm"
            w="full"
            mt="5px"
            borderRadius="8px"
            py="2"
            bgColor="white"
            h="fit-content"
          >
            <Flex align="center" px="5" pt={"20px"} justify="space-between">
              <Flex direction={"column"} lineHeight="20px">
                <b>
                  <Text>Informações Gerais</Text>
                </b>
                <Text fontSize="xs" fontWeight="semibold">
                  Uma visão geral dos dados da loja para a medida de pedidos
                  pagos..
                </Text>
              </Flex>
              {/* <Input
                fontWeight={500}
                size="sm"
                type={"date"}
                maxW="150px"
                onChange={() => {}}
              /> */}
            </Flex>
            <Tabs>
              {/* <Flex
                direction="row"
                align="center"
                justify="space-between"
                pl="25px"
                pr="5"
              >
                <Flex direction="row" align="center">
                  <Text fontWeight="semibold" fontSize="xs">
                    Total
                  </Text>
                  <TabList>
                    {TabItem("Vendas")}
                    {TabItem("Pedidos")}
                    {TabItem("Visitas do Perfil")}
                  </TabList>
                </Flex>
                <Flex align="center">
                  <Text fontSize="sm" fontWeight={500} mr="10px">
                    Período
                  </Text>

                  <Input
                    fontWeight={500}
                    size="sm"
                    type={"date"}
                    maxW="150px"
                  />
                </Flex>
              </Flex> */}
              <TabPanels>
                <TabPanel>
                  <Chart
                    width={"100%"}
                    height={"350px"}
                    options={{
                      chart: {
                        id: "area",
                        height: 300,
                      },
                      colors: ["#8862f7", "#02AA80", "#0143FF"],
                      fill: {
                        type: "gradient",
                        gradient: {
                          type: "vertical",
                          shadeIntensity: 0.5,
                          gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
                          inverseColors: true,
                          opacityFrom: 0.6,
                          opacityTo: 0.2,
                          stops: [0, 50, 100],
                          colorStops: [],
                        },
                      },
                      xaxis: {
                        categories: data?.grafico?.horarios ?? [],
                      },
                      grid: {
                        borderColor: "#F5F5F5",
                        xaxis: {
                          lines: {
                            show: false,
                          },
                        },
                        yaxis: {
                          lines: {
                            show: true,
                            width: 1,
                          },
                        },
                      },

                      dataLabels: {
                        enabled: false,
                      },
                      stroke: {
                        curve: "smooth",
                        width: 4,
                      },
                    }}
                    series={data?.grafico?.dados ?? []}
                    type="area"
                  />
                </TabPanel>
                <TabPanel>
                  <Flex></Flex>
                </TabPanel>
                <TabPanel>
                  <Flex></Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
          {/* <Box
            boxShadow="sm"
            w="full"
            mt="5px"
            borderRadius="8px"
            px="5"
            py="2"
            bgColor="white"
            h="fit-content"
          >
            <Flex align="center">
              <Flex direction={"column"} lineHeight="20px">
                <b>
                  <Text>Vendas em tempo real</Text>
                </b>
                <Text fontSize="xs">
                  Uma visão geral dos dados da loja para a medida de pedidos
                  pagos.
                </Text>
              </Flex>
            </Flex>
            <Flex
              justify="space-between"
              align="center"
              mt="5"
              pr="75px"
              pb="50px"
            >
              <Flex direction="row" align="center" maxW="280px">
                <Image
                  src="https://m.media-amazon.com/images/I/61pJ2Jl85jL._AC_SY450_.jpg"
                  width="40px"
                  height="40px"
                  objectFit="contain"
                  alt="Deshopnise"
                  mr="5px"
                />
                <Text fontSize="xs" noOfLines="2" fontWeight="500">
                  Smartphone samsung galaxy a51 preto 128gb tela 6.5 câm.48mp
                </Text>
              </Flex>
              <Flex direction="column">
                <Flex direction="row" align="center">
                  <Text
                    fontSize="xs"
                    fontWeight="semibold"
                    color="primary.400"
                    mr="2px"
                  >
                    1x
                  </Text>
                  <Text
                    fontSize="xs"
                    fontWeight="semibold"
                    color="#2EB413"
                    mr="2px"
                  >
                    R$ 5442,20
                  </Text>
                </Flex>
                <Text fontSize="10px">Cartão de crédito/débito</Text>
              </Flex>
              <Text fontSize="sm" fontWeight="semibold" color="#2EB413">
                Processando
              </Text>
            </Flex>
          </Box> */}
        </Box>
        <Flex direction="column" w="25%" minWidth={"300px"}>
          <a href="https://www.central.deshopnise.com.br/" target="_blank">
            <Box
              boxShadow="sm"
              borderRadius={"8px"}
              marginTop={"10px"}
              backgroundColor={"white"}
              my={"12px"}
              mt={"15px"}
              py={"19px"}
              pb={"17px"}
            >
              <Flex
                align="center"
                justifyContent="space-between"
                alignSelf="center"
                h={"100%"}
                px="5"
              >
                <Text fontWeight="bold" color="primary.400" fontSize="16px">
                  Central do vendedor
                </Text>
                <MdChevronRight color="primary.400" />
              </Flex>
            </Box>
          </a>

          <BoxAvisosDeshopnise />
        </Flex>
      </Flex>
    </PageScaffold>
  );
}
