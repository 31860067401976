import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";

import LoginBackground from "./../../assets/certificado.png";

import useEstabelecimento from "../../hooks/useEstabelecimento";
import useAuthToken from "../../hooks/useAuthToken";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import { AuthContext } from "../../contexts/authContext";
import { MdCloudUpload } from "react-icons/md";
import { useFilePicker } from "use-file-picker";

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export default function CertificadoDigital() {
  const toast = useToast();
  const context = useContext(AuthContext);
  const repo = new VendedoresRepository({ token: context.currentToken });
  const loja = useEstabelecimento();

  const [ie, setIe] = useState("");
  const [serie, setSerie] = useState("");
  const [senha, setSenha] = useState("");

  useEffect(() => {
    setSerie(loja?.dados_fiscais?.serie_nfe);
    setIe(loja?.dados_fiscais?.inscricao_estadual);
  }, []);

  const [uploadFile, file] = useFilePicker({
    readAs: "DataURL",
    multiple: false,
    limitFilesConfig: { max: 2 },
    maxFileSize: 3, // in megabytes
  });

  const setupEnotas = async () => {
    const response = await repo.atualizarEnotas({
      inscricao_estadual: ie,
      serie_nfe: serie,
    });

    if (response.status === 200) {
      toast({
        description: "Emissor NFE configurado com sucesso",
        status: "success",
      });
    } else {
      toast({
        description: response.data.error,
        status: "error",
      });
    }
  };

  const saveFormData = async () => {
    await setupEnotas();

    const formData = new FormData();

    formData.append("vendendor_id", context.firebaseUser.uid);
    formData.append("serie", serie);
    formData.append("ie", ie);

    if (file.filesContent.length === 0) {
      toast({
        description: "Selecione um arquivo",
        status: "error",
      });
      return;
    }
    formData.append(
      "arquivo",
      dataURItoBlob(file.filesContent[0].content),
      file.filesContent[0].name
    );
    formData.append("senha", senha);

    const certificadoResponse = await repo.cadastrarCertificado(formData);
    if (certificadoResponse.status === 200) {
      toast({
        description: "Dados alterados com sucesso",
        status: "success",
      });

      setTimeout(() => {
        window.location = "/";
      }, 1000);
    } else {
      let error = certificadoResponse?.data.error;

      toast({
        description: error ?? "Erro não identificado",
        status: "error",
      });
    }
  };

  return (
    <>
      <Flex direction="row" h="100vh" alignItems="center">
        <Image
          display={{ base: "none", md: "block" }}
          w="35%"
          h="full"
          fit="cover"
          src={LoginBackground}
        />
        <Flex w={"100%"} direction="column" px="5">
          <Box
            style={{
              zoom: "1.1",
            }}
            width="full"
            my="5"
            mt="20"
            align={"center"}
          >
            <Box w={"450px"} align="start">
              <Text
                fontSize={"22px"}
                fontWeight={"700"}
                w={"400px"}
                mb="2"
                textAlign={"start"}
              >
                Informe o certificado
              </Text>
            </Box>
            <Box w={"450px"} align="start">
              <Text
                fontSize={"14px"}
                fontWeight={"500"}
                w={"400px"}
                mb="2"
                textAlign={"start"}
              >
                Digite abaixo as informações corretas para liberação de notas
                fiscais e emissão de etiquetas de envio.
              </Text>
            </Box>
            <Flex
              justifyContent={"start"}
              w={"450px"}
              align="start"
              direction="column"
            >
              <FormControl
                display={"flex"}
                flexDirection={"column"}
                align={"start"}
                onChange={(v) => {
                  setIe(v.target.value);
                }}
                isRequired
              >
                <Text p={"1"} fontWeight={"500"} fontSize={"14px"}>
                  Inscrição Estadual
                </Text>

                <Input
                  variant="filled"
                  bg="#EBEBEB"
                  fontWeight="500"
                  fontSize="sm"
                  placeholder="Digite o número de sua inscrição estadual"
                  size="lg"
                  value={ie}
                  mb="1"
                  color={"#000"}
                  _placeholder={{
                    fontWeight: "500",
                  }}
                />
              </FormControl>

              <FormControl
                display={"flex"}
                flexDirection={"column"}
                align={"start"}
                onChange={(v) => {
                  setSerie(v.target.value);
                }}
                isRequired
              >
                <Text p={"1"} fontWeight={"500"} fontSize={"14px"}>
                  Série NFe
                </Text>

                <Input
                  variant="filled"
                  bg="#EBEBEB"
                  fontWeight="500"
                  w={"250px"}
                  fontSize="sm"
                  placeholder="Digite o número de série"
                  size="lg"
                  value={serie}
                  mb="1"
                  color={"#000"}
                  _placeholder={{
                    fontWeight: "500",
                  }}
                />
              </FormControl>

              <FormControl
                display={"flex"}
                flexDirection={"column"}
                align={"start"}
                isRequired
              >
                <Text p={"1"} fontWeight={"500"} fontSize={"14px"}>
                  Upload do certificado
                </Text>

                <InputGroup
                  size="sm"
                  w="340px"
                  variant="filled"
                  onClick={uploadFile}
                >
                  <Input
                    variant="filled"
                    bg="#EBEBEB"
                    fontWeight="500"
                    fontSize="sm"
                    placeholder="Coloque aqui seu Certificado Digital"
                    size="lg"
                    value={
                      file.filesContent[0]?.name ??
                      loja.dados_fiscais?.razao_social
                    }
                    readOnly
                    mb="1"
                    color={"#000"}
                    _placeholder={{
                      fontWeight: "500",
                    }}
                  />
                  <InputRightElement mr="5" pt="13px">
                    <MdCloudUpload
                      cursor="pointer"
                      color="#6E41F1"
                      size="30px"
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <FormControl
                display={"flex"}
                flexDirection={"column"}
                align={"start"}
                onChange={(v) => {
                  setSenha(v.target.value);
                }}
                isRequired
              >
                <Text p={"1"} fontWeight={"500"} fontSize={"14px"}>
                  Senha do Certificado
                </Text>

                <Input
                  variant="filled"
                  bg="#EBEBEB"
                  fontWeight="500"
                  fontSize="sm"
                  placeholder="Digite a senha do seu Certificado Digital"
                  size="lg"
                  value={senha}
                  mb="1"
                  color={"#000"}
                  _placeholder={{
                    fontWeight: "500",
                  }}
                />
              </FormControl>
            </Flex>
            <Button
              onClick={saveFormData}
              bg={"#683FF3"}
              color={"white"}
              mt={"5"}
              w={"450px"}
              borderRadius={"8px"}
              h={"45px"}
              fontWeight={"700"}
              _hover={{
                bg: "#4C378F",
              }}
            >
              CONFIRMAR
            </Button>
          </Box>
        </Flex>
      </Flex>
    </>
  );
}
