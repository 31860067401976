import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../../assets/lottie/carregamentocadastro.json";

export default function CompletedComponent() {
  return (
    <Player
      src={animationData}
      style={{ maxWidth: "800px" }}
      autoplay
      loop
      speed={1.3}
    />
  );
}
