import moment from "moment/moment";

///Retorna se a data A é anterior a data B
const dateRangeIsValid = ({ first, last, maxDays }) => {
  console.log("first", first.length);
  console.log("last", last.length);
  var formattedNowDate = moment(new Date()).format("YYYY-MM-DD");

  if (moment(first).isAfter(moment(last))) {
    console.log("first is after last");
    return false;
  }

  maxDays = maxDays || null;
  if (maxDays !== null) {
    console.log("maxDays");
    let difDays = parseInt(
      (new Date(last) - new Date(first)) / (1000 * 60 * 60 * 24),
      10
    );
    console.log(difDays);
    if (difDays > maxDays) return false;
  }

  return true;
};

export default dateRangeIsValid;
