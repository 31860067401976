import {
  ArrowForwardIcon,
  ChevronRightIcon,
  TriangleDownIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Text,
  Flex,
  Select,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Image,
  chakra,
  Stack,
  Button,
  Spacer,
  Input,
  Switch,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Divider,
} from "@chakra-ui/react";

import DeleteIconSrc from "../../../assets/delete_black_24dp.svg";
import CorreiosSrc from "../../../assets/correios.png";
import JadLogSrc from "../../../assets/jadlog.png";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import Formatters from "../../../utils/formatters";
import { useRef } from "react";

const ProdutosList = ({ produtos, onRemove, onChange }) => {
  return (
    <Flex
      w={"100%"}
      margin={"0 auto"}
      backgroundColor={"white"}
      borderRadius={"6px"}
      direction={"column"}
      color={"black"}
      marginTop={"15px"}
    >
      <TableContainer color={"black"}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Produtos
              </Th>

              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Variação
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Estoque
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Tipo de envio
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Ação
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {produtos.map((produto, index) => {
              const suportaCorreios =
                produto.dados_frete.envios_disponiveis.includes(1) ||
                produto.dados_frete.envios_disponiveis.includes(2);
              const suportaJadLog = false; // TODO: COLOCAR O ID DA JADLOG AQUI

              return (
                <Tr>
                  <Td>
                    <Flex direction={"row"} padding={"10px 20px"}>
                      <Image
                        src={produto.variante.imagem ?? produto.imagens.at(0)}
                        width={"80px"}
                        height={"80px"}
                        mr={"15px"}
                      />
                      <Box width={"10px"} />
                      <Flex direction={"column"}>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"bold"}
                          wordBreak={"break-word"}
                          pb={"5px"}
                          width={"400px"}
                          whiteSpace={"normal"}
                          noOfLines={4}
                        >
                          {produto.titulo}
                        </Text>
                        {produto.variante.preco_promocional && (
                          <>
                            <Text fontWeight={"bold"} fontSize={"15px"}>
                              De {Formatters.money(produto.variante.preco)}
                            </Text>

                            <Text
                              color={"#089D3E"}
                              fontWeight={"bold"}
                              fontSize={"15px"}
                            >
                              Por R${" "}
                              {Formatters.money(
                                produto.variante.preco_promocional
                              )}
                            </Text>
                          </>
                        )}
                        {!produto.variante.preco_promocional && (
                          <>
                            <Text fontWeight={"bold"} fontSize={"15px"}>
                              R$ {Formatters.money(produto.variante.preco)}
                            </Text>
                          </>
                        )}
                      </Flex>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex direction={"row"}>
                      {!produto.variante.propriedades && (
                        <Box
                          fontSize="xs"
                          p="2"
                          py="1"
                          backgroundColor="#f2f2f2"
                          borderRadius="8"
                          fontWeight="medium"
                          w="100px"
                        >
                          <Text fontSize="10px">{"TIPO"}</Text>
                          <Text color="primary.400" fontWeight="semibold">
                            Unico
                          </Text>
                        </Box>
                      )}
                      {Object.keys(produto.variante.propriedades ?? {}).map(
                        (key) => {
                          return (
                            <>
                              <Box
                                fontSize="xs"
                                p="2"
                                py="1"
                                backgroundColor="#f2f2f2"
                                borderRadius="8"
                                fontWeight="medium"
                                w="100px"
                              >
                                <Text fontSize="10px">
                                  {" "}
                                  {key.toUpperCase()}:
                                </Text>
                                <Text color="primary.400" fontWeight="semibold">
                                  {produto.variante.propriedades[key]}
                                </Text>
                              </Box>
                              <Flex h="5px" />
                            </>
                          );
                        }
                      )}
                    </Flex>
                  </Td>
                  <Td>
                    <Text fontSize={"13px"} fontWeight={"bold"} maxW={"320px"}>
                      {produto.variante.estoque}
                    </Text>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      {suportaCorreios && (
                        <Image
                          src={CorreiosSrc}
                          width={"100px"}
                          paddingBottom={"15px"}
                        />
                      )}

                      {suportaJadLog && (
                        <Image
                          src={JadLogSrc}
                          width={"85px"}
                          paddingBottom={"15px"}
                        />
                      )}
                    </Flex>
                  </Td>
                  <Td>
                    <Image
                      cursor={"pointer"}
                      src={DeleteIconSrc}
                      opacity={"0.67"}
                      onClick={() => {
                        onRemove(produto);
                      }}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default ProdutosList;
