import { Flex, Image, Box, Text, Button } from "@chakra-ui/react";
import useAuthToken from "../../../hooks/useAuthToken";
import NotificacoesRepository from "../../../repositories/notificacoes_repository";
import { useState } from "react";
import { useEffect } from "react";
import useEstabelecimento from "../../../hooks/useEstabelecimento";
import { useNavigate } from "react-router-dom";

function NotificationList({ onClose }) {
  const estabelecimento = useEstabelecimento();
  const token = useAuthToken();
  const repo = new NotificacoesRepository({ token });
  const navigate = useNavigate();

  const [notificacoes, setNotificacoes] = useState([]);

  useEffect(() => {
    repo
      .consultar({
        estabelecimento_id: estabelecimento._id,
        app_destino: "vendedor",
      })
      .then((res) => {
        setNotificacoes(res.data);
      });
  }, []);

  const redirectNotification = async (notification) => {
    let payload = notification.payload.action.split(":");
    if (payload[0] === "go_to_page") {
      if (payload[1] === "pedido_detalhes") {
        navigate(`/pedido/${notification.payload.pedido_id}`);
      }
      if (payload[1] === "produto_detalhes") {
        navigate(`/editar-produto/${notification.payload.produto_id}`);
      }
      if (payload[1] === "home") {
        navigate("/");
      }
    }

    if (payload[0] === "go_to_link") {
      window.open(notification.payload.link);
    }
    onClose();
  };

  return (
    <Flex direction={"column"}>
      {notificacoes.map((n) => (
        <Flex
          onClick={() => {
            redirectNotification(n);
          }}
          _hover={{
            color: "#693ff3",
          }}
          key={n._id}
          direction={"row"}
          p={"10px"}
          pb="0px"
          justify={"center"}
          cursor={"pointer"}
        >
          <Image src={n.imagem} height={"60px"} width={"60px"} mr={"12px"} />{" "}
          <Flex direction={"column"}>
            <Text fontSize={"13px"} fontWeight={"bold"}>
              {n.titulo}
            </Text>
            <Text fontSize={"12px"} fontWeight={500}>
              {n.descricao}
            </Text>
            <Text fontSize={"10px"} opacity={0.8} fontWeight={500} pt="1px">
              {new Date(n.createdAt).toLocaleString()}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
}

export default NotificationList;
