import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  Switch,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";

import PageScaffold from "../../components/PageScaffold";
import ProdutosTr from "./components/ProdutosTr";
import ProductsSelectorDialog from "../../components/ProductsSelectorDialog";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import useAuthToken from "../../hooks/useAuthToken";
import CupomDeDescontoRepository from "../../repositories/cupom_de_desconto_repository";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import dateRangeIsValid from "../../utils/date_comparator";
import ProdutosList from "./components/ProdutosList";
import AnunciosRepository from "../../repositories/anuncios_repository";

import backAsset from "../../assets/back.png";
import CaixaEntrega from "../../assets/caixa-de-entrega.svg";
import Disquete from "../../assets/disquete.svg";
import { InfoOutlineIcon } from "@chakra-ui/icons";

export default function NovoCupomPage() {
  const token = useAuthToken();
  const repo = new CupomDeDescontoRepository({ token });
  const toast = useToast();

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [tipo, setTipo] = useState("loja");

  const [nome, setNome] = useState("");
  const [codigo, setCodigo] = useState("");
  const [desconto, setDesconto] = useState("");
  const [descontoTipo, setDescontoTipo] = useState("porcentagem");
  const [descontoMaximo, setDescontoMaximo] = useState("");
  const [valorMinimoPedido, setValorMinimoPedido] = useState("");
  const [quantidade, setQuantidade] = useState("");

  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");

  const [requestInProgress, setRequestInProgress] = useState(false);

  const [hasLimit, setHasLimit] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      loadCupom(params.id);
    }
  }, [params.id]);

  function Info({ text }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
      <>
        <InfoOutlineIcon
          cursor={"pointer"}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          fontSize={"14px"}
        />
        {isOpen && (
          <Box position={"relative"}>
            <Text
              backgroundColor={"white"}
              borderRadius={"4px"}
              padding={"10px"}
              minW={"200px"}
              shadow={"md"}
              position={"absolute"}
              fontSize={"12px"}
              fontWeight={"500"}
              color={"black"}
              zIndex={4}
            >
              {text}
            </Text>
          </Box>
        )}
      </>
    );
  }

  async function loadCupom(id) {
    console.log("loadCupom", id);
    repo.consultar({ id }).then(async (res) => {
      if (res.status === 200) {
        setNome(res.data.nome);
        setCodigo(res.data.codigo);
        setTipo(res.data.tipo);

        if (res.data.desconto_porcentagem) {
          setDesconto(res.data.desconto_porcentagem);
          setDescontoTipo("porcentagem");
        } else {
          setDesconto(res.data.desconto_reais);
          setDescontoTipo("valor");
        }
        setDescontoMaximo(res.data.valor_maximo_desconto);
        setValorMinimoPedido(res.data.valor_minimo_pedido);
        setQuantidade(res.data.quantidade);
        setDataInicio(res.data.data_inicio.split("T")[0]);
        setDataFim(res.data.data_fim.split("T")[0]);

        const pRepo = new AnunciosRepository({ token });

        const res2 = await Promise.all(
          res.data.produtos.map(async (p) => {
            const res = await pRepo.consultar({
              id: p,
            });

            return res.data;
          })
        );

        setSelectedProducts(
          res2.map((e) => {
            const variante = e.variantes.find((v) =>
              res.data.variantes.find((vv) => vv === v._id)
            );

            return {
              ...e,
              variante,
            };
          })
        );
      }
    });
  }

  return (
    <>
      <PageScaffold showChat={false}>
        <Box w={"99%"} margin={"0 auto"}>
          <Box
            paddingTop={"20px"}
            paddingBottom={"15px"}
            fontWeight={"700"}
            fontSize={"22px"}
          >
            <Flex direction={"row"} alignItems={"center"}>
              <Image
                pl={"5px"}
                cursor={"pointer"}
                src={backAsset}
                height={"15px"}
                alt="Voltar"
                onClick={() => {
                  window.history.back();
                }}
              />
              <Box width={"10px"} />
              <span>Criar Cupom</span>
            </Flex>
          </Box>

          <Box paddingBottom={"4px"} fontWeight={"600"} fontSize={"16px"}>
            {/* <span>Meus Envios</span> */}
            <Flex direction="row" mb="5">
              <Box
                py="4"
                mr="30px"
                color={tabIndex === 0 ? "primary.400" : "grey"}
                borderBottom={tabIndex === 0 ? "2px solid #693FF3" : null}
                onClick={() => setTabIndex(0)}
                cursor="pointer"
              >
                <Text>Informações Básicas</Text>
              </Box>
              <Box
                py="4"
                mr="30px"
                color={tabIndex === 1 ? "primary.400" : "grey"}
                borderBottom={tabIndex === 1 ? "2px solid #693FF3" : null}
                onClick={() => setTabIndex(1)}
                cursor="pointer"
              >
                <Text>Detalhes</Text>
              </Box>
            </Flex>
          </Box>

          {tabIndex === 0 && (
            <Flex direction="column">
              <Box
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Flex direction="column">
                  <Flex ml="2" direction="column" maxW="600px">
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="sm" fontWeight={700} mr="2">
                        Tipo de cupom
                      </Text>
                      <Info
                        text={"O Nome do cupom não é visível para compradores"}
                      />
                    </Flex>

                    <Flex>
                      <Box
                        p="2"
                        borderRadius="4"
                        borderWidth="2px"
                        borderColor={tipo !== "produto" ? "primary.400" : null}
                        maxW="300px"
                        cursor="pointer"
                        mr="2"
                      >
                        <Flex
                          direction="row"
                          align="center"
                          py="1"
                          color={tipo === "loja" ? "primary.400" : undefined}
                          onClick={() => setTipo("loja")}
                        >
                          <Image src={CaixaEntrega} h="20px" />
                          <Text ml="2" fontSize="xs" fontWeight="600">
                            Todos os produtos
                          </Text>
                        </Flex>
                      </Box>
                      <Box
                        p="2"
                        borderRadius="4"
                        borderWidth="2px"
                        borderColor={tipo === "produto" ? "primary.400" : null}
                        maxW="300px"
                        cursor="pointer"
                        ml="1"
                      >
                        <Flex
                          direction="row"
                          align="center"
                          py="1"
                          color={tipo === "produto" ? "primary.400" : undefined}
                          onClick={() => setTipo("produto")}
                        >
                          <Image src={Disquete} h="16px" color="green" />
                          <Text ml="2" fontSize="xs" fontWeight="600">
                            Produtos específicos
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                </Flex>
              </Box>

              <Box
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
                mt="5"
              >
                <Flex direction="column">
                  <Flex ml="2" direction="column" maxW="600px">
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="sm" fontWeight={700} mr="2">
                        Nome do cupom
                      </Text>
                      <Info
                        text={"O Nome do cupom não é visível para compradores"}
                      />
                    </Flex>
                    <Input
                      placeholder="Ex: Cupom de 10% de desconto"
                      variant={"customGrey"}
                      value={nome}
                      onChange={(e) => setNome(e.target.value)}
                    />
                  </Flex>
                </Flex>
              </Box>

              <Box
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
                mt="5"
              >
                <Flex direction="column">
                  <Flex ml="2" direction="column" maxW="600px">
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="sm" fontWeight={700} mr="2">
                        Código do cupom
                      </Text>
                      <Info
                        text={
                          "Por favor, digite A-Z,0-9; Máximo de 13 characters."
                        }
                      />
                    </Flex>
                    <Input
                      placeholder="Ex: MARCELO15OFF"
                      variant={"customGrey"}
                      value={codigo}
                      maxLength={13}
                      onChange={(e) => setCodigo(e.target.value)}
                    />
                  </Flex>
                </Flex>
              </Box>

              <Box
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
                mt="5"
              >
                <Flex direction="column">
                  <Flex ml="2" direction="column" maxW="600px">
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="sm" fontWeight={700} mr="2">
                        Período do cupom
                      </Text>
                      <Info
                        text={"O tempo do cupom deve ser inferior a 180 dias "}
                      />
                    </Flex>
                    <Flex align="center">
                      <Input
                        fontWeight={500}
                        size="sm"
                        type={"date"}
                        maxW="140px"
                        value={dataInicio}
                        onChange={(e) => {
                          let isValid = dateRangeIsValid({
                            first: e.target.value,
                            last: dataFim,
                            maxDays: 180,
                          });

                          if (isValid) {
                            setDataInicio(e.target.value);
                          } else {
                            toast({
                              title: "Erro",
                              description:
                                "Verifique as datas de início e fim da promoção",
                              status: "error",
                              duration: 5000,
                              isClosable: true,
                            });
                          }
                        }}
                      />
                      <Box
                        mx="2"
                        w={"18px"}
                        h="2px"
                        backgroundColor="grey"
                        borderRadius="4px"
                      />
                      <Input
                        fontWeight={500}
                        size="sm"
                        type={"date"}
                        maxW="140px"
                        value={dataFim}
                        onChange={(e) => {
                          let isValid = dateRangeIsValid({
                            first: dataInicio,
                            last: e.target.value,
                            maxDays: 180,
                          });

                          if (isValid) {
                            setDataFim(e.target.value);
                          } else {
                            toast({
                              title: "Erro",
                              description:
                                "Verifique as datas de início e fim da promoção",
                              status: "error",
                              duration: 5000,
                              isClosable: true,
                            });
                          }
                        }}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          )}

          {tabIndex === 1 && (
            <Flex direction="column">
              <Box
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Flex direction="column">
                  <Text fontSize="md" fontWeight="semibold">
                    Configurações do Cupom
                  </Text>
                  <Flex ml="2" direction="column" mt="5" maxW="600px">
                    <Text fontSize="sm" mb="2" fontWeight={500}>
                      Tipo de desconto / quantidade*
                    </Text>
                    <InputGroup>
                      <InputLeftAddon
                        padding={"0px"}
                        backgroundColor={"#EBEBEB"}
                        size="sm"
                        children={
                          <Select
                            size={"sm"}
                            fontWeight={500}
                            onChange={(e) => {
                              setDescontoTipo(e.target.value);
                              setDesconto("");
                            }}
                            value={descontoTipo}
                          >
                            <option value="porcentagem">Por porcentagem</option>
                            <option value="real">Por valor</option>
                          </Select>
                        }
                      />
                      {descontoTipo === "porcentagem" ? (
                        <Input
                          type="number"
                          fontWeight={500}
                          max={100}
                          width={"500px"}
                          placeholder="10%"
                          variant={"customGrey"}
                          value={desconto}
                          onChange={(e) => {
                            if (e.target.value > 100) return;

                            setDesconto(Number(e.target.value));
                          }}
                        />
                      ) : (
                        <NumericFormat
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={"R$ "}
                          customInput={Input}
                          placeholder="R$ 0,00"
                          fontWeight={500}
                          variant={"customGrey"}
                          value={Number(desconto)}
                          onValueChange={({ floatValue }) => {
                            setDesconto(floatValue);
                          }}
                        />
                      )}

                      <InputRightAddon
                        backgroundColor={"#EBEBEB"}
                        children={
                          descontoTipo === "real" ? (
                            <Text>R$ OFF</Text>
                          ) : (
                            <Text>% OFF</Text>
                          )
                        }
                      />
                    </InputGroup>
                    <Flex mt="4" align="center">
                      <Text fontSize="sm" fontWeight={500} mr="2">
                        Quantidade*
                      </Text>
                      <Input
                        type="text"
                        size="sm"
                        fontWeight={500}
                        placeholder="0"
                        w="80px"
                        variant={"customGrey"}
                        value={quantidade}
                        onChange={(e) => {
                          setQuantidade(e.target.value);
                        }}
                      />
                    </Flex>
                    <Flex mt="4" align="center">
                      <Text fontSize="sm" mr="2" fontWeight={500}>
                        Condição de desconto
                      </Text>
                      <Switch
                        defaultChecked={hasLimit}
                        onChange={(e) => {
                          setHasLimit(e.target.checked);
                        }}
                      />
                    </Flex>

                    {hasLimit && (
                      <Flex mt="4" align="center">
                        <Text fontSize="sm" fontWeight={500} mr="2" w="200px">
                          Valor de desconto máximo
                        </Text>
                        <Flex direction="column" w="180px">
                          <InputGroup size="sm" w="210px">
                            <InputLeftAddon
                              backgroundColor={"#EBEBEB"}
                              size="sm"
                              fontSize="sm"
                              children={<Text fontSize="10px">R$</Text>}
                            />

                            <NumericFormat
                              decimalScale={2}
                              decimalSeparator=","
                              thousandSeparator="."
                              prefix={"R$ "}
                              customInput={Input}
                              placeholder="opcional"
                              fontWeight={500}
                              variant={"filled"}
                              backgroundColor={"#F9F9F9"}
                              value={descontoMaximo}
                              onValueChange={({ floatValue }) => {
                                setDescontoMaximo(floatValue);
                              }}
                            />
                          </InputGroup>
                        </Flex>
                      </Flex>
                    )}
                    {hasLimit && (
                      <Flex mt="4" align="center">
                        <Text fontSize="sm" fontWeight={500} mr="2" w="200px">
                          Valor mínimo do carrinho
                        </Text>
                        <InputGroup size="sm" w="210px">
                          <InputLeftAddon
                            backgroundColor={"#EBEBEB"}
                            size="sm"
                            fontSize="sm"
                            children={<Text fontSize="10px">R$</Text>}
                          />

                          <NumericFormat
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={"R$ "}
                            customInput={Input}
                            placeholder="opcional"
                            fontWeight={500}
                            variant={"filled"}
                            backgroundColor={"#F9F9F9"}
                            value={valorMinimoPedido}
                            onValueChange={({ floatValue }) => {
                              setValorMinimoPedido(floatValue);
                            }}
                          />
                        </InputGroup>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          )}

          {tipo === "produto" && tabIndex === 1 && (
            <Box
              boxShadow="sm"
              w="full"
              mt="15px"
              borderRadius="8px"
              p="5"
              bgColor="white"
            >
              <Flex direction="column" w="full">
                <Text fontSize="md" fontWeight="semibold">
                  Adicionar produtos aplicáveis
                </Text>
                <Text fontSize="xs" mr="2" fontWeight={500}>
                  Adicionar produtos que estarão disponíveis para este cupom
                </Text>
                <Flex mt="5" ml="2" justify="space-between" align="center">
                  <Flex fontSize="sm">
                    <Text>Produtos aplicáveis: </Text>
                    <Text fontWeight="semibold">
                      {" "}
                      {selectedProducts.length} produto(s) selecionado(s)
                    </Text>
                  </Flex>
                  <Button
                    backgroundColor="primary.400"
                    color="white"
                    onClick={onOpen}
                  >
                    Adicionar Produto
                  </Button>
                </Flex>

                <ProdutosList
                  produtos={selectedProducts}
                  onRemove={(product) => {
                    setSelectedProducts(
                      selectedProducts.filter((p) => p.id !== product.id)
                    );
                  }}
                />
              </Flex>
            </Box>
          )}
        </Box>
        <Box
          // boxShadow="sm"
          w="full"
          mt="5px"
          borderRadius="8px"
          p="5"
          // bgColor="white"
          align="end"
        >
          <Button
            ml="2"
            onClick={() => {
              if (tabIndex === 1) {
                setTabIndex(0);
                return;
              }
              navigate(-1);
            }}
          >
            Cancelar
          </Button>
          <Button
            ml="2"
            bgColor="primary.400"
            color="white"
            onClick={() => {
              if (tabIndex === 0) {
                setTabIndex(1);
                return;
              }
              const data = {
                nome,
                tipo,
                codigo,
                desconto,
                desconto_tipo: descontoTipo,
                desconto_porcentagem:
                  descontoTipo === "porcentagem" ? desconto : null,
                desconto_reais:
                  descontoTipo !== "porcentagem" ? desconto : null,

                valor_maximo_desconto: descontoMaximo,
                valor_minimo_pedido: valorMinimoPedido,
                quantidade,
                produtos: [...new Set(selectedProducts.map((p) => p._id))],
                variantes: selectedProducts.map((p) => p.variante._id),
                data_inicio: dataInicio,
                data_fim: dataFim + "T23:59:59.000Z",
              };

              if (params.id) {
                repo
                  .alterar({
                    ...data,
                    id: params.id,
                  })
                  .then((res) => {
                    toast({
                      title: "Por favor, aguarde!",
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                    });

                    setRequestInProgress(true);

                    if (res.status === 200) {
                      toast({
                        title: "Cupom editado com sucesso",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });
                      navigate(-1);
                    } else {
                      toast({
                        title: res.data.error.toString(),
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  });
              } else {
                repo.cadastrar(data).then((res) => {
                  if (res.status === 200) {
                    toast({
                      title: "Cupom cadastrado com sucesso",
                      status: "success",
                      duration: 9000,
                      isClosable: true,
                    });
                    navigate(-1);
                  } else {
                    toast({
                      title: res.data.error.toString(),
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  }
                });
              }
            }}
          >
            {false ? "Atualizar" : tabIndex === 0 ? "Próximo" : "Salvar"}
          </Button>
        </Box>
      </PageScaffold>
      <ProductsSelectorDialog
        isOpen={isOpen}
        currentProducts={selectedProducts}
        onClose={onClose}
        onSelect={(p) => {
          setSelectedProducts(p);
          onClose();
        }}
      />
    </>
  );
}
