import { Box, Text, Flex } from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { useState } from "react";

function InfoCard({
  title,
  subtitle,
  value,
  active = false,
  helpText,
  onClick,
  time,
}) {
  return (
    <Box
      borderRadius={"5px"}
      shadow={"sm"}
      backgroundColor={"white"}
      color={"black"}
      width={"210px"}
      minW={"210px"}
      height={"110px"}
      marginRight={"20px"}
      marginTop={"40px"}
      padding={"15px"}
      borderBottom={active ? "6px solid #693FF3" : ""}
    >
      <Flex
        direction={"row"}
        align={"center"}
        color={active ? "black" : "blackAlpha.600"}
      >
        <Text fontWeight={"bold"} fontSize={"16px"}>
          {title}
        </Text>
        <Box width={"5px"} />
        <Info text={helpText} />
      </Flex>
      {subtitle && (
        <Text fontSize={"10px"} fontWeight={"medium"}>
          {subtitle}
        </Text>
      )}
      {value && (
        <Text
          fontSize={"20px"}
          fontWeight={"bold"}
          color={active ? "#693FF3" : "black"}
        >
          {value}
        </Text>
      )}
      {time && (
        <Text fontSize={"10px"} fontWeight={"medium"}>
          {time}
        </Text>
      )}
    </Box>
  );
}

function Info({ text }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <InfoOutlineIcon
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
        cursor={"pointer"}
        fontSize={"12px"}
      />
      {isOpen && (
        <Box position={"relative"}>
          <Text
            backgroundColor={"white"}
            borderRadius={"4px"}
            padding={"10px"}
            minW={"200px"}
            shadow={"md"}
            position={"absolute"}
            fontSize={"12px"}
            fontWeight={"500"}
            color={"black"}
          >
            {text}
          </Text>
        </Box>
      )}
    </>
  );
}

export default InfoCard;
