import { Box, Flex, Heading, Text, Button, Spacer } from "@chakra-ui/react";
import TabProdutosRaspadinha from "./TabProdutosRaspadinha";
import React from "react";
import TabPremiosRaspadinha from "./TabPremiosRaspadinha";

export default function PremiosRaspadinha({
  openProductList,
  products,
  onChange,
  onRemove,
  disabled,
}) {
  console.log("BUILDING PremiosRaspadinha");
  return (
    <Flex direction={"column"}>
      <Box
        boxShadow="sm"
        w="full"
        mt="5"
        borderRadius="8px"
        p="5"
        bgColor="white"
      >
        <Flex justifyContent="space-between" mb="2">
          <Flex direction="column" w="60%">
            <Heading fontSize="md">Premios</Heading>
            <Spacer h="1" />

            <Text fontWeight={500} fontSize="14px" noOfLines="2">
              Selecione os produtos que deseja colocar como prêmio e digite a
              quantidade e valor de desconto.
            </Text>
          </Flex>
          <Button
            disabled={disabled}
            w="250px"
            bgColor="primary.400"
            color="white"
            onClick={openProductList}
          >
            Adicionar Premio
          </Button>
        </Flex>
        <TabPremiosRaspadinha
          disabled={disabled}
          products={products}
          onRemove={onRemove}
          onChangeProducts={(p) => {
            onChange(p);
          }}
        />
      </Box>
    </Flex>
  );
}
