import { buildHttpClient } from "../services/api";
import axios from "axios";

class PedidosRepository {
  constructor({ token }) {
    this.http = buildHttpClient(token);

    this.http.interceptors.request.use(
      function (config) {
        if (!config.headers.token) {
          throw new axios.Cancel("");
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  consultar(params) {
    return this.http.post("/v2/pedidos/consultar-pedidos", params);
  }

  novoPedido(params) {
    return this.http.post("/v2/pedidos/novo-pedido", params);
  }

  gerarNfe(params) {
    return this.http.post("/v2/pedidos/gerar-nfe", params);
  }

  consultarDanfeUrl(params) {
    return this.http.post("/v2/pedidos/consultar-nfe-pdf", params);
  }
}

export default PedidosRepository;
