import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBack, MdBackspace, MdLocationOn } from "react-icons/md";
import PageScaffold from "../../components/PageScaffold";
import OthersRepository from "../../repositories/others_repository";
import { useDisclosure } from "@chakra-ui/react";
import { useRef, useState, useEffect, useContext } from "react";
import { AuthContext } from "../../contexts/authContext";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import placeImg from "../../assets/place.svg";
import { useNavigate } from "react-router-dom";

export default function MeusEnderecosPage() {
  const context = useContext(AuthContext);

  const estabelecimento = useEstabelecimento();

  const repo = new VendedoresRepository({ token: context.currentToken });
  const toast = useToast();
  const [loja, setLoja] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();

  const nomeRef = useRef();
  const telefoneRef = useRef();
  const cepRef = useRef();
  const estadoRef = useRef();
  const cidadeRef = useRef();
  const bairroRef = useRef();
  const ruaRef = useRef();
  const numeroRef = useRef();
  const complememtoRef = useRef();
  const navigate = useNavigate();

  useEffect(() => {
    setLoja(estabelecimento);
  }, []);

  const handleCep = (e) => {
    clearValues();
    cepRef.current.value = e.target.value;
    let cepOnlyNumbers = e.target.value.replace(/\D/g, "");

    if (!cepOnlyNumbers || cepOnlyNumbers.length !== 8) return;
    OthersRepository.fetchCep(cepOnlyNumbers).then((res) => {
      if (res.status === 200) {
        estadoRef.current.value = res.data.uf;
        cidadeRef.current.value = res.data.localidade;
        bairroRef.current.value = res.data.bairro;
        ruaRef.current.value = res.data.logradouro;
      }
    });
  };

  const clearValues = () => {
    estadoRef.current.value = "";
    cidadeRef.current.value = "";
    bairroRef.current.value = "";
    ruaRef.current.value = "";
    numeroRef.current.value = "";
    complememtoRef.current.value = "";
  };

  const fetchLojaData = async (params) => {
    repo.consultar({ firebase_id: context.firebaseUser.uid }).then((res) => {
      if (res.status === 200) {
        setLoja(res.data);
      }
    });
  };

  const saveData = async () => {
    if (loja == null) return;

    loja.endereco = {
      nome: nomeRef.current.value,
      telefone: telefoneRef.current.value,
      cep: cepRef.current.value,
      rua: ruaRef.current.value,
      numero: numeroRef.current.value,
      bairro: bairroRef.current.value,
      cidade: cidadeRef.current.value,
      estado: estadoRef.current.value,
      complemento: complememtoRef.current.value,
    };

    repo.alterar(loja).then((res) => {
      if (res.status === 200) {
        toast({
          description: "Endereço alterado com sucesso",
          status: "success",
        });
      } else {
        toast({
          description: "Houve uma falha ao alterar o endereço",
          status: "error",
        });
      }
    });

    onClose();
  };

  if (loja === null) return <> </>;

  return (
    <>
      <PageScaffold showSideBar={true}>
        <Flex direction="row" align="center" ml="25px" mt="5">
          <MdArrowBack
            size="25px"
            cursor={"pointer"}
            onClick={() => {
              navigate(-1);
            }}
          />
          <Box w="5px" />
          <Heading fontSize="2xl">Meu endereço</Heading>
        </Flex>
        <Text ml="30px" fontSize="13px">
          Gerencie seu endereço de envio e retirada.
        </Text>
        <Flex w="full" direction="column" p="5" pb="0">
          <Box boxShadow="sm" w="full" borderRadius="8px" p="5" bgColor="white">
            <Flex
              direction="row"
              justify="space-between"
              w="full"
              alignItems="center"
            >
              <Flex>
                <Image src={placeImg} boxSize="30px" />
                <Flex ml="2" direction="column" align="start">
                  <Flex direction="row" align="center" fontSize="sm">
                    <Text fontSize="xs" mr="5">
                      Nome Completo:
                    </Text>
                    <Text w="150" mr="5" fontWeight="bold">
                      {loja.responsavel.nome}
                    </Text>
                    <Button
                      size="xs"
                      backgroundColor="primary.400"
                      color="white"
                      p="4"
                      py="2"
                      borderRadius={16}
                    >
                      Endereço de Origem
                    </Button>
                  </Flex>
                  <Flex direction="row" align="center" fontSize="sm" mt="2">
                    <Text mr="2" fontSize="xs">
                      Número de telefone:
                    </Text>
                    <Text w="150" mr="2" fontWeight="bold">
                      {loja.responsavel.telefone}
                    </Text>
                  </Flex>
                  <Flex direction="row" align="center" fontSize="sm" mt="2">
                    <Text mr="2" fontSize="xs">
                      Endereço:
                    </Text>
                    <Text noOfLines="3" mr="5" fontWeight="bold">
                      {loja.endereco.rua +
                        ", " +
                        loja.endereco.numero +
                        ", " +
                        loja.endereco.bairro +
                        ", " +
                        loja.endereco.cidade +
                        " - " +
                        loja.endereco.estado +
                        " - " +
                        loja.endereco.cep}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
              <Button
                size="xs"
                backgroundColor="white"
                color="#693FF3"
                onClick={onOpen}
                fontWeight="bold"
              >
                Editar
              </Button>
            </Flex>
          </Box>
        </Flex>
      </PageScaffold>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Text> Adicionar endereço</Text>
              <Text fontWeight="normal" fontSize="xs">
                Preencha os campos abaixo com os dados dos seus endereços para
                receber os seus pedidos.
              </Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Grid templateColumns="repeat(2, 1fr)" gap={2}>
                <Input placeholder="Nome" variant="filled" ref={nomeRef} />
                <Input
                  placeholder="Telefone"
                  variant="filled"
                  ref={telefoneRef}
                />
              </Grid>
              <Input
                placeholder="CEP"
                variant="filled"
                mt="2"
                ref={cepRef}
                onChange={handleCep}
              />
              <Input
                placeholder="Estado"
                variant="filled"
                mt="2"
                ref={estadoRef}
              />
              <Input
                placeholder="Cidade"
                variant="filled"
                mt="2"
                ref={cidadeRef}
              />
              <Input
                placeholder="Bairro"
                variant="filled"
                mt="2"
                ref={bairroRef}
              />
              <Flex gap={2} mt="2">
                <Input
                  placeholder="Rua"
                  variant="filled"
                  w="70%"
                  ref={ruaRef}
                />
                <Input
                  placeholder="Número"
                  variant="filled"
                  w="30%"
                  ref={numeroRef}
                />
              </Flex>
              <Input
                placeholder="Complemento"
                variant="filled"
                mt="2"
                ref={complememtoRef}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme="purple" onClick={saveData} ml={3}>
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
