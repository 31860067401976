import Dinero from "dinero.js";

class Formatters {
  static money(amount) {
    const fmt = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return fmt.format(amount);
  }

  static fromDinero(amount) {
    const price = Dinero(amount);
    return price.toFormat();
  }

  static date(date) {
    let dateResult;
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    dateResult =
      day +
      "/" +
      month +
      "/" +
      date.getFullYear() +
      " - " +
      hours +
      ":" +
      minutes;
    return dateResult;
  }

  static addDays = (date, days) => {
    date.setDate(date.getDate() + days);
    return date;
  };

  static numbersOnly = (str) => {
    return str.replace(/\D/g, "");
  };

  static properties = (obj) => {
    const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

    return Object.entries(obj)
      .map((e) => {
        return e.map(capitalize).join(": ");
      })
      .join(" ");
  };

  static paymentMethod(method, installments) {
    const methods = {
      pix: "Pix",
      boleto: "Boleto",
      credit_card: `Cartão em ${installments || 1}x`,
    };

    if (Object.keys(methods).includes(method)) {
      return methods[method];
    } else {
      return method;
    }
  }
}

export default Formatters;
