import {
  Box,
  AspectRatio,
  Text,
  Textarea,
  Flex,
  Avatar,
  AvatarBadge,
  Input,
  Heading,
  Button,
  Image,
  InputGroup,
  InputRightAddon,
  InputRightElement,
} from "@chakra-ui/react";
import FirebaseStorageService from "../../../services/firebase_storage_service";
import { AuthContext } from "../../../contexts/authContext";
import { useContext } from "react";
import { useFilePicker } from "use-file-picker";
import {
  MdAdd,
  MdChevronLeft,
  MdChevronRight,
  MdLinkedCamera,
} from "react-icons/md";
import { useEffect } from "react";
import ImageUploader from "../../NovoAnuncio/helpers/ImageUploader";
import { useState, useRef } from "react";
import "react-image-crop/dist/ReactCrop.css";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import BannerCropperDialog from "./bannerCropDialog";
import PerfilCropperDialog from "./perfilCropDialog";
import { ChevronRightIcon, DeleteIcon } from "@chakra-ui/icons";

export default function TabPerfil({ loja, save }) {
  const firebaseStorageService = new FirebaseStorageService();
  const authContext = useContext(AuthContext);

  const [vendendor, setVendedor] = useState(loja);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenProfile, setIsOpenProfile] = useState(false);
  const [bannerCropped, setBannerCropped] = useState();
  const [profileCropped, setProfileCropped] = useState();
  const [bannerIndex, setBannerIndex] = useState(0);

  useEffect(() => {
    setVendedor(loja);
  }, [loja]);

  const [onUpdateFolder, folder] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 3, // in megabytes
  });

  const [onUpdateProfile, profile] = useFilePicker({
    readAs: "DataURL",
    accept: "image/*",
    multiple: false,
    limitFilesConfig: { max: 1 },
    maxFileSize: 3, // in megabytes
  });

  useEffect(() => {
    if (folder.plainFiles.length > 0) {
      setIsOpen(true);
    }
  }, [folder.plainFiles]);

  useEffect(() => {
    if (profile.plainFiles.length > 0) {
      setIsOpenProfile(true);
    }
  }, [profile.plainFiles]);

  useEffect(() => {
    if (!bannerCropped) return;
    bannerCropped["name"] = "banner.jpg";
    ImageUploader.upload(bannerCropped, { compress: true }).then((url) => {
      setVendedor((prev) => ({
        ...prev,
        imagem_capa:
          vendendor.imagem_capa?.length > 0
            ? [...vendendor.imagem_capa, url]
            : [url],
      }));
      setBannerCropped(null);
      console.log("imagem_capa");
      console.log(vendendor);
    });
  }, [bannerCropped]);

  useEffect(() => {
    if (!profileCropped) return;
    profileCropped["name"] = "perfil.jpg";
    ImageUploader.upload(profileCropped, { compress: true }).then((url) => {
      setVendedor((prev) => ({
        ...prev,
        imagem_perfil: url,
      }));
      setProfileCropped(null);
    });
  }, [profileCropped]);

  if (vendendor == null) {
    return <></>;
  }

  if (vendendor.imagem_capa == null) {
    vendendor.imagem_capa = [];
  }

  if (typeof vendendor.imagem_capa === "string") {
    let imgUrl = vendendor.imagem_capa;
    vendendor.imagem_capa = [];
    vendendor.imagem_capa[0] = imgUrl;
  }

  let hasCapa =
    vendendor.imagem_capa?.length > 0 &&
    vendendor.imagem_capa?.length > bannerIndex;

  return (
    <>
      <Box>
        {/* <CropPreview img={imgRef.current} crop={completedCrop} /> */}
        <Flex direction="row" align="center">
          <MdChevronLeft
            size="32px"
            opacity={bannerIndex === 0 ? 0.2 : 1}
            cursor={"pointer"}
            onClick={() => {
              if (bannerIndex > 0) {
                setBannerIndex(bannerIndex - 1);
              }
            }}
          />

          <AspectRatio
            w="100%"
            ratio={14 / 4}
            onClick={() => {}}
            cursor="pointer"
          >
            {!hasCapa ? (
              <Flex
                onClick={() => {
                  onUpdateFolder();
                }}
                ratio={14 / 4}
                w="90%"
                bgColor="#EDF2F7"
                direction="column"
                borderRadius="10"
              >
                <MdAdd size="40px" />
                <Text>
                  <b>Adicionar Banner no perfil</b>
                </Text>
              </Flex>
            ) : (
              <Flex position={"relative"} w="90%">
                <Image
                  w="100%"
                  objectFit={"cover"}
                  ratio={14 / 4}
                  src={vendendor.imagem_capa[bannerIndex]}
                  borderRadius={8}
                  onClick={() => {}}
                />
                <DeleteIcon
                  color={"grey"}
                  position={"absolute"}
                  right={"10px"}
                  top={"10px"}
                  onClick={() => {
                    vendendor.imagem_capa.splice(bannerIndex, 1);
                    setVendedor((prev) => ({
                      ...prev,
                      imagem_capa: vendendor.imagem_capa,
                    }));
                  }}
                />
              </Flex>
            )}
          </AspectRatio>
          <MdChevronRight
            size="32px"
            opacity={
              bannerIndex < (vendendor.imagem_capa?.length ?? 0) &&
              bannerIndex < 5
                ? 1
                : 0.2
            }
            cursor={"pointer"}
            onClick={() => {
              if (
                bannerIndex < (vendendor.imagem_capa?.length ?? 0) &&
                bannerIndex < 5
              ) {
                setBannerIndex(bannerIndex + 1);
              }
            }}
          />
        </Flex>

        <Flex mt="10" align="center" justify="space-between">
          <Flex direction="row">
            <Avatar
              borderRadius="full"
              boxSize="100px"
              onClick={() => {
                onUpdateProfile();
              }}
              src={vendendor.imagem_perfil}
              mr="5"
            >
              <AvatarBadge cursor="pointer">
                <MdLinkedCamera color="black" />
              </AvatarBadge>
            </Avatar>
            <Flex direction="column">
              <Heading fontSize="sm">Nome da Loja</Heading>
              <InputGroup mt="2">
                <Input
                  maxLength={40}
                  defaultValue={vendendor.nome}
                  variant="filled"
                  placeholder={"Sua loja"}
                  onChange={(e) => {
                    setVendedor((prev) => ({
                      ...prev,
                      nome: e.target.value,
                    }));
                  }}
                />
                <InputRightElement
                  children={
                    <Text fontSize="2xs" ml="1" mt="2">
                      {vendendor.nome.length}/40
                    </Text>
                  }
                />
              </InputGroup>
              <Text fontSize="xs" ml="1" mt="2" fontWeight={"semibold"}>
                O nome da loja só pode ser alterar 1 vez a cada 30 dias.
              </Text>
            </Flex>
          </Flex>
          <Button
            visibility={!vendendor?.pre_cadastro ? "visible" : "hidden"}
            variant="customOutline"
            w="100px"
            h="35px"
            p="1"
            onClick={() => {
              window.open(
                "https://deshopnise.com.br/vendedor/" + vendendor._id,
                "_blank",
                "noreferrer"
              );
            }}
          >
            <Flex align="center" justify="space-between" w="full">
              <Text pl="5px">Ver perfil</Text>
              <ChevronRightIcon width="16px" height="16px" color="#693ff3" />
            </Flex>
          </Button>
        </Flex>
        <InputGroup mt="2">
          <Textarea
            maxLength={500}
            defaultValue={loja.descricao}
            variant="filled"
            mt="5"
            placeholder={"Sua descrição"}
            onChange={(e) => {
              setVendedor((prev) => ({
                ...prev,
                descricao: e.target.value,
              }));
            }}
          />
          <InputRightElement
            children={
              <Text fontSize="2xs" ml="1" mt="120px">
                {vendendor.descricao.length}/500
              </Text>
            }
          />
        </InputGroup>
        <Box w="full" align="end" mt="5">
          <Button
            colorScheme="purple"
            size="sm"
            onClick={async () => {
              save(vendendor);
            }}
          >
            Salvar
          </Button>
        </Box>
      </Box>
      <BannerCropperDialog
        file={folder.plainFiles[0]}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        onSave={(cropped) => {
          setIsOpen(false);
          setBannerCropped(cropped);
        }}
      />
      <PerfilCropperDialog
        file={profile.plainFiles[0]}
        isOpen={isOpenProfile}
        onClose={() => {
          setIsOpenProfile(false);
        }}
        onSave={(cropped) => {
          setIsOpenProfile(false);
          setProfileCropped(cropped);
        }}
      />
    </>
  );
}
