import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Switch,
  Button,
  Spacer,
} from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import { Link } from "react-router-dom";
import FreteDialog from "../dialogs/FreteDialog";
import useAuthToken from "../../../hooks/useAuthToken";
import EnvioRepository from "../../../repositories/envio_repository";
import useEstabelecimento from "../../../hooks/useEstabelecimento";

function VarianteItem({
  variante,
  index,
  variantes,
  onFreteSelected,
  onChange,
  fretes,
}) {
  const [isOpen, setIsOpen] = useState(false);

  if (!fretes || fretes.length === 0) return <></>;

  return (
    <Box
      borderRadius={"8px"}
      background={"#F6F6F6"}
      maxW={"920px"}
      w={"100%"}
      mt="8px"
      key={index}
    >
      <FreteDialog
        fretes={fretes}
        isOpen={isOpen}
        onClose={(selected) => {
          if (selected) {
            onFreteSelected({
              frete_gratis: true,
              frete_gratis_envio_id: selected.delivery_method_id,
              preco_simulado_frete_gratis: selected.final_shipping_cost,
            });
          }

          setIsOpen(false);
        }}
      />
      <Flex
        direction="row"
        justifyContent={"space-between"}
        align="center"
        height={"130px"}
      >
        <Box
          visibility={variante.imagem ? "visible" : "hidden"}
          height={"100%"}
          w={"150px"}
          align={"center"}
          display={"flex"}
          justifyContent={"center"}
        >
          <img
            alt="imagem do produto"
            width={"90px"}
            height={"90px"}
            style={{
              objectFit: "contain",
              borderRadius: "8px",
            }}
            src={variante.imagem}
          ></img>
        </Box>
        <Box height={"100%"} w={"120px"}>
          <Flex
            direction={"column"}
            height={"100%"}
            justifyContent={"space-evenly"}
          >
            <Text fontWeight="bold" fontSize="13px">
              HABILITADO
            </Text>
            <Switch
              isChecked={variante.frete_gratis}
              value={variante.frete_gratis ?? false}
              onChange={(e) => {
                onChange({
                  variantes: variantes.map((v, i) => {
                    if (i === index) {
                      v.frete_gratis = e.target.checked;
                    }
                    return v;
                  }),
                });
              }}
            />
          </Flex>
        </Box>
        <Box height={"100%"} w={"130px"} mt={"55px"}>
          {Object.keys(variante.propriedades ?? {}).map((key) => {
            return (
              <Fragment key={key}>
                <Text fontWeight="bold" fontSize="12px">
                  {key.toUpperCase()}
                </Text>
                <Text fontSize="12px" fontWeight={"500"}>
                  {variante.propriedades[key]}
                </Text>
              </Fragment>
            );
          })}
        </Box>
        <Box height={"100%"} w={"150px"} pr={"10px"}>
          <Flex
            direction={"column"}
            height={"100%"}
            justifyContent={"space-evenly"}
          >
            <Text fontWeight="bold" fontSize="13px">
              PREÇO (R$)
            </Text>
            <NumericFormat
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              value={variante.preco_frete_gratis}
              prefix={"R$ "}
              customInput={Input}
              size={"md"}
              backgroundColor={"white"}
              onValueChange={({ floatValue }) => {
                onChange({
                  variantes: variantes.map((v, i) => {
                    if (i === index) {
                      v.preco_frete_gratis = floatValue;
                    }
                    return v;
                  }),
                });
              }}
            />
          </Flex>
        </Box>
        <Box height={"100%"} w={"150px"}>
          <Flex
            direction={"column"}
            height={"100%"}
            justifyContent={"space-evenly"}
          >
            <Text fontWeight="bold" fontSize="13px">
              Limite de estoque
            </Text>
            <Input
              width={"90px"}
              size={"md"}
              backgroundColor={"white"}
              value={variante.frete_gratis_estoque}
              onChange={(e) => {
                if (isNaN(Number(e.target.value))) return;
                if (Number(e.target.value) < 0) return;

                onChange({
                  variantes: variantes.map((v, i) => {
                    if (i === index) {
                      v.frete_gratis_estoque = e.target.value;
                    }
                    return v;
                  }),
                });
              }}
            ></Input>
          </Flex>
        </Box>
        <Box height={"100%"} w={"200px"}>
          <Flex
            direction={"column"}
            height={"100%"}
            pr={"20px"}
            justifyContent={"space-evenly"}
          >
            <Text fontWeight="bold" fontSize="13px">
              Modo de Envio
            </Text>
            <Button
              w={"150px"}
              fontWeight={"bold"}
              fontSize={"14px"}
              background={"transparent"}
              border={"2px solid #000"}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              {variante.preco_simulado_frete_gratis && (
                <>
                  {
                    fretes.find(
                      (e) =>
                        e.delivery_method_id === variante.frete_gratis_envio_id
                    ).logistic_provider_name
                  }
                  <br />
                </>
              )}

              {variante.preco_simulado_frete_gratis && (
                <>R$ {variante.preco_simulado_frete_gratis}</>
              )}

              {!variante.preco_simulado_frete_gratis && <>Escolha aqui</>}
              <Spacer />
              <span>
                <MdArrowForwardIos pr="5px" size={"12px"} />
              </span>
            </Button>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default function FreteGratis({
  variantes,
  onChange,
  initialValues,
  dadosEnvio,
  isFreteGratis,
  onToggleFreteGratis,
}) {
  const [preEncomenda, setPreEncomenda] = useState(false);
  const [fretes, setFretes] = useState([]);

  const token = useAuthToken();
  const envioRepo = new EnvioRepository({ token });
  const loja = useEstabelecimento();

  async function calcularFrete(peso, altura, largura, comprimento) {
    if (peso && altura && largura && comprimento) {
      await envioRepo
        .estimarCusto({
          vendedor_id: loja._id,
          cep_destino: "80240210",
          peso,
          largura,
          comprimento,
          altura,
          preco: null,
        })
        .then((res) => {
          setFretes([]);

          if (res.status === 200) {
            setFretes(res.data);

            // setErro(null);
          } else {
            // setErro(res.data.error);
          }
        });
    }
  }

  useEffect(() => {
    if (dadosEnvio) {
      calcularFrete(
        dadosEnvio.peso,
        dadosEnvio.altura,
        dadosEnvio.largura,
        dadosEnvio.comprimento
      );
    }
  }, [dadosEnvio]);

  return (
    <Box
      boxShadow="sm"
      w="full"
      mt="5px"
      borderRadius="8px"
      p="5"
      bgColor="white"
    >
      <Heading fontSize="md">Opção de Frete</Heading>
      <Text fontSize="xs" noOfLines="2">
        Habilite o campo abaixo e defina quais produtos ou variações você deseja
        anunciar com Frete Grátis. Para saber mais{" "}
        <Link
          style={{
            color: "blue",
            textDecoration: "underline",
          }}
          href=""
        >
          Clique aqui
        </Link>
      </Text>
      <Flex direction="row" align="center" mt="5">
        <Text fontWeight="bold" fontSize="sm">
          Frete Grátis
        </Text>
        <Text
          ml="2"
          fontSize="sm"
          noOfLines="2"
          color="grey"
          fontWeight="semibold"
        >
          Não
        </Text>
        <Switch
          isChecked={isFreteGratis}
          value={isFreteGratis}
          mx="2"
          onChange={(e) => {
            onToggleFreteGratis(e.target.checked);
          }}
        />
        <Text fontSize="sm" noOfLines="2" color="green" fontWeight="semibold">
          Sim
        </Text>
      </Flex>
      <Flex direction="column" align="start" mt="2">
        {variantes &&
          variantes.map((variante, index) => (
            <VarianteItem
              key={index}
              index={index}
              variante={variante}
              variantes={variantes}
              fretes={fretes}
              onFreteSelected={(frete) => {
                onChange({
                  variantes: variantes.map((v, i) => {
                    if (v._id === variante._id) {
                      return {
                        ...v,
                        ...frete,
                      };
                    }
                    return v;
                  }),
                });
              }}
              onChange={(selected) => {
                onChange(selected);
              }}
            />
          ))}
      </Flex>
    </Box>
  );
}
