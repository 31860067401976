import { Box, Button, Flex } from "@chakra-ui/react";
import React from "react";
import { MdPlace } from "react-icons/md";

function ActionsCard({
  data,
  onPrintEtiqueta = () => {},
  onNfe = () => {},
  isPj,
}) {
  return (
    <Box
      p={"20px"}
      boxShadow="sm"
      width={"100%"}
      borderRadius={"10px"}
      marginTop={"5px"}
      backgroundColor={"white"}
    >
      <Flex
        padding={"20px"}
        direction={"column"}
        justifyContent={"space-between"}
      >
        {data.status_pedido === "pago" && (
          <Button
            margin={"6px 0px"}
            background={"#663BFF"}
            color={"white"}
            padding={"25px"}
            onClick={onPrintEtiqueta}
          >
            Imprimir etiqueta de envio
          </Button>
        )}

        <Button
          onClick={() => {
            if (data?.url_rastreio) {
              window.open(data?.url_rastreio, "_blank");
            }
          }}
          margin={"6px 0px"}
          background={"#E6DFFF"}
          padding={"25px"}
        >
          Detalhes do Envio
        </Button>
        {data?.url_rastreio_reversa && (
          <Button
            onClick={() => {
              if (data?.url_rastreio_reversa) {
                window.open(data?.url_rastreio_reversa, "_blank");
              }
            }}
            margin={"6px 0px"}
            background={"#E6DFFF"}
            padding={"25px"}
          >
            Detalhes da Devolução
          </Button>
        )}

        {isPj && (
          <Button
            onClick={() => {
              onNfe();
            }}
            disabled={data?.fiscal.status === "Issued"}
            margin={"6px 0px"}
            background={"#E6DFFF"}
            padding={"25px"}
          >
            Informar NF-e
          </Button>
        )}
        <Button margin={"6px 0px"} background={"#E6DFFF"} padding={"25px"}>
          Preciso de Ajuda
        </Button>
      </Flex>

      <Box
        background={"#F7F7F7"}
        width={"80%"}
        margin={"0 auto"}
        borderRadius={"8px"}
      >
        <Flex dir={"row"} padding={"13px"}>
          <MdPlace fontSize={"25px"} color={"#663BFF"} />
          <Box width={"10px"} />
          <Flex direction={"column"}>
            <Box fontWeight={700}>Endereço de entrega</Box>
            <Box fontWeight={500} fontSize={"15px"}>
              {data.endereco.nome_completo} | {data.endereco.telefone}
            </Box>
            <Box fontWeight={500} fontSize={"15px"}>
              {data.endereco.rua} {data.endereco.numero}, {data.endereco.bairro}{" "}
            </Box>
            <Box fontWeight={500} fontSize={"15px"}>
              {data.endereco.cidade} - {data.endereco.estado} ,{" "}
              {data.endereco.cep} {data.endereco.complemento}
            </Box>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
}

export default ActionsCard;
