import {
  Box,
  Flex,
  Grid,
  Image,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import FilterBox from "./components/FilterBox";
import CuponsTr from "./components/CuponsTr";
import { useState } from "react";
import { useEffect } from "react";
import useAuthToken from "../../hooks/useAuthToken";
import CupomDeDescontoRepository from "../../repositories/cupom_de_desconto_repository";
import backAsset from "../../assets/back.png";

function Infos({ title, value, description }) {
  return (
    <Box backgroundColor="white" px="4" py="4" borderRadius="8">
      <Flex direction="column" lineHeight="1.1">
        <Text fontSize="sm" fontWeight="semibold">
          {title}
        </Text>
        <Text fontSize="2xl" fontWeight="bold" color="primary.400">
          {value}
        </Text>
        <Text fontSize="xs">{description}</Text>
      </Flex>
    </Box>
  );
}

export default function CuponsPage() {
  const token = useAuthToken();
  const repo = new CupomDeDescontoRepository({ token });

  const [cupons, setCupons] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    repo.consultar(filters).then((res) => {
      if (res.status === 200) {
        setCupons(res.data);
      }
    });
  }, [filters]);

  return (
    <PageScaffold>
      <Box w={"99%"} margin={"0 auto"}>
        <Box
          paddingTop={"32px"}
          paddingBottom={"24px"}
          fontWeight={"700"}
          fontSize={"22px"}
        >
          <Flex direction={"row"} alignItems={"center"}>
            <Image
              cursor={"pointer"}
              src={backAsset}
              height={"15px"}
              alt="Voltar"
              onClick={() => {
                window.history.back();
              }}
            />
            <Box width={"10px"} />
            <span>Cupons</span>
          </Flex>
        </Box>

        {/* <Grid templateColumns="repeat(4, 1fr)" gap={2} mt="2">
          <Infos
            title="Vendas"
            value="R$ 10223,43"
            description="7 dias anteriores 0%"
          />
          <Infos
            title="Pedidos"
            value="R$ 4843,47"
            description="7 dias anteriores 0%"
          />
          <Infos
            title="Taxa de Utilização"
            value="234"
            description="7 dias anteriores 0%"
          />
          <Infos
            title="Número de Compradores"
            value="321"
            description="7 dias anteriores 0%"
          />
        </Grid> */}

        <FilterBox
          onFilterChange={(props) => {
            setFilters(props);
          }}
          onReset={() => {
            setFilters({});
          }}
        />
        <Box
          boxShadow="sm"
          width={"100%"}
          borderRadius={"8px"}
          padding={"16px"}
          backgroundColor={"white"}
        >
          <Flex direction={"column"}>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th textTransform="none">Nome</Th>
                    <Th textTransform="none">Tipo</Th>
                    <Th textTransform="none">Desconto (R$)</Th>
                    <Th textTransform="none">Disponível</Th>
                    <Th textTransform="none">Usados</Th>
                    <Th textTransform="none">Status</Th>
                    <Th textTransform="none">Período</Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody fontWeight={600}>
                  {cupons.map((c) => (
                    <CuponsTr
                      key={c._id}
                      data={c}
                      onEnable={async (id) => {
                        await repo.ativar({ id });
                        repo.consultar().then((res) => {
                          if (res.status === 200) {
                            setCupons(res.data);
                          }
                        });
                      }}
                      onDisable={async (id) => {
                        await repo.inativar({ id });
                        repo.consultar().then((res) => {
                          if (res.status === 200) {
                            setCupons(res.data);
                          }
                        });
                      }}
                    />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        </Box>
      </Box>
    </PageScaffold>
  );
}
