import { buildHttpClient } from "../services/api";
import axios from "axios";

class RaspadinhasRepository {
  constructor({ token }) {
    this.http = buildHttpClient(token);

    this.http.interceptors.request.use(
      function (config) {
        if (!config.headers.token) {
          throw new axios.Cancel("");
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  consultar(params) {
    return this.http.post("/raspadinhas/consultar", params);
  }

  cadastrar(params) {
    return this.http.post("/raspadinhas/cadastrar", params);
  }

  encerrar(params) {
    return this.http.post("/raspadinhas/encerrar", params);
  }
}

export default RaspadinhasRepository;
