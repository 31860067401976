import React from "react";
import {
  Flex,
  Box,
  Input,
  FormControl,
  Button,
  Text,
  Image,
  CircularProgress,
  InputGroup,
  InputRightElement,
  Checkbox,
  useToast,
} from "@chakra-ui/react";

import LoginBackground from "./../../assets/loginvendedordeshopnise.png";
import logo from "./../../assets/logo.png";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/authContext";
import { useNavigate } from "react-router-dom";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import FirebaseAuthService from "../../services/firebase_auth_service";

export default function CadastroPage() {
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();
  const authContext = useContext(AuthContext);

  const [isPageReady, setIsPageReady] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [terms, setTerms] = useState(false);

  const onSubmited = async () => {
    if (!terms) {
      toast({
        title:
          "Você precisa aceitar os termos de uso e privacidade para continuar.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!email) {
      toast({
        description: "Campo e-mail não preenchido",
        status: "error",
      });
      return;
    }

    if (password.length < 6) {
      toast({
        description: "A senha precisa ter pelo menos 6 caracters",
        status: "error",
      });
      return;
    }

    // const firebaseAuthService = new FirebaseAuthService();
    // const userExists = await firebaseAuthService.fetchSignInMethodsForEmail({
    //   email,
    // });

    // if (userExists > 0) {
    //   return toast({
    //     title: "O e-mail informado já está cadastrado.",
    //     status: "error",
    //     duration: 5000,
    //     isClosable: true,
    //   });
    // }

    const res = await authContext.createEstabelecimento({
      nome: name,
      email,
      senha: password,
    });

    if (res) {
      const user = await context.signInEmailAndPassword({
        email,
        password,
      });

      window.location.href = "/";
    }

    // setLoading(true);

    // setLoading(false);

    // onLoginCompleted(user);
  };

  if (!isPageReady) {
    return <CircularProgress />;
  }

  return (
    <>
      <Flex direction="row" h="100vh" alignItems="center">
        <Flex
          w="20%"
          minW={["100%", "550px"]}
          maxW="700px"
          direction="column"
          px={["0", "5"]}
        >
          <Box p={8} minW="200px" w="full" h="full" alignItems="start">
            <Box mb="10" w="full">
              <Image
                src={logo}
                style={{ filter: "invert(1) grayscale(1)" }}
                maxWidth="200px"
                objectFit="contain"
                alt="Deshopnise logo"
                alignSelf="center"
                display="inline-flex"
              />
              <Text size="xl" fontWeight="medium">
                Seja bem vindo a <b>Central do Vendedor.</b>
                <br />
                Crie seus anúncios de forma fácil e prática.
              </Text>
            </Box>

            <Box>
              <form>
                <FormControl isRequired>
                  <Text fontWeight="medium">Nome</Text>
                  <Input
                    variant={"filled"}
                    placeholder="Digite o seu nome completo"
                    size="lg"
                    mb="2"
                    onChange={(e) => setName(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <Text fontWeight="medium">Email</Text>
                  <Input
                    variant={"filled"}
                    placeholder="Digite seu e-mail"
                    size="lg"
                    mb="2"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormControl>

                <FormControl isRequired>
                  <Text fontWeight="medium">Senha</Text>
                  <InputGroup size="md">
                    <Input
                      variant={"filled"}
                      type={show ? "text" : "password"}
                      placeholder="Informe a senha"
                      size="lg"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <InputRightElement
                      pt="5px"
                      onClick={() => setShow(!show)}
                      children={show ? <MdVisibility /> : <MdVisibilityOff />}
                    />
                  </InputGroup>
                </FormControl>
                <Flex
                  width="full"
                  px="8"
                  mt="3"
                  mb="3"
                  style={{
                    fontWeight: "500",
                  }}
                >
                  <Checkbox
                    value={terms}
                    onChange={(e) => {
                      setTerms(e.target.checked);
                    }}
                    scale={4}
                    size="lg"
                  />
                  <Text fontSize="12px" mt="2" ml="5">
                    Ao clicar aqui. Eu concordo com os{" "}
                    <a
                      style={{
                        cursor: "pointer",
                        color: "#7E5EFA",
                        textDecoration: "underline",
                      }}
                      target="_blank"
                      href="https://www.central.deshopnise.com.br/termos-de-uso-e-servicos"
                      rel="noreferrer"
                    >
                      Termos de uso{" "}
                    </a>
                    e
                    <a
                      target="_blank"
                      style={{
                        cursor: "pointer",
                        color: "#7E5EFA",
                        textDecoration: "underline",
                      }}
                      href="https://www.central.deshopnise.com.br/politicas-de-privacidadeds"
                      rel="noreferrer"
                    >
                      {" "}
                      políticas de privacidade{" "}
                    </a>
                    da Deshopnise e declado ser maior de idade de acordo com a
                    legislação aplicável.
                  </Text>
                </Flex>

                {/* <Button
                  width="full"
                  colorScheme="purple"
                  backgroundColor={"primary.400"}
                  mt="2"
                  size="lg"
                  onClick={async () => {
                    setLoading(true);

                    const user = await context.signInEmailAndPassword({
                      email,
                      password,
                    });

                    setLoading(false);

                    onLoginCompleted(user);

                    // setTimeout(() => {
                    //   onLoginCompleted(user);
                    // }, 1500);
                  }}
                >
                  {loading ? <CircularProgress isIndeterminate /> : "Entrar"}
                </Button> */}
                <Button
                  width="full"
                  colorScheme="purple"
                  backgroundColor={"#009E45"}
                  mt="2"
                  size="lg"
                  onClick={onSubmited}
                >
                  {loading ? (
                    <CircularProgress isIndeterminate />
                  ) : (
                    "Cadastre-se"
                  )}
                </Button>

                {/* <Flex align="center">
                <Divider />
                <Text padding="2">ou</Text>
                <Divider />
              </Flex>
              <Button
                width="full"
                colorScheme="purple"
                variant="outline"
                size="lg"
                onClick={() => navigate("/cadastro")}
              >
                Cadastre-se
              </Button> */}
              </form>
            </Box>
          </Box>
        </Flex>
        <Image
          display={{ base: "none", md: "block" }}
          w="80%"
          h="full"
          fit="cover"
          src={LoginBackground}
        />
      </Flex>
    </>
  );
}
