import React, { useContext, useEffect } from "react";
import PageScaffold from "../../components/PageScaffold";

import {
  Box,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  Flex,
  Button,
  Center,
  Spacer,
} from "@chakra-ui/react";

import CustomTabButtonComponent from "../../components/CustomTabButtonComponent";
import TabEnvios from "./components/TabEnvios";
import TabPendentes from "./components/TabPendentes";
import TabEnviados from "./components/TabEnviados";
import TabFinalizados from "./components/TabFinalizados";
import TabCancelados from "./components/TabCancelados";
import TabDevolucoes from "./components/TabDevolucoes";
import FilterBox from "./components/FilterBox";
import PedidosRepository from "../../repositories/pedidos_repository";
import useAuthToken from "../../hooks/useAuthToken";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { MdBook, MdChevronRight, MdReceipt, MdTag } from "react-icons/md";
import Chat from "../../components/Chat";
import { ChatContext } from "../../contexts/chatContext";
import CertificadoComponent from "./components/Certificado";

export default function EnviosPage() {
  const token = useAuthToken();
  const estabelecimento = useEstabelecimento();
  const chatContext = useContext(ChatContext);
  const repo = new PedidosRepository({ token });
  const navigate = useNavigate();
  const filtroPorTab = [
    null, // todos os pedidos
    "pago",
    "enviado",
    "entregue",
    "cancelado",
    // "devolvido",
    "disputa",
  ];

  const [pedidos, setPedidos] = React.useState(null);
  const [tab, setTab] = React.useState();
  const [filters, setFilters] = React.useState({});
  const [currentPage, setCurrentPage] = React.useState(1);

  const [params] = useSearchParams();

  useEffect(() => {
    if (params.get("target") === "cancelados") {
      setTab("cancelado");
    } else if (params.get("target") === "devolucoes") {
      setTab("disputa");
    } else if (params.get("target") === "a_enviar") {
      setTab("pago");
    } else {
      setTab(null);
    }
  }, [params]);

  const fetch = async () => {
    const res = await repo.consultar({
      estabelecimento_id: estabelecimento._id,
      page: currentPage,
      status_pedido: tab,
      ...filters,
    });

    if (res.status === 200) {
      return res.data;
    }

    return [];
  };

  useEffect(() => {
    setPedidos(null);
    setCurrentPage(1);

    if (tab === undefined) {
      return;
    }

    fetch().then((res) => {
      setPedidos(res);
    });
  }, [tab, filters]);

  useEffect(() => {
    if (currentPage === 1 || pedidos === null) {
      return;
    }

    fetch().then((res) => {
      setPedidos([...pedidos, ...res]);
    });
  }, [currentPage]);

  const openChat = (usuario) => {
    chatContext.openChatWith({
      usuario_id: usuario,
    });
  };

  if (estabelecimento.status === "bloqueado") {
    return (
      <PageScaffold showSideBar={true}>
        <Center h={"90vh"}>
          <Text fontWeight={"600"}>Sua conta foi bloqueada</Text>
        </Center>
      </PageScaffold>
    );
  }

  return (
    <PageScaffold showSideBar={true}>
      <Box w={"100%"}>
        <Box fontWeight={"700"} fontSize={"22px"}>
          <Flex direction="row" justify={"space-between"} my="5">
            <Flex
              direction="row"
              alignContent={"center"}
              alignItems={"center"}
              align={"center"}
            >
              <Link to="/meus-envios">
                <Box
                  px="5"
                  py="2"
                  borderRadius="32"
                  backgroundColor="primary.400"
                  mr="30px"
                  color="white"
                  fontSize="lg"
                >
                  <Text>Meus Envios</Text>
                </Box>
              </Link>
              <Link to="/envio-em-massa">
                <Box
                  px="5"
                  py="2"
                  borderRadius="32"
                  backgroundColor="transparent"
                  mr="30px"
                  color="grey"
                  fontSize="lg"
                >
                  <Text>Enviar em massa</Text>
                </Box>
              </Link>
              <Link to="/config-de-envio">
                <Box
                  px="5"
                  py="2"
                  borderRadius="32"
                  backgroundColor="transparent"
                  mr="30px"
                  color="grey"
                  fontSize="lg"
                >
                  <Text>Configurações</Text>
                </Box>
              </Link>
              <a
                rel="noreferrer noopener"
                target="_blank"
                href="https://www.central.deshopnise.com.br/como-enviar-produtos"
              >
                <Box
                  px="5"
                  py="2"
                  borderRadius="32"
                  backgroundColor="transparent"
                  mr="30px"
                  color="grey"
                  fontSize="lg"
                >
                  <Text>Como enviar?</Text>
                </Box>
              </a>

              {true && (
                <Box
                  visibility={
                    estabelecimento.pre_cadastro === false &&
                    estabelecimento.tipo_cadastro === "pessoa_juridica" &&
                    estabelecimento.dados_fiscais?.nfeio_id === null
                      ? "visible"
                      : "hidden"
                  }
                  onClick={() => {
                    navigate("/certificado-digital");
                  }}
                  shadow={"sm"}
                  boxShadow="sm"
                  maxW={"100%"}
                  my="7px"
                  borderRadius="8px"
                  px="5"
                  py="4"
                  bgColor="#7C5EFA"
                  color={"white"}
                  h="fit-content"
                  className="pulse"
                  cursor={"pointer"}
                >
                  <Flex align="center">
                    <CertificadoComponent />
                    <Flex direction={"column"} lineHeight="15px" ml={"4"}>
                      <b>
                        <Text fontSize="15px">
                          Colocar o certificado digital
                        </Text>
                      </b>
                      <Text fontSize="14px" fontWeight="medium" pt={"2px"}>
                        Clique aqui, para preencher as informações do seu
                        certificado digital e emitir notas e etiquetas de envio
                      </Text>
                    </Flex>
                    <Spacer />
                    <MdChevronRight color="white" size={"35px"} />
                  </Flex>
                </Box>
              )}
            </Flex>
            {/* <Flex direction="row">
              <Button
                variant="outline"
                colorScheme="purple"
                fontSize="sm"
                borderWidth={2}
              >
                <MdReceipt style={{ marginRight: "5px" }} /> Informar NF em
                massa
              </Button>
              <Box width="10px" />
              <Button colorScheme="purple" bg="#693FF3" fontSize="sm">
                <MdReceipt style={{ marginRight: "5px" }} />
                Enviar em massa
              </Button>
            </Flex> */}
          </Flex>
        </Box>
        <FilterBox
          onFilterChange={(props) => {
            setFilters(props);
          }}
          setTab={(v) => {
            setTab(filtroPorTab.at(v));
          }}
          currentTab={filtroPorTab.indexOf(tab)}
          numeroPedidos={pedidos?.length}
        />
        <Box
          boxShadow="sm"
          width={["75vw", "75vw", "75vw", "75vw", "100%"]}
          minHeight={"88vh"}
          borderRadius={"8px"}
          marginTop={"5px"}
          backgroundColor={"white"}
        >
          <Tabs
            index={filtroPorTab.indexOf(tab)}
            onChange={(index) => {
              // setTab(filtroPorTab.at(index));
            }}
          >
            <TabList>
              {/* <CustomTabButtonComponent>Todos</CustomTabButtonComponent>
              <CustomTabButtonComponent>A Enviar</CustomTabButtonComponent>
              <CustomTabButtonComponent>Enviados</CustomTabButtonComponent>
              <CustomTabButtonComponent>Concluídos</CustomTabButtonComponent>
              <CustomTabButtonComponent>Cancelados</CustomTabButtonComponent>
              <CustomTabButtonComponent>Devolução</CustomTabButtonComponent> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <TabEnvios
                  data={pedidos}
                  isLoading={pedidos === null}
                  onLoadMore={() => {
                    setCurrentPage(() => currentPage + 1);
                  }}
                  openChatWith={openChat}
                />
              </TabPanel>
              <TabPanel>
                <TabPendentes
                  data={pedidos}
                  isLoading={pedidos === null}
                  onLoadMore={() => {
                    setCurrentPage(() => currentPage + 1);
                  }}
                  openChatWith={openChat}
                />
              </TabPanel>
              <TabPanel>
                <TabEnviados
                  data={pedidos}
                  isLoading={pedidos === null}
                  onLoadMore={() => {
                    setCurrentPage(() => currentPage + 1);
                  }}
                  openChatWith={openChat}
                />
              </TabPanel>
              <TabPanel>
                <TabFinalizados
                  data={pedidos}
                  isLoading={pedidos === null}
                  onLoadMore={() => {
                    setCurrentPage(() => currentPage + 1);
                  }}
                  openChatWith={openChat}
                />
              </TabPanel>
              <TabPanel>
                <TabCancelados
                  data={pedidos}
                  isLoading={pedidos === null}
                  onLoadMore={() => {
                    setCurrentPage(() => currentPage + 1);
                  }}
                  openChatWith={openChat}
                />
              </TabPanel>
              <TabPanel>
                <TabDevolucoes
                  data={pedidos}
                  isLoading={pedidos === null}
                  onLoadMore={() => {
                    setCurrentPage(() => currentPage + 1);
                  }}
                  openChatWith={openChat}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
    </PageScaffold>
  );
}
