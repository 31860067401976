import axios from "axios";

const DEV_MODE = false;

const buildHttpClient = (token) =>
  axios.create({
    baseURL: DEV_MODE
      ? "http://localhost:3001"
      : "https://api.deshopnise.com.br",
    timeout: 15000,
    headers: {
      Accept: "application/json",
      client: "desconto_parceiros",
      token,
    },
    validateStatus: function () {
      return true;
    },
  });

export { buildHttpClient };
