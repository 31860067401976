import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";

import {
  MdLocalShipping,
  MdLocalMall,
  MdAllInbox,
  MdRadar,
  MdOutlineAttachMoney,
  MdStore,
  MdOutlineSettings,
  MdEngineering,
  MdAnalytics,
  MdSettings,
  MdLogout,
  MdOutlet,
  MdBusinessCenter,
  MdDesignServices,
  MdMoney,
  MdAccountBalanceWallet,
} from "react-icons/md";
import SideBarTile from "./components/SideBarTile";
import { Link } from "react-router-dom";
import logo from "./../../assets/logo.png";

import { ReactComponent as Analytics } from "./../../assets/siderbar/analitcs.svg";

import { ReactComponent as Carteira } from "./../../assets/siderbar/carteira.svg";
import { ReactComponent as Configuracoes } from "./../../assets/siderbar/configuracoes.svg";
import { ReactComponent as Envios } from "./../../assets/siderbar/envios.svg";
import { ReactComponent as Loja } from "./../../assets/siderbar/loja.svg";
import { ReactComponent as Marketing } from "./../../assets/siderbar/marketing.svg";
import { ReactComponent as Produto } from "./../../assets/siderbar/novoproduto.svg";
import { ReactComponent as Pedido } from "./../../assets/siderbar/pedido.svg";
import { ReactComponent as Sair } from "./../../assets/siderbar/sair.svg";
import { ReactComponent as Servico } from "./../../assets/siderbar/servico.svg";

export default function SideBar() {
  return (
    <Box
      boxShadow="sm"
      width={"250px"}
      backgroundColor={"#232544"}
      color="white"
      h="100vh"
    >
      <Flex direction="column" justifyContent="space-between" h="90vh">
        <Flex direction="column">
          <Flex align="center">
            <Link to="/">
              <Image
                py="5"
                px="8"
                src={logo}
                objectFit="contain"
                alt="Deshopnise"
              />
            </Link>
          </Flex>

          <SideBarTile
            key={"Analytics"}
            label={"Analytics"}
            target={"/"}
            icon={<Analytics size={"20px"} />}
          />

          <Box h="10px"></Box>

          <SideBarTile
            key={"Envios"}
            label={"Envios"}
            target={"/meus-envios"}
            allTargets={["/envio-em-massa", "/meus-envios", "/config-de-envio"]}
            icon={<Envios size={"20px"} />}
          />
          {/* 
          <Box h="10px"></Box>

          <SideBarTile
            key={"Pedidos"}
            label={"Pedidos"}
            target={"/meus-envios"}
            icon={<MdLocalMall size={"20px"} color={"#8E51FF"} />}
          /> */}

          <Box h="10px"></Box>

          <SideBarTile
            key={"Produtos"}
            label={"Produtos"}
            target={"/meus-produtos"}
            icon={<Produto size={"20px"} />}
          />

          {/* <Box h="10px"></Box>

          <SideBarTile
            key={"Serviços"}
            label={"Serviços"}
            target={"/meus-servicos"}
            icon={<Servico size={"20px"} />}
          /> */}

          <Box h="10px"></Box>

          <SideBarTile
            key={"Marketing"}
            label={"Marketing"}
            target={"/central-de-marketing"}
            icon={<Marketing size={"20px"} />}
          />

          <Box h="10px"></Box>
          <SideBarTile
            key={"Loja"}
            label={"Loja"}
            target={"/meu-perfil"}
            icon={<Loja size={"20px"} />}
          />
          <Box h="10px"></Box>
          <SideBarTile
            key={"Carteira"}
            label={"Carteira"}
            target={"/meus-lucros"}
            icon={<Carteira size={"20px"} />}
          />
        </Flex>

        <Flex direction="column">
          <SideBarTile
            key={"Configurações"}
            label={"Configurações"}
            target={"/configuracoes"}
            icon={<Configuracoes size={"20px"} />}
          />

          <Box h="10px" />

          <SideBarTile
            key={"Logout"}
            label={"Logout"}
            target={"/login"}
            icon={<Sair size={"20px"} />}
          />
        </Flex>
      </Flex>

      <Box h="10px"></Box>

      {/* <SideBarTile
        key={"Envios"}
        label={"Envios"}
        icon={<MdLocalShipping size={"20px"} color={"#8E51FF"} />}
        itens={[
          {
            label: "Meus Envios",
            target: "/meus-envios",
          },
          {
            label: "Envio em massa",
            target: "/envio-em-massa",
          },
          {
            label: "Config de envio",
            target: "/config-de-envio",
          },
        ]}
      />

      <SideBarTile
        key={"Pedidos"}
        label={"Pedidos"}
        icon={<MdLocalMall size={"20px"} color={"#8E51FF"} />}
        itens={[
          // {
          //   label: "Meus Pedidos",
          //   target: "/meus-envios",
          // },
          {
            label: "Pedidos Cancelados",
            target: "/meus-envios?target=cancelados",
          },
          {
            label: "Devoluções",
            target: "/meus-envios?target=devolucoes",
          },
        ]}
      />

      <SideBarTile
        key={"Produtos"}
        icon={<MdAllInbox size={"20px"} color={"#8E51FF"} />}
        label={"Produtos"}
        itens={[
          {
            label: "Meus Produtos",
            target: "/meus-produtos",
          },
          {
            label: "Novo Produto",
            target: "/novo-produto",
          },
          {
            label: "Raspadinha",
            target: "/raspadinha",
          },
        ]}
      />

      <SideBarTile
        key={"servicos"}
        icon={<MdEngineering size={"20px"} color={"#8E51FF"} />}
        label={"Serviços"}
        itens={[
          {
            label: "Meus Serviços",
            target: "/meus-servicos",
          },
          {
            label: "Novo Serviço",
            target: "/novo-servico",
          },
        ]}
      />

      <SideBarTile
        key={"Marketing"}
        icon={<MdRadar size={"20px"} color={"#8E51FF"} />}
        label={"Marketing"}
        itens={[
          {
            label: "Central de marketing",
            target: "/central-de-marketing",
          },
          {
            label: "Meus cupons",
            target: "/cupons",
          },
          {
            label: "Deshopnise ADS",
            target: "/deshopnise-ads",
          },
        ]}
      />

      <SideBarTile
        key={"Finanças"}
        icon={<MdOutlineAttachMoney size={"20px"} color={"#8E51FF"} />}
        label={"Finanças"}
        itens={[
          {
            label: "Meus Lucros",
            target: "/meus-lucros",
          },
          {
            label: "A Receber",
            target: "/meus-lucros?target=a_receber",
          },
          {
            label: "Config bancárias",
            target: "/config-bancarias",
          },
        ]}
      />

      <SideBarTile
        key={"Loja"}
        icon={<MdStore size={"20px"} color={"#8E51FF"} />}
        label={"Loja"}
        itens={[
          {
            label: "Meu Perfil",
            target: "/meu-perfil",
          },
          {
            label: "Avaliações",
            target: "/avaliacoes",
          },
        ]}
      />

      <SideBarTile
        key={"Configurações"}
        icon={<MdOutlineSettings size={"20px"} color={"#8E51FF"} />}
        label={"Configurações"}
        itens={[
          {
            label: "Meus endereços",
            target: "/meus-enderecos",
          },
          {
            label: "Configurações da Loja",
            target: "/meu-perfil?target=configuracoes-loja",
          },
          {
            label: "Configurações NF-e",
            target: "/config-fiscais",
          },
        ]}
      /> */}
    </Box>
  );
}
