import {
  Box,
  Flex,
  Heading,
  Text,
  Select,
  Button,
  useToast,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { useState, useRef, useCallback } from "react";
import Categorias from "./components/Categorias";
import { MdArrowBack, MdModeEdit } from "react-icons/md";
import VariacoesNovoProduto from "./components/Variacoes";
import InformacoesFiscaisNovoProduto from "./components/Fiscais";
import OpcoesEnvioNovoProduto from "./components/Frete";
import PrazoPostagemNovoProduto from "./components/PreEncomenda";
import VariacaoNovoProduto from "./components/Variacao";
import useAuthToken from "../../hooks/useAuthToken";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import AnunciosRepository from "../../repositories/anuncios_repository";
import EspecificacoesRepository from "../../repositories/especificacoes_repository";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import InformacoesBase from "./components/InformacoesBase";
import Especificacoes from "./components/Especificacoes";
import CancelamentoDialog from "../../components/CancelamentoDialog";
import FreteGratis from "./components/FreteGratis";

export default function NovoAnuncio({ tipoAnuncio = "produto" }) {
  const token = useAuthToken();
  const estabelecimento = useEstabelecimento();
  const toast = useToast();
  const navigate = useNavigate();

  const params = useParams();

  const repo = new AnunciosRepository({ token });
  const specsRepo = new EspecificacoesRepository({ token });
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => setIsOpen(false);

  const [multiVariantes, setMultiVariantes] = useState(false);

  const [fretes, setFretes] = useState([]);

  const [anuncio, setAnuncio] = useState({
    titulo: "",
    descricao: "",
    imagens: [],
    status: "ativo",
    tipo: tipoAnuncio,
    anuncio_em_destaque: false,
    anuncio_premiado: true,
    anuncio_usado: false,
    premio_id: "",
    variantes: [
      {
        imagem: null,
        sku: "UNICA",
        vendas: 0,
        estoque: 0,
        preco: 0,
      },
    ],
    vendedor: {},
    dados_frete: {
      frete_gratis: false,
      peso: null,
      altura: null,
      largura: null,
      comprimento: null,
      empresas_disponiveis: [],
      pre_encomenda: false,
      dias_prazo_postagem: 0,
    },
    categorias: [],
    dados_metricas: {
      qtd_visitas: 0,
      qtd_vendas_total: 0,
      qtd_estoque_total: 0,
      qtd_avaliacoes: 0,
      media_avaliacoes: 0,
    },
    denuncias: [],
    especificacoes: {},
    metadados: {},
    imagens_descricao: [],
  });

  const [etapa, setEtapa] = useState(
    params.id || tipoAnuncio === "servico"
      ? "dados-produto"
      : "selecao-categoria"
  );

  const [especificacoes, setEspecificacoes] = useState([]);

  useEffect(() => {
    if (params.id) {
      loadAnuncio(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    if (estabelecimento?.pre_cadastro) {
      setEtapa("pre_cadastro");
      return;
    }

    if (estabelecimento.restrito && !params.id) {
      setEtapa("bloqueado");
    }
  }, [estabelecimento]);

  useEffect(() => {}, [anuncio]);

  const loadAnuncio = async (id) => {
    const res = await repo.consultar({ id });

    if (res.status !== 200) {
      toast({
        title: "Erro",
        description: res.data["error"],
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setAnuncio(res.data);

    if (res.data.variantes.length > 1) {
      setMultiVariantes(true);
    }

    loadEspecificacoes(res.data.categorias.map((e) => e.id));
  };

  const irParaDadosProduto = () => {
    setEtapa("dados-produto");
  };

  const cancelar = () => {
    setIsOpen(true);
  };

  const CATEGORIAS = [
    {
      id: "8483a593-2760-4b2d-8e41-cf1dcdcc0f3e",
      nome: "Gastronomia",
      explicito: false,
    },
    {
      id: "d3b84a65-6613-474a-9468-010fa50ccbc3",
      nome: "Entretenimento",
      explicito: false,
    },
    {
      id: "e4f7b390-c3ee-4f07-98b8-cec3b4d0deab",
      nome: "Viagens",
      explicito: false,
    },
    {
      id: "69364f59-d5f4-43e3-9b2d-679e16eb7d4e",
      nome: "Hotéis e Pousadas",
      explicito: false,
    },
    {
      id: "9e98df59-0364-4707-a1ca-fed1f4a2e9a7",
      nome: "Beleza e Perfumaria",
      explicito: false,
    },
    {
      id: "08a9f85e-eb79-48bf-bcfb-afe600a581ba",
      nome: "Produtos",
      explicito: false,
    },
    {
      id: "255c2513-d11d-42d6-8d75-0e5c1f2ce354",
      nome: "Serviços",
      explicito: false,
    },
    {
      id: "58e6e6c6-7b11-4768-bd69-41c3b26a12a7",
      nome: "Informática",
      explicito: false,
    },
    {
      id: "f23cdce8-8a55-4b40-ac3a-7e9f26ff1390",
      nome: "Saúde",
      explicito: false,
    },
    {
      id: "3c8016ea-a2bb-4724-9630-b780248aecec",
      nome: "Eletrodomésticos",
      explicito: false,
    },
    {
      id: "c5c20f8e-e854-4d04-ad6f-8fbea0497185",
      nome: "Esportes e Fitness",
      explicito: false,
    },
    {
      id: "eca34e7d-4228-4dee-b794-5fae654835ba",
      nome: "Ferramentas e Construção",
      explicito: false,
    },
    {
      id: "2f96340d-3887-4faa-9aa8-11325a856fe5",
      nome: "Moda",
      explicito: false,
    },
    {
      id: "e2c93ec9-5b59-4a9e-9660-24d914583555",
      nome: "Brinquedos",
      explicito: false,
    },
    {
      id: "374157fc-e3da-4b1a-a41a-a3c24cda8d06",
      nome: "Móveis e Decoração",
      explicito: false,
    },
    {
      id: "89bae51d-d01b-45dd-a3ff-dadd7875186e",
      nome: "Varejo",
      explicito: false,
    },
  ];

  const loadEspecificacoes = async (ids) => {
    if (ids.length === 0) return;

    const res = await specsRepo.consultar({
      categorias_id: ids,
    });

    if (res.status !== 200) return;

    setEspecificacoes(res.data);
  };

  const onSave = async ({ rascunho }) => {
    if (rascunho) anuncio.status = "rascunho";
    if (!rascunho) anuncio.status = "ativo";
    if (params.id) {
      const res = await repo.atualizar(anuncio);

      if (res.status === 200) {
        toast({
          title: "Sucesso",
          description: anuncioTipo + " atualizado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });

        navigate(-1);
      } else {
        toast({
          title: "Erro",
          description: res.data["error"],
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } else {
      const res = await repo.cadastrar(anuncio);

      if (res.status === 200) {
        toast({
          title: "Sucesso",
          description: anuncioTipo + " cadastrado com sucesso",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate(-1);
      } else {
        toast({
          title: "Erro",
          description: res.data["error"],
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  if (params.id && anuncio.variantes.length === 0) return <></>;
  const anuncioTipo = tipoAnuncio === "produto" ? "Produto" : "Serviço";

  return (
    <>
      <PageScaffold showSideBar={true} showChat={false}>
        <Flex direction={"column"}>
          <Box
            paddingTop={"32px"}
            paddingBottom={"24px"}
            fontWeight={"700"}
            fontSize={"22px"}
          >
            <Flex justify="space-between">
              <Flex direction="column">
                <Flex direction="row" align="center">
                  <MdArrowBack
                    cursor="pointer"
                    size="25px"
                    onClick={() => {
                      navigate(-1);
                    }}
                  />
                  <Box w="5px" />
                  <span>
                    {etapa === "selecao-categoria"
                      ? "Categoria do anúncio"
                      : "Novo anúncio"}
                  </span>
                </Flex>

                <Text fontSize="14px" fontWeight={500}>
                  {etapa === "selecao-categoria"
                    ? "Escolha abaixo a categoria do seu anúncio"
                    : "Preencha os campos abaixo com as informações "}
                </Text>
              </Flex>
            </Flex>
          </Box>
          {etapa === "selecao-categoria" && (
            <Box
              boxShadow="sm"
              w="full"
              mt="5px"
              borderRadius="8px"
              p="5"
              bgColor="white"
              h="fit-content"
            >
              <Categorias
                onSelect={(c) => {
                  setAnuncio((a) => ({
                    ...a,
                    categorias: c,
                  }));

                  irParaDadosProduto();

                  if (tipoAnuncio === "produto" && c.length > 0) {
                    loadEspecificacoes(c.map((e) => e.id) || []);
                  }
                }}
              />
            </Box>
          )}

          {etapa === "bloqueado" && (
            <Text fontWeight={"bold"} color={"red"}>
              Você não pode criar novos anúncios no momento.
            </Text>
          )}

          {etapa === "pre_cadastro" && (
            <Flex direction={"column"}>
              <Text fontWeight={"bold"}>
                Faça a ativação da sua conta para criar novos anúncios.
              </Text>
              <Box mt="8px">
                <Button
                  colorScheme="purple"
                  backgroundColor={"#009E45"}
                  onClick={() => {
                    window.location.href = "/completar-cadastro";
                  }}
                >
                  Ativar conta
                </Button>
              </Box>
            </Flex>
          )}

          {etapa === "dados-produto" && (
            <Flex direction="column">
              <Box
                boxShadow="sm"
                w="full"
                mt="5px"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Heading fontSize="md">Categoria</Heading>

                {tipoAnuncio === "produto" && (
                  <Flex align="center">
                    <Text fontWeight="medium" color="grey" mr="2">
                      {anuncio.categorias.map((e) => e.nome).join(" > ")}
                    </Text>
                    <MdModeEdit
                      cursor="pointer"
                      size="25px"
                      onClick={() => {
                        setEtapa("selecao-categoria");
                      }}
                    />
                  </Flex>
                )}

                {tipoAnuncio === "servico" && (
                  <Flex align="center">
                    <Select
                      pt={2}
                      size="sm"
                      fontWeight={500}
                      defaultValue={"0"}
                      maxW="250px"
                      placeholder="Selecione"
                      onChange={(e) => {
                        setAnuncio((a) => ({
                          ...a,
                          categorias: [
                            CATEGORIAS.find((c) => c.id === e.target.value),
                          ],
                        }));
                      }}
                    >
                      {CATEGORIAS.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.nome}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                )}
              </Box>
              <InformacoesBase value={anuncio} onChange={setAnuncio} />

              {tipoAnuncio === "produto" && (
                <Especificacoes
                  source={especificacoes}
                  anuncio={anuncio}
                  onChange={(prop) => {
                    setAnuncio((a) => ({
                      ...a,
                      especificacoes: {
                        ...a.especificacoes,
                        ...prop,
                      },
                    }));
                  }}
                />
              )}

              {tipoAnuncio === "produto" && (
                <Box
                  boxShadow="sm"
                  w="full"
                  mt="5px"
                  borderRadius="8px"
                  p="5"
                  bgColor="white"
                >
                  <Heading fontSize="md">Condição do {anuncioTipo}</Heading>
                  <Select
                    size="md"
                    value={anuncio.anuncio_usado ? "usado" : "novo"}
                    onChange={(e) => {
                      setAnuncio((a) => ({
                        ...a,
                        anuncio_usado: e.target.value === "usado",
                      }));
                    }}
                    fontWeight={500}
                    placeholder="Selecione"
                    variant={"filled"}
                    w="30%"
                    mt="2"
                  >
                    <option value="novo">Novo</option>
                    <option value="usado">Usado</option>
                  </Select>
                </Box>
              )}

              {multiVariantes ? (
                <VariacoesNovoProduto
                  tipo={tipoAnuncio}
                  canSwitch={!params.id}
                  value={anuncio.variantes}
                  onChange={(dados) => {
                    setAnuncio((a) => ({
                      ...a,
                      variantes: dados,
                    }));
                  }}
                  onClose={() => {
                    setMultiVariantes(false);
                    setAnuncio((a) => ({
                      ...a,
                      variantes: [],
                    }));
                  }}
                />
              ) : (
                <VariacaoNovoProduto
                  tipo={anuncioTipo}
                  canSwitch={!params.id}
                  value={anuncio.variantes.at(0)}
                  onClose={() => {
                    setMultiVariantes(true);

                    setAnuncio((a) => ({
                      ...a,
                      variantes: [],
                    }));
                  }}
                  onChange={(dados) => {
                    setAnuncio((a) => ({
                      ...a,
                      variantes: [dados],
                    }));
                  }}
                />
              )}

              {tipoAnuncio === "produto" && (
                <InformacoesFiscaisNovoProduto
                  value={anuncio.dados_fiscais}
                  onChange={(dados) => {
                    setAnuncio((a) => ({
                      ...a,
                      dados_fiscais: {
                        ...anuncio.dados_fiscais,
                        ...dados,
                      },
                    }));
                  }}
                />
              )}

              {tipoAnuncio === "produto" && (
                <OpcoesEnvioNovoProduto
                  initialData={anuncio.dados_frete}
                  onChange={(dados) => {
                    anuncio.dados_frete = dados;
                  }}
                  notifyUpdate={(f) => {
                    setFretes(f);
                  }}
                />
              )}

              {tipoAnuncio === "produto" && (
                <FreteGratis
                  isFreteGratis={anuncio.dados_frete.frete_gratis}
                  onToggleFreteGratis={(v) => {
                    setAnuncio((a) => ({
                      ...a,
                      dados_frete: {
                        ...a.dados_frete,
                        frete_gratis: v,
                      },
                    }));
                  }}
                  dadosEnvio={anuncio.dados_frete}
                  initialData={anuncio.variantes}
                  variantes={anuncio.variantes}
                  onChange={(dados) => {
                    setAnuncio((a) => ({
                      ...a,
                      variantes: dados.variantes,
                    }));
                  }}
                />
              )}

              {tipoAnuncio === "produto" && (
                <PrazoPostagemNovoProduto
                  initialValues={anuncio.dados_frete}
                  onChange={(dados) => {
                    anuncio.dados_frete = dados;
                    // setAnuncio((a) => ({
                    //   ...a,
                    //   dados_frete: dados,
                    // }));
                  }}
                />
              )}

              <Box
                w="full"
                mt="5px"
                mb="25px"
                borderRadius="8px"
                p="5"
                align="end"
              >
                <Button ml="5" onClick={cancelar}>
                  Cancelar
                </Button>
                <Button
                  ml="5"
                  onClick={() => {
                    onSave({
                      rascunho: true,
                    });
                  }}
                >
                  Salvar como rascunho
                </Button>
                <Button
                  ml="5"
                  bgColor="primary.400"
                  color="white"
                  onClick={() => {
                    onSave({
                      rascunho: false,
                    });
                  }}
                >
                  {params.id && anuncio.status !== "rascunho"
                    ? "Atualizar"
                    : "Publicar"}
                </Button>
              </Box>
            </Flex>
          )}
        </Flex>
      </PageScaffold>
      <CancelamentoDialog
        isOpen={isOpen}
        onClose={(sair) => {
          if (sair) {
            console.log(etapa);
            if (etapa === "selecao-categoria") {
              navigate(-1);
            } else {
              setEtapa("selecao-categoria");
            }
          }
          onClose();
        }}
      />
    </>
  );
}
