import React from "react";

import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Formatters from "../../../utils/formatters";

function ProductCard({ data }) {
  return (
    <Box
      p={"20px"}
      boxShadow="sm"
      maxWidth={"600px"}
      borderRadius={"10px"}
      marginTop={"5px"}
      backgroundColor={"white"}
    >
      {data.itens.map((i) => (
        <Flex direction={"row"}>
          <Image w={"150px"} objectFit={"fill"} src={i.produto_img} />

          <Box w={"40px"} />

          <Flex direction={"column"}>
            <Text
              whiteSpace={"normal"}
              fontWeight={700}
              noOfLines={2}
              height={"45px"}
            >
              {i.produto_nome}
            </Text>
            <Text
              whiteSpace={"normal"}
              fontWeight={500}
              fontSize={"sm"}
              noOfLines={2}
              height={"25px"}
            >
              {Formatters.properties(i.propriedades)}
            </Text>
            <Text
              whiteSpace={"normal"}
              fontWeight={500}
              fontSize={"sm"}
              noOfLines={2}
              height={"25px"}
            >
              Quantidade {i.quantidade}
            </Text>
            <Text
              whiteSpace={"normal"}
              fontWeight={500}
              fontSize={"sm"}
              noOfLines={2}
              height={"45px"}
            >
              SKU: {i.sku}
            </Text>
          </Flex>
        </Flex>
      ))}

      <Box padding={"10px 0px"}>
        <Text fontWeight={600} fontSize={"20px"}>
          {Formatters.money(data.custo_total)}
        </Text>
        <Text fontWeight={600}>
          {Formatters.paymentMethod(
            data.metodo_pagamento,
            data.pagamento.parcelas
          )}
        </Text>
      </Box>
    </Box>
  );
}

export default ProductCard;
