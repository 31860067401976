import {
  Box,
  Text,
  Flex,
  Button,
  Input,
  FormControl,
  useToast,
} from "@chakra-ui/react";

import useAuthToken from "../../../../hooks/useAuthToken";
import VendedoresRepository from "../../../../repositories/vendedores_repositories";
import OthersRepository from "../../../../repositories/others_repository";
import { useEffect, useRef, useState } from "react";

export default function EnderecoComponent({ data, onCompleted }) {
  const [cep, setCep] = useState("");
  const [estado, setEstado] = useState("");
  const [cidade, setCidade] = useState("");
  const [bairro, setBairro] = useState("");
  const [rua, setRua] = useState("");
  const token = useAuthToken();

  const numeroRef = useRef(null);
  const complementoRef = useRef(null);

  const toast = useToast();

  useEffect(() => {
    if (data.endereco) {
      setCep(data.endereco.cep);
      setEstado(data.endereco.estado);
      setCidade(data.endereco.cidade);
      setBairro(data.endereco.bairro);
      setRua(data.endereco.rua);
      numeroRef.current.value = data.endereco.numero;
      complementoRef.current.value = data.endereco.complemento;
    }
  }, [data]);

  const handleCep = (e) => {
    clearValues();
    setCep(e.target.value);
    let cepOnlyNumbers = e.target.value.replace(/\D/g, "");

    if (!cepOnlyNumbers || cepOnlyNumbers.length !== 8) return;
    OthersRepository.fetchCep(cepOnlyNumbers).then((res) => {
      if (res.status === 200) {
        setEstado(res.data.uf);
        setCidade(res.data.localidade);
        setBairro(res.data.bairro);
        setRua(res.data.logradouro);
      }
    });
  };
  const handleEstado = (e) => setEstado(e.target.value);
  const handleCidade = (e) => setCidade(e.target.value);
  const handleBairro = (e) => setBairro(e.target.value);
  const handleRua = (e) => setRua(e.target.value);

  const onSubmit = () => {
    const isAnyEmpty = [
      cep,
      estado,
      cidade,
      bairro,
      rua,
      numeroRef.current.value,
    ].some((value) => !value);

    if (isAnyEmpty) {
      toast({
        title: "Informe o seu endereço completo",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const data = {
      cep,
      rua,
      numero: numeroRef.current.value,
      bairro,
      cidade,
      estado,
      complemento: complementoRef.current.value,
    };

    const repo = new VendedoresRepository({ token });

    repo
      .completarCadastroEndereco(data)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Dados atualizados",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCompleted(data);
        }
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Ocorreu um erro",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const clearValues = () => {
    setEstado("");
    setCidade("");
    setBairro("");
    setRua("");
    numeroRef.current.value = "";
    complementoRef.current.value = "";
  };

  return (
    <Box
      style={{
        zoom: "1.1",
      }}
      width="full"
      my="5"
      mt="20"
      align={"center"}
    >
      <Box w={"450px"} align="start">
        <Text
          fontSize={"18px"}
          fontWeight={"500"}
          w={"400px"}
          mb="5"
          textAlign={"start"}
        >
          Selecione uma das opções abaixo que melhor atende o seu perfil
        </Text>
      </Box>
      <Flex
        justifyContent={"center"}
        w={"450px"}
        align="center"
        direction="column"
      >
        <FormControl onChange={handleCep} isRequired>
          <Input
            variant="filled"
            bg="#EBEBEB"
            fontWeight="500"
            fontSize="sm"
            placeholder="Digite o CEP"
            size="lg"
            value={cep}
            mb="2"
            color={"#000"}
            _placeholder={{
              fontWeight: "500",
            }}
          />
        </FormControl>

        <FormControl onChange={handleEstado} isRequired>
          <Input
            variant="filled"
            bg="#EBEBEB"
            fontWeight="500"
            fontSize="sm"
            placeholder="Digite o estado"
            size="lg"
            mb="2"
            _placeholder={{
              fontWeight: "500",
            }}
            value={estado}
            readOnly={true}
          />
        </FormControl>

        <FormControl onChange={handleCidade} isRequired>
          <Input
            variant="filled"
            bg="#EBEBEB"
            fontWeight="500"
            fontSize="sm"
            placeholder="Digite a cidade"
            size="lg"
            mb="2"
            _placeholder={{
              fontWeight: "500",
            }}
            value={cidade}
            readOnly={true}
          />
        </FormControl>

        <FormControl onChange={handleBairro} isRequired>
          <Input
            variant="filled"
            bg="#EBEBEB"
            fontWeight="500"
            fontSize="sm"
            placeholder="Digite o bairro"
            size="lg"
            mb="2"
            value={bairro}
            readOnly={true}
            _placeholder={{
              fontWeight: "500",
            }}
          />
        </FormControl>

        <Flex w={"450px"}>
          <FormControl w="80%" mr="1" isRequired>
            <Input
              variant="filled"
              bg="#EBEBEB"
              fontWeight="500"
              fontSize="sm"
              onChange={handleRua}
              placeholder="Digite a rua"
              size="lg"
              mb="2"
              value={rua}
              readOnly={true}
              _placeholder={{
                fontWeight: "500",
              }}
            />
          </FormControl>

          <FormControl w="40%" ml="1" isRequired>
            <Input
              variant="filled"
              bg="#EBEBEB"
              fontWeight="500"
              fontSize="sm"
              placeholder="Número"
              size="lg"
              mb="2"
              ref={numeroRef}
              _placeholder={{
                fontWeight: "500",
              }}
            />
          </FormControl>
        </Flex>

        <FormControl isRequired>
          <Input
            variant="filled"
            bg="#EBEBEB"
            fontWeight="500"
            fontSize="sm"
            placeholder="Complemento (ex: Casa, Apartamento)"
            size="lg"
            mb="2"
            ref={complementoRef}
            _placeholder={{
              fontWeight: "500",
            }}
          />
        </FormControl>
      </Flex>

      <Button
        onClick={onSubmit}
        bg={"#683FF3"}
        color={"white"}
        mt={"5"}
        w={"450px"}
        borderRadius={"8px"}
        h={"45px"}
        fontWeight={"700"}
        _hover={{
          bg: "#4C378F",
        }}
      >
        CONFIRMAR
      </Button>
    </Box>
  );
}
