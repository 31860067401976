import { useEffect } from "react";
import {
  Button,
  Flex,
  Text,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Td,
  Radio,
} from "@chakra-ui/react";
import { addDays, isSameHour, isSameDay, parseJSON } from "date-fns";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Calendar.css";
import { useState } from "react";
import useAuthToken from "../../../../hooks/useAuthToken";
import OfertaRelampagoRepository from "../../../../repositories/ofertas_relampago_repository";

const buildRange = (date) => {
  return [
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 2),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 2),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 4),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 4),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 6),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 6),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 8),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 10),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 10),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 12),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 14),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 16),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 18),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 18),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 20),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 20),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 22),
      disponiveis: 20,
    },
    {
      start: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 22),
      end: new Date(date.getFullYear(), date.getMonth(), date.getDate(), 24),
      disponiveis: 20,
    },
  ].filter((e) => e.start >= new Date());
};

export default function CalendarPickerDialog({ onSelect, onCancel, isOpen }) {
  const [selectedDate, setSelectedDate] = useState({});
  const [date, setDate] = useState(null);

  const [range, setRange] = useState([]);
  const [busyRange, setBusyRange] = useState([]);

  const token = useAuthToken();

  const repo = new OfertaRelampagoRepository({ token });

  useEffect(() => {
    if (!date) setDate(new Date());
    if (date) {
      repo.horarios({ date }).then((response) => {
        setBusyRange(response.data);
      });
    }
  }, [isOpen]);

  useEffect(() => {
    setRange(buildRange(new Date()));
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      size="3xl"

      // leastDestructiveRef={cancelRef}
      //  onClose={onClose}
    >
      <ModalOverlay>
        <ModalContent w="730px">
          <ModalHeader fontSize="lg" fontWeight="bold">
            <Text> Seleção de período</Text>
            <Text fontWeight="normal" fontSize="xs">
              Selecione o período da oferta Relâmpago
            </Text>
          </ModalHeader>

          <ModalBody fontSize="xs">
            <Flex direction="row" justify="center">
              <Calendar
                minDate={new Date()}
                maxDate={new Date(addDays(new Date(), 7))}
                onChange={(date) => {}}
                onClickDay={(date) => {
                  setRange(buildRange(date));
                  setDate(date);
                }}
              />
              <TableContainer border="1px gainsboro solid">
                <Table>
                  <Thead backgroundColor="#f5f5f5">
                    <Tr>
                      <Th></Th>
                      <Th>Abertura</Th>
                      <Th>Produtos</Th>
                    </Tr>
                  </Thead>
                  <Tbody fontWeight={600}>
                    {range.map((item, index) => {
                      const busyItens = busyRange.filter((busy) => {
                        return (
                          isSameDay(parseJSON(busy.data_inicio), item.start) &&
                          isSameDay(parseJSON(busy.data_fim), item.end) &&
                          isSameHour(parseJSON(busy.data_inicio), item.start) &&
                          isSameHour(parseJSON(busy.data_fim), item.end)
                        );
                      });

                      let usedLenght = 0;

                      if (busyItens.length > 0) {
                        usedLenght = busyItens.reduce((acc, item) => {
                          return acc + item.produtos.length;
                        }, 0);
                      }

                      if (usedLenght >= 20) {
                        return <> </>;
                      }

                      return (
                        <Tr key={index}>
                          <Td>
                            <Radio
                              isChecked={selectedDate.start === item.start}
                              onChange={() => {
                                setSelectedDate(item);
                              }}
                            />
                          </Td>
                          <Td>
                            <Text fontWeight={700}>
                              {item.start.toLocaleTimeString([], {
                                hour: "numeric",
                                minute: "numeric",
                              })}{" "}
                              -{" "}
                              {item.end.toLocaleTimeString([], {
                                hour: "numeric",
                                minute: "numeric",
                              })}
                            </Text>
                          </Td>

                          <Td>
                            <Text fontWeight={700}>
                              {item.disponiveis - usedLenght} Disponíveis
                            </Text>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              Cancelar
            </Button>
            <Button
              colorScheme="purple"
              onClick={() => {
                if (selectedDate && Object.keys(selectedDate).length > 0) {
                  onSelect(selectedDate);
                }
              }}
              ml={3}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
