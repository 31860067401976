import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Image,
  Progress,
  Text,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { AuthContext } from "../../contexts/authContext";
import { useContext, useEffect, useState } from "react";
import AvaliacoesRepository from "../../repositories/avaliacoes_repository";
import { MdStar } from "react-icons/md";
import ProdutosTr from "./components/ProdutosTr";
import ProductsSelectorDialog from "../../components/ProductsSelectorDialog";

function Produtos({ produto, onChange }) {
  return (
    <Box
      key={produto?._id}
      width={"100%"}
      borderRadius={"8px"}
      marginTop={"1px"}
      padding={"16px"}
    >
      <Flex direction={"column"}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Produto</Th>
                <Th>Preço atual</Th>
                <Th>Estoque</Th>
                <Th>Avaliações</Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={600}>
              {
                <ProdutosTr
                  onChange={onChange}
                  key={produto?._id}
                  data={produto}
                />
              }
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
}

export default function AvaliacoesProdutosPage() {
  const [selectedProduct, setSelectedProduct] = useState(null);

  const context = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [avaliacoes, setAvaliacoes] = useState([]);
  const [avaliacaoNota, setAvaliacaoNota] = useState(0);
  const avaliacoesRepository = new AvaliacoesRepository({
    token: context.currentToken,
  });
  useEffect(() => {
    avaliacoesRepository
      .consultar({
        estabelecimento_id: context.estabelecimento._id,
      })
      .then((response) => {
        if (response.status === 200) {
          setAvaliacoes(response.data);

          if (response.data.length > 0) {
            const sum = response.data.reduce(
              (a, b) => a.avaliacao + b.avaliacao
            );
            setAvaliacaoNota((sum / response.data.length).toFixed(2));
          }
        }
      });
  }, []);
  return (
    <>
      <Box w={"100%"}>
        <Box w="full" borderRadius="8px" p="5" bgColor="white">
          <Flex justify="space-between">
            <Flex direction="column">
              <Text fontSize="md" fontWeight="700">
                Selecione um produto
              </Text>

              <Text fontSize="xs" fontWeight={500}>
                Selecione o produto desejado para ver as avaliações
              </Text>
            </Flex>
            <Button
              variant="purple"
              backgroundColor="primary.400"
              color="white"
              onClick={() => {
                onOpen();
              }}
            >
              Selecionar produto
            </Button>
          </Flex>
          <Produtos
            produto={selectedProduct}
            onChange={(p) => {
              console.log(p);
            }}
          />
        </Box>

        <Box
          boxShadow="sm"
          width={"100%"}
          borderRadius={"8px"}
          padding={"5px"}
          backgroundColor={"white"}
          minH="200px"
          marginTop={"10px"}
          px={5}
        >
          <Flex direction="column" py="3" mb={"10px"}>
            <Text fontSize="md" fontWeight="700">
              Avaliações do produto
            </Text>

            <Text fontSize="14px" fontWeight={500} color="primary.400">
              {selectedProduct?.nome}
            </Text>
          </Flex>
          <Flex
            justifyContent="center"
            alignItems="start"
            direction="column"
            h="full"
            color="black"
          >
            {avaliacoes.map((avaliacao) => {
              return (
                <Flex w="full" direction={"column"}>
                  <Flex
                    justifyContent="center"
                    alignItems="start"
                    direction="column"
                    color="black"
                    w="full"
                  >
                    <Flex
                      direction="row"
                      align="center"
                      w="100%"
                      justifyContent="space-between"
                    >
                      <Flex direction="column">
                        <Text fontSize="md" fontWeight="semibold">
                          {avaliacao.usuario_nome}
                        </Text>
                        <Text fontSize="sm" overflow="hidden">
                          {avaliacao.comentario}
                        </Text>
                      </Flex>
                    </Flex>

                    <Flex
                      direction={"row"}
                      justifyContent={"space-between"}
                      width={"100%"}
                    >
                      <Flex>
                        {avaliacao.imagens.map((imagem) => {
                          return (
                            <Flex my="2" mr="5">
                              <Image
                                src={imagem}
                                alt={imagem.nome}
                                w="80px"
                                h="80px"
                                objectFit="cover"
                                borderRadius="8px"
                                mr="5"
                              />
                              <Text
                                w="280px"
                                fontSize="xs"
                                overflow="hidden"
                                fontWeight={"semibold"}
                                noOfLines={2}
                              >
                                {avaliacao.produto_nome}
                              </Text>
                            </Flex>
                          );
                        })}
                      </Flex>

                      <Flex direction="row" align="center">
                        <Text
                          fontSize="2xl"
                          overflow="hidden"
                          fontWeight={"bold"}
                        >
                          {avaliacao.avaliacao}{" "}
                        </Text>
                        <MdStar size={"30px"} color="#ffd700" />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Divider w="100%" py="2" />
                </Flex>
              );
            })}
          </Flex>
        </Box>
      </Box>
      <ProductsSelectorDialog
        isOpen={isOpen}
        onClose={onClose}
        allAvailable={true}
        onSelect={(p) => {
          setSelectedProduct(p[0]);
          onClose();
        }}
      />
    </>
  );
}
