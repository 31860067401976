import {
  Flex,
  Text,
  Button,
  Image,
  InputGroup,
  InputLeftAddon,
  Input,
  Checkbox,
  Switch,
} from "@chakra-ui/react";

import { Tr, Td } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

export default function ProdutosTr({ data, onChange, initialData }) {
  // <Th>Variação</Th>
  // <Th>Preço original(R$)</Th>
  // <Th>Preço oferta (R$)</Th>
  // <Th>Desconto oferta (%)</Th>
  // <Th>Estoque produto</Th>
  // <Th>Estoque oferta</Th>
  // <Th>Limite de compra</Th>
  // <Th>Status</Th>

  return (
    <Variante
      variante={data.variantes.at(0)}
      initialData={initialData}
      produto={data}
      onChange={(promo) => {
        onChange(promo);
      }}
    />
  );
}

const Variante = ({ variante, produto, onChange, initialData }) => {
  // const descontoPorcentagemRef = useRef();
  const descontoPorcentagemRef = useRef("");
  const estoqueRef = useRef("");
  const limiteRef = useRef("");
  const [ativo, setAtivo] = useState(false);

  useEffect(() => {
    if (initialData) {
      descontoPorcentagemRef.current.value =
        initialData?.desconto_porcentagem ?? "";
      estoqueRef.current.value = initialData?.estoque_promocao ?? "";
      limiteRef.current.value = initialData?.limite_compras ?? "";
      setAtivo(initialData.ativo);
    }
  }, [initialData]);

  const onChanged = (obj) => {
    onChange({
      ativo,
      estoque_promocao: estoqueRef.current.value,
      limite_compras: limiteRef.current.value,
      desconto_porcentagem: descontoPorcentagemRef.current.value,

      ...obj,
    });
  };

  return (
    <>
      <Tr key={produto._id}>
        <Td>
          <Flex align="center" w="250px">
            <Image
              src={produto.imagens.at(0)}
              mr="2"
              objectFit={"cover"}
              width="60px"
            />
            <Flex direction="column" w="200px">
              <Text fontSize="xs" noOfLines="1" fontWeight="bold">
                {produto.titulo}
              </Text>
              {/* <Text fontSize="10px" noOfLines="1">
                {variante.sku}
              </Text> */}
            </Flex>
          </Flex>
        </Td>
        {/* <Td>
          <Text fontSize="xs">{Formatters.money(variante.preco)}</Text>
        </Td> */}
        {/* <Td>
          <InputGroup size="sm" w="210px">
            <InputLeftAddon
              backgroundColor={"#EBEBEB"}
              size="sm"
              fontSize="sm"
              children={<Text fontSize="10px">R$</Text>}
            />
            <Input
              ref={descontoReaisRef}
              type="text"
              onChange={(e) => {
                descontoPorcentagemRef.current.value = null;
              }}
              size="sm"
              w={150}
              fontWeight={500}
              variant={"filled"}
            backgroundColor={"#F9F9F9"}
            />
          </InputGroup>
        </Td> */}
        <Td>
          <InputGroup size="sm" w="210px">
            <InputLeftAddon
              backgroundColor={"#EBEBEB"}
              size="sm"
              fontSize="sm"
              children={<Text fontSize="10px">%</Text>}
            />
            <Input
              ref={descontoPorcentagemRef}
              type="text"
              onChange={(e) => {
                onChanged();
              }}
              w={150}
              size="sm"
              fontWeight={500}
              variant={"filled"}
              backgroundColor={"#F9F9F9"}
            />
          </InputGroup>
        </Td>
        <Td>
          <Text fontSize="xs">{variante.estoque}</Text>
        </Td>
        <Td>
          <Input
            ref={estoqueRef}
            onChange={(e) => {
              onChanged();
            }}
            type="text"
            size="sm"
            fontWeight={500}
            placeholder="Opcional"
            variant={"filled"}
            backgroundColor={"#F9F9F9"}
          />
        </Td>
        <Td>
          <Input
            ref={limiteRef}
            onChange={(e) => {
              onChanged();
            }}
            type="text"
            size="sm"
            fontWeight={500}
            placeholder="Opcional"
            variant={"filled"}
            backgroundColor={"#F9F9F9"}
          />
        </Td>
        <Td>
          <Switch
            isChecked={ativo}
            onChange={(e) => {
              setAtivo(e.target.checked);
              onChanged({
                ativo: e.target.checked,
              });
            }}
          />
        </Td>
      </Tr>
    </>
  );
};
