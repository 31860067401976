import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import "react-image-crop/dist/ReactCrop.css";
import CropPreview from "./cropPreview";

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";

import { useState, useRef, useEffect } from "react";

function centerAspectCrop(e, aspect) {
  const { width, height } = e.currentTarget;
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      width,
      height
    ),
    width,
    height
  );
}

export default function BannerCropperDialog({ isOpen, onClose, file, onSave }) {
  const aspect = 14 / 4;
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ aspect: aspect });
  const [completedCrop, setCompletedCrop] = useState();
  const [imgUrl, setImgUrl] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [imgCropped, setImgCropped] = useState(null);
  const [croppedBlob, setCroppedBlob] = useState(null);

  async function onImageLoad(e) {
    setCrop(centerAspectCrop(e, aspect));
    setLoaded(true);
    let b = await fetch(imgRef.current.currentSrc).then((r) => r.blob());
    setImgCropped(b);
  }

  useEffect(() => {
    if (!file) return;
    setImgUrl(URL.createObjectURL(file));
  }, [loaded, file]);

  return (
    <Modal isOpen={isOpen} size="xl" height="300px">
      <ModalOverlay>
        <ModalContent height="700px">
          <ModalHeader fontSize="lg" fontWeight="bold">
            <Text>Editar Banner</Text>
            <Text fontWeight="normal" fontSize="xs">
              Dimensões de imagem recomendadas: Largura 1920px, Altura 640 px.{" "}
              <br />
              Tamanho máximo do arquivo: 2.0MB. <br />
              Formato de imagem aplicável: JPG, JPEG ou PNG.
            </Text>
          </ModalHeader>

          <ModalBody overflowY="scroll">
            <div style={{ maxWidth: "500px" }}>
              <ReactCrop
                crop={crop}
                onChange={async (v) => {
                  setCrop(v);
                  if (!imgRef) return;
                  console.log(imgRef.current);
                  let b = await fetch(imgRef.current.currentSrc).then((r) =>
                    r.blob()
                  );
                  setImgCropped(b);
                }}
                aspect={aspect}
                onComplete={(c) => setCompletedCrop(c)}
              >
                <img ref={imgRef} src={imgUrl} onLoad={onImageLoad} />
              </ReactCrop>
            </div>
            <CropPreview
              img={imgRef.current}
              crop={completedCrop}
              onBlobGenerated={(blob) => {
                setCroppedBlob(blob);
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancelar</Button>
            <Button
              colorScheme="purple"
              onClick={() => {
                onSave(croppedBlob || imgCropped);
              }}
              ml={3}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
