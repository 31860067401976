import { Flex, Text, Button, Image } from "@chakra-ui/react";

import { Tr, Td } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../../hooks/useAuthToken";
import PromocaoRepository from "../../../repositories/promocao_repository";
import Formatters from "../../../utils/formatters";

export default function RaspadinhaTr({ data, onCancel }) {
  const navigate = useNavigate();
  const token = useAuthToken();

  return (
    <>
      <Tr key={data._id}>
        <Td>
          <Text fontSize="xs">{data.nome}</Text>
        </Td>
        <Td>
          <Image src={data.produtos_sorteados.at(0).imagem} w="90px" />
        </Td>
        <Td>
          <Text fontSize="xs" fontWeight={700}>
            {data.ganhadores.length}
          </Text>
        </Td>
        <Td>
          <Text fontSize="xs">
            {" "}
            {data.produtos_sorteados.at(0).quantidade_disponivel}{" "}
          </Text>
        </Td>
        <Td>
          <Text fontSize="xs">{data.acessos.length}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">{data.status}</Text>
        </Td>

        <Td>
          <Flex direction="column">
            <Text fontSize="xs">
              {new Date(data.createdAt.replace("Z", "")).toLocaleDateString()}
            </Text>
          </Flex>
        </Td>
        <Td>
          <Flex direction="column">
            <Button
              variant={"customOutline"}
              onClick={() => {
                navigate(`/raspadinha/${data._id}`);
              }}
              size="sm"
              mb="0.5"
            >
              Ver
            </Button>
            <Button
              disabled={data.status === "Finalizado"}
              variant={"customOutline"}
              onClick={async () => {
                onCancel();
              }}
              size="sm"
              mt="0.5"
            >
              Encerrar
            </Button>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
