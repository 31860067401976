import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Page404 from "./pages/404";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Cadastro from "./pages/Cadastro";
import EnviosPage from "./pages/Envios";
import DevolucaoPage from "./pages/Devolucao";
import Loja from "./pages/Loja";
import Lucros from "./pages/Lucros";
import DetalhesPedido from "./pages/DetalhesPedido";
import RaspadinhaPage from "./pages/Raspadinha";
import NovoLembreteCarrinhoPage from "./pages/NovoLembreteCarrinho";
import ConfiguracoesEnviosPage from "./pages/ConfiguracoesEnvios";
import MeusEnderecosPage from "./pages/Enderecos";
import ProdutosMaisVendidosPage from "./pages/ProdutosMaisVendidos";
import EnvioEmMassaPage from "./pages/EnvioEmMassa";
import CentralMarketingPage from "./pages/CentralMarketing";
import ConfiguracoesBancariasPage from "./pages/ConfiguracoesBancarias";
import AvaliacoesPage from "./pages/Avaliacoes";
import CuponsPage from "./pages/Cupons";
import NovoCupomPage from "./pages/NovoCupom";
import PromocoesPage from "./pages/Promocoes";
import NovaPromocaoPage from "./pages/NovaPromocao";
import OfertaComboPage from "./pages/OfertaCombo";
import NovaOfertaComboPage from "./pages/NovaOfertaCombo";
import OfertaRelampagoPage from "./pages/OfertaRelampago";
import NovaOfertaRelampagoPage from "./pages/NovaOfertaRelampago";
import AnunciosPage from "./pages/Anuncios";
import NovoAnuncio from "./pages/NovoAnuncio";
import Estatisticas from "./pages/Estatisticas";
import LembreteCarrinhoPage from "./pages/LembreteCarrinho";
import Configuracao from "./pages/Configuracoes";
import NovaRaspadinhaPage from "./pages/NovaRaspadinha";
import CompletarCadastroPage from "./pages/CompletarCadastro";
import CertificadoDigital from "./pages/CertificadoDigital";

export default function AppRoutes({ isAuthorized }) {
  if (!isAuthorized) {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/login" replace={true} />} />
        <Route path="/cadastro" element={<Cadastro />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Page404 />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to="/inicio" replace={true} />}
      ></Route>
      <Route path="/completar-cadastro" element={<CompletarCadastroPage />} />
      <Route path="/certificado-digital" element={<CertificadoDigital />} />

      <Route path="/inicio" element={<Home />} />
      <Route path="/estatisticas" element={<Estatisticas />} />
      <Route path="/cadastro" element={<Cadastro />} />
      <Route path="/login" element={<Login />} />
      <Route path="/meus-envios" element={<EnviosPage />} />
      <Route path="/pedido/:id" element={<DetalhesPedido />} />

      <Route
        path="/meus-produtos"
        element={<AnunciosPage tipoAnuncio={"produto"} />}
      />
      <Route path="/meus-lucros" element={<Lucros />} />
      <Route
        path="/novo-produto"
        element={<NovoAnuncio tipoAnuncio={"produto"} />}
      />
      <Route
        path="/editar-produto/:id"
        element={<NovoAnuncio tipoAnuncio={"produto"} />}
      />

      <Route
        path="/meus-servicos"
        element={<AnunciosPage tipoAnuncio={"servico"} />}
      />
      <Route
        path="/novo-servico"
        element={<NovoAnuncio tipoAnuncio={"servico"} />}
      />
      <Route
        path="/editar-servico/:id"
        element={<NovoAnuncio tipoAnuncio={"servico"} />}
      />

      <Route path="/central-de-marketing" element={<CentralMarketingPage />} />
      <Route path="/cupons" element={<CuponsPage />} />
      <Route path="/promocoes" element={<PromocoesPage />} />

      <Route path="/lembrete-carrinho" element={<LembreteCarrinhoPage />} />

      <Route path="/oferta-combo" element={<OfertaComboPage />} />
      <Route path="/oferta-combo/:id" element={<NovaOfertaComboPage />} />
      <Route path="/oferta-relampago" element={<OfertaRelampagoPage />} />
      <Route path="/criar-oferta-combo" element={<NovaOfertaComboPage />} />

      <Route path="/criar-cupom" element={<NovoCupomPage />} />
      <Route path="/cupom/:id" element={<NovoCupomPage />} />

      <Route path="/criar-promocao" element={<NovaPromocaoPage />} />
      <Route path="/promocao/:id" element={<NovaPromocaoPage />} />

      <Route
        path="/criar-oferta-relampago"
        element={<NovaOfertaRelampagoPage />}
      />
      <Route
        path="/oferta-relampago/:id"
        element={<NovaOfertaRelampagoPage />}
      />

      <Route
        path="/config-bancarias"
        element={<ConfiguracoesBancariasPage />}
      />

      {/* <Route path="/config-fiscais" element={<ConfiguracoesFiscaisPage />} /> */}

      <Route path="/meu-perfil" element={<Loja />} />
      <Route path="/configuracoes" element={<Configuracao />} />
      <Route path="/avaliacoes" element={<AvaliacoesPage />} />
      <Route path="/devolucao/:id" element={<DevolucaoPage />} />
      <Route path="/criar-raspadinha" element={<NovaRaspadinhaPage />} />
      <Route path="/raspadinha" element={<RaspadinhaPage />} />
      <Route path="/raspadinha/:id" element={<NovaRaspadinhaPage />} />

      <Route
        path="/criar-lembrete-carrinho"
        element={<NovoLembreteCarrinhoPage />}
      />
      <Route path="/meus-enderecos" element={<MeusEnderecosPage />} />
      <Route path="/config-de-envio" element={<ConfiguracoesEnviosPage />} />

      <Route path="/mais-vendidos" element={<ProdutosMaisVendidosPage />} />

      <Route path="/envio-em-massa" element={<EnvioEmMassaPage />} />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
