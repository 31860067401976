import React, { useState, useEffect } from "react";
import { Box, Flex, Heading, Text, Input } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";
import { NumericFormat } from "react-number-format";

function VariacaoNovoProduto({ tipo, value, onChange, onClose, canSwitch }) {
  return (
    <Box
      boxShadow="sm"
      w="full"
      mt="5px"
      borderRadius="8px"
      p="5"
      bgColor="white"
    >
      <Heading fontSize="md">Variações do {tipo}</Heading>
      {/* <Text fontSize="xs" noOfLines="2">
        Complete: 0/2 Preencha mais atributos para anunciar a exposição do seu
        produto.
      </Text> */}

      <Flex direction="row" align="center" w="40%" my="5">
        {/* <Text fontSize="sm" fontWeight="semibold" w="100px" textAlign={"end"}>
          Variação
        </Text> */}

        {canSwitch && (
          <Box
            borderStyle="dashed"
            borderColor="primary.400"
            borderWidth="1px"
            boxSizing="border-box"
            borderRadius="6px"
            my="5"
            ml={"3"}
            cursor="pointer"
            justifyContent="center"
            align="center"
            w="100%"
          >
            <Flex
              justifyContent="center"
              align="center"
              direction="row"
              color="primary.400"
              h="10"
              onClick={onClose}
            >
              <MdAdd size="20" m="5 auto" />
              <Text fontSize="xs" fontWeight="semibold">
                Habilitar variações
              </Text>
            </Flex>
          </Box>
        )}
      </Flex>
      <Flex direction="row" align="center" w="40%" my="5">
        <Text mr="2" fontWeight={"medium"} w="100px" textAlign={"end"}>
          Preço
        </Text>

        <NumericFormat
          decimalScale={2}
          decimalSeparator=","
          thousandSeparator="."
          prefix={"R$ "}
          customInput={Input}
          variant={"filled"}
          placeholder="R$ 0,00"
          size="md"
          value={value?.preco}
          onValueChange={({ floatValue }) => {
            onChange({ ...value, preco: floatValue });
          }}
        />
      </Flex>
      <Flex direction="row" align="center" w="40%" my="5">
        <Text mr="2" fontWeight={"medium"} w="100px" textAlign={"end"}>
          Estoque
        </Text>
        <Input
          value={value?.estoque}
          onChange={(e) => {
            onChange({ ...value, estoque: Number(e.target.value) });
          }}
          size="md"
          variant={"filled"}
          placeholder="0"
        />
      </Flex>
      <Flex direction="row" align="center" w="40%" my="5">
        <Text mr="2" fontWeight={"medium"} w="100px" textAlign={"end"}>
          SKU
        </Text>
        <Input
          value={value?.sku}
          onChange={(e) => {
            onChange({ ...value, sku: e.target.value });
          }}
          variant={"filled"}
          placeholder="opcional"
        />
      </Flex>
    </Box>
  );
}

export default VariacaoNovoProduto;
