import {
  Box,
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

import Mail from "../assets/mail.png";

import { useEffect, useState } from "react";

export default function RecuperarSenhaDialog({
  isOpen,
  onSend,
  onClose,
  send,
}) {
  const [email, setEmail] = useState("");

  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent>
        <Box h="40px" />

        <ModalCloseButton onClick={onClose} />
        <ModalBody
          w="full"
          style={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}
        >
          <Text fontWeight={"bold"} fontSize="xl">
            {send ? "Recuperação Solicitada" : "Recuperar Senha"}
          </Text>
          <Box h="10px" />
          {send && <Image src={Mail} w="100px" />}
          <Box h="10px" />
          {send && (
            <Text fontSize="sm" textAlign={"center"}>
              Foi enviado um e-mail de confirmação para o endereço de e-mail
              <b> {email}. </b>
              <br />
              Pedimos que verifique o e-mail enviado e siga as instruções
              fornecidas para redefinição da senha.
            </Text>
          )}
          {!send && (
            <Text fontSize="sm">
              Digite seu e-mail abaixo para recuperar a sua senha
            </Text>
          )}
          <Box h="30px" />
          {!send && (
            <Input
              variant={"customGrey"}
              placeholder="Digite seu e-mail"
              onChange={(v) => {
                setEmail(v.target.value);
              }}
            />
          )}
        </ModalBody>

        <ModalFooter w="full">
          <Button
            w="full"
            colorScheme="purple"
            backgroundColor={"primary.400"}
            onClick={() => {
              if (!send) {
                onSend(email);
              } else {
                onClose();
              }
            }}
          >
            {send ? "Entendi" : "Confirmar"}
          </Button>
        </ModalFooter>
        <Box h="40px" />
      </ModalContent>
    </Modal>
  );
}
