import {
  ListItem,
  ListIcon,
  UnorderedList,
  Flex,
  Box,
  Spacer,
  Collapse,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { MdCircle } from "react-icons/md";

import { useState } from "react";

export default function SideBarTile({ label, icon, target, allTargets }) {
  const { pathname, search } = useLocation();

  return (
    <Link to={target}>
      <Flex direction={"column"}>
        <Flex
          alignItems={"center"}
          cursor={"pointer"}
          padding={"0px 15px"}
          marginRight={"50px"}
          borderRadius={"5px"}
          backgroundColor={
            allTargets != null
              ? allTargets.includes(pathname + search)
                ? "primary.400"
                : null
              : target === pathname + search
              ? "primary.400"
              : null
          }
        >
          <Box
            boxSize={"20px"}
            margin={"9px 3px"}
            borderRadius={"5px"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {icon}
          </Box>
          <Box
            margin={"0px 10px"}
            fontWeight={"700"}
            fontSize={"14px"}
            color={
              allTargets != null
                ? allTargets.includes(pathname + search)
                  ? "white"
                  : "#737872"
                : target === pathname + search
                ? "white"
                : "#737872"
            }
          >
            {label}
          </Box>
          <Spacer />
        </Flex>
      </Flex>
    </Link>
  );
}
