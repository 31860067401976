import { useContext } from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/authContext";

export default function useEstabelecimento() {
  // const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [estabelecimento, setEstabelecimento] = useState(
    authContext.estabelecimento
  );

  useEffect(() => {
    if (!authContext.estabelecimento) {
      return;
    }

    const { strikes } = authContext.estabelecimento;

    let restrito = false;

    strikes.forEach((s) => {
      if (new Date(s.bloqueado_ate) > new Date()) {
        restrito = true;
      }
    });

    setEstabelecimento({
      ...authContext.estabelecimento,
      restrito,
    });
  }, [authContext.estabelecimento]);

  return estabelecimento;
}
