import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "../../../assets/lottie/lampadaanimada.json";

export default function LampComponent() {
  return (
    <Player
      src={animationData}
      style={{ maxWidth: "40px" }}
      autoplay
      loop
      speed={1.3}
    />
  );
}
