import {
  Avatar,
  Button,
  Circle,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MdChevronRight, MdSearch } from "react-icons/md";
import { useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function Contacts({ chatContext, loggedUserID }) {
  const [filter, setFilter] = useState("all");
  const [search, setSearch] = useState("");

  let data = chatContext.allChats;
  let source = data;

  if (search) {
    source = data.filter((c) =>
      c.estabelecimento_nome.toLowerCase().startsWith(search.toLowerCase())
    );
  }

  if (filter === "unreads") {
    source = data.filter(
      (s) =>
        !s.mensagens?.at(-1)?.lido &&
        s.mensagens?.at(-1)?.autor_id !== loggedUserID
    );
  }

  if (filter === "reads") {
    source = data.filter(
      (s) =>
        (s.mensagens?.at(-1)?.lido &&
          s.mensagens?.at(-1)?.autor_id !== loggedUserID) ||
        s.mensagens?.at(-1)?.autor_id === loggedUserID
    );
  }

  return (
    <Flex w="50%" h="100%" alignSelf="center" direction="column">
      <Flex
        direction="row"
        justifyContent="space-between"
        px="5px"
        h="45px"
        align="center"
      >
        <InputGroup align="center" size="sm">
          <InputLeftElement
            pointerEvents="none"
            children={<MdSearch color="#BCC1BB" size="20px" />}
          />
          <Input
            placeholder="Pesquisar conversa"
            mr="5"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            w="180px"
            borderRadius={8}
          />
        </InputGroup>

        <Flex direction="row" align="center">
          <Text fontWeight={500} mr="2px" fontSize="xs">
            {filter === "all"
              ? "Todos"
              : filter === "unreads"
              ? "Não lidos"
              : "Lidos"}
          </Text>
          <Menu>
            <MenuButton
              variant="ghost"
              w="5px"
              as={Button}
              rightIcon={<ChevronDownIcon />}
            ></MenuButton>
            <MenuList>
              <MenuItem
                onClick={() => {
                  setFilter("all");
                }}
              >
                Todos
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilter("unreads");
                }}
              >
                Não lidos
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setFilter("reads");
                }}
              >
                Lidos
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      {source.map((chat) => {
        const naoLidosAmount = chat.mensagens?.filter(
          (e) => !e.lido && e.autor_id !== loggedUserID
        ).length;

        return (
          <Flex
            mt="2"
            direction="row"
            px="5px"
            cursor="pointer"
            justify="space-between"
            align="center"
            onClick={() => {
              chatContext.setActiveChat(chat);

              chatContext.loadChat({
                chat_id: chat._id,
              });
            }}
          >
            <Flex direction="row" align="center">
              <Avatar
                mr="2"
                size="md"
                src={
                  chat.usuario_imagem ??
                  "https://loja.br.abb.com/static/version1663728825/frontend/Zaelab/abb/pt_BR/Mageplaza_Blog/media/images/no-artist-image.jpg"
                }
              />
              <Flex direction="column">
                <Text fontWeight="semibold" fontSize="sm">
                  {chat.usuario_nome}
                </Text>
                <Text fontSize="xs">
                  {chat.mensagens?.at(-1)?.mensagem ?? ""}
                  {chat.mensagens?.at(-1)?.anexos?.length > 0
                    ? `${
                        chat.mensagens?.at(-1)?.anexos?.at(0).tipo === "pdf"
                          ? "Anexou um documento"
                          : "Anexou uma imagem"
                      }`
                    : ""}
                </Text>
              </Flex>
            </Flex>
            <Flex direction="column" align="center">
              {chat.mensagens?.at(-1) && (
                <Text fontSize="10px">
                  {new Date(
                    chat.mensagens?.at(-1)?.createdAt
                  ).toLocaleTimeString([], { timeStyle: "short" })}
                </Text>
              )}

              {naoLidosAmount > 0 && (
                <Text fontSize="10px" color="primary.400" fontWeight="bold">
                  {naoLidosAmount}
                </Text>
              )}
            </Flex>
          </Flex>
        );
      })}
    </Flex>
  );
}
