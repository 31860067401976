import React from "react";
import {
  Box,
  Flex,
  Input,
  Text,
  Button,
  Link,
  Divider,
  Select,
  InputGroup,
  Image,
  InputLeftElement,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import dateRangeIsValid from "../../../utils/date_comparator";
import { MdCircle, MdShowChart } from "react-icons/md";

import searchImg from "../../../assets/pesquisar.svg";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";

export default function FilterBox({ onFilterChange = () => {}, onReset }) {
  const navigate = useNavigate();
  const [status, setStatus] = useState("todos");
  const [name, setName] = React.useState(null);

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  return (
    <Box width={"100%"} borderRadius={"8px"} marginTop={"5px"}>
      <Flex
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
        px="4"
      >
        <Flex direction="column" maxW="50%">
          <Text fontSize="17px" fontWeight="bold" color="#4e4e4e">
            Lista de Promoções
          </Text>
          <Text fontSize="13px" fontWeight={"medium"} margin={"6px 0px"}>
            Comece agora a oferecer promoções e preços competitivos na
            Deshopnise! Acesse nosso Guia do Vendedor para aprender como criar
            suas próprias promoções.
            <a
              style={{
                color: "#693FF3",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
              href="https://www.central.deshopnise.com.br/como-criar-uma-promocao-facil"
              target="_blank"
            >
              {" Saiba mais."}
            </a>
          </Text>
        </Flex>
        <Flex direction="row">
          <Link to={"/estatisticas"} mr="2">
            <Button
              width={"140px"}
              onClick={() => {
                navigate("/estatisticas");
              }}
              marginLeft={"15px"}
              backgroundColor="#232544"
              color={"white"}
              _hover={{ opacity: "0.8" }}
            >
              <Flex direction="row" align="center">
                <MdShowChart size={20} color="cyan" />
                <Text mx="2" fontSize="xs">
                  Métricas
                </Text>
                <Divider h="10px" mr="2" orientation="vertical" />
                <MdCircle size={15} color="cyan" />
              </Flex>
            </Button>
          </Link>
          <Button
            backgroundColor="primary.400"
            color={"white"}
            _hover={{ opacity: "0.8" }}
            colorScheme="purple"
            onClick={() => {
              navigate("/criar-promocao");
            }}
          >
            Criar Promoção
          </Button>
        </Flex>
      </Flex>
      <Flex
        padding={"16px"}
        backgroundColor="white"
        direction="row"
        justifyContent="space-between"
        borderRadius="8"
        mt="2"
      >
        <InputGroup spacing="24px" width={"280px"}>
          <InputLeftElement
            children={<Image src={searchImg} boxSize="17px" />}
          />
          <Input
            type="text"
            fontWeight={700}
            placeholder="Buscar por nome..."
            value={name}
            fontSize={"15px"}
            w="220px"
            onChange={(e) => {
              setName(e.target.value);
            }}
            variant={"filled"}
          />
        </InputGroup>

        <Flex align="center">
          <Text fontSize="sm" fontWeight={500} w="60px">
            Status
          </Text>

          <Select
            mx="2"
            w="180px"
            fontSize={"15px"}
            variant={"filled"}
            fontWeight={"700"}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="todos">Todos</option>
            <option value="true">Ativo</option>
            <option value="false">Inativo</option>
          </Select>
        </Flex>

        <Flex direction="row">
          <Flex align="center">
            <Text fontSize="sm" fontWeight={500} w="60px">
              Período
            </Text>
            <Box w={"10px"} />

            <CustomDateRangePicker
              currentRange={[startDate, endDate]}
              onChange={({ start, end }) => {
                setStartDate(start);
                setEndDate(end);
              }}
            />
            {/* <Input
              fontWeight={500}
              size="sm"
              type={"date"}
              ref={startDateRef}
              maxW="130px"
              onChange={() => {
                setDataInicialValida(
                  dateRangeIsValid({
                    first: startDateRef.current.value,
                    last: endDateRef.current.value,
                  })
                );
              }}
              isInvalid={!dataInicialValida}
            />
            <Input
              fontWeight={500}
              size="sm"
              type={"date"}
              maxW="130px"
              ref={endDateRef}
              onChange={() => {
                setDataInicialValida(
                  dateRangeIsValid({
                    first: startDateRef.current.value,
                    last: endDateRef.current.value,
                  })
                );
              }}
              isInvalid={!dataInicialValida}
            /> */}
          </Flex>
          <Button
            width={"150px"}
            marginLeft={"15px"}
            backgroundColor="primary.400"
            color={"white"}
            _hover={{ opacity: "0.8" }}
            onClick={() => {
              onFilterChange({
                nome: name,
                status: status === "todos" ? null : status,
                data_inicio: startDate,
                data_fim: endDate,
              });
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              setName(null);
              setStatus(null);
              setStartDate(null);
              setEndDate(null);
              onReset();
            }}
            width={"150px"}
            marginLeft={"15px"}
            variant={"outline"}
          >
            Redefinir
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
