import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Select,
  Text,
  Button,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../../hooks/useAuthToken";
import OfertaRelampagoRepository from "../../../repositories/ofertas_relampago_repository";

export default function OfertasList({ data = [] }) {
  return (
    <Box padding={"12px"}>
      <Flex direction={"column"}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th textTransform="none" fontSize="14px">
                  Produtos
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Nº de Cliques
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Status
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Período
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Ação
                </Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={600}>
              {data.map((p) => (
                <OfertasTr key={p._id} data={p} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
}

function OfertasTr({ data }) {
  const navigate = useNavigate();
  const token = useAuthToken();
  const repo = new OfertaRelampagoRepository({ token });

  const [desconto] = data.descontos;

  return (
    <>
      <Tr key={data._id}>
        <Td>
          <Flex direction="row" alignItems="center">
            <Image src={data.anuncio.imagens[0]} w="100px" />
            <Flex w="10px" />
            <Text
              fontSize={"14px"}
              noOfLines={2}
              textOverflow={"ellipsis"}
              overflow={"hidden"}
              width={"280px"}
            >
              {data.anuncio.titulo}
            </Text>
          </Flex>
        </Td>

        <Td>
          <Text fontSize={"14px"}>{desconto.cliques}</Text>
        </Td>
        {/* <Td>
          <Text fontSize={"14px"}>{data.pedidos.length}</Text>
        </Td> */}
        <Td>
          <Text
            fontSize={"14px"}
            color={
              {
                Agendado: "red.400",
                "Em andamento": "green.400",
              }[data.status] ?? "black"
            }
          >
            {data.status}
          </Text>
        </Td>
        <Td>
          <Text fontSize="xs" fontWeight={700}>
            {new Date(data.data_inicio).toLocaleString("pt-BR")}
            <br />
            {new Date(data.data_fim).toLocaleString("pt-BR")}
          </Text>
        </Td>
        <Td>
          <Flex direction="column">
            <Button
              disabled={
                data.status === "Cancelado" ||
                data.status === "Em andamento" ||
                data.status === "Finalizado"
              }
              variant={"customOutline"}
              onClick={() => {
                navigate(`/oferta-relampago/${data._id}`);
              }}
              size="sm"
              mb="0.5"
            >
              Editar
            </Button>
            <Button
              disabled={
                data.status === "Cancelado" ||
                data.status === "Em andamento" ||
                data.status === "Finalizado"
              }
              variant={"customOutline"}
              onClick={() => {
                repo.cancelar({ id: data._id }).then((e) => {
                  window.location.reload();
                });
              }}
              size="sm"
              mt="0.5"
            >
              Encerrar
            </Button>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
