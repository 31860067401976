import axios from "axios";

const fetchCep = async (cep) =>
  axios.get("https://viacep.com.br/ws/" + cep + "/json/");

const fetchCnpj = async (cnpj) =>
  axios.get("https://publica.cnpj.ws/cnpj/" + cnpj);

const methods = { fetchCep, fetchCnpj };
export default methods;
