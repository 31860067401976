import { Box, Flex, Heading, Text, Input, Switch } from "@chakra-ui/react";
import { useEffect, useState } from "react";

export default function PrazoPostagemNovoProduto({ onChange, initialValues }) {
  const [preEncomenda, setPreEncomenda] = useState(false);
  const [diasPrazoPostagem, setDiasPrazoPostagem] = useState(0);

  useEffect(() => {
    if (initialValues) {
      setPreEncomenda(initialValues.pre_encomenda);
      setDiasPrazoPostagem(initialValues.dias_prazo_postagem);
    }
  }, [initialValues]);

  return (
    <Box
      boxShadow="sm"
      w="full"
      mt="5px"
      borderRadius="8px"
      p="5"
      bgColor="white"
    >
      <Heading fontSize="md">Prazo de postagem</Heading>
      <Text fontSize="xs" noOfLines="2">
        Preencha abaixo as dimensões do seu produto e selecione a melhor opção
        de envio para a sua região.
      </Text>
      <Flex direction="row" align="center" mt="5">
        <Text fontWeight="bold" fontSize="sm">
          Pré-Encomenda
        </Text>
        <Text
          ml="2"
          fontSize="sm"
          noOfLines="2"
          color="grey"
          fontWeight="semibold"
        >
          Não
        </Text>
        <Switch
          isChecked={preEncomenda}
          value={preEncomenda}
          mx="2"
          onChange={(e) => {
            setPreEncomenda(e.target.checked);
            onChange({
              ...initialValues,
              pre_encomenda: e.target.checked,
              dias_prazo_postagem: diasPrazoPostagem,
            });
          }}
        />
        <Text fontSize="sm" noOfLines="2" color="green" fontWeight="semibold">
          Sim
        </Text>
      </Flex>
      <Flex direction="row" align="center" mt="2">
        <Text fontWeight="bold" fontSize="xs">
          Preciso de
        </Text>
        <Input
          placeholder="7"
          mx="2"
          w="150px"
          variant={"filled"}
          type="number"
          value={diasPrazoPostagem}
          size="md"
          onChange={(e) => {
            setDiasPrazoPostagem(e.target.value);
            onChange({
              ...initialValues,
              pre_encomenda: preEncomenda,
              dias_prazo_postagem: Number(e.target.value),
            });
          }}
        />
        <Text fontWeight="bold" fontSize="xs">
          dias para o prazo de postagem (Você deve preencher entre 7 e 30)
        </Text>
      </Flex>
    </Box>
  );
}
