import {
  Avatar,
  Box,
  Divider,
  Flex,
  Grid,
  Image,
  Progress,
  Spacer,
  Text,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { AuthContext } from "../../contexts/authContext";
import { useContext, useEffect, useState } from "react";
import AvaliacoesRepository from "../../repositories/avaliacoes_repository";
import { MdStar } from "react-icons/md";
import useEstabelecimento from "../../hooks/useEstabelecimento";
export default function AvaliacoesPage() {
  const context = useContext(AuthContext);
  const [avaliacoes, setAvaliacoes] = useState([]);
  const [avaliacaoNota, setAvaliacaoNota] = useState(0);
  const avaliacoesRepository = new AvaliacoesRepository({
    token: context.currentToken,
  });

  const estabelecimento = useEstabelecimento();
  useEffect(() => {
    avaliacoesRepository
      .consultar({
        estabelecimento_id: context.estabelecimento._id,
      })
      .then((response) => {
        if (response.status === 200 && response.data.length > 0) {
          setAvaliacoes(response.data);
          if (response.data.length > 0) {
            const sum = response.data.reduce(
              (a, b) => a.avaliacao + b.avaliacao
            );
            setAvaliacaoNota((sum / response.data.length).toFixed(2));
          }
        }
      });
  }, []);

  const getStringSatisfacao = (nota) => {
    if (nota === 0) return "N/A";
    if (nota < 10) {
      return "Ruim";
    }

    if (nota < 50) {
      return "Razoável";
    }

    return "Ótimo";
  };

  const getStringSatisfacaoAlt = (nota) => {
    if (nota === 0) return "Alta";
    if (nota < 10) {
      return "Baixa";
    }

    if (nota < 50) {
      return "Media";
    }

    return "Alta";
  };

  return (
    <Box w={"100%"}>
      <Grid templateColumns="230px 120px 40px 120px 40px 120px" gap={6}>
        <Box
          borderRadius="8"
          border="solid"
          borderColor="grey"
          borderWidth="2px"
          height={"130px"}
          alignItems="center"
          display="flex"
          justifyContent={"center"}
        >
          <Flex direction="column" align="center">
            <Text fontWeight="700" color="#32CD32" fontSize="6xl" h="75px">
              {getStringSatisfacao(estabelecimento.score.score)}
            </Text>
            <Text fontWeight="500" fontSize="xs">
              Vendedor com {getStringSatisfacaoAlt(estabelecimento.score.score)}{" "}
              qualificação
            </Text>
          </Flex>
        </Box>
        <Flex direction="column" align="start" justify="center" h="150px">
          <Text fontWeight="700" color="black" fontSize="2xl">
            {estabelecimento.score.qtd_vendas_ultimos_60_dias}
          </Text>
          <Text fontWeight="500" fontSize="sm">
            Vendas nos últimos 60 dias
          </Text>
        </Flex>
        <Divider orientation="vertical" h="120px" colorScheme="telegram" />
        <Flex direction="column" align="start" h="150px" justify="center">
          <Text fontWeight="700" color="#32CD32" fontSize="2xl">
            {getStringSatisfacao(
              estabelecimento.score.media_satisfacao_comprador
            )}
          </Text>
          <Text fontWeight="500" fontSize="sm">
            Experiência com o cliente
          </Text>
        </Flex>
        <Divider orientation="vertical" h="120px" colorScheme="telegram" />
        <Flex direction="column" align="start" h="150px" justify="center">
          <Text fontWeight="700" color="#32CD32" fontSize="2xl">
            {getStringSatisfacao(estabelecimento.score.media_prazo_postagem)}
          </Text>
          <Text fontWeight="500" fontSize="sm">
            Entregas dentro do prazo
          </Text>
        </Flex>
      </Grid>
      <Grid templateColumns="420px 420px" gap="4" mt="5">
        <Box borderRadius="8" backgroundColor="white" minH="360px" p="4">
          <Flex direction="row" align="center">
            <Avatar src={estabelecimento.imagem_perfil} size="xl" mr="5" />
            <Flex direction="column" align="start">
              <Text fontSize="2xl" fontWeight="700" noOfLines={1}>
                {estabelecimento.nome}
              </Text>
              <Text fontSize="md" fontWeight="500">
                {" "}
                {estabelecimento.metricas.qtd_seguidores} seguidores
              </Text>
            </Flex>
          </Flex>
          <Flex direction="column" align="start" mt="5" fontWeight="semibold">
            <Flex direction="row" justify="space-between" w="full">
              <Text>Experiencia com o cliente</Text>
              <Text fontSize="sm" fontWeight="bold">
                {estabelecimento.score.media_satisfacao_comprador}
              </Text>
            </Flex>
            <Progress
              colorScheme="whatsapp"
              borderRadius="8"
              w="full"
              value={estabelecimento.score.media_satisfacao_comprador}
            />
            <Flex
              direction="row"
              w="full"
              justifyContent={"end"}
              mt="1"
              fontSize="xs"
            >
              {estabelecimento.metricas.media_avaliacoes === 0 && (
                <Text>-</Text>
              )}
            </Flex>
          </Flex>
          <Flex direction="column" align="start" mt="2" fontWeight="semibold">
            <Flex direction="row" justify="space-between" w="full">
              <Text>Entregas dentro do prazo</Text>
              <Text fontSize="sm" fontWeight="bold">
                {Math.round(estabelecimento.score.media_prazo_postagem / 20)}
              </Text>
            </Flex>
            <Progress
              colorScheme="whatsapp"
              borderRadius="8"
              w="full"
              value={estabelecimento.score.media_prazo_postagem}
            />
            {/* <Flex
              direction="row"
              w="full"
              justifyContent={"end"}
              mt="1"
              fontSize="xs"
            >
              <Text color="#32CD32">Ótima</Text>
            </Flex> */}
          </Flex>
          <Flex direction="column" align="start" mt="2" fontWeight="semibold">
            <Flex direction="row" justify="space-between" w="full">
              <Text>Respostas no chat</Text>
              <Text fontSize="sm" fontWeight="bold">
                {estabelecimento.metricas.media_resposta_chat}
              </Text>
            </Flex>
            <Progress
              colorScheme="whatsapp"
              borderRadius="8"
              w="full"
              value={estabelecimento.metricas.media_resposta_chat * 20}
            />
            <Flex
              direction="row"
              w="full"
              justifyContent={"end"}
              mt="1"
              fontSize="xs"
            >
              {estabelecimento.metricas.media_avaliacoes === 0 && (
                <Text>-</Text>
              )}
            </Flex>
          </Flex>
        </Box>
      </Grid>
      <Box
        paddingTop={"12px"}
        paddingBottom={"4px"}
        fontWeight={"700"}
        fontSize={"22px"}
        my="2"
      >
        <Flex direction="column">
          <Text fontSize="md">Avaliações da loja</Text>

          <Text fontSize="sm" fontWeight={500}>
            Veja as avaliações da experiência dos seus clientes com sua loja.
          </Text>
        </Flex>
      </Box>
      <Box
        boxShadow="sm"
        width={"100%"}
        borderRadius={"8px"}
        marginTop={"5px"}
        padding={"16px"}
        backgroundColor={"white"}
        minH="200px"
      >
        <Flex
          justifyContent="center"
          alignItems="start"
          direction="column"
          h="full"
          px="2"
          color="black"
        >
          {avaliacoes.map((avaliacao) => {
            return (
              <Flex w="full" direction={"column"}>
                <Flex
                  justifyContent="center"
                  alignItems="start"
                  direction="column"
                  px="2"
                  color="black"
                  w="full"
                >
                  <Flex
                    direction="row"
                    align="center"
                    w="100%"
                    justifyContent="space-between"
                  >
                    <Flex direction="column">
                      <Text fontSize="md" fontWeight="semibold">
                        {avaliacao.usuario_nome}
                      </Text>
                      <Text fontSize="sm" overflow="hidden">
                        {avaliacao.comentario}
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex
                    direction={"row"}
                    justifyContent={"space-between"}
                    width={"100%"}
                  >
                    <Flex direction={"row"}>
                      {avaliacao.imagens.map((imagem) => {
                        return (
                          <Flex my="2" mr="5">
                            <Image
                              src={imagem}
                              alt={imagem.nome}
                              w="80px"
                              h="80px"
                              objectFit="cover"
                              borderRadius="8px"
                              mr="5"
                            />
                            <Text
                              w="280px"
                              fontSize="xs"
                              overflow="hidden"
                              fontWeight={"semibold"}
                              noOfLines={1}
                            >
                              {avaliacao.produto_nome}
                            </Text>
                          </Flex>
                        );
                      })}
                    </Flex>

                    <Flex direction="row" align="center">
                      <Text
                        fontSize="4xl"
                        overflow="hidden"
                        fontWeight={"bold"}
                      >
                        {avaliacao.avaliacao}
                      </Text>
                      <MdStar size={"40px"} color="#ffd700" />
                    </Flex>
                  </Flex>
                </Flex>

                <Divider w="100%" py="2" />
              </Flex>
            );
          })}
        </Flex>
      </Box>
    </Box>
  );
}
