import FirebaseStorageService from "../../../services/firebase_storage_service";
import imageCompression from "browser-image-compression";

class ImageUploader {
  static async upload(file, { compress = true }) {
    const firebaseStorage = new FirebaseStorageService();

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

    if (compress) {
      const compressedFile = await imageCompression(file, options);

      const url = await firebaseStorage.handleBlobUpload(compressedFile);

      return url;
    } else {
      const url = await firebaseStorage.handleBlobUpload(file);

      return url;
    }
  }
}

export default ImageUploader;
