import React, { useContext } from "react";
import {
  Container,
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  Spacer,
  Divider,
  Avatar,
  Text,
  Button,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  Search2Icon,
  ChatIcon,
  BellIcon,
  ChevronDownIcon,
} from "@chakra-ui/icons";

import { useNavigate } from "react-router-dom";

import searchImg from "../../assets/pesquisar.svg";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import notificacaoImg from "../../assets/notificacao.svg";
import ButtonPlayImg from "../../assets/buttoplay.png";
import logo from "../../assets/logo.png";
import NotificationList from "./components/NotificationList";

export default function AppBarComponent({
  useDarkerBackground = false,
  showWhiteLogo = false,
  onOpenVideo,
}) {
  const estabelecimento = useEstabelecimento();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = React.useState(false);
  const [showNotificacoes, setShowNotificacoes] = React.useState(false);

  const [inputValue, setInputValue] = React.useState("");

  const pages = ["Produtos", "Envios", "Cupons", "Configurações"];

  const onSearch = () => {
    if (!pages.includes(inputValue)) return;

    if (inputValue === "Produtos") navigate("/meus-produtos");
    if (inputValue === "Envios") navigate("/meus-envios");
    if (inputValue === "Cupons") navigate("/cupons");
    if (inputValue === "Configurações")
      navigate("/meu-perfil?target=configuracoes-loja");
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch();
    }
  };

  return (
    <Box
      boxShadow="sm"
      backgroundColor={useDarkerBackground ? "#232544" : "white"}
      color={useDarkerBackground ? "white" : "black"}
      borderRadius={useDarkerBackground ? "0px" : "4px"}
    >
      <Container maxW="100vw" paddingInline={"25px"}>
        <Flex direction="row" align={"center"} height={"60px"} padding={"10px"}>
          {showWhiteLogo && (
            <>
              <Box width={"15px"} />

              <Image
                src={logo}
                width={"180px"}
                onClick={() => {
                  navigate("/");
                }}
                cursor={"pointer"}
              />
              <Box width={"80px"} />
            </>
          )}
          <InputGroup spacing="24px" width={"280px"}>
            <InputLeftElement
              children={
                // <Search2Icon onClick={onSearch} color={"primary.400"} />
                <Image src={searchImg} boxSize="17px" />
              }
            />
            <Input
              list="pages"
              placeholder="Buscar por aqui"
              fontSize={"12px"}
              fontWeight={"600"}
              variant="filled"
              value={inputValue}
              onKeyPress={onKeyPress}
              onChange={(e) => {
                setInputValue(e.target.value);
              }}
            />
            <datalist id="pages">
              {pages.map((e) => (
                <option key={e} value={e} />
              ))}
            </datalist>
          </InputGroup>
          <Box width={"15px"} />
          <Spacer />
          {/* <ChatIcon
            color={useDarkerBackground ? "whiteAlpha.600" : "blackAlpha.400"}
            fontSize={"18px"}
          /> */}
          <Box width={"15px"} />
          {onOpenVideo && (
            <Image
              src={ButtonPlayImg}
              boxSize="20px"
              cursor={"pointer"}
              onClick={() => {
                onOpenVideo();
              }}
            />
          )}

          <Box width={"15px"} />
          <Image
            src={notificacaoImg}
            boxSize="20px"
            cursor={"pointer"}
            onClick={() => {
              setShowNotificacoes(!showNotificacoes);
            }}
          />
          <Box width={"15px"} />
          <Divider orientation="vertical" />
          <Box width={"15px"} />
          <Flex
            cursor={"pointer"}
            direction="row"
            align={"center"}
            onClick={() => {
              navigate("/meu-perfil");
            }}
          >
            <Avatar size="sm" src={estabelecimento.imagem_perfil} />
            <Flex direction={"column"} padding={"10px"} lineHeight="15px">
              <Text fontSize="sm" fontWeight={"600"}>
                {estabelecimento.nome}
              </Text>
              <Text fontSize="10px">{estabelecimento.email}</Text>
            </Flex>
          </Flex>
          <ChevronDownIcon
            cursor={"pointer"}
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          />
          <Box
            visibility={showMenu ? "visible" : "hidden"}
            position={"fixed"}
            top={"50px"}
            right={"20px"}
            width={"200px"}
            borderRadius={"8px"}
            background={"white"}
            style={{
              boxShadow:
                "0 0 16px 0 rgba(0,0,0,.1), 0 8px 16px 0 rgba(0,0,0,.06)",
            }}
          >
            <Flex padding={"10px"} direction={"column"} alignItems={"start"}>
              <Button
                variant={"link"}
                bg={"white"}
                onClick={() => {
                  setShowMenu(false);
                  navigate("/meu-perfil");
                }}
                p={"5px"}
              >
                Meu Perfil
              </Button>
              <Button
                onClick={() => {
                  setShowMenu(false);

                  navigate("/login");
                }}
                p={"5px"}
                bg={"white"}
                variant={"link"}
              >
                Sair
              </Button>
            </Flex>
          </Box>
          <Box
            visibility={showNotificacoes ? "visible" : "hidden"}
            position={"absolute"}
            width={"380px"}
            top={"55px"}
            right={"290px"}
            background={"white"}
            color={"black"}
            borderRadius={"8px"}
            zIndex={99}
            style={{
              boxShadow:
                "0 0 16px 0 rgba(0,0,0,.1), 0 8px 16px 0 rgba(0,0,0,.06)",
            }}
          >
            <Flex
              padding={"20px 10px"}
              paddingBottom={"5px"}
              direction={"column"}
              alignItems={"center"}
            >
              <Text fontSize={"sm"} fontWeight="600" pb={"6px"}>
                Notificações
              </Text>
              <Divider />
              <Box height={"450px"} overflowY={"scroll"}>
                <NotificationList
                  onClose={() => {
                    setShowNotificacoes(false);
                  }}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Container>
    </Box>
  );
}
