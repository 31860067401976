import {
  Box,
  Text,
  Flex,
  Button,
  Input,
  Checkbox,
  HStack,
  PinInput,
  PinInputField,
  useToast,
} from "@chakra-ui/react";

import OthersRepository from "../../../../repositories/others_repository";
import { useRef, useState } from "react";
import useEstabelecimento from "../../../../hooks/useEstabelecimento";
import { RecaptchaVerifier, getAuth, linkWithPhoneNumber } from "firebase/auth";
import PublicRepository from "../../../../repositories/public_repository";
import VendedoresRepository from "../../../../repositories/vendedores_repositories";
import useAuthToken from "../../../../hooks/useAuthToken";

const STEP = {
  TELEFONE: "TELEFONE",
  CODIGO: "CODIGO",
};
const errors = {
  "auth/code-expired":
    "O código expirou. recarregue a página e tente novamente",
  "auth/account-exists-with-different-credential":
    "Já existe uma conta com este número de telefone",
  "auth/invalid-verification-code": "Código inválido",
  "auth/provider-already-linked":
    "Já existe uma conta com este número de telefone",
};

export default function TelefoneComponent({ data, onCompleted }) {
  const estabelecimento = useEstabelecimento();
  const publicRepo = new PublicRepository();
  const token = useAuthToken();
  const vendedorRepo = new VendedoresRepository({ token });

  const [telefone, setTelefone] = useState("");
  const [code, setCode] = useState("");
  const phoneRef = useRef(null);
  const toast = useToast();
  const [step, setStep] = useState(STEP.TELEFONE);
  const [terms, setTerms] = useState(false);

  const checkTelefone = async () => {
    if (!terms) {
      toast({
        description: "Você precisa aceitar os termos para continuar",
        status: "error",
      });
      return;
    }

    if (!phoneRef.current.value) {
      toast({
        description: "Você precisa informar um telefone",
        status: "error",
      });
      return;
    }

    let resp = await publicRepo.consultar({ telefone: phoneRef.current.value });
    if (resp.status !== 200) {
      toast({
        description: "Já existe um cadastro com esse telefone",
        status: "error",
      });
      return;
    }

    // setComponenteExibido("confirmacao_cadastro");

    const auth = getAuth();

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal",
        callback: async (response) => {
          linkWithPhoneNumber(
            auth.currentUser,
            "+55" + phoneRef.current.value,
            window.recaptchaVerifier
          )
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
              setTelefone("+55" + phoneRef.current.value);
              console.log(confirmationResult);
              setStep(STEP.CODIGO);
            })
            .catch((error) => {
              console.log("error");
              console.log(error);

              toast({
                title: "Erro ao enviar SMS",
                description: error.code ?? "",
                status: "error",
                duration: 9000,
                isClosable: true,
              });

              // Error; SMS not sent
              // ...
            });
        },
        "expired-callback": () => {
          // console.log("expired", response);
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        },
      },
      auth
    );

    window.recaptchaVerifier.render().then((widgetId) => {
      window.recaptchaWidgetId = widgetId;
    });
  };

  const checkValue = () => {
    if (code.length < 4) return; //Adicionar um toast
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        vendedorRepo.completarCadastroTelefone({ telefone }).then((resp) => {
          onCompleted(code);
        });
      })
      .catch((error) => {
        const desc = errors[error.code] || error.code;

        toast({
          description: desc,
          status: "error",
        });
      });
  };

  return (
    <Box
      style={{
        zoom: "1.1",
      }}
      width="full"
      my="5"
      mt="20"
      align={"center"}
    >
      {step === STEP.TELEFONE && (
        <>
          <Box w={"450px"} align="start">
            <Text
              fontSize={"22px"}
              fontWeight={"700"}
              w={"400px"}
              mb="2"
              textAlign={"start"}
            >
              Informe seu telefone
            </Text>
          </Box>
          <Box w={"450px"} align="start">
            <Text
              fontSize={"14px"}
              fontWeight={"500"}
              w={"400px"}
              mb="2"
              textAlign={"start"}
            >
              Vamos enviar um código por SMS para validar o seu celular.
              Lembre-se de colocar um número valido de celular.
            </Text>
          </Box>
          <Flex
            justifyContent={"center"}
            w={"550px"}
            align="start"
            direction="row"
          >
            <Input
              w={"80px"}
              placeholder="Digite aqui o DDD + número"
              variant="filled"
              fontSize={"sm"}
              value={"+55"}
              mt="2"
              mr={"2"}
              ref={phoneRef}
              _placeholder={{
                fontWeight: "500",
              }}
              fontWeight={"600"}
              size={"lg"}
              mb={"22px"}
            />
            <Flex direction={"column"}>
              <Input
                w={"360px"}
                placeholder="Digite aqui o DDD + número"
                variant="filled"
                fontSize={"sm"}
                mt="2"
                ref={phoneRef}
                _placeholder={{
                  fontWeight: "500",
                }}
                fontWeight={"600"}
                size={"lg"}
              />
              <Text fontSize={"12px"} fontWeight={"500"} ml={"1"} mt={"1"}>
                Digite apenas números, sem traços ou pontos.
              </Text>
            </Flex>
          </Flex>

          <Box w={"450px"} align="start" mt={"15px"}>
            <Flex
              width="full"
              px="8"
              mt="3"
              mb="3"
              style={{
                fontWeight: "500",
              }}
            >
              <Checkbox
                value={terms}
                onChange={(e) => {
                  setTerms(e.target.checked);
                }}
                scale={5}
                size="lg"
              />
              <Text fontSize="13px" mt="2" ml="5">
                Aceito que entrem em contato comigo via WhatsApp e/ou SMS nesse
                número.
              </Text>
            </Flex>
          </Box>
        </>
      )}

      {step === STEP.CODIGO && (
        <>
          <Box w={"450px"} align="start">
            <Text
              fontSize={"22px"}
              fontWeight={"700"}
              w={"400px"}
              mb="2"
              textAlign={"start"}
            >
              Digite o código abaixo
            </Text>
          </Box>
          <Box w={"450px"} align="start">
            <Text
              fontSize={"14px"}
              fontWeight={"500"}
              w={"400px"}
              mb="2"
              textAlign={"start"}
            >
              Enviamos um código para o número{" "}
              <Text color={"#683FF3"} as={"span"} fontWeight={"700"}>
                {telefone}
              </Text>
              . Verifique sua caixa de mensagem. Esse não é seu número?{" "}
              <Text
                onClick={() => {
                  setStep(STEP.TELEFONE);
                }}
                as={"span"}
                fontWeight={"700"}
                color={"#683FF3"}
                cursor={"pointer"}
              >
                Clique aqui
              </Text>{" "}
            </Text>
          </Box>
          <Flex
            justifyContent={"center"}
            w={"550px"}
            align="start"
            direction="row"
          >
            <HStack mt={5} mb={5}>
              <PinInput
                placeholder=""
                size="lg"
                fontWeight={"500"}
                onChange={(e) => setCode(e)}
                _placeholder={{
                  fontWeight: "600",
                }}
              >
                <PinInputField
                  w={"60px"}
                  h={"60px"}
                  fontSize={"30px"}
                  fontWeight={"600"}
                  _placeholder={{
                    fontWeight: "600",
                  }}
                  variant="filled"
                  bg="#EBEBEB"
                />
                <PinInputField
                  w={"60px"}
                  h={"60px"}
                  fontSize={"30px"}
                  fontWeight={"600"}
                  _placeholder={{
                    fontWeight: "600",
                  }}
                  variant="filled"
                  bg="#EBEBEB"
                />
                <PinInputField
                  w={"60px"}
                  h={"60px"}
                  fontSize={"30px"}
                  fontWeight={"600"}
                  _placeholder={{
                    fontWeight: "600",
                  }}
                  variant="filled"
                  bg="#EBEBEB"
                />
                <PinInputField
                  w={"60px"}
                  h={"60px"}
                  fontSize={"30px"}
                  fontWeight={"600"}
                  _placeholder={{
                    fontWeight: "600",
                  }}
                  variant="filled"
                  bg="#EBEBEB"
                />
                <PinInputField
                  w={"60px"}
                  h={"60px"}
                  fontSize={"30px"}
                  fontWeight={"600"}
                  _placeholder={{
                    fontWeight: "600",
                  }}
                  variant="filled"
                  bg="#EBEBEB"
                />
                <PinInputField
                  w={"60px"}
                  h={"60px"}
                  fontSize={"30px"}
                  fontWeight={"600"}
                  _placeholder={{
                    fontWeight: "600",
                  }}
                  variant="filled"
                  bg="#EBEBEB"
                />
              </PinInput>
            </HStack>
          </Flex>
        </>
      )}

      {step === STEP.TELEFONE && (
        <Flex pt={"12px"} justify={"center"} align={"center"}>
          <div id="recaptcha-container" />
        </Flex>
      )}

      <Button
        onClick={() => {
          if (step === STEP.TELEFONE) {
            checkTelefone(telefone);
          } else {
            checkValue();
          }
        }}
        bg={"#683FF3"}
        color={"white"}
        mt={"2"}
        w={"450px"}
        borderRadius={"8px"}
        h={"45px"}
        fontWeight={"700"}
        _hover={{
          bg: "#4C378F",
        }}
      >
        Confirmar validação
      </Button>
    </Box>
  );
}
