import {
  Box,
  Text,
  Flex,
  Select,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Image,
  chakra,
} from "@chakra-ui/react";
import Formatters from "../../../utils/formatters";

const SalesListSection = ({ pedidos }) => {
  return (
    <Flex
      maxW={"90vw"}
      margin={"0 auto"}
      backgroundColor={"white"}
      borderRadius={"6px"}
      shadow={"md"}
      marginTop={"40px"}
      direction={"column"}
    >
      <Select
        p={"25px 10px"}
        width={"260px"}
        mx="2"
        color={"black"}
        variant={"filled"}
        backgroundColor={"#F9F9F9"}
        fontWeight={"bold"}
        borderRadius={"4px"}
        size="sm"
        value={"today"}
        onChange={(e) => {}}
      >
        <option value="today">Pagamentos Efetuados</option>
      </Select>
      <TableContainer color={"black"}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Data
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Produto/Serviço
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Status
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Valor
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {pedidos?.map((pedido) => (
              <Tr key={pedido._id}>
                <Td fontSize={"14px"} fontWeight={"bold"}>
                  {Formatters.date(new Date(pedido.createAt))}
                </Td>
                <Td>
                  <Flex direction={"row"}>
                    <Image
                      src={pedido.itens[0]?.produto_img}
                      width={"80px"}
                      height={"80px"}
                    />
                    <Box width={"10px"} />
                    <Flex direction={"column"}>
                      <Text
                        fontSize={"14px"}
                        fontWeight={"bold"}
                        wordBreak={"break-word"}
                        height={"40px"}
                        maxW={"320px"}
                        whiteSpace={"normal"}
                        noOfLines={2}
                      >
                        {pedido.itens[0]?.produto_nome}
                      </Text>
                      <Text fontWeight={"500"} fontSize={"12px"}>
                        Cor: <chakra.span color={"#4300FF"}>Preto</chakra.span>
                      </Text>
                      <Text fontWeight={"500"} fontSize={"12px"}>
                        Armazenamento:
                        <chakra.span color={"#4300FF"}>128GB</chakra.span>
                      </Text>
                    </Flex>
                  </Flex>
                </Td>
                <Td>
                  <Text
                    fontSize={"13px"}
                    fontWeight={"bold"}
                    maxW={"320px"}
                    whiteSpace={"normal"}
                    noOfLines={2}
                  >
                    {pedido.eventos.at(-1).titulo}
                  </Text>
                </Td>
                <Td>
                  <Flex direction={"column"}>
                    <Text fontSize={"13px"} fontWeight={"bold"} maxW={"320px"}>
                      Pagamento a receber
                    </Text>
                    <Text fontSize={"13px"} fontWeight={"bold"} maxW={"320px"}>
                      {Formatters.money(pedido.custo_total)}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default SalesListSection;
