import React, { useState } from "react";

import { BrowserRouter, useNavigate } from "react-router-dom";
import AppRoutes from "./AppRoutes";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/800.css";

import AuthProvider from "./contexts/authContext";

import { Center } from "@chakra-ui/react";

import ChatProvider from "./contexts/chatContext";
import animationData from "./assets/lottie/loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import CookieConsent from "react-cookie-consent";

export default function App() {
  const [appReady, setAppReady] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(false);

  return (
    <AuthProvider
      onAppReady={(authorized) => {
        setIsAuthorized(authorized);
        setAppReady(true);
      }}
    >
      {appReady ? (
        <BrowserRouter>
          <ChatProvider>
            <AppRoutes isAuthorized={isAuthorized} />
            <CookieConsent
              style={{
                zIndex: 9999,
              }}
              buttonText="Aceitar"
            >
              Este site utiliza cookies para melhorar a sua experiência.
            </CookieConsent>
          </ChatProvider>
        </BrowserRouter>
      ) : (
        <Center bg={"#232544"} h={"100vh"}>
          <Player
            src={animationData}
            style={{ maxWidth: "800px" }}
            autoplay
            loop
            speed={1.3}
          />
        </Center>
      )}
    </AuthProvider>
  );
}
