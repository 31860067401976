import PageScaffold from "../../components/PageScaffold";

import {
  Box,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Flex,
  Text,
  Button,
  Center,
} from "@chakra-ui/react";

import CustomTabButtonComponent from "../../components/CustomTabButtonComponent";
import TabInativos from "./components/TabInativos";
import TabProdutosEsgotados from "./components/TabProdutosEsgotados";
import TabProdutosAtivos from "./components/TabProdutosAtivos";
import TabDenucias from "./components/TabDenucias";
import TabProdutos from "./components/TabProdutos";
import FilterBox from "./components/FilterBox";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/authContext";
import AnunciosRepository from "../../repositories/anuncios_repository";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import { useNavigate, useSearchParams } from "react-router-dom";
import FiltersDialog from "../../components/FiltersDialog";
import { MdChevronRight } from "react-icons/md";
import TabProdutosRascunhos from "./components/TabProdutoRascunho";
import { useToast } from "@chakra-ui/react";
import PageHeader from "../../components/PageHeader";

export default function AnunciosPage({ tipoAnuncio = "produto" }) {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const navigate = useNavigate();
  const context = useContext(AuthContext);
  const estabelecimento = useEstabelecimento();
  const repo = new AnunciosRepository({ token: context.currentToken });
  const toast = useToast();

  const filtroPorTab = [
    null, // todos os pedidos
    "ativo",
    "pendente",
    "esgotado",
    "bloqueado",
    "inativo",
    "rascunho",
  ];

  const [tab, setTab] = useState();
  const [filters, setFilters] = useState({
    vendedor_id: estabelecimento._id,
    texto_busca: "",
    filtros: {
      categoria_id: "",
      categoria: "",
      estoque_min: "",
      estoque_max: "",
      vendas_min: "",
      vendas_max: "",
    },
  });

  const [params] = useSearchParams();

  useEffect(() => {
    if (params.get("target") === "denunciados") {
      setTab("bloqueado");
    } else if (params.get("target") === "esgotados") {
      setTab("esgotado");
    } else {
      setTab(null);
    }
  }, [params]);

  const [currentPage, setCurrentPage] = useState(1);

  const [produtos, setProdutos] = useState([]);

  useEffect(() => {
    if (tab !== undefined) {
      fetchProducts();
    }
  }, [filters, tab, currentPage, tipoAnuncio]);

  const fetchProducts = async () => {
    setProdutos([]);

    const res = await repo.consultar({
      vendedor_id: estabelecimento._id,
      page: currentPage,
      status_anuncio: tab,
      tipo_anuncio: tipoAnuncio,
      ...filters,
    });

    if (res.status === 200) {
      res.data.sort(function (a, b) {
        if (a.createdAt < b.createdAt) {
          return 1;
        }
        if (a.createdAt > b.createdAt) {
          return -1;
        }
        return 0;
      });
      setProdutos(res.data);
    }
  };

  if (estabelecimento.status === "bloqueado") {
    return (
      <PageScaffold showSideBar={true}>
        <Center h={"90vh"}>
          <Text fontWeight={"600"}>Sua conta foi bloqueada</Text>
        </Center>
      </PageScaffold>
    );
  }

  return (
    <>
      <PageScaffold showSideBar={true}>
        <Box w={"100%"} margin={"0 auto"}>
          <Box
            paddingTop={"32px"}
            paddingBottom={"24px"}
            fontWeight={"700"}
            fontSize={"22px"}
          >
            <Flex justify="space-between">
              <Flex direction="column">
                <span>Meus anúncios</span>

                <Text fontSize="14px" fontWeight={500}>
                  Acompanhe todos os seus anuncios de {tipoAnuncio}s na
                  Deshopnise.
                </Text>
              </Flex>
              <Button
                variant="purple"
                backgroundColor="primary.400"
                color="white"
                onClick={() => {
                  if (tipoAnuncio === "produto") {
                    navigate("/novo-produto");
                    return;
                  }
                  if (estabelecimento.tipo_cadastro === "pessoa_fisica") {
                    toast({
                      description:
                        "Apenas lojas cadastradas como pessoa jurídica podem anunciar serviços.",
                      status: "error",
                    });
                    return;
                  }
                  navigate("/novo-servico");
                }}
              >
                Novo anúncio{" "}
                <MdChevronRight color="white" style={{ marginLeft: "10px" }} />
              </Button>
            </Flex>
          </Box>

          <FilterBox
            openFilters={() => {
              setFiltersOpen(true);
            }}
            onChange={(filtros) => {
              setFilters({ ...filters, ...filtros });
            }}
            setTab={(v) => {
              setTab(filtroPorTab.at(v));
            }}
            filters={filters}
          />

          <Box
            boxShadow="sm"
            width={"100%"}
            minHeight={"88vh"}
            borderRadius={"8px"}
            marginTop={"2px"}
            backgroundColor={"white"}
          >
            <Tabs
              index={filtroPorTab.indexOf(tab)}
              onChange={(index) => {
                setTab(filtroPorTab.at(index));
              }}
            >
              {/* <TabList>
              <CustomTabButtonComponent>Todos</CustomTabButtonComponent>
              <CustomTabButtonComponent>Ativos</CustomTabButtonComponent>
              <CustomTabButtonComponent>Pendentes</CustomTabButtonComponent>
              <CustomTabButtonComponent>Esgotados</CustomTabButtonComponent>
              <CustomTabButtonComponent>Denuncias</CustomTabButtonComponent>
              <CustomTabButtonComponent>Inativos</CustomTabButtonComponent>
            </TabList> */}
              <TabPanels>
                <TabPanel>
                  <TabProdutos produtos={produtos} />
                </TabPanel>
                <TabPanel>
                  <TabProdutos produtos={produtos} />
                </TabPanel>
                <TabPanel>
                  <TabProdutosAtivos produtos={produtos} />
                </TabPanel>
                <TabPanel>
                  <TabProdutosEsgotados produtos={produtos} />
                </TabPanel>
                <TabPanel>
                  <TabDenucias produtos={produtos} />
                </TabPanel>
                <TabPanel>
                  <TabInativos produtos={produtos} />
                </TabPanel>
                <TabPanel>
                  <TabProdutosRascunhos produtos={produtos} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </PageScaffold>
      <FiltersDialog
        isOpen={filtersOpen}
        onClose={() => {
          setFiltersOpen(false);
        }}
        setTab={(v) => {
          setTab(filtroPorTab.at(v));
        }}
        onChange={(filtros) => {
          setFiltersOpen(false);
          setFilters({ ...filters, ...filtros });
        }}
      />
    </>
  );
}
