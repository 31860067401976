import { Box, Heading, Text } from "@chakra-ui/react";

export default function InformacoesAlteracaoTitularidade() {
  return (
    <Box
      boxShadow="sm"
      width={"25%"}
      height={"max-content"}
      borderRadius={"8px"}
      marginTop={"5px"}
      backgroundColor={"#232544"}
      color="white"
      p="5"
    >
      <Heading fontSize="22px">Alterar titularidade da minha conta</Heading>
      <br />
      <Text fontSize="xs">
        Para pode alterar a sua titularidade de pessoa física para{" "}
        <b>Jurídica</b>, você deve informar o CNPJ que faremos uma validação.
        <br />
        <br />
        Para continuar pedimos a seguinte documentação para comprovar o vínculo:
        <br />
        <br />
        {/* <b>RG ou CNH do titular atual da conta.</b>
        <br />
        <br /> */}
        <b>Número do CNPJ.</b>
        <br />
        <br />- Certifique-se que a situção do CNPJ é <b>Ativa</b>.
        <br />
        <br />- Certifique-se que você faz parte do <b>Quadro Societário</b>.
        <br />
        <br />
        {/* <b>Nº de inscrição estadual / municipal</b>
        <br />
        <br />
        Para alterar sua titularidade de pessoa física para <b>MEI</b>, você
        deve anexar a documentação que comprove o vínculo.
        <br />
        <br />
        <b>RG ou CNH do titular atual da conta</b>
        <br />
        <br />
        <b>Documento CNPJ</b> */}
      </Text>
    </Box>
  );
}
