import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useToast,
} from "@chakra-ui/react";
import { MdChevronRight } from "react-icons/md";
import { useEffect, useState } from "react";
import CategoriasRepository from "../../../repositories/categorias_repository";
import useAuthToken from "../../../hooks/useAuthToken";
import searchImg from "../../../assets/pesquisar.svg";

function ItemCategoria({ nome, onClick, hasChildren, isSelected }) {
  return (
    <Flex onClick={onClick} className="p-hover" justifyContent="space-between">
      <Text color={isSelected ? "primary.300" : "black"} fontWeight={500}>
        {nome}
      </Text>
      {hasChildren && <MdChevronRight />}
    </Flex>
  );
}

export default function Categorias({ onSelect }) {
  const token = useAuthToken();

  const repo = new CategoriasRepository({ token });

  const toast = useToast();

  const [categorias, setCategorias] = useState([]);
  const [categoriasLevel2, setCategoriasLevel2] = useState([]);
  const [categoriasLevel3, setCategoriasLevel3] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    repo.listarCategoriasCascata().then((res) => {
      setCategorias(res.data);
    });
  }, []);

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      // do something
    }
  };

  return (
    <Flex direction="column" w="60%">
      <InputGroup spacing="24px" minW={"350px"} w="70%">
        <InputLeftElement children={<Image src={searchImg} boxSize="17px" />} />
        <Input
          list="pages"
          placeholder="Buscar categoria"
          fontSize={"12px"}
          fontWeight={"medium"}
          variant="filled"
          backgroundColor="#fafafa"
          value={inputValue}
          onKeyPress={onKeyPress}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
        />
      </InputGroup>
      <Box height={"10px"} />
      <Flex w="800px" h="300px" px="2" mb="5">
        <Flex
          w="full"
          direction="column"
          overflowY="scroll"
          p={"10px"}
          css={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
          }}
        >
          {categorias.map((categoria) => (
            <ItemCategoria
              key={categoria._id}
              nome={categoria.nome}
              hasChildren={categoria.filhos.length > 0}
              isSelected={selectedCategory[0]?.id === categoria._id}
              onClick={() => {
                setCategoriasLevel2(categoria.filhos);
                setCategoriasLevel3([]);
                setSelectedCategory([
                  {
                    nome: categoria.nome,
                    id: categoria._id,
                    explicito: categoria.explicito || false,
                  },
                ]);
              }}
            />
          ))}
        </Flex>
        <Flex
          w="full"
          direction="column"
          overflowY="scroll"
          p={"10px"}
          css={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
          }}
        >
          {categoriasLevel2.map((categoria) => (
            <ItemCategoria
              key={categoria._id}
              isSelected={selectedCategory[1]?.id === categoria._id}
              hasChildren={categoria.filhos.length > 0}
              onClick={() => {
                setCategoriasLevel3(categoria.filhos);
                setSelectedCategory([
                  selectedCategory[0],
                  {
                    nome: categoria.nome,
                    id: categoria._id,
                    explicito: categoria.explicito || false,
                  },
                ]);
              }}
              nome={categoria.nome}
            />
          ))}
        </Flex>
        <Flex
          w="full"
          direction="column"
          overflowY="scroll"
          p={"10px"}
          css={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
          }}
        >
          {categoriasLevel3.map((categoria) => (
            <ItemCategoria
              hasChildren={categoria.filhos.length > 0}
              nome={categoria.nome}
              isSelected={selectedCategory[2]?.id === categoria._id}
              onClick={() => {
                setSelectedCategory([
                  selectedCategory[0],
                  selectedCategory[1],
                  {
                    nome: categoria.nome,
                    id: categoria._id,
                    explicito: categoria.explicito || false,
                  },
                ]);
              }}
            />
          ))}
        </Flex>
      </Flex>
      <Flex direction="row">
        <Text fontSize="sm" fontWeight="semibold">
          Categoria selecionada: &nbsp;
        </Text>
        <br />
        <Text fontSize="sm" fontWeight="bold" color="primary.400">
          {selectedCategory.map((e) => e.nome).join(" > ")}
        </Text>
      </Flex>
      <Button
        w="150px"
        backgroundColor="primary.400"
        color="white"
        mt="2"
        onClick={() => {
          if (selectedCategory.length === 0) {
            toast({
              title: "Selecione uma categoria",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            return;
          }

          onSelect(selectedCategory);
        }}
      >
        Próximo
      </Button>
    </Flex>
  );
}
