import React from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Button,
  Divider,
  InputLeftElement,
  Image,
} from "@chakra-ui/react";
import { useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { MdCircle, MdPieChart, MdShowChart } from "react-icons/md";
import searchImg from "../../../assets/pesquisar.svg";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";

export default function FilterBox({ onFilterChange = () => {}, onReset }) {
  const [nome, setNome] = React.useState("");
  const [status, setStatus] = React.useState("");

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const navigate = useNavigate();

  return (
    <Flex direction="column" mt="10px">
      <Flex
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
        mb="10px"
        px="4"
      >
        <Flex direction="column" maxW="50%">
          <Text fontSize="md" fontWeight="bold" color="#4e4e4e">
            Lista de Cupons
          </Text>
          <Text fontSize="xs" fontWeight={"medium"}>
            Crie cupons de descontos para toda a sua loja ou produto especifico.
            <span
              style={{
                color: "#7048f3",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {" Saiba mais."}
            </span>
          </Text>
        </Flex>

        <Flex direction="row">
          <Link to={"/estatisticas"}>
            <Button
              onClick={() => {
                navigate("/estatisticas");
              }}
              width={"140px"}
              marginLeft={"15px"}
              backgroundColor="#232544"
              color={"white"}
              _hover={{ opacity: "0.8" }}
            >
              <Flex direction="row" align="center">
                <MdShowChart size={20} color="cyan" />
                <Text mx="2" fontSize="xs">
                  Métricas
                </Text>
                <Divider h="10px" mr="2" orientation="vertical" />
                <MdCircle size={15} color="cyan" />
              </Flex>
            </Button>
          </Link>
          <Box w="5px" />
          <Link to={"/criar-cupom"}>
            <Button
              width={"180px"}
              marginLeft={"15px"}
              backgroundColor="primary.400"
              color={"white"}
              _hover={{ opacity: "0.8" }}
            >
              Adicionar Cupom
            </Button>
          </Link>
        </Flex>
        {/* <Flex align="center">
          <Text fontSize="sm" fontWeight={500}>
            Período
          </Text>
          <Box w={"10px"} />
          <Input
            fontWeight={500}
            size="sm"
            type={"date"}
            ref={startDateRef}
            maxW="130px"
          />
          <Input
            fontWeight={500}
            size="sm"
            type={"date"}
            maxW="130px"
            ref={endDateRef}
          />
        </Flex> */}
      </Flex>
      <Box
        boxShadow="sm"
        width={"100%"}
        borderRadius={"8px"}
        marginTop={"5px"}
        padding={"16px"}
        backgroundColor={"white"}
        fontWeight="500"
      >
        <Flex padding={"16px 0px"} align="center" justify="space-between">
          <Flex direction="row" align="center">
            <InputGroup spacing="24px" width={"280px"} size="md">
              <InputLeftElement
                children={<Image src={searchImg} boxSize="17px" />}
              />
              <Input
                type="text"
                fontWeight={500}
                placeholder="Buscar por nome..."
                w="270px"
                value={nome}
                onChange={(e) => {
                  setNome(e.target.value);
                }}
                mr="5"
                variant={"filled"}
              />
            </InputGroup>
          </Flex>
          <Flex direction="row" align="center">
            <Text fontSize="sm" fontWeight={500} w="200px">
              Data de criação do pedido
            </Text>

            <CustomDateRangePicker
              currentRange={[startDate, endDate]}
              onChange={({ start, end }) => {
                setStartDate(start);
                setEndDate(end);
              }}
              size="md"
            />
            <Text mx="2" fontSize="sm">
              Status
            </Text>
            <Select
              mx="2"
              variant={"filled"}
              w="150px"
              size="md"
              fontWeight={"bold"}
              value={status}
              // value={currentTab}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value="todos">Todos</option>
              <option value="ativo">Ativos</option>
              <option value="inativo">Inativos</option>
            </Select>
            <Button
              width={"140px"}
              marginLeft={"15px"}
              backgroundColor="primary.400"
              color={"white"}
              _hover={{ opacity: "0.8" }}
              onClick={() => {
                onFilterChange({
                  nome: nome,
                  status: status === "todos" ? "" : status,
                  data_inicio: startDate,
                  data_fim: endDate,
                });
              }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                onFilterChange({});
                setNome("");
                setStatus("todos");

                setStartDate(null);
                setEndDate(null);
                onReset();
              }}
              width={"140px"}
              marginLeft={"15px"}
              variant={"outline"}
            >
              Redefinir
            </Button>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
