import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Formatters from "../../utils/formatters";

export default function ProductComponent({ produto, index }) {
  return (
    <Flex direction="column">
      <Box
        w="30px"
        h="30px"
        backgroundColor="grey"
        borderRadius="4px"
        align="center"
      >
        <Text color="white" fontWeight="bold" pt="1">
          {index + 1}
        </Text>
      </Box>
      <Image h="250px" objectFit="scale-down" src={produto.imagens.at(0)} />
      <Text fontSize="14px" fontWeight="bold" h={"100px"}>
        {produto.titulo}
      </Text>
      <Flex direction="row" align="center">
        <Text fontSize="20px" fontWeight="bold" color="black">
          {Formatters.money(produto.variantes.at(0).preco)}
        </Text>
        {/* <Box w="10px" h="1px" backgroundColor="grey" mx="2"></Box>
        <Text fontSize="xs" fontWeight="bold" color="#0fd115">
          R$ 3.500,00
        </Text> */}
      </Flex>
      <Text fontSize="14px" fontWeight="bold">
        {produto.variantes.at(0).vendas} Vendidos
      </Text>
    </Flex>
  );
}
