import {
  Box,
  Text,
  Flex,
  Button,
  Input,
  FormControl,
  useToast,
} from "@chakra-ui/react";

import { useEffect, useRef, useState } from "react";
import useEstabelecimento from "../../../../hooks/useEstabelecimento";
import valida_cpf_cnpj from "../../../../utils/validator_cpf_cnpj";
import useAuthToken from "../../../../hooks/useAuthToken";
import VendedoresRepository from "../../../../repositories/vendedores_repositories";
import PublicRepository from "../../../../repositories/public_repository";

export default function PerfilComponent({ data, onCompleted }) {
  const estabelecimento = useEstabelecimento();
  const isPj = estabelecimento?.tipo_cadastro !== "pessoa_fisica";
  const publicRepo = new PublicRepository();

  const [name, setName] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [doc, setDoc] = useState("");
  const token = useAuthToken();

  const toast = useToast();

  useEffect(() => {
    if (data) {
      setName(data.nome);
      setRazaoSocial(data.razao_social);
      setDoc(data.documento);
    }
  }, [data]);

  const onSubmit = async () => {
    const isAnyEmpty = [name, razaoSocial, doc].some((value) => !value);

    if (isAnyEmpty) {
      toast({
        title: "Informe os dados",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (!valida_cpf_cnpj(doc)) {
      toast({
        title: `O ${isPj ? "CNPJ" : "CPF"} informado é inválido`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (doc !== estabelecimento.documento) {
      let resp = await publicRepo.consultar({ documento: doc });
      if (resp.status !== 200) {
        toast({
          description:
            "Já existe um cadastro com esse " + (isPj ? "CNPJ" : "CPF"),
          status: "error",
        });
        return;
      }
    }

    const data = {
      doc,
      doc_type: isPj ? "CNPJ" : "CPF",
      nome: name,
      razao_social: razaoSocial,
    };

    const repo = new VendedoresRepository({ token });

    repo
      .completarCadastroPerfil(data)
      .then((response) => {
        if (response.status === 200) {
          toast({
            title: "Dados atualizados",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onCompleted(data);
        }
      })
      .catch((err) => {
        console.log(err);
        toast({
          title: "Ocorreu um erro",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <Box
      style={{
        zoom: "1.1",
      }}
      width="full"
      my="5"
      mt="20"
      align={"center"}
    >
      <Box w={"450px"} align="start">
        <Text
          fontSize={"22px"}
          fontWeight={"700"}
          w={"400px"}
          mb="5"
          textAlign={"start"}
        >
          Informações do {isPj ? "CNPJ" : "CPF"}
        </Text>
      </Box>
      <Box w={"450px"} align="start">
        <Text
          fontSize={"14px"}
          fontWeight={"500"}
          w={"400px"}
          mb="5"
          textAlign={"start"}
        >
          Preencha as informações do seu {isPj ? "CNPJ" : "CPF"} para liberar o
          acesso a todas as funções da plataforma.
        </Text>
      </Box>
      <Flex
        justifyContent={"start"}
        w={"450px"}
        align="start"
        direction="column"
      >
        <Text size={"lg"} mb={"7px"} fontWeight={"500"}>
          Nome da sua loja
        </Text>
        <FormControl
          onChange={(e) => {
            setName(e.target.value);
          }}
          isRequired
        >
          <Input
            value={name}
            variant="filled"
            bg="#EBEBEB"
            fontWeight="500"
            fontSize="sm"
            placeholder="Digite o nome da sua loja"
            size="lg"
            mb="2"
            color={"#000"}
            _placeholder={{
              fontWeight: "500",
            }}
          />
        </FormControl>

        <Text size={"lg"} mb={"7px"} fontWeight={"500"}>
          {isPj ? "Razão Social" : "Nome Completo"}
        </Text>

        <FormControl
          onChange={(e) => {
            setRazaoSocial(e.target.value);
          }}
          isRequired
        >
          <Input
            variant="filled"
            bg="#EBEBEB"
            fontWeight="500"
            fontSize="sm"
            placeholder={
              isPj ? "Digite sua razão social" : "Digite seu nome completo"
            }
            size="lg"
            mb="2"
            _placeholder={{
              fontWeight: "500",
            }}
            value={razaoSocial}
          />
        </FormControl>

        <Text size={"lg"} mb={"7px"} fontWeight={"500"}>
          Digite seu {isPj ? "CNPJ" : "CPF"}
        </Text>

        <FormControl
          onChange={(e) => {
            setDoc(e.target.value);
          }}
          isRequired
        >
          <Input
            variant="filled"
            bg="#EBEBEB"
            fontWeight="500"
            fontSize="sm"
            placeholder={isPj ? "Digite seu CNPJ" : "Digite seu CPF"}
            size="lg"
            mb="2"
            _placeholder={{
              fontWeight: "500",
            }}
            value={doc}
          />
        </FormControl>
      </Flex>

      <Text fontSize={"12px"} fontWeight={"500"}>
        Digite apenas números, sem traços ou pontos
      </Text>

      <Button
        onClick={onSubmit}
        bg={"#683FF3"}
        color={"white"}
        mt={"5"}
        w={"450px"}
        borderRadius={"8px"}
        h={"45px"}
        fontWeight={"700"}
        _hover={{
          bg: "#4C378F",
        }}
      >
        CONFIRMAR
      </Button>
    </Box>
  );
}
