import { Flex, Image, Heading, Text } from "@chakra-ui/react";
import {
  MdBadge,
  MdLocationOn,
  MdTextSnippet,
  MdLocalShipping,
  MdExpandMore,
  MdExpandLess,
  MdCircle,
} from "react-icons/md";

import Formatters from "../../../utils/formatters";
import TimelineComponent from "../../../components/TimeLineComponent";
import { useState } from "react";

export default function SolicitacaoBox({ divergencia, pedido }) {
  const [showTimeline, setShowTimeline] = useState(false);

  return (
    <Flex
      boxShadow="sm"
      w="full"
      mt="10px"
      borderRadius="8px"
      p="5"
      bgColor="white"
      direction="column"
    >
      <Flex
        w="80%"
        backgroundColor="#F5F5F5"
        borderRadius="4"
        p="4"
        direction="row"
        align="center"
      >
        <MdBadge size={"25px"} color={"#693FF3"} />
        <Flex direction="column" pl="2">
          <Heading fontSize="xs">ID da solicitação</Heading>
          <Text fontSize="xs">{divergencia?._id}</Text>
        </Flex>
      </Flex>
      <Flex
        w="80%"
        backgroundColor="#F5F5F5"
        borderRadius="4"
        p="4"
        direction="row"
        mt="2"
      >
        <MdTextSnippet size={"25px"} color={"#693FF3"} />
        <Flex direction="column" pl="2">
          <Heading fontSize="xs">Motivo do comprador</Heading>
          <Text fontSize="xs">
            {divergencia?.motivo.split("_").join(" ").toUpperCase()}
          </Text>

          <Flex direction="row">
            {divergencia?.imagens.map((key) => {
              return (
                <Image
                  borderRadius="4"
                  src={key}
                  h="75px"
                  w="75px"
                  objectFit="cover"
                  mr="2"
                />
              );
            })}
          </Flex>

          <Text fontSize="xs">{divergencia?.comentario_usuario}</Text>
        </Flex>
      </Flex>
      <Flex
        w="80%"
        backgroundColor="#F5F5F5"
        borderRadius="4"
        p="4"
        direction="row"
        align="center"
        mt="2"
      >
        <MdLocationOn size={"25px"} color={"#693FF3"} />
        <Flex direction="column" pl="2">
          <Heading fontSize="xs">Endereço de entrega</Heading>
          <Text fontSize="xs">
            {pedido?.endereco?.rua +
              ", " +
              pedido?.endereco?.numero +
              ", " +
              pedido?.endereco?.bairro +
              ", " +
              pedido?.endereco?.cidade +
              ", " +
              pedido?.endereco?.estado +
              "- " +
              pedido?.endereco?.cep}
          </Text>
        </Flex>
      </Flex>
      <Flex
        w="80%"
        backgroundColor="#F5F5F5"
        borderRadius="4"
        p="4"
        direction="column"
        mt="2"
      >
        <Flex w="full" direction="row">
          <MdLocalShipping size={"25px"} color={"#693FF3"} />
          <Flex direction="row" pl="2" justify="space-between" w="full">
            <Flex direction="column">
              <Heading fontSize="xs">Informações de logística</Heading>
              <Text fontSize="xs">
                {/* R. Con Vicente, 342, São Paulo, São Paulo, 34323-342 */}
              </Text>
            </Flex>
            {showTimeline ? (
              <MdExpandLess
                size={"25px"}
                color={"#693FF3"}
                cursor="pointer"
                onClick={() => {
                  setShowTimeline(!showTimeline);
                }}
              />
            ) : (
              <MdExpandMore
                size={"25px"}
                color={"#693FF3"}
                cursor="pointer"
                onClick={() => {
                  setShowTimeline(!showTimeline);
                }}
              />
            )}
          </Flex>
        </Flex>
        <Flex direction="column" p="4" pl="8">
          <Heading fontSize="md" mb="4">
            {"Situação: "}
            {pedido?.eventos.at(-2)?.titulo}
          </Heading>
          <Flex direction="column" pl="4">
            {showTimeline &&
              pedido?.eventos.map((row, index, arr) => {
                return (
                  <TimelineComponent
                    logo={row.logo}
                    title={row.titulo}
                    description={row.descricao}
                    date={row.data}
                    color={"#32CD32"}
                    index={index}
                    arrLength={pedido.eventos.length}
                  />
                );
              })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
