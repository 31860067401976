import { buildHttpClient } from "../services/api";
import axios from "axios";

class CarteiraRepository {
  constructor({ token }) {
    this.http = buildHttpClient(token);

    this.http.interceptors.request.use(
      function (config) {
        if (!config.headers.token) {
          throw new axios.Cancel("");
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  consultar(data) {
    return this.http.get("/carteira/consultar?" + data);
  }
  resumo() {
    return this.http.get("/carteira/resumo");
  }
  saque(body) {
    return this.http.post("/carteira/saque", body);
  }
}

export default CarteiraRepository;
