import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";

import { Tr, Td } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import Formatters from "../../../utils/formatters";

export default function CuponsTr({ data, onDisable, onEnable }) {
  const isValid = new Date(data.data_fim) > new Date() && data.quantidade > 0;

  return (
    <>
      <Tr key={data._id}>
        <Td>
          <Text fontSize="xs">{data.nome}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">{data.tipo === "loja" ? "Loja" : "Produto"}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">
            {data.desconto_porcentagem && data.desconto_porcentagem + "%"}{" "}
            {data.desconto_reais && Formatters.money(data.desconto_reais)}
          </Text>
        </Td>
        <Td>
          <Text fontSize="xs">{data.quantidade}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">{data.pedidos.length}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">{data.ativo ? "Ativo" : "Inativo"}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">
            {new Date(data.data_inicio.replace("Z", "")).toLocaleDateString()} -{" "}
            {new Date(data.data_fim.replace("Z", "")).toLocaleDateString()}
          </Text>
        </Td>
        <Td>
          <Flex direction="column">
            <Link to={"/cupom/" + data._id}>
              <Button
                disabled={!isValid}
                variant={"customOutline"}
                size="sm"
                mb="0.5"
                w="120px"
              >
                Editar
              </Button>
            </Link>
            {isValid && !data.ativo && (
              <Button
                onClick={() => {
                  onEnable(data._id);
                }}
                variant={"customOutline"}
                size="sm"
                mb="0.5"
                w="120px"
              >
                Ativar
              </Button>
            )}
            {data.ativo && (
              <Button
                disabled={!isValid}
                onClick={() => {
                  onDisable(data._id);
                }}
                variant={"customOutline"}
                size="sm"
                mb="0.5"
                w="120px"
              >
                Desativar
              </Button>
            )}
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
