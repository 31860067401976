import useAuthToken from "../hooks/useAuthToken";
import { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  GridItem,
  Grid,
  Th,
  TableContainer,
  Table,
  Thead,
  Tr,
  Checkbox,
  Tbody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { MdClose } from "react-icons/md";

import CompreTambemAsset from "../assets/pipoca.png";
import DeshopFlashAsset from "../assets/raio.png";
import RapadinhaAsset from "../assets/trevo.png";
import Cupom2Asset from "../assets/cupom2.png";
import DescontoAsset from "../assets/desconto.png";

import CupomDeDescontoRepository from "../repositories/cupom_de_desconto_repository";
import OfertaComboRepository from "../repositories/ofertas_combo_repository";
import OfertaRelampagoRepository from "../repositories/ofertas_relampago_repository";
import PromocaoRepository from "../repositories/promocao_repository";

import Formatters from "../utils/formatters";
// import RaspadinhaRepository from "../repositories/raspadinha_repository";

function CampanhaButton({ text, description, onClick, asset, color }) {
  return (
    <GridItem onClick={onClick}>
      <Box
        width={"290px"}
        height={"220px"}
        bgColor={color ?? "primary.400"}
        p="5"
        borderRadius="16px"
        alignItems="center"
        cursor="pointer"
        color="white"
      >
        <Flex
          direction="row"
          justifyContent="space-between"
          h="100%"
          align="center"
        >
          <Flex direction="column" py="10px" align="start">
            <Image src={asset} h="80px" fit="contain" />
            <Box h="10px" />
            <Text fontWeight="bold">{text}</Text>
            <Box h="1" />
            <Text fontSize="xs" fontWeight={500} noOfLines={[2, 2, 2, 2, 2, 4]}>
              {description}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </GridItem>
  );
}

export default function CampanhasSelectorDialog({
  onSelect,
  isOpen,
  onClose,
  currentProducts = [],
  blockProducts = [],
  allAvailable = false,
}) {
  const token = useAuthToken();
  const [currentCampanha, setCurrentCampanha] = useState(null);
  const [campanhas, setCampanhas] = useState([]);
  const [picked, setPicked] = useState([]);

  const promocaoRepository = new PromocaoRepository({ token });
  const cupomDeDescontoRepository = new CupomDeDescontoRepository({ token });
  const ofertaComboRepository = new OfertaComboRepository({ token });
  const ofertaRelampagoRepository = new OfertaRelampagoRepository({ token });
  // const raspadinhaRepository = new RaspadinhaRepository(token);

  useEffect(() => {}, [isOpen]);

  // promocao, cupom_desconto, oferta_combo, oferta_relampago

  useEffect(() => {
    if (currentCampanha === "promocao") {
      promocaoRepository.consultar().then((res) => {
        if (res.data.length > 0) {
          setCampanhas(
            res.data.map((cupom) => ({
              nome: cupom.nome,
              tipo: "Promoção",
              raw_tipo: "promocao",
              status: cupom.ativo ? "Ativo" : "Inativo",
              id: cupom._id,
              data_inicio: cupom.data_inicio,
              data_fim: cupom.data_fim,
            }))
          );
        }
      });
    }

    if (currentCampanha === "cupom_desconto") {
      cupomDeDescontoRepository.consultar().then((res) => {
        if (res.data.length > 0) {
          setCampanhas(
            res.data.map((cupom) => ({
              nome: cupom.nome,
              tipo: "Cupom",
              raw_tipo: "cupom",
              status: cupom.ativo ? "Ativo" : "Inativo",
              id: cupom._id,
              data_inicio: cupom.data_inicio,
              data_fim: cupom.data_fim,
            }))
          );
        }
      });
    }

    if (currentCampanha === "oferta_combo") {
      ofertaComboRepository.consultar().then((res) => {
        if (res.data.length > 0) {
          setCampanhas(
            res.data.map((d) => ({
              nome: d.nome,
              tipo: "Oferta Combo",
              raw_tipo: "oferta_combo",
              status: d.status,
              id: d._id,
              data_inicio: d.data_inicio,
              data_fim: d.data_fim,
            }))
          );
        }
      });
    }

    if (currentCampanha === "oferta_relampago") {
      ofertaRelampagoRepository.consultar().then((res) => {
        if (res.data.length > 0) {
          setCampanhas(
            res.data.map((d) => ({
              nome: "Oferta Relâmpago",
              tipo: "Oferta Relâmpago",
              raw_tipo: "oferta_relampago",
              status: d.status,
              id: d._id,
              data_inicio: d.data_inicio,
              data_fim: d.data_fim,
              is_selected: false,
            }))
          );
        }
      });
    }
  }, [currentCampanha]);

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      size="6xl"
      height="400px"
      width={"600px"}
      // leastDestructiveRef={cancelRef}
      //  onClose={onClose}
    >
      <ModalOverlay>
        <ModalContent width={currentCampanha ? "1000px" : "700px"}>
          <ModalHeader fontSize="2xl" fontWeight="bold">
            <Flex justify={"space-between"}>
              <Text>
                {" "}
                {currentCampanha
                  ? "Selecione"
                  : "Selecione o tipo de campanha"}{" "}
              </Text>
              <MdClose
                cursor={"pointer"}
                onClick={() => {
                  onClose();
                  setCampanhas([]);
                  setCurrentCampanha(null);
                }}
              />
            </Flex>
          </ModalHeader>

          <ModalBody p={"24px"}>
            {!currentCampanha && (
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={6}
                width={"500px"}
                pb={"20px"}
                pl={"20px"}
              >
                {/* <CampanhaButton
                  text={"Promoções"}
                  description={
                    "Crie descontos em seus produtos para vender mais"
                  }
                  asset={DescontoAsset}
                  color={"#01AA5A"}
                  onClick={() => {
                    setCurrentCampanha("promocao");
                  }}
                /> */}

                <CampanhaButton
                  text={"Cupons"}
                  description={
                    "Crie cupons exclusivos de produtos e de sua loja para aumentar ainda mais suas vendas."
                  }
                  asset={Cupom2Asset}
                  color={"#693FF3"}
                  onClick={() => {
                    setCurrentCampanha("cupom_desconto");
                  }}
                />

                <CampanhaButton
                  text={"Compre também"}
                  description={
                    "Ofereça mais opções em conjunto com seu anuncio."
                  }
                  asset={CompreTambemAsset}
                  color={"#FF3A3A"}
                  onClick={() => {
                    setCurrentCampanha("oferta_combo");
                  }}
                />
                <CampanhaButton
                  text={"Oferta Relâmpago"}
                  description={
                    "Crie ofertas por tempo limitado para aumentar o engajamento da sua loja."
                  }
                  asset={DeshopFlashAsset}
                  color={"#474747"}
                  onClick={() => {
                    setCurrentCampanha("oferta_relampago");
                  }}
                />
                <CampanhaButton
                  text={"Raspadinha"}
                  description={"Crie raspadinhas premiadas para seus clientes"}
                  color={"#17AC84"}
                  asset={RapadinhaAsset}
                  onClick={() => {
                    setCurrentCampanha("raspadinha");
                  }}
                />
              </Grid>
            )}

            {currentCampanha && (
              <TableContainer maxH={"600px"} overflowY={"scroll"}>
                <Table variant="simple">
                  <Thead>
                    <Tr>
                      <Th w={"20px"} padding={"0px"}>
                        <Checkbox
                          ml={"3px"}
                          onChange={(e) => {
                            setCampanhas(
                              campanhas.map((c) => {
                                return { ...c, is_selected: e.target.checked };
                              })
                            );
                          }}
                        />
                      </Th>
                      <Th
                        color={"black"}
                        fontSize={"14px"}
                        textTransform={"none"}
                        w="390px"
                      >
                        Nome
                      </Th>
                      <Th
                        color={"black"}
                        fontSize={"14px"}
                        textTransform={"none"}
                        w={"200px"}
                      >
                        Tipo de campanha
                      </Th>
                      <Th
                        color={"black"}
                        fontSize={"14px"}
                        textTransform={"none"}
                      >
                        Status
                      </Th>
                      <Th
                        color={"black"}
                        fontSize={"14px"}
                        textTransform={"none"}
                      >
                        Periodo
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {campanhas.map((campanha) => {
                      return (
                        <Tr
                          height={"100px"}
                          key={campanha.id}
                          cursor={"pointer"}
                          onClick={() => {
                            // onSelect(campanha);
                            // onClose();
                            // setCampanhas([]);
                            // setCurrentCampanha(null);
                          }}
                        >
                          <Th w={"20px"} padding={"0px"}>
                            <Checkbox
                              ml={"3px"}
                              isChecked={campanha.is_selected}
                              onChange={(e) => {
                                setCampanhas(
                                  campanhas.map((c) => {
                                    if (c.id === campanha.id) {
                                      return {
                                        ...c,
                                        is_selected: e.target.checked,
                                      };
                                    }
                                    return c;
                                  })
                                );
                              }}
                            />
                          </Th>
                          <Th
                            color={"black"}
                            fontSize={"14px"}
                            textTransform={"none"}
                            w="390px"
                          >
                            {campanha.nome}
                          </Th>
                          <Th
                            color={"black"}
                            fontSize={"14px"}
                            textTransform={"none"}
                            w={"200px"}
                          >
                            {campanha.tipo}
                          </Th>
                          <Th
                            color={"black"}
                            fontSize={"14px"}
                            textTransform={"none"}
                          >
                            {campanha.status}
                          </Th>
                          <Th
                            color={"black"}
                            fontSize={"14px"}
                            textTransform={"none"}
                          >
                            {new Date(
                              campanha.data_inicio
                            ).toLocaleDateString()}
                            <br />
                            {new Date(campanha.data_fim).toLocaleDateString()}
                          </Th>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={() => {
                setCurrentCampanha(null);
                setCampanhas([]);
                onClose();
              }}
              w="150px"
            >
              Cancelar
            </Button>
            <Button
              colorScheme="purple"
              backgroundColor="primary.400"
              w="150px"
              onClick={() => {
                setCurrentCampanha(null);
                setCampanhas([]);
                onSelect({
                  campanha: currentCampanha,
                  items: campanhas.filter((c) => c.is_selected),
                });
              }}
              ml={3}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
