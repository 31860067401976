import { Box, Flex, Heading, Text, Button, Spacer } from "@chakra-ui/react";
import TabCuponsRaspadinha from "./TabCuponsRaspadinha";

export default function ListaCuponsRaspadinha({ onOpen, cupons, disabled }) {
  return (
    <Flex direction={"column"}>
      <Box
        boxShadow="sm"
        w="full"
        mt="5"
        borderRadius="8px"
        p="5"
        bgColor="white"
      >
        <Flex justifyContent="space-between" mb="2">
          <Flex direction="column" w="60%">
            <Heading fontSize="md">Lista de cupons</Heading>
            <Spacer h="2" />

            <Text fontWeight={500} fontSize="14px" noOfLines="2">
              Comece oferecendo seus melhores preços aqui na Deshopnise! Clique
              aqui para acessar nosso Guia do Usuario, onde explica como criar
              seus próprios descontos.
            </Text>
          </Flex>
          <Spacer h="1" />

          <Button
            disabled={disabled}
            onClick={onOpen}
            w="250px"
            bgColor="primary.400"
            color="white"
          >
            Adicionar Cupom
          </Button>
        </Flex>
        <TabCuponsRaspadinha cupons={cupons} />
      </Box>
    </Flex>
  );
}
