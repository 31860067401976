import {
  Box,
  Flex,
  Grid,
  Image,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import PageScaffold from "../../components/PageScaffold";
import useAuthToken from "../../hooks/useAuthToken";
import LembretesRepository from "../../repositories/lembretes_repository";
import FilterBox from "./components/FilterBox";
import OfertasTr from "./components/LembretesTr";
import backAsset from "../../assets/back.png";

function Infos({ title, value, description }) {
  return (
    <Box backgroundColor="white" px="4" py="4" borderRadius="8">
      <Flex direction="column" lineHeight="1.1">
        <Text fontSize="sm" fontWeight="semibold">
          {title}
        </Text>
        <Text fontSize="2xl" fontWeight="bold" color="primary.400">
          {value}
        </Text>
        <Text fontSize="xs">{description}</Text>
      </Flex>
    </Box>
  );
}

function Lembretes({ lembretes }) {
  return (
    <Box
      boxShadow="sm"
      width={"100%"}
      borderRadius={"8px"}
      marginTop={"1px"}
      padding={"16px"}
      backgroundColor={"white"}
    >
      <Flex direction={"column"}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th textTransform="none">Nome</Th>
                <Th textTransform="none">Tipo de cupom</Th>
                <Th textTransform="none">Valor do desconto</Th>
                <Th textTransform="none">Limite de uso</Th>
                <Th textTransform="none">Status</Th>
                <Th textTransform="none">Data</Th>
                <Th textTransform="none">Ação</Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={600}>
              {lembretes.map((c) => (
                <OfertasTr key={c._id} data={c} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
}

export default function LembreteCarrinhoPage() {
  const token = useAuthToken();
  const repo = new LembretesRepository({ token });
  const [lembretes, setLembretes] = useState([]);
  const [filters, setFilters] = useState({});

  const fetch = async () => {
    const res = await repo.consultar(filters);

    if (res.status === 200) {
      setLembretes(res.data);
      return res.data;
    }

    return [];
  };

  useEffect(() => {
    fetch();
  }, [filters]);

  return (
    <PageScaffold>
      <Box w={"99%"} margin={"0 auto"}>
        <Box
          paddingTop={"32px"}
          paddingBottom={"24px"}
          fontWeight={"700"}
          fontSize={"22px"}
        >
          <Flex direction={"row"} alignItems={"center"}>
            <Image
              cursor={"pointer"}
              src={backAsset}
              height={"15px"}
              alt="Voltar"
              onClick={() => {
                window.history.back();
              }}
            />
            <Box width={"10px"} />
            <span>Lembretes do carrinho</span>
          </Flex>
        </Box>
        {/* <Grid templateColumns="repeat(4, 1fr)" gap={2} mt="2">
            <Infos
              title="Venda de produtos principais"
              value="R$ 10223,43"
              description="7 dias anteriores 0%"
            />
            <Infos
              title="Venda de produtos da oferta"
              value="R$ 4843,47"
              description="7 dias anteriores 0%"
            />
            <Infos
              title="Número de pedidos combo pagos"
              value="234"
              description="7 dias anteriores 0%"
            />
            <Infos
              title="Número de compradores"
              value="321"
              description="7 dias anteriores 0%"
            />
          </Grid> */}
        <FilterBox
          onFilterChange={(props) => {
            setFilters(props);
          }}
          onReset={() => {
            setFilters({});
          }}
        />
        <Lembretes lembretes={lembretes} />
      </Box>
    </PageScaffold>
  );
}
