import { useEffect, useRef } from "react";

import { canvasPreview } from "./canvasPreview.js";

export default function CropPreview({ img, crop, onBlobGenerated }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (!crop?.width || !crop?.height || !img || !canvasRef.current) {
      return;
    }

    canvasPreview(img, canvasRef.current, crop, 1, 0);

    canvasRef.current.toBlob((blob) => {
      onBlobGenerated(blob);
    });
  }, [img, crop]);

  if (!!crop && !!img) {
    return (
      <canvas
        style={{
          display: "none",
        }}
        ref={canvasRef}
      />
    );
  }
}
