import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";

import PageScaffold from "../../components/PageScaffold";
import ProductComponent from "../../components/ProductComponent";
import useAuthToken from "../../hooks/useAuthToken";
import AnunciosRepository from "../../repositories/anuncios_repository";
import backAsset from "../../assets/back.png";
import { useRef } from "react";
import { m } from "framer-motion";

export default function ProdutosMaisVendidosPage() {
  const token = useAuthToken();

  const repo = new AnunciosRepository({ token });
  const [produtos, setProdutos] = useState([]);
  const [filteredProdutos, setFilteredProduts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);

  const maxPriceRef = useRef(null);
  const minPriceRef = useRef(null);

  useEffect(() => {
    repo.consultarMaisVendidos().then((res) => {
      if (res.status === 200) {
        setProdutos(res.data);

        let cat = [];

        res.data.forEach((produto) => {
          produto.categorias.forEach((categoria) => {
            if (!cat.find((c) => c.id === categoria.id)) {
              cat.push(categoria);
            }
          });

          setCategories(cat);
        });
      }
    });
  }, []);

  useEffect(() => {
    setFilteredProduts(produtos);
  }, [produtos]);

  const filter = () => {
    let p = produtos;

    if (currentCategory && currentCategory !== "*") {
      p = p.filter((produto) => {
        return produto.categorias.find((c) => c.nome === currentCategory);
      });
    }

    if (minPrice && maxPrice) {
      p = p.filter((produto) => {
        return (
          produto.variantes.at(0).preco >= Number(minPrice.replace(",", "."))
        );
      });
    }

    if (maxPrice) {
      p = p.filter((produto) => {
        return (
          produto.variantes.at(0).preco <= Number(maxPrice.replace(",", "."))
        );
      });
    }

    setFilteredProduts(p);
  };

  return (
    <PageScaffold showSideBar={true}>
      <Box paddingTop={"32px"} fontWeight={"700"} fontSize={"22px"}>
        <Flex direction={"row"} alignItems={"center"}>
          <Image
            cursor={"pointer"}
            src={backAsset}
            height={"15px"}
            alt="Voltar"
            onClick={() => {
              window.history.back();
            }}
          />
          <Box width={"10px"} />
          <span>Produtos mais vendidos</span>
        </Flex>
      </Box>
      <Text pl="25px" fontSize={"sm"} fontWeight={"500"}>
        O Cálculo dos produtos mais vendidos é baseado na quantidade de itens
        vendidos na Deshopnise
        <br /> (não inclui seus próprios produtos)
      </Text>
      <Flex w="full" direction="column" p="5" pb="0">
        <Flex
          direction={["column", "column", "column", "row"]}
          w="full"
          justifyContent="space-between"
        >
          <Flex direction="column" fontSize="xs" size="md">
            <Text fontWeight="semibold">Categorias</Text>
            <Select
              backgroundColor="white"
              fontWeight={500}
              value={currentCategory}
              onChange={(e) => setCurrentCategory(e.target.value)}
            >
              <option value={"*"}>Todos</option>
              {categories.map((category) => {
                return <option value={category.nome}>{category.nome}</option>;
              })}
            </Select>
          </Flex>
          {/* <Flex direction="column" fontSize="xs" size="sm">
                  <Text>Produto</Text>
                  <Select
                    backgroundColor="white"
                    fontWeight={500}
                    value={"option1"}
                  >
                    <option value="option1">Playstation</option>
                    <option value="option2">XBox</option>
                    <option value="option3">Nitendo</option>
                  </Select>
                </Flex> */}
          <Flex
            direction="column"
            fontSize="xs"
            size="sm"
            fontWeight="semibold"
          >
            <Text>Faixa de preço</Text>
            <Flex direction="row" align="center">
              <InputGroup w="210px">
                <InputLeftAddon
                  children={<Text>R$</Text>}
                  backgroundColor="white"
                />
                <Input
                  backgroundColor="white"
                  onChange={(e) => setMinPrice(e.target.value)}
                  placeholder="Preço mínimo"
                  value={minPrice}
                  ref={minPriceRef}
                />
              </InputGroup>
              <Text mx="2" fontWeight="bold">
                ou
              </Text>
              <InputGroup w="210px">
                <InputLeftAddon
                  children={<Text>R$</Text>}
                  backgroundColor="white"
                />
                <Input
                  onChange={(e) => setMaxPrice(e.target.value)}
                  placeholder="Preço máximo"
                  backgroundColor="white"
                  value={maxPrice}
                  ref={maxPriceRef}
                />
              </InputGroup>
              <Button
                onClick={() => {
                  filter();
                }}
                backgroundColor="primary.400"
                color="white"
                mx="2"
              >
                Buscar
              </Button>
              <Button
                onClick={() => {
                  setCurrentCategory("*");
                  setMinPrice(null);
                  setMaxPrice(null);
                  setFilteredProduts(produtos);
                  minPriceRef.current.value = "";
                  maxPriceRef.current.value = "";
                }}
              >
                Redefinir
              </Button>
            </Flex>
          </Flex>
        </Flex>
        <Box
          boxShadow="sm"
          w="full"
          borderRadius="8px"
          p="5"
          bgColor="white"
          mt="5"
        >
          <Flex ml="2" direction="column">
            <Grid templateColumns="repeat(4, 1fr)" gap={2} mt="5">
              {filteredProdutos.map((produto, index) => {
                return (
                  <a
                    href={`https://deshopnise.com.br/produto/${produto.slug}`}
                    target="_blank"
                  >
                    <ProductComponent
                      key={produto._id}
                      produto={produto}
                      index={index}
                    />
                  </a>
                );
              })}
            </Grid>
          </Flex>
        </Box>
      </Flex>
    </PageScaffold>
  );
}
