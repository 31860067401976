import PageScaffold from "../../components/PageScaffold";
import CustomTabButtonComponent from "../../components/CustomTabButtonComponent";
import {
  Box,
  Flex,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Heading,
  Text,
  useToast,
  Divider,
  Center,
} from "@chakra-ui/react";
import TabPerfil from "./Components/TabPerfil";
import { AuthContext } from "../../contexts/authContext";
import { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import AvaliacoesPage from "../Avaliacoes";
import AvaliacoesProdutosPage from "../AvaliacoesProdutosPage";
import useEstabelecimento from "../../hooks/useEstabelecimento";

export default function Loja() {
  const estabelecimento = useEstabelecimento();
  const [params] = useSearchParams();
  const toast = useToast();
  const context = useContext(AuthContext);
  var repo = new VendedoresRepository({ token: context.currentToken });
  const [loja, setLoja] = useState(null);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (params.get("target") == "configuracoes-loja") {
      setTab(0);
    }
    //params.get("target")
    //setTab();

    fetchLojaData();
  }, [params]);

  const fetchLojaData = async (params) => {
    let data = {};
    if (params != null) {
      data = params;
    }
    data["firebase_id"] = context.firebaseUser.uid;

    repo.consultar(data).then((res) => {
      if (res.status === 200) {
        setLoja(res.data);
      }
    });
  };

  const saveData = async (loja) => {
    repo.alterar(loja).then((res) => {
      if (res.status === 200) {
        toast({
          description: "Dados alterados com sucesso",
          status: "success",
        });
      } else {
        toast({
          description: "Houve um erro ao alterar os dados",
          status: "sucess",
        });
      }
    });
  };

  if (estabelecimento.status === "bloqueado") {
    return (
      <PageScaffold showSideBar={true}>
        <Center h={"90vh"}>
          <Text fontWeight={"600"}>Sua conta foi bloqueada</Text>
        </Center>
      </PageScaffold>
    );
  }

  return (
    <PageScaffold showSideBar={true}>
      <Flex direction="column">
        <Box
          paddingTop={"32px"}
          paddingBottom={"24px"}
          fontWeight={"700"}
          fontSize={"22px"}
        >
          <Flex direction="column">
            <span>Loja</span>

            <Text fontSize="14px" fontWeight={500}>
              Informações do perfil de sua loja
            </Text>
          </Flex>
        </Box>
        <Box paddingBottom={"4px"} fontWeight={"600"} fontSize={"16px"}>
          {/* <span>Meus Envios</span> */}
          <Flex direction="row" mb="5" fontWeight={"bold"}>
            <Box
              py="4"
              mr="60px"
              color={tab === 0 ? "primary.400" : "grey"}
              borderBottom={tab === 0 ? "2px solid #693FF3" : null}
              onClick={() => {
                setTab(0);
              }}
              cursor="pointer"
            >
              <Text>Perfil da Loja</Text>
            </Box>

            <Box
              py="4"
              mr="60px"
              color={tab === 1 ? "primary.400" : "grey"}
              borderBottom={tab === 1 ? "2px solid #693FF3" : null}
              onClick={() => {
                setTab(1);
              }}
              cursor="pointer"
            >
              <Text>Avaliação da Loja</Text>
            </Box>
            <Box
              py="4"
              mr="30px"
              color={tab === 2 ? "primary.400" : "grey"}
              borderBottom={tab === 2 ? "2px solid #693FF3" : null}
              onClick={() => {
                setTab(2);
              }}
              cursor="pointer"
            >
              <Text>Avaliação do Produto</Text>
            </Box>
          </Flex>
        </Box>
        <Flex
          w={"100%"}
          margin={"0 0"}
          direction={"row"}
          justify="space-between"
          mt="2"
        >
          <Box
            boxShadow="sm"
            width={"70%"}
            minW={"800px"}
            borderRadius={"8px"}
            marginTop={"5px"}
            backgroundColor={tab === 0 ? "white" : null}
          >
            <Tabs
              index={tab}
              onChange={(index) => {
                setTab(index);
              }}
            >
              <TabPanels>
                <TabPanel>
                  <TabPerfil loja={loja} save={saveData} />
                </TabPanel>
                <TabPanel>
                  <AvaliacoesPage />
                </TabPanel>
                <TabPanel>
                  <AvaliacoesProdutosPage />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Flex>
    </PageScaffold>
  );
}
