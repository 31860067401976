import React from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Button,
  Link,
  Divider,
  InputLeftElement,
  Image,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdCircle, MdShowChart } from "react-icons/md";
import searchImg from "../../../assets/pesquisar.svg";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";

export default function FilterBox({ onFilterChange = () => {}, onReset }) {
  const navigate = useNavigate();
  const [currentFilterKey, setCurrentFilterKey] = useState("numero_pedido");
  const [currentFilterValue, setCurrentFilterValue] = useState("");
  const [currentStatusFilter, setCurrentStatusFilter] = useState("todos");

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  return (
    <Box boxShadow="sm" width={"100%"} borderRadius={"8px"} marginTop={"5px"}>
      <Flex
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
        px="4"
      >
        <Flex direction="column" maxW="50%">
          <Text fontSize="md" fontWeight="bold" color="#4e4e4e">
            Lista de Ofertas
          </Text>
          <Text fontSize="xs" fontWeight={"medium"}>
            Amplie suas vendas oferecendo ofertas combo! Através dessa opção,
            você pode aumentar suas vendas ao proporcionar descontos aos
            clientes que adquirem múltiplos produtos.
            <a
              href="https://www.central.deshopnise.com.br/como-criar-uma-oferta-combo"
              target="_blank"
              style={{
                color: "#7048f3",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {" Saiba mais."}
            </a>
          </Text>
        </Flex>

        <Flex direction="row">
          <Link to={"/estatisticas"} mr="2">
            <Button
              width={"140px"}
              marginLeft={"15px"}
              backgroundColor="#232544"
              color={"white"}
              _hover={{ opacity: "0.8" }}
              onClick={() => {
                navigate("/estatisticas");
              }}
            >
              <Flex direction="row" align="center">
                <MdShowChart size={20} color="cyan" />
                <Text mx="2" fontSize="xs">
                  Métricas
                </Text>
                <Divider h="10px" mr="2" orientation="vertical" />
                <MdCircle size={15} color="cyan" />
              </Flex>
            </Button>
          </Link>
          <Button
            backgroundColor="primary.400"
            color={"white"}
            _hover={{ opacity: "0.8" }}
            colorScheme="purple"
            onClick={() => {
              navigate("/criar-oferta-combo");
            }}
          >
            Criar Oferta Combo
          </Button>
        </Flex>
      </Flex>
      <Flex
        padding={"16px"}
        backgroundColor="white"
        direction="row"
        justifyContent="space-between"
        borderRadius="8"
        mt="2"
      >
        <InputGroup spacing="24px" width={"320px"}>
          <InputLeftElement
            children={<Image src={searchImg} boxSize="17px" />}
          />
          <Input
            type="text"
            fontWeight={500}
            placeholder="Buscar por nome..."
            value={currentFilterValue}
            w="250px"
            onChange={(e) => {
              setCurrentFilterValue(e.target.value);
            }}
            variant={"filled"}
          />
        </InputGroup>

        <Flex direction="row">
          <Flex align="center">
            <Text fontSize="sm" fontWeight={500} w="60px">
              Período
            </Text>
            <Box w={"10px"} />

            <CustomDateRangePicker
              currentRange={[startDate, endDate]}
              onChange={({ start, end }) => {
                setStartDate(start);
                setEndDate(end);
              }}
            />
          </Flex>
          <Flex align="center">
            <Select
              mx="2"
              w="180px"
              variant={"filled"}
              size="md"
              fontWeight={"bold"}
              value={currentStatusFilter}
              onChange={(e) => {
                setCurrentStatusFilter(e.target.value);
              }}
            >
              <option value="todos">Todos</option>
              <option value="Em andamento">Em andamento</option>
              <option value="Agendado">Agendado</option>
              <option value="Finalizado">Finalizado</option>
              <option value="Cancelado">Cancelado</option>
            </Select>
          </Flex>
          <Button
            width={"150px"}
            marginLeft={"15px"}
            backgroundColor="primary.400"
            color={"white"}
            _hover={{ opacity: "0.8" }}
            onClick={() => {
              onFilterChange({
                nome: currentFilterValue,
                status:
                  currentStatusFilter === "todos" ? null : currentStatusFilter,
                data_inicio: startDate,
                data_fim: endDate,
              });
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              onFilterChange({});
              setCurrentFilterValue("");
              setCurrentFilterKey("numero_pedido");
              setCurrentStatusFilter("todos");
              setStartDate(null);
              setEndDate(null);
              onReset();
            }}
            width={"150px"}
            marginLeft={"15px"}
            variant={"outline"}
          >
            Redefinir
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
