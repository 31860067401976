import { initializeApp } from "firebase/app";
import {
  EmailAuthProvider,
  GoogleAuthProvider,
  getAuth,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  signInWithPhoneNumber,
  signInWithPopup,
  sendPasswordResetEmail,
  linkWithPhoneNumber,
  FacebookAuthProvider,
  createUserWithEmailAndPassword,
  reauthenticateWithCredential,
  updatePassword,
  deleteUser,
  RecaptchaVerifier,
} from "firebase/auth";

import firebaseConfig from "../configs/firebase";

class FirebaseAuthService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
  }

  async linkWithPhoneNumber(auth, phone, RecaptchaVerifier) {
    const res = linkWithPhoneNumber(auth, phone, RecaptchaVerifier);

    return res;
  }

  async fetchSignInMethodsForEmail({ email }) {
    const auth = getAuth();
    let signInMethods = await fetchSignInMethodsForEmail(auth, email);

    return signInMethods.length;
  }

  async signInEmailAndPassword({ email, password }) {
    const res = await signInWithEmailAndPassword(this.auth, email, password);

    return res;
  }

  async signInWithPhoneNumber(phone, verify) {
    const res = await signInWithPhoneNumber(phone, verify);

    return res;
  }

  async createUserEmailAndPassword({ email, password }) {
    const res = await createUserWithEmailAndPassword(
      this.auth,
      email,
      password
    );

    return res;
  }

  async updatePassword(newPassword) {
    updatePassword(newPassword)
      .then(() => {
        console.log("Senha atualizada!");
      })
      .catch((erro) => {
        console.log(erro);
      });
  }

  async deleteFirebaseUser(user) {
    const res = await deleteUser(user);

    return res;
  }

  async signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    const res = await signInWithPopup(this.auth, provider);

    return res;
  }

  async signInWithFacebook() {
    const provider = new FacebookAuthProvider();
    const res = await signInWithPopup(this.auth, provider);

    return res;
  }

  async sendPasswordEmail({ email }) {
    const res = await sendPasswordResetEmail(this.auth, email);

    return res;
  }

  async reUpdatePassword(oldPassword, newPassword) {
    const auth = getAuth();
    const user = auth.currentUser;

    const credential = EmailAuthProvider.credential(user.email, oldPassword);

    await reauthenticateWithCredential(user, credential);
    const res = await updatePassword(user, newPassword);

    return res;
  }

  getError(code) {
    switch (code) {
      case "auth/email-already-in-use":
      case "auth/email-already-exists":
        return "O endereço de e-mail já está sendo usado por outra conta.";
      case "auth/invalid-password":
        return "A senha deve ser uma string com pelo menos 6 caracteres.";
      case "auth/invalid-email":
        return "O endereço de email está mal formatado.";
      case "auth/invalid-phone-number":
        return "O número de telefone deve ser uma string de identificador compatível com padrão E.164 não vazia.";
      case "auth/phone-number-already-exists":
        return "O usuário com o número de telefone fornecido já existe.";
      case "auth/invalid-photo-url":
        return "O campo foto url deve ser um URL válido.";
      case "auth/invalid-uid":
        return "O uid deve ser uma string não vazia com no máximo 128 caracteres.";
      case "auth/user-not-found":
        return "Usuário não encontrado.";
      case "auth/wrong-password":
        return "A senha ou o usuário inválido.";
      case "auth/popup-closed-by-user":
        return "Login cancelado.";
      default:
        return code;
    }
  }
}
export default FirebaseAuthService;
