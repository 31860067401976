import {
  Avatar,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { MdClose, MdImageSearch, MdSend } from "react-icons/md";
import Contacts from "./components/Contacts";
import Messages from "./components/Messages";
import { ChatContext } from "../../contexts/chatContext";
import { useContext, useState, useRef, useEffect } from "react";
import { AuthContext } from "../../contexts/authContext";
import FirebaseStorageService from "../../services/firebase_storage_service";
import { ChevronDownIcon } from "@chakra-ui/icons";
import chatImg from "../../assets/chat.svg";
import expandirChatImg from "../../assets/expandirchat.svg";

export default function Chat() {
  const inputRef = useRef("");
  const chatContext = useContext(ChatContext);
  const authContext = useContext(AuthContext);
  const messagesEndRef = useRef();
  const uploader = new FirebaseStorageService();

  async function handleAttachs(event) {
    const allowed = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "application/pdf",
    ];

    const type = event.target.files[0].type;

    const isPdf = type === "application/pdf";

    if (!allowed.includes(type)) {
      // toast_service.error("documento não suportado");
      return;
    }

    const urls = await uploader.handleUpload("chat/", [event.target.files[0]]);
    ///event.target.files[0].type.includes()

    if (Array.isArray(urls) && urls.length > 0) {
      chatContext.sendMessage({
        chat_id: chatContext.activeChat._id,
        conteudo: {
          mensagem: inputRef.current.value,
          anexos: [
            {
              tipo: isPdf ? "pdf" : "imagem",
              url: urls[0],
            },
          ],
        },
      });
    }
  }

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <Box position={"fixed"} right={"10px"} bottom={"50px"} zIndex={1000}>
      <Box
        alignSelf="end"
        borderRadius={"8px"}
        w={!chatContext.isChatOpen ? "110px" : null}
        style={{
          position: "absolute",
          right: chatContext.isChatOpen ? "100px" : "30px",
          bottom: "-49px",
          boxShadow: "0 0 16px 0 rgba(0,0,0,.1), 0 8px 16px 0 rgba(0,0,0,.06)",
        }}
      >
        {!chatContext.isChatOpen && (
          <Button
            onClick={chatContext.openChat}
            bgColor={"#232544"}
            color="white"
          >
            <Image src={chatImg} boxSize="22px" mr="1" />
            <Text fontWeight={800}>Chat</Text>
            <Text fontWeight={100} mx="1" color="grey">
              |
            </Text>
            <Image src={expandirChatImg} boxSize="12px" />
          </Button>
        )}
        {chatContext.isChatOpen && (
          <Box backgroundColor="white" borderRadius={8} w="600px" h="400px">
            <Flex direction="column" align="center">
              <Flex
                direction="row"
                justifyContent="space-between"
                align="center"
                p={2}
                w="full"
              >
                <Text fontWeight="bold" fontSize="16px" color="primary.400">
                  Chat
                </Text>
                <MdClose onClick={chatContext.closeChat} />
              </Flex>
              <Divider />
              <Flex
                direction="row"
                justifyContent="space-between"
                align="center"
                h="350px"
                w="full"
              >
                <Flex w="50%" h="100%" align="start">
                  {chatContext.activeChat ? (
                    <Flex
                      direction="column"
                      w="full"
                      justifyContent="space-between"
                      h="350px"
                      pb="5px"
                    >
                      <Flex
                        direction="row"
                        align="center"
                        justify="space-between"
                        w="full"
                        backgroundColor="#F5F5F5"
                        px="5px"
                      >
                        <Flex direction="row" align="center" py="5px">
                          <Avatar
                            mr="2"
                            size="sm"
                            src={
                              chatContext.activeChat.usuario_imagem ??
                              "https://loja.br.abb.com/static/version1663728825/frontend/Zaelab/abb/pt_BR/Mageplaza_Blog/media/images/no-artist-image.jpg"
                            }
                          />
                          <Text fontSize="sm" fontWeight={"semibold"}>
                            {chatContext.activeChat.usuario_nome}
                          </Text>
                        </Flex>
                        <Menu>
                          <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                          ></MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                if (!chatContext.activeChat.bloqueado_por) {
                                  chatContext.blockUser({
                                    chatObject: chatContext.activeChat,
                                  });
                                } else {
                                  chatContext.unBlockUser({
                                    chatObject: chatContext.activeChat,
                                  });
                                }
                              }}
                            >
                              {!chatContext.activeChat.bloqueado_por
                                ? "Bloquear"
                                : "Desbloquear"}
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </Flex>
                      <Messages
                        messages={chatContext.activeChat.mensagens ?? []}
                        loggedUser={authContext.estabelecimento._id}
                        messagesEnd={messagesEndRef}
                      />

                      <Flex align="end" px="5px" pt="5px">
                        <InputGroup spacing="24px">
                          <InputLeftElement
                            children={
                              <div>
                                <label htmlFor="fileinput">
                                  {" "}
                                  <MdImageSearch color={"#BCC1BB"} />
                                </label>
                                <input
                                  style={{ display: "none" }}
                                  onChange={handleAttachs}
                                  type="file"
                                  name="file"
                                  id="fileinput"
                                />
                              </div>
                            }
                          />
                          <Input
                            ref={inputRef}
                            disabled={chatContext.activeChat.bloqueado_por}
                            placeholder={
                              chatContext.activeChat.bloqueado_por
                                ? `${
                                    chatContext.activeChat.bloqueado_por ===
                                    authContext.estabelecimento._id
                                      ? "Você bloqueou este contato"
                                      : "Você foi bloqueado"
                                  }  `
                                : "Digite sua mensagem aqui"
                            }
                          />
                          <InputRightElement
                            children={
                              <MdSend
                                color={"#BCC1BB"}
                                onClick={() => {
                                  chatContext.sendMessage({
                                    chat_id: chatContext.activeChat._id,
                                    conteudo: {
                                      mensagem: inputRef.current.value,
                                      // anexos: content.attachments
                                      //   ? [content.attachments]
                                      //   : [],
                                    },
                                  });
                                  chatContext.loadAllChats();
                                  inputRef.current.value = "";
                                  scrollToBottom();
                                }}
                              />
                            }
                          />
                        </InputGroup>
                      </Flex>
                    </Flex>
                  ) : (
                    <Text m="0 auto">Sem conversas</Text>
                  )}
                </Flex>
                <Center height="350px">
                  <Divider orientation="vertical" />
                </Center>
                <Contacts
                  chatContext={chatContext}
                  loggedUserID={authContext.firebaseUser.uid}
                />
              </Flex>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
}
