import React from "react";

import { Box, Flex, Image, Text } from "@chakra-ui/react";

import correiosImage from "../../../assets/correios.png";
import jadlogImage from "../../../assets/jadlog.png";
import loggi from "../../../assets/loggi.png";

function ShipImage({ data, width = "100px", ...props }) {
  const isCorreios =
    data.envio.logistic_provider_name.toLowerCase().trim() === "correios";
  const isJadlog =
    data.envio.logistic_provider_name.toLowerCase().trim() === "jadlog";
  const isLoggi =
    data.envio.logistic_provider_name.toLowerCase().trim() === "loggi";

  return (
    <>
      {isCorreios && <Image {...props} src={correiosImage} width={width} />}
      {isJadlog && <Image {...props} src={jadlogImage} width={width} />}
      {isLoggi && <Image {...props} src={loggi} width={width} />}
    </>
  );
}

function ShipCard({ data }) {
  return (
    <Box
      p={"22px"}
      boxShadow="sm"
      w="full"
      borderRadius={"10px"}
      marginTop={"5px"}
      height={"110px"}
      backgroundColor={"white"}
    >
      <Flex
        dir="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        height={"100%"}
      >
        <Text fontSize={"22px"} fontWeight={"700"}>
          Tipo de envio
        </Text>

        <Flex direction={"column"} alignItems={"center"}>
          <ShipImage data={data} />
          {data?.envio?.description}
        </Flex>
      </Flex>
    </Box>
  );
}

export default ShipCard;
export { ShipImage };
