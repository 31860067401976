import PageScaffold from "../../components/PageScaffold";
import { Box, Text, Flex, Select } from "@chakra-ui/react";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import TabButton from "./components/TabButtton";
import InfoCard from "./components/InfoCard";
import { useState } from "react";
import ChartSection from "./components/ChartSection";
import SalesListSection from "./components/SalesListSection";
import ProductPickerList from "./components/ProductPickerList";
import CampanhaPickerList from "./components/CampanhaPickerList";
import useAuthToken from "../../hooks/useAuthToken";
import EstatisticasRepository from "../../repositories/estatisticas_repository";
import { useEffect } from "react";
import Formatters from "../../utils/formatters";
import { endOfDay, startOfDay } from "date-fns";
import CustomDateRangePicker from "../../components/CustomDateRangePicker";

function Estatisticas() {
  const estabelecimento = useEstabelecimento();
  const token = useAuthToken();
  const repo = new EstatisticasRepository({ token });

  const [currentTab, setCurrentTab] = useState("geral");
  const [data, setData] = useState({});
  const [currentProducts, setCurrentProducts] = useState(null);
  const [currentCampanhas, setCurrentCampanhas] = useState(null);

  const [filters, setFilters] = useState({
    data_inicio: startOfDay(new Date()).toISOString(),
    data_fim: endOfDay(new Date()).toISOString(),
    campanha_tipo: null,
    campanhas_ids: null,
    produtos_ids: null,
  });

  useEffect(() => {
    console.log(filters);
    repo.consultar(filters).then((res) => {
      console.log(res.data);
      setData(res.data);
    });
  }, [filters]);

  useEffect(() => {
    if (currentTab === "produtos") {
      setCurrentProducts(null);
    }

    if (currentTab === "campanhas") {
      setCurrentProducts(null);
    }

    setFilters({
      ...filters,
      campanha_tipo: null,
      campanhas_ids: null,
      produtos_ids: null,
    });
  }, [currentTab]);

  useEffect(() => {
    if (currentProducts) {
      setFilters({
        ...filters,
        produtos_ids: currentProducts.map((p) => p._id),
      });
    }

    if (!currentProducts) {
      setFilters({
        ...filters,
        produtos_ids: null,
      });
    }
  }, [currentProducts]);

  // promocao, cupom_desconto, oferta_combo, oferta_relampago

  useEffect(() => {
    if (currentCampanhas) {
      console.log(currentCampanhas);
      setFilters({
        ...filters,
        campanha_tipo: currentCampanhas.campanha,
        campanhas_ids: currentCampanhas?.items.map((c) => c.id),
      });

      console.log(currentCampanhas);
    }

    if (!currentCampanhas) {
      setFilters({
        ...filters,
        campanha_tipo: null,
        campanhas_ids: null,
      });

      console.log(currentCampanhas);
    }
  }, [currentCampanhas]);

  return (
    <PageScaffold
      showSideBar={false}
      useDarkerBackground={true}
      showWhiteLogo={true}
      disableContentPadding={true}
    >
      <Box
        width={"100%"}
        backgroundColor={"#232544"}
        height={"300px"}
        color={"white"}
      >
        <Flex
          direction={"row"}
          justifyContent={"space-between"}
          maxW={"90vw"}
          margin={"0 auto"}
          padding={"50px 0px"}
          paddingBottom={"10px"}
        >
          <Flex direction={"column"}>
            <Text fontSize={"30px"} fontWeight={"bold"}>
              Bem vindo, {estabelecimento.nome}
            </Text>
            <Text fontWeight={"medium"} opacity={"0.66"}>
              Acompanhe os gráficos de crescimento, vendas e acesso...
            </Text>
          </Flex>

          <Flex direction={"column"} alignItems={"baseline"}>
            <Text lineHeight={"5px"} fontSize={"16px"} fontWeight={"bold"}>
              Total
            </Text>
            <Text fontWeight={"bold"} color={"#00FFC2"} fontSize={"40px"}>
              {Formatters.money(data.valor_total_pedidos ?? 0)}
            </Text>
            <CustomDateRangePicker
              currentRange={[filters.data_inicio, filters.data_fim]}
              onChange={({ start, end }) => {
                setFilters({
                  ...filters,
                  data_inicio: start,
                  data_fim: end,
                });
              }}
            />
            {/* <Select
              mx="2"
              variant={"outline"}
              borderColor={"#693FF3"}
              borderRadius={"4px"}
              size="sm"
              fontWeight={500}
              value={"today"}
              onChange={(e) => {}}
            >
              <option value="today">Tempo Real: Hoje até 17h:00min</option>
            </Select> */}
          </Flex>
        </Flex>

        <Flex maxW={"90vw"} margin={"0 auto"}>
          <TabButton
            active={currentTab === "geral"}
            onClick={() => {
              setCurrentTab("geral");
            }}
          >
            Geral
          </TabButton>
          <TabButton
            active={currentTab === "produtos"}
            onClick={() => {
              setCurrentTab("produtos");
            }}
          >
            Produtos
          </TabButton>
          <TabButton
            active={currentTab === "campanhas"}
            onClick={() => {
              setCurrentTab("campanhas");
            }}
          >
            Campanhas
          </TabButton>
        </Flex>

        <Flex maxW={"90vw"} margin={"0 auto"}>
          <InfoCard
            title={"Total de vendas"}
            subtitle={"(Produtos Principais e Adicionais)"}
            value={Formatters.money(data.valor_total_pedidos ?? 0)}
            helpText={"Total de vendas de produtos principais e adicionais"}
            time={"vs 00:00 - 17:00 de Ontem"}
            active={true}
          />
          <InfoCard
            title={"Pedidos"}
            value={data.qtd_pedidos?.toFixed(0) ?? 0}
            helpText={"Total de pedidos realizados"}
            time={"vs 00:00 - 17:00 de Ontem"}
          />
          <InfoCard
            title={"Taxa de Conversão"}
            value={(data.taxa_conversao?.toFixed(0) ?? 0) + "%"}
            helpText={"Taxa de conversão de pedidos"}
            time={"vs 00:00 - 17:00 de Ontem"}
          />
          <InfoCard
            title={"Acessos"}
            value={data.qtd_views ?? 0}
            helpText={"Quantidade de acessos no seu produto"}
            time={"vs 00:00 - 17:00 de Ontem"}
          />
          <InfoCard
            title={"Favoritou"}
            value={data.qtd_favoritados?.toFixed(0) ?? 0}
            helpText={"Quantidade de pessoas que favoritaram seu produto"}
            time={"vs 00:00 - 17:00 de Ontem"}
          />
          <InfoCard
            title={"No carrinho"}
            value={data.qtd_add_carrinho?.toFixed(0) ?? "0"}
            helpText={
              "Quantidade de pessoas que adicionaram seu produto no carrinho"
            }
            time={"vs 00:00 - 17:00 de Ontem"}
          />
        </Flex>

        <ChartSection data={data?.grafico} />

        {currentTab === "produtos" && (
          <ProductPickerList
            currentProducts={currentProducts ?? []}
            onSelect={(p) => {
              setCurrentProducts(p);
            }}
          />
        )}
        {currentTab === "campanhas" && (
          <CampanhaPickerList
            onPick={(c) => {
              setCurrentCampanhas(c);
            }}
          />
        )}

        <Flex
          maxW={"90vw"}
          margin={"0 auto"}
          marginTop={"40px"}
          direction={"row"}
        >
          <Text color={"black"} fontWeight={"bold"} fontSize={"20px"}>
            Total de vendas
          </Text>

          <Box width={"15px"} />

          {/* <Select
            width={"300px"}
            mx="2"
            color={"black"}
            variant={"filled"}
            backgroundColor={"white"}
            borderRadius={"4px"}
            size="sm"
            fontWeight={500}
            value={"today"}
            onChange={(e) => {}}
          >
            <option value="today">Tempo Real: Hoje até 17h:00min</option>
          </Select> */}
        </Flex>

        <SalesListSection pedidos={data?.pedidos} />
        <Box height={"100px"} />
      </Box>
    </PageScaffold>
  );
}

export default Estatisticas;
