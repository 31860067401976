import { Flex, Image, Box, Heading, Text, Divider } from "@chakra-ui/react";
import Formatters from "../../../utils/formatters";

export default function ProdutoBox({ pedido }) {
  return (
    <>
      {pedido.itens.map((item, index) => (
        <Flex
          key={item._id}
          boxShadow="sm"
          w="full"
          mt="10px"
          borderRadius="8px"
          p="5"
          bgColor="white"
        >
          <Flex direction="column" w="45%">
            <Heading fontSize="sm">Produto {index + 1}</Heading>
            <Flex direction="row" mt="5">
              <Image
                src={item?.produto_img}
                width="80px"
                height="80px"
                objectFit="contain"
                alt="Deshopnise"
              />
              <Flex direction="column" justifyContent="space-between">
                <Text fontSize="xs" noOfLines="2" pl="2">
                  <b>{item?.produto_nome}</b>
                </Text>
                <Flex direction="row">
                  <Text fontSize="10px" noOfLines="2" px="2">
                    <b>{Object.keys(item?.propriedades)[0]}: </b>{" "}
                    <b style={{ color: "#693FF3" }}>
                      {Object.values(item?.propriedades)[0]}
                    </b>
                  </Text>
                  {Object.keys(item?.propriedades).length > 1 && (
                    <Text fontSize="10px" noOfLines="2" px="2" pl="5px">
                      <b>{Object.keys(item?.propriedades)[1]}: </b>{" "}
                      <b style={{ color: "#693FF3" }}>
                        {Object.values(item?.propriedades)[1]}
                      </b>
                    </Text>
                  )}
                </Flex>

                {true ? (
                  <Text fontSize="10px" noOfLines="2" px="2">
                    <b>Quantidade: </b>{" "}
                    <b style={{ color: "#693FF3" }}>{item.quantidade}</b>
                  </Text>
                ) : (
                  <Box h="1" />
                )}
              </Flex>
            </Flex>
          </Flex>
          <Divider orientation="vertical" mx="10" />
          <Flex direction="column" w="45%">
            <Heading fontSize="sm">Valor do reembolso</Heading>
            <Flex direction="column" justifyContent="space-between" mt="5">
              <Text fontSize="md" color="#693FF3">
                <b>{Formatters.money(item.preco_promocional || item.preco)}</b>
              </Text>
              {/* <Text fontSize="sm" noOfLines="2">
          Aguardando Confirmação
        </Text> */}
            </Flex>
          </Flex>
        </Flex>
      ))}
    </>
  );
}
