import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Button,
  Avatar,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { MdChat } from "react-icons/md";
import Formatters from "../../../utils/formatters";
import { Link } from "react-router-dom";
import CorreiosImg from "../../../assets/correios.png";
import ShipCard, { ShipImage } from "../../DetalhesPedido/components/ShipCard";

export default function TabEnviados({
  data,
  isLoading,
  onLoadMore,
  openChatWith,
}) {
  if (isLoading) {
    return (
      <Center p={"20px 0px"}>
        <Spinner />
        <Box w={"10px"} />
        <Text>Carregando...</Text>
      </Center>
    );
  }

  if (data.length === 0) {
    return (
      <Center p={"20px 0px"}>
        <Text>Nenhum pedido encontrado</Text>
      </Center>
    );
  }

  return (
    <Flex direction={"column"}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Detalhes</Th>
              <Th>Preço Total</Th>
              <Th>Status</Th>
              <Th>Prazo</Th>
              <Th>Ação</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {data.map((pedido) => {
              return (
                <Tr key={pedido._id}>
                  <Td>
                    <Flex direction={"column"}>
                      <Flex padding={"7px 0px"} alignItems={"center"}>
                        <Avatar
                          size="xs"
                          src={pedido.usuario_imagem}
                          name={pedido.usuario_nome}
                        />
                        <Box w={"8px"} />
                        <Text>{pedido.usuario_nome}</Text>
                        <Box w={"8px"} />

                        <MdChat
                          color={"#20BEAE"}
                          onClick={() => {
                            openChatWith(pedido.usuario_id);
                          }}
                        />
                      </Flex>

                      <Text fontSize={"sm"}>
                        Pedido #{pedido.numero_pedido || "N/A"}
                      </Text>
                      {/* <Text fontSize={"sm"}>
                        PLP: {pedido.transacao_id * 12}
                      </Text> */}
                      <Text fontSize={"sm"}>
                        {new Date(pedido.createAt).toLocaleString()}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      <Text fontSize={"sm"}>
                        {Formatters.money(pedido.custo_total)}
                      </Text>
                      <Text fontSize={"sm"}>
                        {Formatters.paymentMethod(
                          pedido.metodo_pagamento,
                          pedido.pagamento.parcelas
                        )}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      {/* <Text fontSize={"sm"}>
                        {pedido.eventos.at(-1).tipo.replace("_", " ")}
                      </Text> */}
                      <Text
                        as={"span"}
                        maxWidth={"100px"}
                        noOfLines={3}
                        fontSize={"xs"}
                        whiteSpace={"pre-line"}
                      >
                        {pedido.eventos.at(-1).titulo}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      <ShipImage data={pedido} />
                      <Box height={"5px"} />

                      <Text fontSize={"sm"}>
                        {pedido.envio?.delivery_method_name}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      <Link to={`/pedido/${pedido._id}`}>
                        <Button w={"240px"} size={"sm"}>
                          Ver detalhes
                        </Button>
                      </Link>
                      <Box height={"5px"} />
                      <Button w={"240px"} disabled size={"sm"}>
                        Imprimir etiqueta de envio
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align={"center"} justifyContent={"center"}>
        {Array.isArray(data) && data.length >= 15 && (
          <Button onClick={onLoadMore} variant={"link"} padding={"10px 0px"}>
            Carregar mais
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
