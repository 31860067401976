import {
  Box,
  Flex,
  Grid,
  Text,
  AspectRatio,
  Input,
  Select,
  Button,
  Image,
  Center,
  useToast,
} from "@chakra-ui/react";

import OthersRepository from "./../../../repositories/others_repository";
import { useState, useEffect } from "react";
import Formatters from "../../../utils/formatters";
import { useFilePicker } from "use-file-picker";
import ImageUploader from "../../NovoAnuncio/helpers/ImageUploader";
import useAuthToken from "../../../hooks/useAuthToken";
import VendedoresRepository from "../../../repositories/vendedores_repositories";

export default function TabPessoaJuridica({ loja, onCancel, save }) {
  const token = useAuthToken();
  const repo = new VendedoresRepository({ token });
  const [cnpj, setCnpj] = useState("");
  const toast = useToast();

  useEffect(() => {}, []);

  const onSave = async () => {
    const res = await repo.migrarCnpj({ cnpj });

    if (res.status === 200) {
      toast({
        status: "success",
        title: "Sucesso!",
        description: "Sua loja foi migrada para o CNPJ",
      });
    } else {
      toast({
        status: "error",
        title: "Não foi possível migrar sua loja para o CNPJ",
        description:
          res.data.error ?? "Não foi possível migrar sua loja para o CNPJ",
      });
    }
  };

  return (
    <Box>
      <Grid templateColumns="repeat(2, 1fr)" gap={2} mt="10">
        <Flex direction="column">
          <Text>CNPJ da nova titularidade</Text>
          <Input
            onChange={(e) => {
              setCnpj(e.target.value);
            }}
            variant="filled"
            size="lg"
            mb="2"
          />
        </Flex>
        {/* <Flex direction="column">
          <Text>Tipo</Text>
          <Select
            variant="filled"
            size="lg"
            fontWeight={500}
            defaultValue={loja.dados_fiscais?.natureza_juridica}
            onChange={(e) =>
              (loja.dados_fiscais.natureza_juridica = e.target.value)
            }
          >
            <option value="option1">MEI</option>
            <option value="option2">ME</option>
            <option value="option3">LTDA</option>
            <option value="option3">SLU</option>
            <option value="option3">EIRELI</option>
          </Select>
        </Flex> */}
        {/* <Flex direction="column">
          <Text>CEP</Text>
          <Input
            onChange={(e) => {
              fetchCep(e.target.value);
              loja.cep_empresa = e.target.value;
            }}
            defaultValue={Formatters.numbersOnly(loja.endereco.cep)}
            variant="filled"
            size="lg"
            mb="2"
          />
        </Flex>
        <Flex direction="column">
          <Text>Estado</Text>
          <Input
            isReadOnly={true}
            defaultValue={loja.estado_empresa}
            variant="filled"
            size="lg"
            mb="2"
          />
        </Flex> */}
        {/* <Flex direction="column">
          <Text>Número da inscrição estadual</Text>
          <Input
            defaultValue={loja.dados_fiscais?.inscricao_estadual}
            variant="filled"
            size="lg"
            mb="2"
            onChange={(e) =>
              (loja.dados_fiscais.inscricao_estadual = e.target.value)
            }
          />
        </Flex>
        <Flex direction="column">
          <Text>Número da inscrição municipal</Text>
          <Input
            defaultValue={loja.dados_fiscais?.inscricao_municipal}
            variant="filled"
            size="lg"
            mb="2"
            onChange={(e) =>
              (loja.dados_fiscais.inscricao_municipal = e.target.value)
            }
          />
        </Flex> */}
      </Grid>
      {/* <Text my="5" fontSize="sm">
        Anexe o RG ou CNH da titularidade atual | Obrigatório
      </Text>
      {files.length === 0 && (
        <AspectRatio ratio={8 / 3} onClick={openFileSelector}>
          <Flex
            w="90%"
            bgColor="#EDF2F7"
            direction="column"
            borderRadius="10"
            cursor="pointer"
          >
            <Text textAlign="center" fontSize="sm">
              Clique aqui e adicione o arquivo. Ele deve ser em formato
              <br />
              JPG, PNG ou PDF e não deve pesar mais de 5mb.
            </Text>
          </Flex>
        </AspectRatio>
      )}
      {files.length > 0 && (
        <Center>
          <AspectRatio ratio={1 / 1} onClick={openFileSelector} w="350px">
            <Image src={files[0]} />
          </AspectRatio>
        </Center>
      )} */}

      <Box w="full" align="end" mt="5">
        <Button
          variant="outline"
          colorScheme="purple"
          size="sm"
          mr="2"
          onClick={onCancel}
        >
          Cancelar
        </Button>
        <Button
          colorScheme="purple"
          size="sm"
          onClick={() => {
            onSave();
          }}
        >
          Enviar
        </Button>
      </Box>
    </Box>
  );
}
