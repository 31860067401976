import { extendTheme } from "@chakra-ui/react";

const theme = {
  colors: {
    primary: {
      900: "#693FF3",
      800: "#693FF3",
      700: "#693FF3",
      600: "#693FF3",
      500: "#693FF3",
      400: "#693FF3",
      300: "#693FF3",
    },
  },

  components: {
    Button: {
      baseStyle: {
        bg: "#f2f2f2",
      },
      variants: {
        purple: {
          bg: "#f2f2f2",
          color: "white",
        },
        customOutline: {
          bg: "white",
          border: "1px solid #693ff3",
          color: "#693FF3",
          width: "82px",
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
      // defaultProps: {
      //   size: "md",
      //   variant: "sm",
      //   bg: "white",
      //   color: "white",
      // },
    },
    Checkbox: {
      baseStyle: {
        control: {
          bg: "white",
          boxShadow: "0 0 0px 1px white",
          _checked: {
            bg: "#693ff3",
          },
        },
      },
    },
    ButtonStyles: {
      baseStyle: {
        bg: "#f2f2f2",
        color: "white",
      },
      variants: {
        purple: {
          bg: "#f2f2f2",
          color: "white",
        },
        teste: {
          bg: "white",
          color: "purple",
          border: "1px solid #E4E4E4",
        },
      },
    },
    Select: {
      baseStyle: {
        field: {
          border: "none",
          borderColor: "",
          bg: "#E4E4E4",
          ":focus": {
            borderColor: "#E4E4E4",
            bg: "#E4E4E4",
          },
        },
      },
      variants: {
        filled: {
          field: {
            border: "none",
            borderColor: "#E4E4E4",
            bg: "#E4E4E4",
            _focus: {
              borderWidth: 0,
              borderColor: "#E4E4E4",
              boxShadow: "none",
              bg: "#E4E4E4",
            },
          },
        },
        customGrey: {
          field: {
            border: "none",
            borderColor: "#E4E4E4",
            bg: "#E4E4E4",
            _focus: {
              borderWidth: 0,
              borderColor: "#E4E4E4",
              boxShadow: "none",
              bg: "#E4E4E4",
            },
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        border: "none",
        borderColor: "#E4E4E4",
        bg: "#E4E4E4",
        ":focus": {
          borderColor: "#E4E4E4",
          bg: "#E4E4E4",
        },
      },
      variants: {
        filled: {
          border: "none",
          borderColor: "#E4E4E4",
          bg: "#E4E4E4",
          _focus: {
            borderWidth: 0,
            borderColor: "#E4E4E4",
            boxShadow: "none",
            bg: "#E4E4E4",
          },
        },
        customGrey: {
          border: "none",
          borderColor: "#E4E4E4",
          bg: "#E4E4E4",
          _focus: {
            borderWidth: 0,
            borderColor: "#E4E4E4",
            boxShadow: "none",
            bg: "#E4E4E4",
          },
        },
      },
    },

    Input: {
      baseStyle: {
        field: {
          border: "none",
          borderColor: "",
          bg: "#E4E4E4",
          ":focus": {
            borderWidth: 0,
            borderColor: "#E4E4E4",
            boxShadow: "none",
            bg: "#E4E4E4",
          },
        },
      },
      variants: {
        filled: {
          field: {
            border: "none",
            borderColor: "#E4E4E4",
            bg: "#E4E4E4",
            _focus: {
              borderWidth: 0,
              borderColor: "#E4E4E4",
              boxShadow: "none",
              bg: "#E4E4E4",
            },
          },
        },
        customGrey: {
          field: {
            border: "none",
            borderColor: "#E4E4E4",
            bg: "#E4E4E4",
            _focus: {
              borderWidth: 0,
              borderColor: "#E4E4E4",
              boxShadow: "none",
              bg: "#E4E4E4",
            },
          },
        },
      },
    },
  },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    body: `'Montserrat', sans-serif`,
  },
};

export default extendTheme(theme);
