import React, { useEffect, useState, useContext } from "react";

import PageScaffold from "../../components/PageScaffold";

import {
  Box,
  Flex,
  Image,
  Text,
  Center,
  Fade,
  Progress,
  useToast,
} from "@chakra-ui/react";
import ProductCard from "./components/ProductCard";
import OrderResume from "./components/OrderResume";
import ShipCard from "./components/ShipCard";
import StatusCard from "./components/StatusCard";
import ActionsCard from "./components/ActionsCard";
import { MdArrowForwardIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router";
import useAuthToken from "../../hooks/useAuthToken";
import PedidosRepository from "../../repositories/pedidos_repository";

import logo from "../../assets/logo.png";
import Formatters from "../../utils/formatters";
import EnvioRepository from "../../repositories/envio_repository";
import { ChatContext } from "../../contexts/chatContext";
import useEstabelecimento from "../../hooks/useEstabelecimento";

export default function DetalhesPedido() {
  const chatContext = useContext(ChatContext);
  const params = useParams();
  const navigate = useNavigate();
  const token = useAuthToken();
  const toast = useToast();
  const envioRepo = new EnvioRepository({ token });
  const repo = new PedidosRepository({ token });
  const estabelecimento = useEstabelecimento();

  const [pedido, setPedido] = useState();
  const [danfeUrl, setDanfeUrl] = useState(null);

  useEffect(() => {
    repo
      .consultar({
        pedido_id: params.id,
      })
      .then((res) => {
        if (res.status === 200) {
          setPedido(res.data);
        }
      });

    repo.consultarDanfeUrl({ pedido_id: params.id }).then((res) => {
      if (res.status === 200) {
        setDanfeUrl(res.data.uri);
      }
    });
  }, [params.id]);

  if (!params.id) {
    navigate("/");
    return;
  }

  if (!pedido) {
    return (
      <Center bg={"white"} h={"100vh"}>
        <Fade in={true}>
          <Image src={logo} padding={"10px"} maxW={"400px"} />
          <Progress colorScheme={"primary"} size="xs" isIndeterminate />
        </Fade>
      </Center>
    );
  }

  const openChat = (usuario) => {
    chatContext.openChatWith({
      usuario_id: usuario,
    });
  };

  const handleNfe = async (pedido) => {
    const res = await repo.gerarNfe({ pedido_id: pedido._id });

    if (res.status === 200) {
      toast({
        title: "NF-e Solicitada",
        description: "Aguarde a aprovação",
        status: "info",
        duration: 4000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Erro ao solicitar NF-e",
        description: res.data.error,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEtiquta = async (pedido) => {
    toast({
      title: "Gerando etiqueta",
      description: "Aguarde enquanto geramos a etiqueta",
      status: "info",
      duration: 4000,
      isClosable: true,
    });

    const response = await envioRepo.verEtiqueta({
      pedido_envio: pedido._id,
    });

    if (response.status === 200) {
      if (response.data.status === "OK") {
        window.open(response.data.content.label_url, "_blank");
      }
    } else {
      if (
        response.data.messages.at(0).key ===
        "shipmentOrder.label.unknown.order.number.order.volume.number"
      ) {
        const novoEnvioResponse = await envioRepo.novoEnvio({
          pedido_id: pedido._id,
        });

        if (novoEnvioResponse.status === 200) {
          handleEtiquta(pedido);
          return;
        } else {
          toast({
            title: "Erro ao gerar etiqueta",
            description:
              novoEnvioResponse.data?.messages?.at(0)?.text ||
              novoEnvioResponse.data.error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }

      toast({
        title: "Erro ao gerar etiqueta",
        description: response.data.messages.at(0).text,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <PageScaffold showSideBar={true}>
      <Flex direction={"row"} mt={"25px"} justifyContent={"center"}>
        <Flex direction={"column"}>
          <ProductCard data={pedido} />
          <StatusCard data={pedido} />
          <Box
            p={"20px"}
            boxShadow="sm"
            width={"100%"}
            borderRadius={"10px"}
            marginTop={"5px"}
            backgroundColor={"white"}
          >
            <Text fontWeight={700} fontSize={"20px"}>
              Detalhes da compra
            </Text>
            <Flex
              fontSize={"19px"}
              fontWeight={"500"}
              justifyContent={"space-between"}
              padding={"5px 0px"}
            >
              <Box>Produto</Box>
              <Box>
                {Formatters.money(
                  pedido.custo_total - pedido.envio.final_shipping_cost
                )}
              </Box>
            </Flex>
            <Flex
              fontSize={"19px"}
              fontWeight={"500"}
              justifyContent={"space-between"}
              padding={"5px 0px"}
            >
              <Box>Frete</Box>
              <Box>{Formatters.money(pedido.envio.final_shipping_cost)}</Box>
            </Flex>
            <hr />
            <Flex
              fontSize={"19px"}
              fontWeight={"500"}
              justifyContent={"space-between"}
              padding={"5px 0px"}
            >
              <Box>Total</Box>
              <b> {Formatters.money(pedido.custo_total)}</b>
            </Flex>
          </Box>
        </Flex>
        <Box w={"20px"} />

        <Flex direction={"column"} w={"50%"}>
          <OrderResume
            data={pedido}
            onOpenChat={() => {
              console.log("tapped");
              openChat(pedido.usuario_id);
            }}
          />
          <ShipCard data={pedido} />
          <ActionsCard
            data={pedido}
            isPj={estabelecimento.tipo_cadastro === "pessoa_juridica"}
            onPrintEtiqueta={() => {
              handleEtiquta(pedido);
            }}
            onNfe={() => {
              handleNfe(pedido);
            }}
          />
          {estabelecimento.tipo_cadastro === "pessoa_juridica" && (
            <Flex
              width="full"
              direction={"row"}
              justifyContent={"space-between"}
              cursor={"pointer"}
              backgroundColor={"#bababa"}
              padding={"10px"}
              margin={"0 auto"}
              marginTop={"15px"}
              borderRadius={"8px"}
              onClick={() => {
                if (danfeUrl) {
                  window.open(danfeUrl, "_blank");
                }
              }}
            >
              <Flex direction={"column"}>
                <Text fontWeight={700} fontSize={"20px"}>
                  Nota fiscal eletrônica
                </Text>
                <Text fontWeight={500}>
                  {danfeUrl ? "Disponivel" : "Não disponível"}
                </Text>
                {pedido.fiscal.status !== "Issued" && (
                  <Text fontWeight={500}>
                    {pedido.fiscal.status} - {pedido.fiscal.error_message || ""}
                  </Text>
                )}
              </Flex>
              <MdArrowForwardIos size={20} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </PageScaffold>
  );
}
