import { ModalCloseButton, useToast } from "@chakra-ui/react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";

export default function CancelamentoDialog({ isOpen, onClose, customMsg }) {
  const toast = useToast();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cancelar</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <span>
            {customMsg ??
              "Ao clicar em cancelar você perderá todos as informações que você preencheu."}
            <br /> <b>Você confirma a ação? </b>
          </span>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="red"
            mr={3}
            onClick={() => {
              onClose(false);
            }}
          >
            Não
          </Button>
          <Button
            colorScheme="blue"
            onClick={() => {
              onClose(true);
            }}
          >
            Sim
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
