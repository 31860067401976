import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Button,
  Avatar,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Divider,
  Image,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Center,
  Spinner,
} from "@chakra-ui/react";

import { MdChat } from "react-icons/md";
import Formatters from "../../../utils/formatters";
import { Link } from "react-router-dom";
import CorreiosImg from "../../../assets/correios.png";
import { addDays } from "date-fns";

function TabItem(children, onClick) {
  return (
    <Tab
      _selected={{
        color: "#693FF3",
        borderBottom: "3px solid #693FF3",
      }}
      onClick={onClick}
      fontWeight={600}
      color={"#777777"}
      padding={"12px 15px"}
      fontSize="xs"
    >
      {children}
    </Tab>
  );
}

export default function TabDevolucoes({
  data,
  isLoading,
  onLoadMore,
  openChatWith,
}) {
  if (isLoading) {
    return (
      <Center p={"20px 0px"}>
        <Spinner />
        <Box w={"10px"} />
        <Text>Carregando...</Text>
      </Center>
    );
  }

  if (data.length === 0) {
    return (
      <Center p={"20px 0px"}>
        <Text>Nenhum pedido encontrado</Text>
      </Center>
    );
  }
  return (
    <Flex direction={"column"}>
      <Tabs>
        <Flex direction="row" align="center" justify="space-between" pr="5">
          <TabList>
            {TabItem("Todos")}
            {TabItem("A processar")}
            {TabItem("Processado")}
          </TabList>
          {/* <Flex align="center">
            <Text fontSize="sm" fontWeight={500} mr="10px">
              Período
            </Text>

            <Input fontWeight={500} size="sm" type={"date"} maxW="150px" />
          </Flex> */}
        </Flex>
        <Divider />
        <TabPanels>
          <TabPanel>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Detalhes</Th>
                    <Th>Preço Total</Th>
                    <Th>Status</Th>
                    <Th>Prazo</Th>
                    <Th>Ação</Th>
                  </Tr>
                </Thead>
                <Tbody fontWeight={600}>
                  {data.map((pedido) => {
                    return (
                      <Tr key={pedido._id}>
                        <Td>
                          <Flex direction={"column"} align="start">
                            <Flex padding={"7px 0px"} alignItems={"center"}>
                              <Avatar
                                size="xs"
                                src={pedido.usuario_imagem}
                                name={pedido.usuario_nome}
                                background="#232544"
                                color={"white"}
                              />
                              <Box w={"8px"} />
                              <Text>{pedido.usuario_nome}</Text>
                              <Box w={"8px"} />

                              <MdChat
                                color={"#20BEAE"}
                                onClick={() => {
                                  openChatWith(pedido.usuario_id);
                                }}
                              />
                              <Text ml="10px" fontSize={"sm"}>
                                Pedido #{pedido.numero_pedido || "N/A"}
                              </Text>
                            </Flex>
                            <Flex padding={"7px 0px"} alignItems={"center"}>
                              <Image
                                w="80px"
                                name="Dan Abrahmov"
                                src={pedido.itens[0].produto_img}
                              />
                              <Box w={"8px"} />
                              <Text
                                width={"190px"}
                                whiteSpace={"pre-line"}
                                fontSize="sm"
                                noOfLines={2}
                              >
                                {pedido.itens[0].produto_nome}
                              </Text>
                            </Flex>
                          </Flex>
                        </Td>
                        <Td>
                          <Flex direction={"row"}>
                            {/* <Text fontSize={"sm"} mr="2" color="primary.400">
                              {Formatters.paymentMethod(
                                pedido.pagamento?.pagamento.parcelas
                              )}
                              {"x de"}
                            </Text> */}
                            <Text fontSize={"sm"}>
                              {Formatters.money(pedido.custo_total)}
                            </Text>
                          </Flex>
                        </Td>
                        <Td>
                          <Flex direction={"column"}>
                            {/* <Text fontSize={"sm"}>
                        {pedido.eventos.at(-1).tipo.replace("_", " ")}
                      </Text> */}
                            <Text
                              as={"span"}
                              maxWidth={"100px"}
                              noOfLines={3}
                              fontSize={"xs"}
                              whiteSpace={"pre-line"}
                            >
                              {pedido.eventos.at(-1).titulo}
                            </Text>
                          </Flex>
                        </Td>
                        <Td>
                          <Flex>
                            <Text fontSize={"xs"}>
                              {addDays(
                                new Date(
                                  pedido.eventos.find(
                                    (e) => e.id === "disputa_aberta"
                                  )?.data
                                ),
                                7
                              ).toLocaleDateString()}
                            </Text>
                          </Flex>
                        </Td>
                        <Td>
                          <Link to={`/devolucao/${pedido._id}`}>
                            <Button w={"100%"} size={"sm"}>
                              Responder
                            </Button>
                          </Link>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel>
            <Flex></Flex>
          </TabPanel>
          <TabPanel>
            <Flex></Flex>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Flex align={"center"} justifyContent={"center"}>
        {Array.isArray(data) && data.length >= 15 && (
          <Button onClick={onLoadMore} variant={"link"} padding={"10px 0px"}>
            Carregar mais
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
