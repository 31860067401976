import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";

import CustomTabButtonComponent from "../../components/CustomTabButtonComponent";
import PageScaffold from "../../components/PageScaffold";
import TabEnvios from "./components/TabEnvios";
import TabGeracaoDocumento from "./components/TabGeracaoDocumento";
import { useState } from "react";
import useAuthToken from "../../hooks/useAuthToken";
import PedidosRepository from "../../repositories/pedidos_repository";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import EnvioRepository from "../../repositories/envio_repository";

export default function EnvioEmMassaPage() {
  const [tabIndex, setTabIndex] = useState(1);
  const [selectedPedidos, setSelectedPedidos] = useState([]);

  const token = useAuthToken();
  const estabelecimento = useEstabelecimento();

  const repo = new PedidosRepository({ token });
  const envioRepo = new EnvioRepository({ token });
  const toast = useToast();

  const [pedidos, setPedidos] = useState(null);

  const fetch = async () => {
    const res = await repo.consultar({
      estabelecimento_id: estabelecimento._id,
      status_pedido: "pago",
    });

    if (res.status === 200) {
      return res.data;
    }

    return [];
  };

  useEffect(() => {
    fetch().then((data) => {
      setPedidos(data);
    });
  }, []);

  const handleEtiquta = async (pedido) => {
    toast({
      title: "Gerando etiqueta",
      description: "Aguarde enquanto geramos a etiqueta",
      status: "info",
      duration: 4000,
      isClosable: true,
    });

    const response = await envioRepo.verEtiqueta({
      pedido_envio: pedido._id,
    });

    if (response.status === 200) {
      if (response.data.status === "OK") {
        window.open(response.data.content.label_url, "_blank");
      }
    } else {
      if (
        response.data.messages.at(0).key ===
        "shipmentOrder.label.unknown.order.number.order.volume.number"
      ) {
        const novoEnvioResponse = await envioRepo.novoEnvio({
          pedido_id: pedido._id,
        });

        if (novoEnvioResponse.status === 200) {
          handleEtiquta(pedido);
          return;
        } else {
          toast({
            title: "Erro ao gerar etiqueta",
            description:
              novoEnvioResponse.data?.messages?.at(0)?.text ||
              novoEnvioResponse.data.error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }

      toast({
        title: "Erro ao gerar etiqueta",
        description: response.data.messages.at(0).text,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <PageScaffold showSideBar={true}>
      <Box paddingTop={"12px"} fontWeight={"700"} fontSize={"22px"}>
        {/* <span>Meus Envios</span> */}
        <Flex direction="row" my="5">
          <Link to="/meus-envios">
            <Box
              px="5"
              py="2"
              borderRadius="32"
              backgroundColor="transparent"
              mr="30px"
              color="grey"
              fontSize="lg"
            >
              <Text>Meus Envios</Text>
            </Box>
          </Link>
          <Link to="/envio-em-massa">
            <Box
              px="5"
              py="2"
              borderRadius="32"
              backgroundColor="primary.400"
              mr="30px"
              color="white"
              fontSize="lg"
            >
              <Text>Enviar em massa</Text>
            </Box>
          </Link>
          <Link to="/config-de-envio">
            <Box
              px="5"
              py="2"
              borderRadius="32"
              backgroundColor="transparent"
              mr="30px"
              color="grey"
              fontSize="lg"
            >
              <Text>Configurações</Text>
            </Box>
          </Link>
        </Flex>
      </Box>

      <Flex w="100%" direction="row" align="start">
        <Box
          boxShadow="sm"
          minHeight={"88vh"}
          h="max-content"
          borderRadius={"8px"}
          marginTop={"5px"}
          backgroundColor={"white"}
          w="90%"
        >
          <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
            <TabPanels>
              <TabPanel>
                <TabEnvios pedidos={pedidos} />
              </TabPanel>
              <TabPanel>
                <TabGeracaoDocumento
                  onProductsChange={(pedidos) => {
                    setSelectedPedidos(pedidos);
                  }}
                  pedidos={pedidos}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        {selectedPedidos.length > 0 && (
          <Flex w="500px" direction="column" pl=" 0" pb="0" maxW="1440px">
            <Box
              boxShadow="sm"
              width={"100%"}
              h="max-content"
              marginTop={"5px"}
              backgroundColor={"#232544"}
              color="white"
              align="center"
              p="10"
            >
              <Heading fontWeight="700" fontSize="18">
                Gerar documento de envio
              </Heading>
              <Text fontSize="xs" mt="2" fontWeight="semibold">
                {selectedPedidos.length} pedido(s) selecionado(s), selecione os
                documentos de envio para fazer download
              </Text>
              <Box backgroundColor="#494D80" borderRadius="4px" p="2" mt="5">
                <Flex direction="row" align="center">
                  <Checkbox />
                  <Text fontSize="sm" fontWeight="bold" ml="2">
                    Lista de produtos
                  </Text>
                </Flex>
              </Box>
              <Box backgroundColor="#494D80" borderRadius="4px" p="2" mt="2">
                <Flex direction="row" align="start">
                  <Checkbox mt="2px" />
                  <Flex direction="column" align={"start"}>
                    <Text fontSize="sm" fontWeight="bold" ml="2">
                      Etiquetas de envio
                    </Text>
                    <Flex direction="row" pl="5" mt="2.5">
                      <Flex direction="row" align="center" mr="5">
                        <Checkbox />
                        <Text fontSize="xs" fontWeight="bold" ml="2">
                          PDF
                        </Text>
                      </Flex>
                      {/* <Flex direction="row" align="center">
                        <Checkbox />
                        <Text fontSize="xs" fontWeight="bold" ml="2">
                          Térmica ZPL
                        </Text>
                      </Flex> */}
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
              <Box backgroundColor="#494D80" borderRadius="4px" p="2" mt="2">
                <Flex direction="row" align="center">
                  <Checkbox />
                  <Text fontSize="sm" fontWeight="bold" ml="2">
                    Declaração de conteúdo
                  </Text>
                </Flex>
              </Box>

              <Text fontSize="xs" fontWeight="bold" mt="5">
                Detalhes do arquivo de saída
              </Text>

              <Flex direction="row" mt="2" align="center">
                <Image src="/documento.png" w="50px" h="50px" />
                <Flex w="20px" />
                <Text fontSize="xs" textAlign="start" fontWeight="semibold">
                  Lista de produtos + Etiquetas de envio + Declaração de
                  conteúdo
                </Text>
              </Flex>

              <Button
                onClick={() => {
                  Promise.all(selectedPedidos.map(handleEtiquta));
                }}
                my="5"
                backgroundColor="primary.400"
                color="white"
                w="350px"
              >
                Gerar documentos selecionados
              </Button>
              <Text fontSize="xs" textAlign="center" fontWeight="semibold">
                *Permita que as janelas de pop up pré-visualizem os documentos
                de envio
              </Text>
            </Box>
          </Flex>
        )}
      </Flex>
    </PageScaffold>
  );
}
