import { CloseIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { MdAdd } from "react-icons/md";

export default function AddImageButtonComponent({
  title,
  width,
  height,
  onClick,
  onDelete,
  file,
  borderColor,
  borderStyle,
  borderWidth,
  showCapaLabel = false,
}) {
  if (file) {
    return (
      <Box
        w={width ?? "40"}
        h={height ?? "160px"}
        borderStyle={borderStyle ?? "dashed"}
        borderColor={borderColor ?? "black"}
        boxSizing="border-box"
        borderWidth={borderWidth ?? "1px"}
        borderRadius="8px"
        my="4"
        mr="3"
        cursor="pointer"
        justifyContent="center"
        alignItems="center"
        p="2"
        py="1"
      >
        <Flex justifyContent="center" alignItems="start" direction="column">
          <CloseIcon onClick={onDelete} h="15px" />
          <Box h="5px" />
          <Image
            borderRadius="4px"
            height={"120px"}
            style={{
              margin: "0 auto",
            }}
            src={file}
          />
          {showCapaLabel && (
            <Text fontSize="xs" pt={"18px"} fontWeight="semibold" pl={"25px"}>
              Foto da capa
            </Text>
          )}
        </Flex>
      </Box>
    );
  }

  return (
    <Box
      w={width ?? "40"}
      h={height ?? "160px"}
      borderStyle="dashed"
      borderColor="black"
      borderWidth="1px"
      boxSizing="border-box"
      borderRadius="8px"
      my="4"
      display="flex"
      cursor="pointer"
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
    >
      {!height && (
        <Flex
          justifyContent="center"
          alignItems="center"
          direction="column"
          h="full"
        >
          <Box borderRadius="full" backgroundColor="#20BEAE" mb="2">
            <MdAdd size={30} m="5 auto" color="white" />
          </Box>
          <Text fontSize="xs" fontWeight="semibold">
            {title ?? "Adicionar Imagem"}
          </Text>
        </Flex>
      )}
      {height && (
        <Flex
          borderRadius="full"
          backgroundColor="#20BEAE"
          w={"15px"}
          alignSelf={"center"}
          justifyContent={"center"}
        >
          <MdAdd size={height ? 15 : 30} color="white" />
        </Flex>
      )}
    </Box>
  );
}
