import React, { useState } from "react";
import Select from "react-select";

export default function CustomSelect({
  selectedOption,
  onChange,
  options = [],
}) {
  return (
    <Select
      theme={(theme) => ({
        ...theme,
        borderRadius: 4,
        colors: {
          ...theme.colors,
          primary25: "#cec1ff",
          primary50: "#cec1ff",

          primary: "#7E5EFA",
        },
      })}
      styles={{
        control: (provided, state) => ({
          ...provided,
          borderRadius: "4px",
          width: "300px",
          borderColor: "#E2E8F0",
          backgroundColor: "#E4E4E4",
          boxShadow: "none",
          "&:hover": {
            borderColor: "#E2E8F0",
          },
        }),
      }}
      noOptionsMessage={() => "Nenhuma opção encontrada"}
      placeholder={"Selecionar"}
      defaultValue={selectedOption}
      onChange={onChange}
      options={options}
    />
  );
}
