import React from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Button,
  Link,
  Divider,
  InputLeftElement,
  Image,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdCircle, MdShowChart } from "react-icons/md";
import searchImg from "../../../assets/pesquisar.svg";

export default function FilterBox({ onFilterChange = () => {}, onReset }) {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");

  return (
    <Box boxShadow="sm" width={"100%"} borderRadius={"8px"} marginTop={"5px"}>
      <Flex
        direction={"row"}
        alignItems={"center"}
        justifyContent="space-between"
        px="4"
      >
        <Flex direction="column" maxW="50%">
          <Text fontSize="md" fontWeight="bold" color="#4e4e4e">
            Lista de lembretes
          </Text>
          <Text fontSize="xs" fontWeight={"medium"}>
            Crie lembretes para converter seus carrinhos abandonados.
            <span
              style={{
                color: "#7048f3",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {" Saiba mais."}
            </span>
          </Text>
        </Flex>

        <Flex direction="row">
          <Link to={"/estatisticas"} mr="2">
            <Button
              onClick={() => {
                navigate("/estatisticas");
              }}
              width={"140px"}
              marginLeft={"15px"}
              backgroundColor="#232544"
              color={"white"}
              _hover={{ opacity: "0.8" }}
            >
              <Flex direction="row" align="center">
                <MdShowChart size={20} color="cyan" />
                <Text mx="2" fontSize="xs">
                  Métricas
                </Text>
                <Divider h="10px" mr="2" orientation="vertical" />
                <MdCircle size={15} color="cyan" />
              </Flex>
            </Button>
          </Link>
          <Button
            backgroundColor="primary.400"
            color={"white"}
            _hover={{ opacity: "0.8" }}
            colorScheme="purple"
            onClick={() => {
              navigate("/criar-lembrete-carrinho");
            }}
          >
            Criar Lembrete
          </Button>
        </Flex>
      </Flex>
      <Flex
        padding={"16px"}
        backgroundColor="white"
        direction="row"
        justifyContent="space-between"
        borderRadius="8"
        mt="2"
      >
        <InputGroup spacing="24px" width={"280px"}>
          <InputLeftElement
            children={<Image src={searchImg} boxSize="17px" />}
          />
          <Input
            type="text"
            fontWeight={500}
            placeholder="Buscar por nome"
            value={name}
            w="200px"
            onChange={(e) => {
              setName(e.target.value);
            }}
            variant={"filled"}
          />
        </InputGroup>

        <Flex direction="row">
          {/* <Flex align="center">
            <Text fontSize="sm" fontWeight={500} w="60px">
              Período
            </Text>
            <Box w={"10px"} />
            <Input
              fontWeight={500}
              size="sm"
              type={"date"}
              ref={startDateRef}
              maxW="130px"
            />
            <Input
              fontWeight={500}
              size="sm"
              type={"date"}
              maxW="130px"
              ref={endDateRef}
            />
          </Flex> */}
          <Flex align="center">
            <Select
              mx="2"
              w="180px"
              variant={"filled"}
              fontWeight={"bold"}
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value="todos">Todos</option>
              <option value="Agendado">Agendado</option>
              <option value="Em andamento">Em andamento</option>
              <option value="Finalizado">Finalizado</option>
            </Select>
          </Flex>
          <Button
            width={"150px"}
            marginLeft={"15px"}
            backgroundColor="primary.400"
            color={"white"}
            _hover={{ opacity: "0.8" }}
            onClick={() => {
              onFilterChange({
                nome: name,
                status: status === "todos" ? "" : status,
              });
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              onFilterChange({});
              setName("");
              setStatus("");

              onReset();
            }}
            width={"150px"}
            marginLeft={"15px"}
            variant={"outline"}
          >
            Redefinir
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
