import { buildHttpClient } from "../services/api";
import axios from "axios";

class PublicRepository {
  constructor() {
    this.http = buildHttpClient();
  }

  consultar(params) {
    return this.http.post("public/vendedores/consultar-cadastro", params);
  }
}

export default PublicRepository;
