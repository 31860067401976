import {
  Box,
  Flex,
  Text,
  Divider,
  Checkbox,
  ModalBody,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  Input,
  Select,
  useToast,
  CloseButton,
  Spacer,
} from "@chakra-ui/react";
import { useState } from "react";

export default function FiltersDialog({ isOpen, onClose, onChange, setTab }) {
  const [categoria, setCategoria] = useState();
  const toast = useToast();
  const [text, setText] = useState("");
  const [category, setCategory] = useState("");
  const [minStock, setMinStock] = useState("");
  const [maxStock, setMaxStock] = useState("");
  const [minSales, setMinSales] = useState("");
  const [maxSales, setMaxSales] = useState("");
  const [stockIsValid, setStockIsValid] = useState(true);
  const [salesIsValid, setSalesIsValid] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);

  const fetchWithFilters = async () => {
    if (categoria) {
      setTab(categoria);
    }
    onChange({
      texto_busca: text,
      filtros: {
        categoria_id: category,
        categoria: category,
        estoque_min: minStock,
        estoque_max: maxStock,
        vendas_min: minSales,
        vendas_max: maxSales,
      },
    });

    // fetch(text, category, minStock, maxStock, minSales, maxSales);
  };
  const clearFilters = async () => {
    setText("");
    setCategory("");
    setMinStock("");
    setMaxStock("");
    setMinSales("");
    setMaxSales("");

    onChange({
      texto_busca: "",
      filtros: {
        categoria_id: "",
        categoria: "",
        estoque_min: "",
        estoque_max: "",
        vendas_min: "",
        vendas_max: "",
      },
    });
    onClose();
  };

  return (
    <Modal isOpen={isOpen} size="sm" height="200px">
      <ModalOverlay>
        <ModalContent height="400px">
          <ModalHeader fontSize="lg" fontWeight="bold" pt={"30px"}>
            <Flex>
              <Text> Filtrar</Text>
              <Spacer />
              <CloseButton onClick={onClose} />
            </Flex>
            <Text fontWeight="normal" fontSize="14px">
              Use o filtro para encontrar os produtos desejados
            </Text>
          </ModalHeader>

          <ModalBody overflowY="scroll">
            <Text fontSize={"sm"} w="100px" fontWeight="500">
              Categoria
            </Text>
            <Select
              w="180px"
              variant={"filled"}
              fontWeight={500}
              value={categoria}
              onChange={(e) => {
                setCategoria(e.target.value);
              }}
            >
              <option value="0">Todos</option>
              <option value="1">Ativos</option>
              <option value="2">Pendentes</option>
              <option value="3">Esgotados</option>
              <option value="4">Denuncias</option>
              <option value="5">Inativos</option>
              <option value="6">Rascunhos</option>
            </Select>
            <Text fontSize={"sm"} w="100px" mt="1" fontWeight="500">
              Estoque
            </Text>
            <Flex direction="row" align="center">
              <Input
                variant={"filled"}
                placeholder="min"
                onChange={(e) => {
                  setMinStock(e.target.value);
                }}
              />
              <Divider w="20px" mx="2" />
              <Input
                variant={"filled"}
                placeholder="max"
                onChange={(e) => {
                  setMaxStock(e.target.value);
                }}
              />
            </Flex>
            <Text fontSize={"sm"} w="100px" mt="1" fontWeight="500">
              Vendas
            </Text>
            <Flex direction="row" align="center">
              <Input
                variant={"filled"}
                placeholder="min"
                onChange={(e) => {
                  setMinSales(e.target.value);
                }}
              />
              <Divider w="20px" mx="2" />
              <Input
                variant={"filled"}
                placeholder="max"
                onChange={(e) => {
                  setMaxSales(e.target.value);
                }}
              />
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button onClick={clearFilters}>Redefinir</Button>
            <Button colorScheme="purple" onClick={fetchWithFilters} ml={3}>
              Filtrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
