import { Flex, Image, Input, Text } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  TableContainer,
} from "@chakra-ui/react";
import { MdAddAPhoto, MdImage } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import AddImageButtonComponent from "../../../components/AddImageButtonComponent";
import { useFilePicker } from "use-file-picker";
import { useEffect, useState } from "react";
import ImageUploader from "../helpers/ImageUploader";

export default function TabVariacoesNovoProduto({ variacoes, onChange }) {
  const [imageUpdateCount, setImageUpdateCount] = useState(0);

  const [openFileSelector, { plainFiles, loading }] = useFilePicker({
    readAs: "DataURL",
    accept: "Images/*",
    multiple: false,
    limitFilesConfig: { max: 5 },
    maxFileSize: 20, // in megabytes
  });

  useEffect(() => {}, [imageUpdateCount]);

  const adicionarImagemVariante = async (variacao) => {
    const pickerOpts = {
      types: [
        {
          description: "Images",
          accept: {
            "image/*": [".png", ".gif", ".jpeg", ".jpg"],
          },
        },
      ],
      excludeAcceptAllOption: true,
      multiple: false,
    };
    let [fileHandle] = await window.showOpenFilePicker(pickerOpts);
    const fileData = await fileHandle.getFile();
    console.log(fileData);
    // openFileSelector();

    if (fileData) {
      variacao.imagem = await ImageUploader.upload(fileData, {
        compress: true,
      });

      setImageUpdateCount(imageUpdateCount + 1);
      notifyChanges(variacao);
    }
  };

  if (variacoes.length === 0) return <Text></Text>;

  const keys = Object.keys(variacoes.at(0).propriedades);

  const notifyChanges = (variacao) => {
    const v = variacoes.map((v) => {
      if (
        JSON.stringify(v.propriedades) === JSON.stringify(variacao.propriedades)
      ) {
        return variacao;
      }
      return v;
    });

    onChange(v);
  };

  let uniqueValues = [];

  variacoes.forEach((variacao) => {
    const values = Object.values(variacao.propriedades);

    uniqueValues.push(values[0]);

    uniqueValues = [...new Set(uniqueValues)];
  });

  return (
    <Flex
      direction={"column"}
      background={"#F6F6F6"}
      borderRadius={"8px"}
      ml={"5"}
    >
      {uniqueValues.map((value) => (
        <Flex direction={"row"} align={"start"}>
          {/* <Flex
            background={"white"}
            w="50px"
            height="50px"
            justifyContent={"center"}
            alignItems={"center"}
            ml={"10px"}
            mt={"40px"}
            borderRadius={"8px"}
          >
            <MdAddAPhoto size={"20px"} p={"10px"} />
          </Flex> */}
          <Table marginBottom={"10px"}>
            <Thead>
              <Tr>
                <Th>Imagem</Th>
                {keys.map((key) => (
                  <Th key={key}>{key}</Th>
                ))}
                <Th>Preço (R$)</Th>
                <Th>Estoque</Th>
                <Th>SKU</Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={600}>
              {variacoes
                .filter((e) => Object.values(e.propriedades).includes(value))
                .map((variacao) => {
                  console.log(variacao);
                  const values = Object.values(variacao.propriedades);

                  return (
                    <Tr>
                      <Td>
                        {variacao.imagem ? (
                          <Flex direction="column">
                            <Image
                              borderRadius={"8px"}
                              src={variacao.imagem}
                              width="50px"
                              height="50px"
                            />
                            <Text
                              cursor={"pointer"}
                              fontSize="10px"
                              onClick={() => {
                                variacao.imagem = null;
                                notifyChanges(variacao);
                              }}
                            >
                              {" "}
                              Remover
                            </Text>
                          </Flex>
                        ) : (
                          <AddImageButtonComponent
                            width={"50px"}
                            height={"50px"}
                            onClick={() => {
                              adicionarImagemVariante(variacao);
                            }}
                          />
                        )}
                      </Td>
                      {values.map((value) => (
                        <Td key={value} w={"165px"}>
                          <Input
                            value={value}
                            readOnly
                            size={"sm"}
                            variant={"outlined"}
                          />
                        </Td>
                      ))}
                      <Td w={"165px"}>
                        <NumericFormat
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          value={variacao.preco}
                          prefix={"R$ "}
                          customInput={Input}
                          size={"sm"}
                          variant={"outlined"}
                          onValueChange={({ floatValue }) => {
                            variacao.preco = floatValue;
                            notifyChanges(variacao);
                          }}
                        />
                      </Td>
                      <Td w={"85px"}>
                        <Input
                          onChange={(e) => {
                            variacao.estoque = Number(e.target.value);
                            notifyChanges(variacao);
                          }}
                          initialValue={variacao.estoque}
                          value={variacao.estoque}
                          type={"number"}
                          placeholder={"0"}
                          size={"sm"}
                          variant={"outlined"}
                        />
                      </Td>
                      <Td w={"180px"}>
                        <Input
                          // onChange={(e) => {
                          //   variacao.sku = e.target.value;
                          //   notifyChanges(variacao);
                          // }}
                          value={variacao.sku}
                          placeholder={"sku"}
                          size={"sm"}
                          variant={"outlined"}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </Flex>
      ))}

      {/* <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              {keys.map((key) => (
                <Th key={key}>{key}</Th>
              ))}
              <Th>Preço (R$)</Th>
              <Th>Estoque</Th>
              <Th>SKU</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {variacoes.map((variacao) => {
              const values = Object.values(variacao.propriedades);

              return (
                <Tr>
                  {values.map((value) => (
                    <Td key={value}>
                      <Input
                        value={value}
                        readOnly
                        size={"sm"}
                        variant={"filled"}
                      />
                    </Td>
                  ))}
                  <Td>
                    <NumericFormat
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      value={variacao.preco}
                      prefix={"R$ "}
                      customInput={Input}
                      size={"sm"}
                      variant={"filled"}
                      onValueChange={({ floatValue }) => {
                        variacao.preco = floatValue;
                        notifyChanges(variacao);
                      }}
                    />
                  </Td>
                  <Td>
                    <Input
                      onChange={(e) => {
                        variacao.estoque = e.target.value;
                        notifyChanges(variacao);
                      }}
                      type={"number"}
                      value={variacao.estoque}
                      size={"sm"}
                      variant={"filled"}
                    />
                  </Td>
                  <Td>
                    <Input
                      w={"200px"}
                      onChange={(e) => {
                        variacao.sku = e.target.value;
                        notifyChanges(variacao);
                      }}
                      value={variacao.sku}
                      placeholder={"sku"}
                      size={"sm"}
                      variant={"filled"}
                    />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer> */}
    </Flex>
  );
}
