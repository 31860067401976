import useAuthToken from "../hooks/useAuthToken";
import ProdutosRepository from "../repositories/produtos_repository";
import { AuthContext } from "../contexts/authContext";
import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Divider,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputLeftElement,
  Input,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Table,
  TableContainer,
} from "@chakra-ui/react";
import CupomDeDescontoRepository from "../repositories/cupom_de_desconto_repository";
import useEstabelecimento from "../hooks/useEstabelecimento";
import searchImg from "../assets/pesquisar.svg";
import Formatters from "../utils/formatters";
import { MdClose } from "react-icons/md";

export default function CupomSelectorDialog({
  onSelect,
  isOpen,
  onClose,
  currentCupons = [],
  blockedCupons = [],
  allAvailable = false,
}) {
  const token = useAuthToken();
  const estabelecimento = useEstabelecimento();
  const repo = new CupomDeDescontoRepository({ token });

  const [allCupons, setAllCupons] = useState([]);
  const [pickedCupons, setPickedCupons] = useState(currentCupons);
  const [text, setText] = useState("");

  useEffect(() => {
    fetchCupons();
  }, []);

  const fetchCupons = async () => {
    let data = {};

    data["vendedor_id"] = estabelecimento._id;
    repo.consultar(data).then((res) => {
      if (res.status === 200) {
        setAllCupons(res.data);
      }
    });
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      size="6xl"
      height="300px"

      // leastDestructiveRef={cancelRef}
      //  onClose={onClose}
    >
      <ModalOverlay>
        <ModalContent height="700px">
          <ModalHeader fontSize="2xl" fontWeight="bold">
            <Flex justify={"space-between"}>
              <Text> Selecione os Cupons</Text>
              <MdClose
                cursor={"pointer"}
                onClick={() => {
                  onClose();
                }}
              />
            </Flex>
            {/* <Text fontWeight="normal" fontSize="xs">
              Selecione os produtos relacionados a sua raspadinha
            </Text> */}
          </ModalHeader>

          <ModalBody>
            <Flex direction="row">
              <InputGroup spacing="24px" width={"280px"}>
                <InputLeftElement
                  children={
                    // <Search2Icon onClick={onSearch} color={"primary.400"} />
                    <Image src={searchImg} boxSize="17px" />
                  }
                />
                <Input
                  placeholder="Digite o nome do cupom"
                  fontSize={"12px"}
                  mb={"10px"}
                  fontWeight={"600"}
                  variant="filled"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </InputGroup>
              <Button
                colorScheme="purple"
                onClick={() => {
                  let data = allCupons.filter((p) => {
                    return (
                      p.nome.toLowerCase().includes(text.toLowerCase()) ||
                      p.codigo.toLowerCase().includes(text.toLowerCase())
                    );
                  });
                  setAllCupons(data);
                }}
                ml={3}
                w="120px"
                backgroundColor="primary.400"
              >
                Buscar
              </Button>

              <Box w="10px" />
              <Button
                onClick={() => {
                  setText("");
                  fetchCupons();
                }}
                w="120px"
              >
                Redefinir
              </Button>
            </Flex>

            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th w={"25px"} pl={"25px"}>
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            let picked = [];

                            for (const cupons of allCupons) {
                              picked.push(cupons);
                            }

                            setPickedCupons(picked);
                          } else {
                            setPickedCupons([]);
                          }
                        }}
                      />
                    </Th>
                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                      w="390px"
                    >
                      Nome
                    </Th>
                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                      w={"200px"}
                    >
                      Tipo
                    </Th>
                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                    >
                      Código
                    </Th>
                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                    >
                      Quantidade
                    </Th>

                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                    >
                      Período
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {allCupons.map((p) => {
                    // p.hasProducts = p.variantes.at(0).estoque !== 0;
                    // p.isChecked = currentProducts.find((cp) => cp._id === p._id);
                    p.isBlocked = blockedCupons.find((cp) => cp._id === p._id);

                    const blocked =
                      (!p.hasProducts || p.isBlocked) && p.quantidade === 0;
                    return (
                      <Tr opacity={blocked ? "0.5" : "1"} key={p._id}>
                        <Td>
                          <Checkbox
                            isChecked={pickedCupons.find(
                              (pp) => pp._id === p._id
                            )}
                            defaultChecked={pickedCupons.find(
                              (pp) => pp._id === p._id
                            )}
                            isDisabled={blocked}
                            onChange={(value) => {
                              if (value.target.checked) {
                                pickedCupons.push(p);
                                setPickedCupons(pickedCupons);
                              } else {
                                let index = pickedCupons.findIndex(
                                  (pp) => pp._id === p._id
                                );
                                pickedCupons.splice(index, 1);
                                setPickedCupons(pickedCupons);
                              }
                            }}
                          />
                        </Td>
                        <Td>
                          <Text
                            fontSize={"sm"}
                            whiteSpace={"normal"}
                            noOfLines={2}
                            textAlign="start"
                            overflow={"hidden"}
                            fontWeight={"600"}
                          >
                            {p.nome}
                          </Text>
                        </Td>
                        <Td>
                          <Text
                            fontSize={"sm"}
                            whiteSpace={"normal"}
                            noOfLines={2}
                            textAlign="start"
                            overflow={"hidden"}
                            fontWeight={"600"}
                          >
                            {p.tipo}
                          </Text>
                        </Td>
                        <Td>
                          <Text
                            fontSize={"sm"}
                            whiteSpace={"normal"}
                            noOfLines={2}
                            textAlign="start"
                            overflow={"hidden"}
                            fontWeight={"600"}
                          >
                            {p.codigo}
                          </Text>
                        </Td>
                        <Td>
                          <Text
                            fontSize={"sm"}
                            whiteSpace={"normal"}
                            noOfLines={2}
                            fontWeight={"600"}
                            textAlign="start"
                            overflow={"hidden"}
                          >
                            {p.quantidade}
                          </Text>
                        </Td>
                        <Td>
                          <Text
                            fontSize={"sm"}
                            whiteSpace={"normal"}
                            noOfLines={2}
                            textAlign="start"
                            fontWeight={"600"}
                            overflow={"hidden"}
                          >
                            {new Date(p.data_inicio).toLocaleDateString()}{" "}
                            {new Date(p.data_fim).toLocaleDateString()}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} w="150px">
              Cancelar
            </Button>
            <Button
              colorScheme="purple"
              backgroundColor="primary.400"
              w="150px"
              onClick={() => {
                onSelect(pickedCupons);
              }}
              ml={3}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
