import { Flex, Text, Button, Image, Box } from "@chakra-ui/react";

import { Tr, Td } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../../hooks/useAuthToken";
import OfertasComboRepository from "../../../repositories/ofertas_combo_repository";

export default function OfertasTr({ data }) {
  console.log("Data");
  console.log(data);
  const { produto_principal, produto_adicional, produtos_adicionais } = data;
  const navigate = useNavigate();

  const token = useAuthToken();

  const repo = new OfertasComboRepository({ token });

  return (
    <>
      <Tr key={data._id}>
        <Td>
          <Text fontSize="xs">{data.nome}</Text>
        </Td>

        <Td>
          <Image src={produto_principal.imagens.at(0)} w="70px" />
        </Td>
        <Td>
          {produtos_adicionais.length === 1 ? (
            <Image src={produto_adicional.imagens.at(0)} w="70px" />
          ) : (
            <Flex direction="row">
              <Image src={produto_adicional.imagens.at(0)} w="70px" />
              <Flex w="10px" />
              <Box
                shadow="sm"
                p="1px"
                border={"1px"}
                borderRadius="8px"
                borderColor="primary.400"
                boxShadow={"0 3px 10px rgb(0 0 0 / 0.2)"}
                display={"inline-block"}
                position={"relative"}
              >
                {" "}
                <Image
                  src={produto_adicional.imagens.at(0)}
                  w="70px"
                  borderRadius="8px"
                  filter={"brightness(0.6)"}
                />
                <Text position="absolute" top="27px" left="25px" color="white">
                  +{produtos_adicionais.length - 2}
                </Text>
              </Box>
            </Flex>
          )}
        </Td>

        <Td>
          <Text fontSize="xs">{data.status}</Text>
        </Td>
        <Td>
          <Flex direction="column">
            <Text fontSize="xs">
              {new Date(data.data_inicio.replace("Z", "")).toLocaleDateString()}
            </Text>
            <Text fontSize="xs">
              {new Date(data.data_fim.replace("Z", "")).toLocaleDateString()}
            </Text>
          </Flex>
        </Td>
        <Td>
          <Flex direction="column">
            <Button
              variant={"customOutline"}
              disabled={
                data.status === "Cancelado" ||
                data.status === "Finalizado" ||
                data.status === "Em andamento"
              }
              onClick={() => {
                navigate(`/oferta-combo/${data._id}`);
              }}
              size="sm"
              mb="0.5"
            >
              Editar
            </Button>
            <Button
              variant={"customOutline"}
              disabled={
                data.status === "Cancelado" || data.status === "Finalizado"
              }
              onClick={async () => {
                const res = await repo.cancelar({
                  id: data._id,
                });

                if (res.status === 200) {
                  window.location.reload();
                }
              }}
              size="sm"
              mt="0.5"
            >
              Cancelar
            </Button>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
