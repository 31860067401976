import { buildHttpClient } from "../services/api";
import axios from "axios";

class NotificacoesRepository {
  constructor({ token }) {
    this.http = buildHttpClient(token);
  }

  consultar(params) {
    return this.http.post("/v2/notificacoes/consultar", params);
  }
}
export default NotificacoesRepository;
