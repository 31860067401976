import { ArrowForwardIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Text,
  Flex,
  Select,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Image,
  chakra,
  Stack,
  Button,
  Spacer,
} from "@chakra-ui/react";
import ProductsSelectorDialog from "../../../components/ProductsSelectorDialog";
import { useState } from "react";
import Formatters from "../../../utils/formatters";

const ProductPickerList = ({ onSelect, currentProducts }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <Flex
      maxW={"90vw"}
      margin={"0 auto"}
      backgroundColor={"white"}
      borderRadius={"6px"}
      shadow={"md"}
      marginTop={"40px"}
      direction={"column"}
      color={"black"}
    >
      <ProductsSelectorDialog
        isOpen={isDialogOpen}
        blockProducts={[]}
        currentProducts={currentProducts}
        onSelect={(p) => {
          onSelect(p);
          setIsDialogOpen(false);
        }}
        onClose={() => {
          setIsDialogOpen(false);
        }}
      />
      <Flex direction={"row"} padding={"30px"}>
        <Flex direction={"column"}>
          <Text fontWeight={"bold"} fontSize={"20px"}>
            Selecione um produto
          </Text>
          <Box height={"2px"} />
          <Text fontWeight={"500"} fontSize={"14px"}>
            Selecione um produto para analisar os métricas abaixo
          </Text>
        </Flex>
        <Spacer />

        <Button
          width={"240px"}
          marginLeft={"15px"}
          backgroundColor="primary.400"
          color={"white"}
          _hover={{ opacity: "0.8" }}
          onClick={() => {
            setIsDialogOpen(true);
          }}
        >
          Selecionar produto
          <Spacer />
          <ChevronRightIcon />
        </Button>
      </Flex>
      <TableContainer color={"black"} padding={"10px"}>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Produto
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Preço
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Variações
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Estoque
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Acessos
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {currentProducts?.map((e) => {
              const [variante] = e.variantes;

              return (
                <Tr key={e._id}>
                  <Td>
                    <Flex direction={"row"}>
                      <Image
                        src={e.imagens[0]}
                        width={"80px"}
                        height={"80px"}
                      />
                      <Box width={"10px"} />
                      <Flex direction={"column"}>
                        <Text
                          fontSize={"14px"}
                          fontWeight={"bold"}
                          wordBreak={"break-word"}
                          height={"80px"}
                          maxW={"250px"}
                          whiteSpace={"normal"}
                          noOfLines={4}
                        >
                          {e.titulo}
                        </Text>
                      </Flex>
                    </Flex>
                  </Td>
                  <Td fontSize={"14px"} fontWeight={"bold"}>
                    {variante.preco_promocional && (
                      <Flex direction={"column"}>
                        <Text>De {Formatters.money(variante.preco)}</Text>

                        <Text color={"#089D3E"}>
                          Por {Formatters.money(variante.preco_promocional)}
                        </Text>
                      </Flex>
                    )}
                    {!variante.preco_promocional && (
                      <Flex direction={"column"}>
                        <Text> {Formatters.money(variante.preco)}</Text>
                      </Flex>
                    )}
                  </Td>
                  <Td>
                    <Flex direction="column">
                      {!variante.propriedades && (
                        <Box
                          fontSize="xs"
                          p="2"
                          py="1"
                          backgroundColor="#f2f2f2"
                          borderRadius="8"
                          fontWeight="medium"
                          w="100px"
                        >
                          <Text fontSize="10px">{"TIPO"}</Text>
                          <Text color="primary.400" fontWeight="semibold">
                            Unico
                          </Text>
                        </Box>
                      )}
                      {Object.keys(variante.propriedades ?? {}).map((key) => {
                        return (
                          <>
                            <Box
                              fontSize="xs"
                              p="2"
                              py="1"
                              backgroundColor="#f2f2f2"
                              borderRadius="8"
                              fontWeight="medium"
                              w="100px"
                            >
                              <Text fontSize="10px"> {key.toUpperCase()}:</Text>
                              <Text color="primary.400" fontWeight="semibold">
                                {variante.propriedades[key]}
                              </Text>
                            </Box>
                            <Flex h="5px" />
                          </>
                        );
                      })}
                    </Flex>
                    {/* <Flex direction={"column"}>
                      <Stack
                        spacing={3}
                        backgroundColor={"#EFEFEF"}
                        padding={"8px"}
                        width={"150px"}
                        borderRadius={"4px"}
                      >
                        <Text
                          padding={"0 6px"}
                          lineHeight={"8px"}
                          fontSize={"12px"}
                        >
                          Cor:
                        </Text>
                        <Select
                          size="sm"
                          background={"#EFEFEF"}
                          variant={"unstyled"}
                          m="0px !important"
                          color={"#4300FF"}
                          padding={"0 6px"}
                          fontWeight={"500"}
                        >
                          <option value="option1">Branco</option>
                          <option value="option2">Preto</option>
                        </Select>
                      </Stack>
                      <Stack
                        marginTop={"10px"}
                        padding={"8px"}
                        width={"150px"}
                        spacing={3}
                        backgroundColor={"#EFEFEF"}
                        borderRadius={"4px"}
                      >
                        <Text
                          padding={"0 6px"}
                          lineHeight={"8px"}
                          fontSize={"12px"}
                        >
                          Armazenamento:
                        </Text>

                        <Select
                          size="sm"
                          background={"#EFEFEF"}
                          variant={"unstyled"}
                          m="0px !important"
                          color={"#4300FF"}
                          fontWeight={"500"}
                          padding={"0 6px"}
                        >
                          <option value="option1">128GB</option>
                          <option value="option2">256GB</option>
                        </Select>
                      </Stack>
                    </Flex> */}
                  </Td>
                  <Td>
                    <Text fontSize={"13px"} fontWeight={"bold"} maxW={"320px"}>
                      {variante.estoque}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize={"13px"} fontWeight={"bold"} maxW={"320px"}>
                      {variante.vendas}
                    </Text>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default ProductPickerList;
