import { Box, Flex, Heading, Text, Button, Avatar } from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import ProdutoBox from "./components/ProdutoBox";
import TimelineComponent from "../../components/TimeLineComponent";
import SolicitacaoBox from "./components/SolicitacaoBox";
import { MdCircle } from "react-icons/md";
import { useState, useEffect, useContext } from "react";
import DivergenciasRepository from "../../repositories/divergencias_repository";
import PedidosRepository from "../../repositories/pedidos_repository";
import EnvioRepository from "../../repositories/envio_repository";
import { useParams } from "react-router";
import useAuthToken from "../../hooks/useAuthToken";
import { useToast } from "@chakra-ui/react";
import { Form, useNavigate } from "react-router-dom";
import Formatters from "../../utils/formatters";
import { ChatContext } from "../../contexts/chatContext";
import StatusHeader from "./components/StatusHeader";
export default function DevolucaoPage() {
  const token = useAuthToken();
  const toast = useToast();
  const navigate = useNavigate();
  const dRepository = new DivergenciasRepository({ token });
  const pRepository = new PedidosRepository({ token });
  const [divergencia, setDivergencia] = useState(null);
  const [pedido, setPedido] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const params = useParams();
  const chatContext = useContext(ChatContext);

  useEffect(() => {
    dRepository.consultar({ pedido_id: params.id }).then((res) => {
      if (res.status === 200) {
        setDivergencia(res.data[0]);
        setLoaded(true);
      }
    });

    pRepository.consultar({ pedido_id: params.id }).then((res) => {
      if (res.status === 200) {
        setPedido(res.data);
      }
    });
  }, []);

  const iniciarDisputa = () => {
    dRepository.iniciarDisputa(divergencia).then((res) => {
      toast({
        title: "Disputa iniciada com sucesso",
        status: "success",
      });
      navigate("/");
    });
  };

  const aprovarDevolucao = () => {
    dRepository.aprovarDevolucao(divergencia).then((res) => {
      toast({
        title: "Devolução aprovada com sucesso",
        status: "success",
      });
      navigate("/");
    });
  };

  const aprovarReembolso = async () => {
    dRepository.aprovarReembolso(divergencia).then((res) => {
      toast({
        title: "Reembolso aprovada com sucesso",
        status: "success",
      });
      navigate("/");
    });
  };

  return (
    <PageScaffold showSideBar={true}>
      {loaded && (
        <Flex w={"100%"} direction="row">
          <Flex direction="column" width="70%">
            <StatusHeader
              pedido={pedido}
              divergencia={divergencia}
              callback={(action) => {
                if (action === "iniciar_disputa") {
                  iniciarDisputa();
                }

                if (action === "aprovar_reembolso") {
                  aprovarReembolso();
                }

                if (action === "aprovar_devolucao") {
                  aprovarDevolucao();
                }
              }}
            />
            <ProdutoBox pedido={pedido} />
            <SolicitacaoBox divergencia={divergencia} pedido={pedido} />
            <Flex
              boxShadow="sm"
              w="full"
              mt="10px"
              borderRadius="8px"
              p="5"
              bgColor="white"
              justify={"space-between"}
              align="center"
            >
              <Flex align="center">
                <Avatar
                  name={divergencia.usuario_nome}
                  src={divergencia.usuario_imagem}
                  background={"primary.400"}
                  color={"white"}
                  size="sm"
                ></Avatar>
                <Text fontWeight="bold" fontSize="sm" ml="2">
                  {divergencia.usuario_nome}
                </Text>
              </Flex>
              <Button
                onClick={() => {
                  console.log(chatContext);
                  chatContext.openChatWith({
                    usuario_id: divergencia?.usuario_id,
                  });
                }}
                backgroundColor="#693FF3"
                color="white"
                size="sm"
              >
                Conversar com comprador
              </Button>
            </Flex>
          </Flex>
          <Flex w="30%" h="fit-content" p="4" direction="column">
            <Text margin="0 auto" fontWeight="medium">
              Histórico da solicitação
            </Text>
            <Flex direction="column" pl="8" mt="4">
              {divergencia.eventos.toReversed().map((row, index, arr) => {
                return (
                  <TimelineComponent
                    // logo={row.logo}
                    title={row.titulo}
                    description={row.descricao}
                    date={row.data}
                    color={"#7E5EFA"}
                    index={index}
                    arrLength={arr.length}
                  />
                );
              })}
            </Flex>
          </Flex>
        </Flex>
      )}
    </PageScaffold>
  );
}
