import {
  Box,
  Flex,
  Text,
  Button,
  Avatar,
  Center,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import { MdChat } from "react-icons/md";
import { Link } from "react-router-dom";
import Formatters from "../../../utils/formatters";
import useAuthToken from "../../../hooks/useAuthToken";
import EnvioRepository from "../../../repositories/envio_repository";

import CorreiosImg from "../../../assets/correios.png";
import { ShipImage } from "../../DetalhesPedido/components/ShipCard";

export default function TabEnvios({
  data,
  isLoading,
  onLoadMore,
  openChatWith,
}) {
  const token = useAuthToken();
  const envioRepo = new EnvioRepository({ token });
  const toast = useToast();

  if (isLoading) {
    return (
      <Center p={"20px 0px"}>
        <Spinner />
        <Box w={"10px"} />
        <Text>Carregando...</Text>
      </Center>
    );
  }

  if (data.length === 0) {
    return (
      <Center p={"20px 0px"}>
        <Text>Nenhum pedido encontrado</Text>
      </Center>
    );
  }

  const handleEtiquta = async (pedido) => {
    toast({
      title: "Gerando etiqueta",
      description: "Aguarde enquanto geramos a etiqueta",
      status: "info",
      duration: 4000,
      isClosable: true,
    });

    const response = await envioRepo.verEtiqueta({
      pedido_envio: pedido._id,
    });

    if (response.status === 200) {
      if (response.data.status === "OK") {
        window.open(response.data.content.label_url, "_blank");
      }
    } else {
      if (
        response.data.messages.at(0).key ===
        "shipmentOrder.label.unknown.order.number.order.volume.number"
      ) {
        const novoEnvioResponse = await envioRepo.novoEnvio({
          pedido_id: pedido._id,
        });

        if (novoEnvioResponse.status === 200) {
          handleEtiquta(pedido);
          return;
        } else {
          toast({
            title: "Erro ao gerar etiqueta",
            description:
              novoEnvioResponse.data?.messages?.at(0)?.text ||
              novoEnvioResponse.data.error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
      }

      toast({
        title: "Erro ao gerar etiqueta",
        description: response.data.messages.at(0).text,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Flex direction={"column"}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Detalhes</Th>
              <Th>Preço Total</Th>
              <Th>Status</Th>
              <Th>Prazo</Th>
              <Th>Ação</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {data.map((pedido) => {
              return (
                <Tr key={pedido._id}>
                  <Td>
                    <Flex direction={"column"}>
                      <Flex padding={"7px 0px"} alignItems={"center"}>
                        <Avatar
                          size="xs"
                          src={pedido.usuario_imagem}
                          name={pedido.usuario_nome}
                          background="#232544"
                          color={"white"}
                        />
                        <Box w={"8px"} />
                        <Text>{pedido.usuario_nome}</Text>
                        <Box w={"8px"} />

                        <MdChat
                          color={"#20BEAE"}
                          onClick={() => {
                            openChatWith(pedido.usuario_id);
                          }}
                        />
                      </Flex>

                      <Text fontSize={"sm"}>
                        Pedido #{pedido.numero_pedido || "N/A"}
                      </Text>
                      {/* <Text fontSize={"sm"}>
                        PLP: {pedido.transacao_id * 12}
                      </Text> */}
                      <Text fontSize={"sm"}>
                        {new Date(pedido.createAt).toLocaleString()}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      <Text fontSize={"sm"}>
                        {Formatters.money(pedido.custo_total)}
                      </Text>
                      <Text fontSize={"sm"}>
                        {Formatters.paymentMethod(
                          pedido.metodo_pagamento,
                          pedido.pagamento.parcelas
                        )}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      {/* <Text fontSize={"sm"}>
                        {pedido.eventos.at(-1).tipo.replace("_", " ")}
                      </Text> */}
                      <Text
                        as={"span"}
                        maxWidth={"100px"}
                        noOfLines={3}
                        fontSize={"xs"}
                        whiteSpace={"pre-line"}
                      >
                        {pedido.eventos.at(-1).titulo}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      <ShipImage data={pedido} />
                      <Box height={"5px"} />

                      <Text fontSize={"sm"}>
                        {pedido.envio?.delivery_method_name}
                      </Text>
                      <Text fontSize={"sm"}>
                        {new Date(
                          pedido.prazo_maximo_envio
                        ).toLocaleDateString()}
                      </Text>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      <Link to={`/pedido/${pedido._id}`}>
                        <Button w={"240px"} size={"sm"}>
                          Ver detalhes
                        </Button>
                      </Link>
                      <Box height={"5px"} />
                      {pedido.status_pedido === "disputa" && (
                        <Link to={`/devolucao/${pedido._id}`}>
                          <Button w={"240px"} size={"sm"}>
                            Responder
                          </Button>
                        </Link>
                      )}
                      {pedido.status_pedido !== "disputa" && (
                        <Button
                          w={"240px"}
                          disabled={pedido.status_pedido !== "pago"}
                          onClick={() => {
                            if (pedido.status_pedido === "pago") {
                              handleEtiquta(pedido);
                            }
                          }}
                          size={"sm"}
                        >
                          Imprimir etiqueta de envio
                        </Button>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex align={"center"} justifyContent={"center"}>
        {Array.isArray(data) && data.length >= 15 && (
          <Button onClick={onLoadMore} variant={"link"} padding={"10px 0px"}>
            Carregar mais
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
