import {
  Box,
  Heading,
  Text,
  Flex,
  Card,
  CardBody,
  Button,
} from "@chakra-ui/react";

export default function TipoLojaComponente({
  tipoLoja,
  setTipoLoja,
  onCompleted,
}) {
  return (
    <Box
      style={{
        zoom: "1.1",
      }}
      width="full"
      my="5"
      mt="20"
      align={"center"}
    >
      <Box w={"450px"} align="start">
        <Text
          fontSize={"18px"}
          fontWeight={"500"}
          w={"400px"}
          mb="5"
          textAlign={"start"}
        >
          Selecione uma das opções abaixo que melhor atende o seu perfil
        </Text>
      </Box>
      <Flex
        justifyContent={"center"}
        w={"450px"}
        align="center"
        direction="column"
      >
        <Card
          shadow={"none"}
          borderRadius={"10px"}
          maxW="md"
          backgroundColor={"#F2F2F2"}
          border={
            tipoLoja === "pessoa_fisica" ? "3px solid #683FF3" : undefined
          }
          style={{ cursor: "pointer" }}
          onClick={() => {
            setTipoLoja("pessoa_fisica");
          }}
          direction={"row"}
          alignItems={"center"}
        >
          <Flex
            ml={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
            w={"40px"}
            h={"40px"}
            borderRadius={"50%"}
            background={"white"}
          >
            <Box
              visibility={tipoLoja === "pessoa_fisica" ? "visible" : "hidden"}
              w={"27px"}
              h={"27px"}
              borderRadius={"50%"}
              bgGradient="linear(to-b, #683FF3, #4C378F)"
            ></Box>
          </Flex>

          <CardBody>
            <Heading fontSize={"18px"} align="start">
              CPF
            </Heading>
            <Text
              fontSize={"14px"}
              fontWeight={"500"}
              align="start"
              noOfLines={[3, 4]}
            >
              Ideal para você que está começando nas vendas online, faturamento
              até R$ 6.000,00 por mês.
            </Text>
          </CardBody>
        </Card>
        <Flex h={"15px"} />
        <Card
          borderRadius={"10px"}
          maxW="xl"
          shadow={"none"}
          backgroundColor={"#F2F2F2"}
          border={
            tipoLoja === "pessoa_juridica" ? "3px solid #683FF3" : undefined
          }
          style={{ cursor: "pointer" }}
          onClick={() => {
            setTipoLoja("pessoa_juridica");
          }}
          direction={"row"}
          alignItems={"center"}
        >
          <Flex
            ml={"10px"}
            justifyContent={"center"}
            alignItems={"center"}
            w={"40px"}
            h={"40px"}
            borderRadius={"50%"}
            background={"white"}
          >
            <Box
              visibility={tipoLoja === "pessoa_juridica" ? "visible" : "hidden"}
              w={"27px"}
              h={"27px"}
              borderRadius={"50%"}
              bgGradient="linear(to-b, #683FF3, #4C378F)"
            ></Box>
          </Flex>
          <CardBody variant="clickable">
            <Heading align="start" fontSize={"18px"}>
              CNPJ
            </Heading>
            <Text
              align="start"
              fontSize={"14px"}
              fontWeight={"500"}
              noOfLines={[3, 4]}
            >
              Ideal para quem tem um grande volume de vendas e precisa de um
              controle de estoque.
            </Text>
          </CardBody>
        </Card>
      </Flex>

      <Button
        onClick={onCompleted}
        bg={"#683FF3"}
        color={"white"}
        mt={"10"}
        w={"450px"}
        borderRadius={"8px"}
        h={"45px"}
        fontWeight={"700"}
        _hover={{
          bg: "#4C378F",
        }}
      >
        COMEÇAR
      </Button>
    </Box>
  );
}
