import { buildHttpClient } from "../services/api";
import axios from "axios";

class ChatRepository {
  constructor({ token }) {
    this.http = buildHttpClient(token);

    this.http.interceptors.request.use(
      function (config) {
        if (!config.headers.token) {
          throw new axios.Cancel("");
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  consultar(params) {
    return this.http.post("/v2/chats/consultar", params);
  }

  novo(params) {
    return this.http.post("/v2/chats/novo", params);
  }

  enviar(params) {
    return this.http.post("/v2/chats/enviar", params);
  }

  bloquear(params) {
    return this.http.post("/v2/chats/bloquear", params);
  }

  desbloquear(params) {
    return this.http.post("/v2/chats/desbloquear", params);
  }
}

export default ChatRepository;
