import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  Textarea,
  Spacer,
  Select,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";

import ProdutosPrincipaisRaspadinha from "./components/ProdutosPrincipaisRaspadinha";
import ListaCuponsRaspadinha from "./components/ListaCuponsRaspadinha";
import { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../contexts/authContext";
import RaspadinhasRepository from "../../repositories/raspadinhas_repository";
import ProductsSelectorDialog from "../../components/ProductsSelectorDialog";
import { useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router-dom";
import PremiosRaspadinha from "./components/PremiosRaspadinha";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import CupomSelectorDialog from "../../components/CupomSelectorDialog";

export default function NovaRaspadinhaPage() {
  const estabelecimento = useEstabelecimento();

  const context = useContext(AuthContext);

  const repo = new RaspadinhasRepository({ token: context.currentToken });

  const [viewMode, setViewMode] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCupomPickerOpen, setIsCupomPickerOpen] = useState(false);
  const [isPremioPickerOpen, setIsPremioPickerOpen] = useState(false);
  const navigate = useNavigate();

  const [raspadinhaState, setRaspadinhaState] = useState({
    produtos_participantes: [],
    nome: "",
    produtos_sorteados: [],
    cupons_sorteados: [],
    nivel_dificuldade: 50,
    condicao_participar: "livre",
    alcance: "nacional",
    alcance_km: 0,
    quantidade_raspadinhas_por_usuario: 0,
  });

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      // loadRelampago(params.id);
      // setIsEditMode(true);
      console.log(params);
      setViewMode(true);

      repo.consultar({ _id: params.id, include_subdocs: true }).then((res) => {
        console.log(res.data);
        setRaspadinhaState(res.data);
      });
    }
  }, []);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  useEffect(() => {
    // if (estabelecimento.tipo_cadastro === "pessoa_fisica") {
    //   navigate("/");
    // }
  }, [estabelecimento]);

  if (!estabelecimento) return <div />;
  return (
    <>
      <PageScaffold showSideBar={true}>
        <Flex w="100%" direction="row">
          <Flex w="80%" direction="column">
            <Flex direction={"column"}>
              <Box
                mt={"25px"}
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Heading fontSize="2xl">Raspadinha</Heading>
                <Spacer h="1" />

                <Text fontWeight="500">
                  Preencha os campos abaixo com as informações do seu sorteio.
                </Text>
                {/* 
                <Box h="40" w="40">
                  {!image.filesContent || image.filesContent.length === 0 ? (
                    <AddImageButtonComponent onClick={updateImage} />
                  ) : (
                    <Image
                      h="40"
                      w="40"
                      src={image.filesContent[0].content}
                      borderRadius={8}
                      onClick={updateImage}
                      objectFit="cover"
                    />
                  )}
                </Box> */}

                <Flex direction="column" maxW="70%" mt="5">
                  <Text fontWeight="bold">Título</Text>
                  <Input
                    variant="customGrey"
                    size="md"
                    disabled={viewMode}
                    mb="2"
                    value={raspadinhaState.nome}
                    onChange={(e) => {
                      setRaspadinhaState({
                        ...raspadinhaState,
                        nome: e.target.value,
                      });
                    }}
                  />
                </Flex>

                {/* <Flex direction="column" maxW="70%">
                  <Text fontWeight="semibold">Regras do sorteio</Text>
                  <Textarea
                    variant="filled"
                    size="lg"
                    mb="2"
                    maxLength="300"
                    ref={regrasRef}
                  />
                </Flex> */}
              </Box>
            </Flex>
            <PremiosRaspadinha
              disabled={viewMode}
              onChange={(p) => {
                setRaspadinhaState({
                  ...raspadinhaState,
                  produtos_sorteados: p,
                });
              }}
              products={raspadinhaState.produtos_sorteados}
              openProductList={() => {
                setIsPremioPickerOpen(true);
              }}
              onRemove={(id) => {
                setRaspadinhaState({
                  ...raspadinhaState,
                  produtos_sorteados: raspadinhaState.produtos_sorteados.filter(
                    (p) => p._id !== id
                  ),
                });
              }}
            />
            <Flex direction="column" mt="5">
              <Box
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
                minH="120"
              >
                <Heading fontSize="md" noOfLines="1">
                  Condição de aptidão
                </Heading>

                <Text fontWeight={500} fontSize="14px" w="70%" noOfLines="2">
                  Selecione a disponibilidade para este sorteio.
                </Text>
                <Spacer h="2" />
                <Select
                  disabled={viewMode}
                  variant="filled"
                  size="md"
                  fontWeight={600}
                  defaultValue={"cliente"}
                  w="400px"
                  value={raspadinhaState.condicao_participar}
                  onChange={(e) => {
                    if (e.target.value === "livre") {
                      setRaspadinhaState({
                        ...raspadinhaState,
                        condicao_participar: e.target.value,
                        produtos_participantes: [],
                      });
                      return;
                    }

                    setRaspadinhaState({
                      ...raspadinhaState,
                      condicao_participar: e.target.value,
                    });
                  }}
                >
                  {" "}
                  <option value="livre">Livre</option>
                  <option value="seguidores">Seguidores</option>
                  <option value="clientes">Clientes</option>
                </Select>
              </Box>
            </Flex>
            {raspadinhaState.condicao_participar !== "livre" && (
              <ProdutosPrincipaisRaspadinha
                disabled={viewMode}
                products={raspadinhaState.produtos_participantes}
                openProductList={onOpen}
                onRemove={(p) => {
                  setRaspadinhaState({
                    ...raspadinhaState,
                    produtos_participantes:
                      raspadinhaState.produtos_participantes.filter(
                        (item) => item._id !== p._id
                      ),
                  });
                }}
              />
            )}

            <ListaCuponsRaspadinha
              disabled={viewMode}
              cupons={raspadinhaState.cupons_sorteados}
              onOpen={() => {
                setIsCupomPickerOpen(true);
              }}
            />
            <Flex direction="column" mt="5">
              <Box
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
                minH="120"
              >
                <Heading fontSize="md" noOfLines="1">
                  Nível de dificuldade
                </Heading>
                <Spacer h="1" />
                <Text fontWeight={500} fontSize="14px" w="80%" noOfLines="2">
                  Seleciona o valor referente a probabilidade de um usuário ser
                  sorteado na raspadinha - definido{" "}
                  {raspadinhaState.nivel_dificuldade}%.
                </Text>
                <Spacer h="2" />

                <Slider
                  disabled={viewMode}
                  value={raspadinhaState.nivel_dificuldade}
                  min={10}
                  max={100}
                  step={10}
                  w="400px"
                  onChange={(value) => {
                    setRaspadinhaState({
                      ...raspadinhaState,
                      nivel_dificuldade: value,
                    });
                  }}
                >
                  <SliderTrack bg="gray.300">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg="primary.400" />
                  </SliderTrack>
                  <SliderThumb boxSize={6} />
                </Slider>
              </Box>
            </Flex>
            <Flex direction="column">
              <Box
                boxShadow="sm"
                w="full"
                borderRadius="8px"
                p="5"
                bgColor="white"
                minH="120"
                mt="5"
              >
                <Heading fontSize="md" noOfLines="1">
                  Alcance
                </Heading>
                <Spacer h="1" />

                <Select
                  defaultValue={"local"}
                  disabled={viewMode}
                  variant="filled"
                  size="md"
                  fontWeight={600}
                  w="400px"
                  mt="2"
                  value={raspadinhaState.alcance}
                  onChange={(e) => {
                    setRaspadinhaState({
                      ...raspadinhaState,
                      alcance: e.target.value,
                    });
                  }}
                >
                  <option value="local">Local</option>
                  <option value="nacional">Nacional</option>
                </Select>
                <Spacer h="1" />

                {raspadinhaState.alcance === "local" && (
                  <Flex direction="column">
                    <Text
                      fontWeight={500}
                      fontSize="14px"
                      w="70%"
                      noOfLines="2"
                    >
                      Sorteio para realização próxima a uma raio de{" "}
                      {raspadinhaState.alcance_km}KM.
                    </Text>
                    <Spacer h="1" />

                    <Slider
                      disabled={viewMode}
                      defaultValue={10}
                      min={10}
                      max={60}
                      step={10}
                      w="400px"
                      value={raspadinhaState.alcance_km}
                      onChange={(value) => {
                        setRaspadinhaState({
                          ...raspadinhaState,
                          alcance_km: value,
                        });
                      }}
                    >
                      <SliderTrack bg="gray.300">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg="primary.400" />
                      </SliderTrack>
                      <SliderThumb boxSize={6} />
                    </Slider>
                  </Flex>
                )}
              </Box>
            </Flex>

            <Box
              boxShadow="sm"
              w="full"
              borderRadius="8px"
              p="5"
              bgColor="white"
              mt="5"
            >
              <Heading fontSize="md">Quantidade de chances</Heading>
              <Text fontWeight={500} fontSize="14px" noOfLines="2">
                Digite abaixo a quantidade de vezes que os clientes podem
                participar da rapspadinha.
              </Text>
              <Input
                disabled={viewMode}
                fontWeight={600}
                variant={"filled"}
                placeholder="10"
                w="140px"
                mt="5"
                value={raspadinhaState.quantidade_raspadinhas_por_usuario}
                onChange={(e) => {
                  setRaspadinhaState({
                    ...raspadinhaState,
                    quantidade_raspadinhas_por_usuario:
                      Number(e.target.value) || 1,
                  });
                }}
              />
            </Box>

            <Box
              boxShadow="sm"
              w="full"
              mt="5"
              borderRadius="8px"
              p="5"
              bgColor="white"
              align="end"
            >
              <Button
                ml="2"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {" "}
                {viewMode ? "Voltar" : "Cancelar"}
              </Button>
              <Button
                disabled={viewMode}
                ml="2"
                bgColor="primary.400"
                color="white"
                onClick={async () => {
                  const res = await repo.cadastrar(raspadinhaState);

                  navigate(-1);
                }}
              >
                Publicar
              </Button>
            </Box>
          </Flex>
        </Flex>
      </PageScaffold>
      <CupomSelectorDialog
        currentCupons={raspadinhaState.cupons_sorteados}
        isOpen={isCupomPickerOpen}
        onClose={() => {
          setIsCupomPickerOpen(false);
        }}
        onSelect={(c) => {
          setIsCupomPickerOpen(false);
          setRaspadinhaState({
            ...raspadinhaState,
            cupons_sorteados: c,
          });
        }}
      />
      <ProductsSelectorDialog
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        onSelect={(p) => {
          setRaspadinhaState({
            ...raspadinhaState,
            produtos_participantes: p,
          });
          onClose();
        }}
      />
      <ProductsSelectorDialog
        isOpen={isPremioPickerOpen}
        currentProducts={raspadinhaState.produtos_sorteados}
        onClose={() => {
          setIsPremioPickerOpen(false);
        }}
        onSelect={(p) => {
          setRaspadinhaState({
            ...raspadinhaState,
            produtos_sorteados: p,
          });

          setIsPremioPickerOpen(false);
        }}
      />
    </>
  );
}
