import { Flex, Select, Text, Checkbox, Image } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { useState } from "react";
import CorreiosImg from "../../../assets/correios.png";

export default function TabEnvios({ pedidos }) {
  const [currentFilter, setCurrentFilter] = useState("all");

  const filteredPedidos =
    pedidos &&
    pedidos.filter((pedido) => {
      if (currentFilter === "all") {
        return true;
      }

      if (currentFilter === "correios") {
        return (
          pedido.envio.logistic_provider_name.toLowerCase().trim() ===
          "correios"
        );
      }

      if (currentFilter === "jadlog") {
        return (
          pedido.envio.logistic_provider_name.toLowerCase().trim() === "jadlog"
        );
      }
    });

  return (
    <Flex direction={"column"}>
      <Flex direction="row" my="2" align="center">
        <Text mr="5" fontWeight="700">
          Filtrar pedidos por:
        </Text>
        <Select
          variant={"filled"}
          fontWeight={500}
          value={currentFilter}
          w="200px"
          onChange={(e) => {
            setCurrentFilter(e.target.value);
          }}
        >
          <option value="all">Todos</option>
          <option value="correios">Correios</option>
          <option value="jadlog">JadLog</option>
        </Select>
      </Flex>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                <Checkbox />
              </Th>

              <Th>Produtos</Th>
              <Th>ID do pedido</Th>
              <Th>Comprador</Th>
              <Th>Opção de envio</Th>
              <Th>Status do pedido</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {filteredPedidos &&
              filteredPedidos.map((pedido) => {
                return (
                  <Tr key={pedido._id}>
                    <Td>
                      <Checkbox />
                    </Td>
                    <Td>
                      <Image
                        h="70px"
                        objectFit="contain"
                        src={pedido.itens.at(0).produto_img}
                      />
                    </Td>
                    <Td>
                      <Text fontSize={"sm"}>#{pedido.numero_pedido}</Text>
                    </Td>
                    <Td>
                      <Text fontSize={"sm"}>{pedido.usuario_nome}</Text>
                    </Td>
                    <Td>
                      <Image
                        w={"80px"}
                        h={"30px"}
                        objectFit={"contain"}
                        src={CorreiosImg}
                        mx="5"
                      />
                    </Td>
                    <Td>{pedido.eventos.at(-1).descricao}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
