import {
  ArrowForwardIcon,
  ChevronRightIcon,
  TriangleDownIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Text,
  Flex,
  Select,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Image,
  chakra,
  Stack,
  Button,
  Spacer,
  Input,
  Switch,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Divider,
} from "@chakra-ui/react";

import DeleteIconSrc from "../../assets/delete_black_24dp.svg";
import CorreiosSrc from "../../assets/correios.png";
import JadLogSrc from "../../assets/jadlog.png";
import { Fragment, useEffect } from "react";
import { useState } from "react";
import Formatters from "../../utils/formatters";
import { useRef } from "react";

const DeshopFlashProductList = ({ produtos, onRemove, onChange }) => {
  return (
    <Flex
      w={"100%"}
      margin={"0 auto"}
      backgroundColor={"white"}
      borderRadius={"6px"}
      shadow={"md"}
      direction={"column"}
      color={"black"}
    >
      <TableContainer color={"black"} padding={"10px"}>
        {produtos.map((produto, index) => (
          <ProdutoComponente
            key={produto.variante._id}
            produto={{
              ...produto,
              estoque: produto?.estoque || null,
              limite: produto?.limite || null,
              desconto_porcentagem: produto?.desconto_porcentagem || null,
              desconto_reais: produto?.desconto_reais || null,
              produto_id: produto?._id,
              variante_id: produto?.variante?._id,
            }}
            showTableHeader={index === 0}
            onRemove={onRemove}
            onChange={(produto) => {
              onChange(produto);
            }}
          />
        ))}
      </TableContainer>
    </Flex>
  );
};

const ProdutoComponente = ({
  produto,
  showTableHeader = false,
  onRemove,
  onChange,
}) => {
  const { preco, preco_promocional } = produto.variante;

  const [precoOriginal, setPrecoOriginal] = useState(preco);
  const [precoPromocional, setPrecoPromocional] = useState(null);

  const suportaCorreios =
    produto.dados_frete.envios_disponiveis.includes(1) ||
    produto.dados_frete.envios_disponiveis.includes(2);
  const suportaJadLog = false; // TODO: COLOCAR O ID DA JADLOG AQUI

  const [descontoReais, setDescontoReais] = useState(produto.desconto_reais);
  const [descontoPorcentagem, setDescontoPorcentagem] = useState(
    produto.desconto_porcentagem
  );
  const [limiteEstoque, setLimiteEstoque] = useState(produto?.estoque);
  const [limitePorCliente, setLimitePorCliente] = useState(produto?.limite);
  const [ativo, setAtivo] = useState(produto?.ativo ?? true);

  useEffect(() => {
    if (descontoPorcentagem) {
      const desconto =
        (preco_promocional || preco) * (descontoPorcentagem / 100);

      setPrecoPromocional((preco_promocional || preco) - desconto);
      setDescontoReais(null);
    }

    if (descontoReais) {
      const desconto = (preco_promocional || preco) - descontoReais;
      setPrecoPromocional(desconto);
      setDescontoPorcentagem(null);
    }

    if (!descontoPorcentagem && !descontoReais) {
      setDescontoPorcentagem(null);
      setDescontoReais(null);
      setPrecoPromocional(null);
    }

    produto.desconto_porcentagem = descontoPorcentagem;
    produto.desconto_reais = descontoReais;
    produto.estoque = limiteEstoque;
    produto.limite = limitePorCliente;
    produto.ativo = ativo;

    onChange(produto);
  }, [
    descontoPorcentagem,
    descontoReais,
    preco,
    preco_promocional,
    limiteEstoque,
    limitePorCliente,
    ativo,
  ]);

  return (
    <Fragment>
      <Table variant="simple" key={produto._id}>
        {showTableHeader && (
          <Thead>
            <Tr>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Produto
              </Th>

              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Variação
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Estoque
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Modo de envio
              </Th>
              <Th color={"black"} fontSize={"14px"} textTransform={"none"}>
                Status
              </Th>
            </Tr>
          </Thead>
        )}
        <Tbody>
          <Tr>
            <Td w={"30vw"}>
              <Flex direction={"row"}>
                <Image
                  src={produto.variante.imagem ?? produto.imagens.at(0)}
                  width={"80px"}
                  height={"80px"}
                />
                <Box width={"10px"} />
                <Flex direction={"column"}>
                  <Text
                    fontSize={"14px"}
                    fontWeight={"bold"}
                    wordBreak={"break-word"}
                    height={"80px"}
                    width={"400px"}
                    whiteSpace={"normal"}
                    noOfLines={4}
                  >
                    {produto.titulo}
                  </Text>
                </Flex>
              </Flex>
            </Td>

            <Td w={"20vw"}>
              <Flex direction={"row"}>
                {!produto.variante.propriedades && (
                  <Box
                    fontSize="xs"
                    p="2"
                    py="1"
                    backgroundColor="#f2f2f2"
                    borderRadius="8"
                    fontWeight="medium"
                    w="100px"
                  >
                    <Text fontSize="10px">{"TIPO"}</Text>
                    <Text color="primary.400" fontWeight="semibold">
                      Unico
                    </Text>
                  </Box>
                )}
                {Object.keys(produto.variante.propriedades ?? {}).map((key) => {
                  return (
                    <>
                      <Box
                        ml={"7px"}
                        fontSize="xs"
                        p="2"
                        py="1"
                        backgroundColor="#f2f2f2"
                        borderRadius="8"
                        fontWeight="medium"
                        w="100px"
                      >
                        <Text fontSize="10px"> {key.toUpperCase()}:</Text>
                        <Text color="primary.400" fontWeight="semibold">
                          {produto.variante.propriedades[key]}
                        </Text>
                      </Box>
                      <Flex h="5px" />
                    </>
                  );
                })}
              </Flex>
            </Td>
            <Td>
              <Text fontSize={"13px"} fontWeight={"bold"} maxW={"320px"}>
                {produto.variante.estoque}
              </Text>
            </Td>
            <Td>
              <Flex direction={"column"}>
                {suportaCorreios && (
                  <Image
                    src={CorreiosSrc}
                    width={"100px"}
                    paddingBottom={"15px"}
                  />
                )}

                {suportaJadLog && (
                  <Image
                    src={JadLogSrc}
                    width={"85px"}
                    paddingBottom={"15px"}
                  />
                )}
              </Flex>
            </Td>
            <Td>
              <Image
                cursor={"pointer"}
                src={DeleteIconSrc}
                opacity={"0.67"}
                onClick={() => {
                  onRemove(produto);
                }}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>
      <Flex
        w={"98.5%"}
        margin={"0 auto"}
        backgroundColor={"#EFEFEF"}
        padding={"10px"}
        borderRadius={"4px"}
        direction={"row"}
      >
        <Flex direction={"column"} maxWidth={"400px"} padding={"0 10px"}>
          <Text fontWeight={"bold"} fontSize={"12px"}>
            Valor da oferta
          </Text>
          <Box height={"20px"} />
          <Flex direction={"row"} alignItems={"center"}>
            <InputGroup size="md">
              <Input
                padding={"0 10px"}
                maxWidth={"140px"}
                paddingLeft={"50px"}
                backgroundColor={"white"}
                fontWeight={"bold"}
                value={descontoReais}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return;
                  if (e.target.value < 0) return;
                  if (e.target.value > (preco_promocional || preco)) return;

                  setDescontoReais(e.target.value);
                  setDescontoPorcentagem(null);
                }}
              />
              <InputLeftElement
                children={
                  <Flex>
                    <Text paddingLeft={"25px"} fontWeight={"600"} color="GREY">
                      R$
                    </Text>
                    <Text padding={"0 5px"} color="GREY" fontSize={"17px"}>
                      |
                    </Text>
                  </Flex>
                }
              />
            </InputGroup>

            <Text padding={"0 10px"} fontWeight={"bold"}>
              ou
            </Text>

            <InputGroup size="md">
              <InputRightElement
                children={
                  <Flex height={"33px"} alignItems={"center"}>
                    <Text padding={"0 5px"} color="GREY" fontSize={"17px"}>
                      |
                    </Text>
                    <Text
                      fontWeight={"700"}
                      paddingRight={"30px"}
                      fontSize={"15px"}
                      color="GREY"
                    >
                      %Off
                    </Text>
                  </Flex>
                }
              />
              <Input
                padding={"0 10px"}
                maxWidth={"140px"}
                paddingRight={"55px"}
                backgroundColor={"white"}
                fontWeight={"bold"}
                value={descontoPorcentagem}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return;
                  if (Number(e.target.value) > 99) return;
                  if (Number(e.target.value) < 0) return;

                  setDescontoPorcentagem(e.target.value);
                  setDescontoReais(null);
                }}
              />
            </InputGroup>
          </Flex>
        </Flex>
        <Spacer />
        <Spacer />

        <Flex direction={"column"} maxWidth={"300px"} padding={"0 16px"}>
          <Text fontWeight={"bold"} fontSize={"12px"}>
            Valor total
          </Text>
          <Box height={"20px"} />

          <Flex direction={"column"} fontSize={"16px"} fontWeight={"bold"}>
            {(precoPromocional || preco_promocional) && (
              <>
                <Text>De {Formatters.money(precoOriginal)}</Text>

                <Text color={"#089D3E"}>
                  Por {Formatters.money(precoPromocional || preco_promocional)}
                </Text>

                {preco_promocional && precoPromocional && (
                  <Text fontSize={"10px"} opacity={"0.5"}>
                    Valor atual:
                    {" " + Formatters.money(preco_promocional)}
                  </Text>
                )}
              </>
            )}
            {!(precoPromocional || preco_promocional) && (
              <>
                <Text> {Formatters.money(precoOriginal)}</Text>
              </>
            )}
          </Flex>
        </Flex>
        <Spacer />
        <Flex direction={"row"} width={"300px"}>
          <Flex direction={"column"} padding={"0 10px"}>
            <Text fontWeight={"bold"} fontSize={"12px"}>
              Estoque da promoção
            </Text>
            <Box height={"20px"} />

            <Flex direction={"column"} fontSize={"14px"} fontWeight={"bold"}>
              <Input
                width={"130px"}
                backgroundColor={"white"}
                value={limiteEstoque}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return;
                  if (Number(e.target.value) < 0) return;

                  setLimiteEstoque(e.target.value);
                }}
              ></Input>
              <Box height={"6px"} />
              <Text
                width={"130px"}
                fontWeight={"500"}
                fontSize={"12px"}
                textAlign={"center"}
              >
                Limite opcional
              </Text>
            </Flex>
          </Flex>

          <Flex direction={"column"} padding={"0 10px"}>
            <Text fontWeight={"bold"} fontSize={"12px"}>
              Limite por cliente
            </Text>
            <Box height={"20px"} />

            <Flex direction={"column"} fontSize={"14px"} fontWeight={"bold"}>
              <Input
                width={"130px"}
                backgroundColor={"white"}
                value={limitePorCliente}
                onChange={(e) => {
                  if (isNaN(Number(e.target.value))) return;
                  if (Number(e.target.value) < 0) return;

                  setLimitePorCliente(e.target.value);
                }}
              ></Input>
              <Box height={"6px"} />
              <Text
                width={"130px"}
                fontWeight={"500"}
                fontSize={"12px"}
                textAlign={"center"}
              >
                Limite opcional
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Spacer />

        <Flex alignItems={"center"}>
          <Switch
            defaultChecked={ativo}
            defaultValue={ativo}
            value={ativo}
            onChange={(e) => {
              setAtivo(e.target.checked);
            }}
          />
        </Flex>
        <Box width={"30px"} />
      </Flex>
    </Fragment>
  );
};

export default DeshopFlashProductList;
