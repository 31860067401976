import useAuthToken from "../hooks/useAuthToken";
import ProdutosRepository from "../repositories/produtos_repository";
import { AuthContext } from "../contexts/authContext";
import { useState, useEffect, useContext } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Divider,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputLeftElement,
  Input,
  Thead,
  Th,
  Tr,
  Tbody,
  Td,
  Table,
  TableContainer,
} from "@chakra-ui/react";
import AnunciosRepository from "../repositories/anuncios_repository";
import useEstabelecimento from "../hooks/useEstabelecimento";
import searchImg from "../assets/pesquisar.svg";
import Formatters from "../utils/formatters";
import { MdClose } from "react-icons/md";
import { Fragment } from "react";

export default function ProductsSelectorDialog({
  onSelect,
  isOpen,
  onClose,
  currentProducts = [],
  blockProducts = [],
  allAvailable = false,
}) {
  const token = useAuthToken();
  const estabelecimento = useEstabelecimento();
  const repo = new AnunciosRepository({ token });

  const [allProducts, setAllProducts] = useState([]);
  const [pickedProducts, setPickedProducts] = useState(currentProducts);
  const [text, setText] = useState("");

  useEffect(() => {
    setPickedProducts(currentProducts);

    fetchProducts();
  }, [isOpen]);

  const fetchProducts = async () => {
    repo
      .consultar({
        vendedor_id: estabelecimento._id,
        tipo: "produto",
      })
      .then((res) => {
        if (res.status === 200) {
          setAllProducts(res.data);
        }
      });
  };

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      size="6xl"
      height="300px"

      // leastDestructiveRef={cancelRef}
      //  onClose={onClose}
    >
      <ModalOverlay>
        <ModalContent height="700px">
          <ModalHeader fontSize="2xl" fontWeight="bold">
            <Flex justify={"space-between"}>
              <Text> Selecione os produtos</Text>
              <MdClose
                cursor={"pointer"}
                onClick={() => {
                  onClose();
                }}
              />
            </Flex>
            {/* <Text fontWeight="normal" fontSize="xs">
              Selecione os produtos relacionados a sua raspadinha
            </Text> */}
          </ModalHeader>

          <ModalBody>
            <Flex direction="row">
              <InputGroup spacing="24px" width={"280px"}>
                <InputLeftElement
                  children={
                    // <Search2Icon onClick={onSearch} color={"primary.400"} />
                    <Image src={searchImg} boxSize="17px" />
                  }
                />
                <Input
                  placeholder="Digite o nome do produto"
                  fontSize={"12px"}
                  mb={"10px"}
                  fontWeight={"600"}
                  variant="filled"
                  value={text}
                  onChange={(e) => {
                    setText(e.target.value);
                  }}
                />
              </InputGroup>
              <Button
                colorScheme="purple"
                onClick={() => {
                  let data = allProducts.filter((p) => {
                    return (
                      p.titulo.toLowerCase().includes(text.toLowerCase()) ||
                      p.descricao.toLowerCase().includes(text.toLowerCase())
                    );
                  });
                  setAllProducts(data);
                }}
                ml={3}
                w="120px"
                backgroundColor="primary.400"
              >
                Buscar
              </Button>

              <Box w="10px" />
              <Button
                onClick={() => {
                  setText("");
                  fetchProducts();
                }}
                w="120px"
              >
                Redefinir
              </Button>
            </Flex>

            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th w={"15px"} padding={"0px"}>
                      <Checkbox
                        onChange={(e) => {
                          if (e.target.checked) {
                            let picked = [];

                            for (const product of allProducts) {
                              for (const variante of product.variantes) {
                                if (variante.estoque !== 0) {
                                  picked.push({
                                    ...product,
                                    variante,
                                    quantidade: 0,
                                    desconto: 0,
                                  });
                                }
                              }
                            }

                            setPickedProducts(picked);
                          } else {
                            setPickedProducts([]);
                          }
                        }}
                      />
                    </Th>
                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                      w="390px"
                    >
                      Produto
                    </Th>
                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                      w={"200px"}
                    >
                      Preço
                    </Th>
                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                    >
                      Variação
                    </Th>
                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                    >
                      Estoque
                    </Th>

                    <Th
                      color={"black"}
                      fontSize={"14px"}
                      textTransform={"none"}
                    >
                      Status
                    </Th>
                  </Tr>
                </Thead>
              </Table>
            </TableContainer>

            <Flex overflowY="scroll" direction={"column"} height={"430px"}>
              {allProducts.map((p) => {
                p.hasProducts = p.variantes.at(0).estoque !== 0;
                p.isChecked = currentProducts.find((cp) => cp._id === p._id);
                p.isBlocked = blockProducts.find((cp) => cp._id === p._id);

                const blocked =
                  (!p.hasProducts || p.isBlocked) && !allAvailable;

                return (
                  <Fragment>
                    {p.variantes.map((v) => {
                      return (
                        <Flex direction="column" key={v._id}>
                          <Flex align="center" opacity={!blocked ? 1 : 0.2}>
                            <Checkbox
                              isChecked={pickedProducts.find(
                                (pp) => pp.variante._id === v._id
                              )}
                              defaultChecked={pickedProducts.find(
                                (pp) => pp.variante._id === v._id
                              )}
                              isDisabled={blocked}
                              onChange={(value) => {
                                if (value.target.checked) {
                                  setPickedProducts([
                                    ...pickedProducts,
                                    {
                                      ...p,
                                      variante: v,
                                      quantidade: 0,
                                      desconto: 0,
                                    },
                                  ]);
                                } else {
                                  setPickedProducts(
                                    pickedProducts.filter(
                                      (pp) => pp.variante._id !== v._id
                                    )
                                  );
                                }
                              }}
                            />
                            <Flex direction={"column"} mx="5" w="full">
                              <Flex
                                align="center"
                                justify="space-between"
                                fontWeight="bold"
                              >
                                <Flex direction="row" align="center">
                                  <Image
                                    w={"80px"}
                                    h={"80px"}
                                    objectFit={"contain"}
                                    src={v.imagem ?? p.imagens.at(0)}
                                  />
                                  <Box w={"20px"} />
                                  <Text
                                    fontSize={"sm"}
                                    whiteSpace={"normal"}
                                    noOfLines={2}
                                    textAlign="start"
                                    w="200px"
                                    overflow={"hidden"}
                                  >
                                    {p.titulo}
                                  </Text>
                                </Flex>
                                {v.preco_promocional ? (
                                  <Flex
                                    w="120px"
                                    direction="column"
                                    fontWeight="medium"
                                    lineHeight={1.2}
                                  >
                                    <Text
                                      fontSize={"sm"}
                                      whiteSpace={"normal"}
                                      noOfLines={2}
                                      textAlign="start"
                                    >
                                      {"De " + Formatters.money(v.preco)}
                                    </Text>
                                    <Text
                                      fontSize={"sm"}
                                      whiteSpace={"normal"}
                                      noOfLines={2}
                                      textAlign="start"
                                      color="#20a751"
                                    >
                                      {"por " +
                                        Formatters.money(v.preco_promocional)}
                                    </Text>
                                  </Flex>
                                ) : (
                                  <Text
                                    fontSize={"sm"}
                                    whiteSpace={"normal"}
                                    noOfLines={2}
                                    textAlign="start"
                                    w="120px"
                                    fontWeight="medium"
                                  >
                                    {Formatters.money(v.preco)}
                                  </Text>
                                )}
                                <Flex direction="column">
                                  {!v.propriedades && (
                                    <Box
                                      fontSize="xs"
                                      p="2"
                                      py="1"
                                      backgroundColor="#f2f2f2"
                                      borderRadius="8"
                                      fontWeight="medium"
                                      w="100px"
                                    >
                                      <Text fontSize="10px">{"TIPO"}</Text>
                                      <Text
                                        color="primary.400"
                                        fontWeight="semibold"
                                      >
                                        Unico
                                      </Text>
                                    </Box>
                                  )}
                                  {Object.keys(v.propriedades ?? {}).map(
                                    (key) => {
                                      return (
                                        <>
                                          <Box
                                            fontSize="xs"
                                            p="2"
                                            py="1"
                                            backgroundColor="#f2f2f2"
                                            borderRadius="8"
                                            fontWeight="medium"
                                            w="100px"
                                          >
                                            <Text fontSize="10px">
                                              {" "}
                                              {key.toUpperCase()}:
                                            </Text>
                                            <Text
                                              color="primary.400"
                                              fontWeight="semibold"
                                            >
                                              {v.propriedades[key]}
                                            </Text>
                                          </Box>
                                          <Flex h="5px" />
                                        </>
                                      );
                                    }
                                  )}
                                </Flex>
                                <Text
                                  fontSize={"sm"}
                                  whiteSpace={"normal"}
                                  noOfLines={2}
                                  textAlign="start"
                                  w="100px"
                                  fontWeight="bold"
                                >
                                  {v.vendas}
                                </Text>
                                <Text
                                  fontSize={"sm"}
                                  color={p.hasProducts ? "black" : "red"}
                                  w="100px"
                                  fontWeight="bold"
                                >
                                  {v.estoque}
                                </Text>
                              </Flex>
                            </Flex>
                          </Flex>
                          <Divider my="2" />
                        </Flex>
                      );
                    })}
                  </Fragment>
                );
              })}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose} w="150px">
              Cancelar
            </Button>
            <Button
              colorScheme="purple"
              backgroundColor="primary.400"
              w="150px"
              onClick={() => {
                onSelect(pickedProducts);
              }}
              ml={3}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
