import { Flex, Text, Image } from "@chakra-ui/react";

import { Tr, Td } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import Formatters from "../../../utils/formatters";

export default function ProdutosTr({ data, onChange, initialData }) {
  return (
    <Variante
      variante={data?.variantes.at(0)}
      initialData={initialData}
      produto={data}
      onChange={(promo) => {
        onChange(promo);
      }}
    />
  );
}

const Variante = ({ variante, produto, onChange, initialData }) => {
  // const descontoPorcentagemRef = useRef();
  const descontoPorcentagemRef = useRef("");
  const estoqueRef = useRef("");
  const limiteRef = useRef("");
  const [ativo, setAtivo] = useState(false);

  useEffect(() => {
    if (initialData) {
      descontoPorcentagemRef.current.value =
        initialData?.desconto_porcentagem ?? "";
      estoqueRef.current.value = initialData?.estoque_promocao ?? "";
      limiteRef.current.value = initialData?.limite_compras ?? "";
      setAtivo(initialData.ativo);
    }
  }, [initialData]);

  const onChanged = (obj) => {
    onChange({
      ativo,
      estoque_promocao: estoqueRef.current.value,
      limite_compras: limiteRef.current.value,
      desconto_porcentagem: descontoPorcentagemRef.current.value,

      ...obj,
    });
  };

  return (
    <>
      <Tr key={produto?._id}>
        <Td>
          <Flex align="center" w="250px">
            <Image
              src={produto?.imagens.at(0)}
              mr="2"
              objectFit={"cover"}
              width="60px"
            />
            <Flex direction="column" w="200px">
              <Text fontSize="xs" noOfLines="1" fontWeight="bold">
                {produto?.titulo ?? "—"}
              </Text>
              {/* <Text fontSize="10px" noOfLines="1">
                {variante.sku}
              </Text> */}
            </Flex>
          </Flex>
        </Td>
        <Td>
          <Text fontSize="xs">{Formatters.money(variante?.preco ?? 0)}</Text>
        </Td>

        <Td>
          <Text fontSize="xs">{variante?.estoque ?? "—"}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">5.0</Text>
        </Td>
      </Tr>
    </>
  );
};
