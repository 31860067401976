import React from "react";
import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";

import { Tr, Td } from "@chakra-ui/react";
import Formatters from "../../../utils/formatters";
import { useNavigate } from "react-router-dom";

const denunciasLabels = {
  falsificacao: "Falsificações e direitos autorais",
  item_proibido: "Itens proibidos (Banidos / impórprios)",
  violacao_politicas:
    " Violação das políticas de listagem(ex: Palavras-chaves inadequadas, links externos e similares.",
  item_ofensivo: "Itens ofensivos e/ou potencialmente ofensivos",
  listagem_fraudulenta:
    "Listagens fraudulentas (demandas de vendedores ilegais, etc)",
  propriedade_furtada: "Propriedade furtada",
  outros: "Outros",
};

function ProdutoTile({ data, template = "default" }) {
  const { variantes } = data;
  const [variante] = variantes;

  const navigate = useNavigate();
  const [expanded, setExpanded] = React.useState(false);

  const canEdit = data.metadados?.denuncia?.bloqueado_ate
    ? new Date(data.metadados?.denuncia?.bloqueado_ate) < new Date()
    : true;

  return (
    <>
      <Tr key={data._id}>
        <Td>
          <Flex direction={"column"}>
            <Flex maxW={"340px"} align="center">
              <Image
                mr={"10px"}
                w={"80px"}
                h={"80px"}
                objectFit={"cover"}
                src={variante?.imagem ?? data.imagens.at(0)}
              />
              <Flex direction={"column"}>
                {template === "denuncias" && (
                  <Text color={"red"} fontSize={"sm"} fontWeight={"700"}>
                    Banido
                  </Text>
                )}

                <Text fontSize={"sm"} whiteSpace={"normal"} noOfLines={2}>
                  {data.titulo}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Box h={"10px"} />
          <Flex direction={"row"}>
            <Box p="4" borderRadius="8" border="1px solid #F5F5F5 ">
              <Flex direction={"column"}>
                <Text fontWeight={700}>
                  {data.dados_metricas.qtd_vendas_total}
                </Text>
                <Text fontSize={"xs"}>Total de vendas</Text>
              </Flex>
            </Box>
            <Box w={"10px"} />
            <Box p="4" borderRadius="8" border="1px solid #F5F5F5 ">
              <Flex direction={"column"}>
                <Text fontWeight={700}>{data.dados_metricas.qtd_visitas}</Text>
                <Text fontSize={"xs"}>Total de acessos</Text>
              </Flex>
            </Box>
          </Flex>
        </Td>

        <Td>
          <Flex direction={"column"}>
            <Text fontSize={"13px"} fontWeight={700}>
              {variante.propriedades === null ? "UNICA" : variante?.sku}
            </Text>
            <Box h={"10px"} />

            {Object.keys(variante.propriedades ?? {}).map((key) => {
              return (
                <>
                  <Text key={key} fontSize={"10px"} lineHeight={"11px"}>
                    {key.toUpperCase()}:
                  </Text>
                  <Text fontSize={"12px"} color={"primary.500"}>
                    {variante.propriedades[key]}
                  </Text>
                </>
              );
            })}
          </Flex>
        </Td>
        <Td>
          <Flex>
            <Text fontSize={"14px"}>
              {Formatters.money(variante.preco_promocional ?? variante.preco)}
            </Text>
          </Flex>
        </Td>
        <Td>
          <Text fontSize={"14px"}>{variante.estoque}</Text>
        </Td>
        <Td>
          {template === "default" && (
            <Text fontSize={"14px"}>{variante.vendas}</Text>
          )}

          {template === "denuncias" && (
            <Text fontSize={"14px"}>
              {denunciasLabels[data.metadados?.denuncia?.motivo ?? "outros"]}
            </Text>
          )}
        </Td>
        <Td>
          <Flex
            title={canEdit ? "Editar anuncio" : "Anuncio bloqueado"}
            onClick={() => {
              if (data.tipo === "servico") {
                return navigate("/editar-servico/" + data._id);
              } else {
                return navigate("/editar-produto/" + data._id);
              }
            }}
            direction={"column"}
          >
            <Button disabled={!canEdit} size={"sm"}>
              Editar
            </Button>
          </Flex>
        </Td>
      </Tr>

      {expanded &&
        variantes.slice(1).map((variante) => {
          return (
            <Tr key={variante._id}>
              <Td>
                <Flex direction={"column"}>
                  <Flex maxW={"250px"}></Flex>
                </Flex>
              </Td>

              <Td>
                <Flex direction={"column"}>
                  <Text fontSize={"13px"} fontWeight={700}>
                    {variante?.sku}
                  </Text>

                  <Box h={"10px"} />

                  {Object.keys(variante.propriedades ?? {}).map((key) => {
                    return (
                      <>
                        <Text key={key} fontSize={"10px"} lineHeight={"11px"}>
                          {key.toUpperCase()}:
                        </Text>
                        <Text fontSize={"12px"} color={"primary.500"}>
                          {variante.propriedades[key]}
                        </Text>
                      </>
                    );
                  })}
                </Flex>
              </Td>
              <Td>
                <Flex>
                  <Text fontSize={"14px"}>
                    {Formatters.money(variante.preco)}
                  </Text>
                </Flex>
              </Td>
              <Td>
                <Text fontSize={"14px"}>{variante.estoque}</Text>
              </Td>
              <Td>
                <Text fontSize={"14px"}>{variante.vendas}</Text>
              </Td>
              <Td></Td>
            </Tr>
          );
        })}

      {variantes.length > 1 && (
        <Tr>
          <Td colSpan={6}>
            <Flex
              onClick={() => setExpanded(!expanded)}
              direction={"row"}
              justifyContent={"center"}
              alignItems={"center"}
              cursor={"pointer"}
            >
              <Text fontSize={"sm"} fontWeight={700}>
                {expanded ? "Ocultar" : "Mostrar"} {variantes.length - 1}{" "}
                {variantes.length - 1 === 1 ? "variação" : "variações"}
              </Text>

              <Box w={"5px"} />

              <Text fontSize={"sm"} fontWeight={700}>
                {expanded ? "-" : "+"}
              </Text>
            </Flex>
          </Td>
        </Tr>
      )}
    </>
  );
}

export default ProdutoTile;
