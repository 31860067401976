const firebaseConfig = {
  apiKey: "AIzaSyAW_XKQbqgpRJRcpnFrtNrUJo2nxFIukEk",
  authDomain: "desconto-premiado---empresas.firebaseapp.com",
  projectId: "desconto-premiado---empresas",
  storageBucket: "desconto-premiado---empresas.appspot.com",
  messagingSenderId: "336292934834",
  appId: "1:336292934834:web:d8ef0da9b3e1fa3a7f45ab",
  measurementId: "G-0N487FH516",
};

export default firebaseConfig;
