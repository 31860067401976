import {
  Box,
  Flex,
  Text,
  Divider,
  Checkbox,
  ModalBody,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  Input,
  Select,
  useToast,
  CloseButton,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import Formatters from "../utils/formatters";
import { useContext } from "react";
import { AuthContext } from "../contexts/authContext";
import VendedoresRepository from "../repositories/vendedores_repositories";
import { Link } from "react-router-dom";
import CarteiraRepository from "../repositories/carteira_repository";

export default function SaqueDialog({
  isOpen,
  onClose,
  onChange,
  data,
  onSaqueDone,
}) {
  const [categoria, setCategoria] = useState();
  const toast = useToast();

  const [saldoDisponivel, setSaldoDisponivel] = useState("R$ 0,00");
  const [saldoPendente, setSaldoPendente] = useState("R$ 0,00");
  const [saqueDisponivel, setSaqueDisponivel] = useState(false);
  const [contaBancaria, setContaBancaria] = useState(null);
  const [saldoASacar, setSaldoASacar] = useState(0);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data && Object.keys(data).length > 0) {
      if (data.saldo_disponivel > 0) {
        setSaldoDisponivel(Formatters.money(data.saldo_disponivel / 100));
        setSaqueDisponivel(data.pode_sacar);
      }

      if (data.saldo_pendente > 0) {
        setSaldoPendente(Formatters.money(data.saldo_pendente / 100));
      }
    }
  }, [data]);

  const context = useContext(AuthContext);

  const repo = new VendedoresRepository({
    token: context.currentToken,
  });

  const carteiraRepo = new CarteiraRepository({
    token: context.currentToken,
  });

  useEffect(() => {
    if (contaBancaria) return;
    repo.consultarContaBancaria().then((res) => {
      if (res.status === 200) {
        setContaBancaria(res.data);
      }
    });
  }, []);

  const onSaque = async () => {
    if (saldoASacar < 7.5) {
      toast({
        title: "O valor mínimo para saque é de R$ 7,50",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    onSaqueDone();

    const res = await carteiraRepo.saque({
      amount: saldoASacar,
    });

    if (res.status === 200) {
      toast({
        title: "Saque solicitado com sucesso!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onClose();
    } else {
      toast({
        title: JSON.stringify(res.data),
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} size="sm" height="200px">
      <ModalOverlay>
        <ModalContent height="400px">
          <ModalHeader fontSize="lg" fontWeight="bold" pt={"30px"}>
            <CloseButton
              onClick={onClose}
              position="absolute"
              right="20px"
              top="20px"
            />
            <Text> Sacar da Deshopnise para:</Text>
            <Box bg={"#ededed"} p={"10px 12px"} mt={"5px"} borderRadius={"5px"}>
              <Link to={"/config-bancarias"}>
                <Flex dir="row" justifyContent={"space-between"}>
                  <Text fontWeight={"600"}>
                    {contaBancaria?.banco ?? "Não configurado"}
                  </Text>
                  <Text fontWeight={"600"}>
                    Conta {contaBancaria?.conta ?? "?"}
                  </Text>
                </Flex>
              </Link>
            </Box>
          </ModalHeader>

          <ModalBody overflowY="scroll">
            <Text fontWeight={"600"}>Valor do saque</Text>

            <NumericFormat
              decimalScale={2}
              decimalSeparator=","
              thousandSeparator="."
              prefix={"R$ "}
              customInput={Input}
              placeholder="R$ 0,00"
              fontWeight={"600"}
              size="lg"
              value={0}
              onValueChange={({ floatValue }) => {
                setSaldoASacar(floatValue);
                // onChange({ ...value, preco: floatValue });
              }}
            />

            <Divider pt={"10px"} mt={"10px"} mb={"10px"} />

            <Flex dir="row">
              <Text fontWeight={"600"} fontSize={20}>
                Saldo atual
              </Text>
              <Box width={"5px"}></Box>
              <Text fontWeight={"600"} color={"#02ab7c"} fontSize={20}>
                {saldoDisponivel}
              </Text>
            </Flex>

            <Box bg={"#ededed"} p={"10px 12px"} mt={"5px"} borderRadius={"5px"}>
              <Flex dir="row" justifyContent={"space-between"}>
                <Text fontWeight={"600"}>Taxa de Saque</Text>
                <Text fontWeight={"600"}>R$ 2,50</Text>
              </Flex>
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="purple"
              disabled={!saqueDisponivel}
              onClick={() => {
                onSaque();
              }}
            >
              Confirmar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
