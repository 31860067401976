import {
  Box,
  Flex,
  Heading,
  Text,
  Input,
  Switch,
  Image,
  Spacer,
  InputGroup,
  InputRightElement,
  Divider,
} from "@chakra-ui/react";
import { NumericFormat } from "react-number-format";

import { useEffect, useState } from "react";
import useEstabelecimento from "../../../hooks/useEstabelecimento";
import { Link, useNavigate } from "react-router-dom";
import useAuthToken from "../../../hooks/useAuthToken";
import EnvioRepository from "../../../repositories/envio_repository";
import Formatters from "../../../utils/formatters";
import { useRef } from "react";
import ShipCard, { ShipImage } from "../../DetalhesPedido/components/ShipCard";

export default function OpcoesEnvioNovoProduto({
  onChange,
  initialData,
  notifyUpdate,
}) {
  const token = useAuthToken();
  const envioRepo = new EnvioRepository({ token });
  const loja = useEstabelecimento();
  const [fretes, setFretes] = useState([]);
  const [erro, setErro] = useState(null);

  // useEffect(() => {
  //   envioRepo.estimarCusto().then((res) => {
  //     setFretes(res.data);
  //   });
  // }, []);

  const pesoRef = useRef(null);
  const alturaRef = useRef(null);
  const larguraRef = useRef(null);
  const comprimentoRef = useRef(null);
  const custoFreteRef = useRef(null);
  const freteGratisRef = useRef(null);

  const [loaded, setLoaded] = useState(false);

  // const [peso, setPeso] = useState(0);
  // const [altura, setAltura] = useState(0);
  // const [largura, setLargura] = useState(0);
  // const [comprimento, setComprimento] = useState(0);
  const [envios, setEnvios] = useState([]);

  async function calcularFrete(peso, altura, largura, comprimento) {
    if (peso && altura && largura && comprimento) {
      await envioRepo
        .estimarCusto({
          vendedor_id: loja._id,
          cep_destino: "80240210",
          peso,
          largura,
          comprimento,
          altura,
          preco: null,
        })
        .then((res) => {
          setFretes([]);

          if (res.status === 200) {
            setFretes(res.data);

            setErro(null);
          } else {
            setErro(res.data.error);
          }
        });
    }
  }

  async function handleInput() {
    let peso = parseFloat(pesoRef?.current?.value);
    let altura = parseFloat(alturaRef?.current?.value);
    let largura = parseFloat(larguraRef?.current?.value);
    let comprimento = parseFloat(comprimentoRef?.current?.value);

    await calcularFrete(peso, altura, largura, comprimento);

    onChange({
      ...initialData,
      peso,
      largura,
      comprimento,
      altura,
      envios_disponiveis: envios,
      fretes,
    });
  }

  const InputKg = (props) => {
    return (
      <InputGroup w="195px">
        <Input
          key={"peso"}
          {...props}
          variant={"filled"}
          placeholder="Peso"
          w="100%"
          ref={pesoRef}
          size="md"
        />
        <InputRightElement
          w="50px"
          children={<Text color="GREY">| Kg </Text>}
        />
      </InputGroup>
    );
  };

  const InputAltura = (props) => {
    return (
      <InputGroup w="200px">
        <Input
          key={"altura"}
          {...props}
          variant={"filled"}
          placeholder="Altura"
          w="100%"
          ref={alturaRef}
          size="md"
        />
        <InputRightElement
          w="70px"
          children={<Text color="GREY">| cm </Text>}
        />
      </InputGroup>
    );
  };

  const InputLargura = (props) => {
    return (
      <InputGroup w="200px">
        <Input
          key={"largura"}
          {...props}
          variant={"filled"}
          placeholder="Largura"
          w="100%"
          ref={larguraRef}
          size="md"
        />
        <InputRightElement
          w="70px"
          children={<Text color="GREY">| cm </Text>}
        />
      </InputGroup>
    );
  };

  const InputComprimento = (props) => {
    return (
      <InputGroup w="200px">
        <Input
          key={"comprimento"}
          variant={"filled"}
          {...props}
          placeholder="Comprimento"
          w="100%"
          ref={comprimentoRef}
          size="md"
        />
        <InputRightElement
          w="70px"
          children={<Text color="GREY">| cm </Text>}
        />
      </InputGroup>
    );
  };

  const InputCustoFrete = (props) => {
    return (
      <InputGroup w="200px">
        <Input
          key={"custo_frete"}
          {...props}
          variant={"filled"}
          placeholder="Valor"
          w="100%"
          ref={custoFreteRef}
          size="md"
        />
      </InputGroup>
    );
  };

  useEffect(() => {
    handleInput();
    loadEnviosDisponiveis();
  }, [initialData, envios]);

  const loadEnviosDisponiveis = () => {
    if (!loaded && initialData?.envios_disponiveis?.length > 0) {
      setEnvios(initialData.envios_disponiveis);
      setLoaded(true);
    }
  };

  return (
    <Box
      boxShadow="sm"
      w="full"
      mt="5px"
      borderRadius="8px"
      p="5"
      bgColor="white"
    >
      <Heading fontSize="md">Opções de envio</Heading>
      <Text fontSize="xs" noOfLines="2">
        Preencha abaixo as dimensões do seu produto e selecione a melhor opção
        de envio para a sua região.
      </Text>
      <Text fontWeight="semibold" fontSize="sm" mt="5">
        Peso (ex: 0.5Kg)
      </Text>

      <NumericFormat
        key={"peso"}
        decimalScale={2}
        decimalSeparator={"."}
        thousandSeparator={false}
        customInput={InputKg}
        size="sm"
        w="20%"
        value={initialData?.peso}
        onValueChange={({ _ }) => {
          handleInput();
        }}
      />
      <Text fontWeight="semibold" fontSize="sm" mt="2">
        Tamanho do pacote
      </Text>
      <Flex direction="row">
        <NumericFormat
          key={"largura"}
          decimalScale={2}
          decimalSeparator={"."}
          thousandSeparator={false}
          placeholder="Largura"
          customInput={InputLargura}
          size="sm"
          w="200px"
          mr="2"
          onValueChange={({ _ }) => {
            handleInput();
          }}
          value={initialData?.largura}
        />

        <NumericFormat
          key={"comprimento"}
          decimalScale={2}
          decimalSeparator={"."}
          thousandSeparator={false}
          placeholder="Comprimento"
          customInput={InputComprimento}
          size="sm"
          w="250px"
          mr="2"
          onValueChange={({ _ }) => {
            handleInput();
          }}
          value={initialData?.comprimento}
        />

        <NumericFormat
          key={"altura"}
          decimalScale={2}
          decimalSeparator={"."}
          thousandSeparator={false}
          placeholder="Altura"
          customInput={InputAltura}
          size="sm"
          w="200px"
          mr="2"
          onValueChange={({ _ }) => {
            handleInput();
          }}
          value={initialData?.altura}
        />
      </Flex>
      <Heading fontSize="md" mt="5">
        Habilite as opções disponíveis em sua região:
      </Heading>
      <Flex align="center" direction="row">
        <Text fontSize="xs" noOfLines="2" mr="2">
          {loja.endereco.rua +
            ", " +
            loja.endereco.numero +
            ", " +
            loja.endereco.bairro +
            ", " +
            loja.endereco.cidade +
            " - " +
            loja.endereco.estado +
            " - " +
            loja.endereco.cep}
        </Text>

        <Link to={"/meus-enderecos"}>
          <Text color="primary.400" fontSize="10px" fontWeight="semibold">
            Trocar Endereço {">"}
          </Text>
        </Link>
      </Flex>
      {erro && (
        <Flex
          direction="column"
          mt="2"
          p="4"
          backgroundColor="#F5F5F5"
          borderRadius="8px"
          w="700px"
        >
          <Text
            noOfLines="2"
            color="red"
            fontSize="xs"
            fontWeight="semibold"
            lineHeight="1"
          >
            {erro}
          </Text>
        </Flex>
      )}
      {!erro &&
        fretes.map((frete) => {
          return (
            <Flex
              key={frete.delivery_method_id}
              direction="column"
              mt="2"
              p="4"
              backgroundColor="#F5F5F5"
              borderRadius="8px"
              w="700px"
            >
              <Flex direction="row" w="full">
                <Flex
                  direction="row"
                  justifyContent="space-between"
                  align="center"
                >
                  <Switch
                    defaultChecked={envios?.includes(frete.delivery_method_id)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setEnvios([...envios, frete.delivery_method_id]);
                      } else {
                        setEnvios(
                          envios.filter(
                            (envio) => envio !== frete.delivery_method_id
                          )
                        );
                      }

                      handleInput();
                    }}
                  />
                  <Box w={"15px"} />
                  <ShipImage
                    w={"30px"}
                    data={{
                      envio: {
                        logistic_provider_name: frete.logistic_provider_name,
                        delivery_method_name: frete.delivery_method_name,
                        final_shipping_cost: frete.final_shipping_cost,
                        description: frete.description,
                      },
                    }}
                  />

                  <Box w={"15px"} />

                  <Flex direction="column">
                    <Text
                      noOfLines="2"
                      color="grey"
                      fontSize="xs"
                      fontWeight="semibold"
                      lineHeight="1"
                    >
                      {frete?.delivery_method_name}
                    </Text>
                    {frete.logistic_provider_name === "Correios" && (
                      <Text
                        noOfLines="2"
                        color="grey"
                        fontSize="xs"
                        fontWeight="semibold"
                        lineHeight="1"
                      >
                        para envios de até 30KG
                      </Text>
                    )}
                  </Flex>
                  <Box w={"400px"} />
                  <Text
                    w={"100px"}
                    noOfLines="2"
                    color="grey"
                    fontSize="xs"
                    fontWeight="semibold"
                    lineHeight="1"
                  >
                    {Formatters.money(frete.final_shipping_cost)}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          );
        })}
      <Text fontSize="xs" noOfLines="2" mr="2" w="70%" mt="2" mb={"7px"}>
        A taxa de frete acima é um valor padrão. O valor exato do custo será
        calculado no momento da compra baseando-se no endereço do comprador e do
        vendedor. Para promoções ativas de frete grátis, essa taxa não será
        considerada.
      </Text>

      {/* <Divider /> */}

      {/* <Heading fontSize="md" mt={"10px"}>
        Frete grátis
      </Heading>
      <Text fontSize="xs" noOfLines="2">
        Habilite o campo abaixo e defina se deseja anunciar com Frete Grátis.
        Para saber mais clique aqui
      </Text>
      <Flex direction="row" align="center" mt="5">
        <Text fontWeight="bold" fontSize="sm">
          Habilitar Frete Grátis
        </Text>
        <Text
          ml="2"
          fontSize="sm"
          noOfLines="2"
          color="grey"
          fontWeight="semibold"
        >
          Não
        </Text>
        <Switch
          ref={freteGratisRef}
          mx="2"
          onChange={(e) => {
            handleInput();
          }}
        />
        <Text fontSize="sm" noOfLines="2" color="green" fontWeight="semibold">
          Sim
        </Text>
      </Flex>
      <Flex direction="row" align="center" mt="5">
        <NumericFormat
          decimalScale={2}
          decimalSeparator={"."}
          thousandSeparator={false}
          customInput={InputCustoFrete}
          size="md"
          variant={"filled"}
          w="150px"
          value={0}
          onValueChange={({ floatValue }) => {
            handleInput();
          }}
        />
      </Flex>
      <Text fontSize="xs" noOfLines="2" mr="2" w="70%" mt="4" mb={"7px"}>
        Defina um valor base para o frete grátis. Este valor será aplicado no
        seu produto para custear o frete grátis. Caso o valor do frete no ato da
        compra seja maior que o valor definido, será cobrado da sua comissão.
        Para saber mais clique aqui
      </Text> */}
    </Box>
  );
}
