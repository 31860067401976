import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { MdArrowBack, MdImportContacts, MdOutlineInfo } from "react-icons/md";
import PageScaffold from "../../components/PageScaffold";
import ProdutosTr from "./components/ProdutosTr";
import ProductsSelectorDialog from "../../components/ProductsSelectorDialog";
import { useState } from "react";
import AnunciosRepository from "../../repositories/anuncios_repository";
import useAuthToken from "../../hooks/useAuthToken";
import PromocaoRepository from "../../repositories/promocao_repository";

import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import dateRangeIsValid from "../../utils/date_comparator";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import PromocaoProductList from "../../components/PromocaoProductList";

function Produtos({ produtos, onChange, initialData }) {
  return (
    <Box
      boxShadow="sm"
      width={"100%"}
      borderRadius={"8px"}
      marginTop={"1px"}
      padding={"16px"}
      backgroundColor={"white"}
    >
      <Flex direction={"column"}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Produto</Th>
                {/* <Th>Preço(R$)</Th> */}
                {/* <Th>Preço oferta (R$)</Th> */}
                <Th>Desconto oferta (%)</Th>
                <Th>Estoque</Th>

                <Th>Estoque oferta</Th>
                <Th>Limite de compra</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={600}>
              {produtos.map((c) => (
                <ProdutosTr
                  onChange={onChange}
                  key={c._id}
                  data={c}
                  initialData={initialData}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
}

function Info({ text }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <InfoOutlineIcon
        cursor={"pointer"}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        fontSize={"14px"}
      />
      {isOpen && (
        <Box position={"relative"}>
          <Text
            backgroundColor={"white"}
            borderRadius={"4px"}
            padding={"10px"}
            minW={"200px"}
            shadow={"md"}
            position={"absolute"}
            fontSize={"12px"}
            fontWeight={"500"}
            color={"black"}
            zIndex={4}
          >
            {text}
          </Text>
        </Box>
      )}
    </>
  );
}

export default function NovoPromocaoPage() {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const token = useAuthToken();
  const repo = new PromocaoRepository({ token });
  const [promocao, setPromocao] = useState({});

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [requestInProgress, setRequestInProgress] = useState(false);

  const [titulo, setTitulo] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [tabIndex, setTabIndex] = useState(0);
  const toast = useToast();
  const navigate = useNavigate();

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      loadPromocao(params.id);
    }
  }, []);

  const loadPromocao = async (id) => {
    const res = await repo.consultar({ id });

    if (res.status === 200) {
      setPromocao(res.data);
      setTitulo(res.data.nome);
      setStartDate(new Date(res.data.data_inicio).toISOString().slice(0, 10));
      setEndDate(new Date(res.data.data_fim).toISOString().slice(0, 10));

      const pRepo = new AnunciosRepository({ token });

      const res2 = await Promise.all(
        res.data.produtos.map(async (p) => {
          const res = await pRepo.consultar({
            id: p,
          });

          return res.data;
        })
      );

      setSelectedProducts(
        res2.map((e) => {
          const desconto = res.data.descontos.find(
            (d) => d.produto_id === e._id
          );

          const variante = e.variantes.find(
            (v) => v._id === desconto.variante_id
          );

          return {
            ...e,
            cliques: desconto.cliques,
            desconto_porcentagem: desconto.desconto_porcentagem,
            desconto_reais: desconto.desconto_reais,
            estoque: desconto.estoque,
            limite: desconto.limite,
            vendas: desconto.vendas,
            variante,
            ativo: desconto.ativo,
          };
        })
      );
    }
  };

  return (
    <>
      <PageScaffold showChat={false}>
        <Box w={"99%"} margin={"0 auto"}>
          <Box py={"5"} fontWeight={"700"} fontSize={"22px"}>
            <Flex direction="row" align="center">
              <MdArrowBack
                cursor="pointer"
                size="25px"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <Box w="5px" />
              <span>Criar Promoção</span>
            </Flex>
          </Box>
          <Box paddingBottom={"4px"} fontWeight={"600"} fontSize={"16px"}>
            {/* <span>Meus Envios</span> */}
            <Flex direction="row" mb="2">
              <Box
                py="4"
                mr="30px"
                color={tabIndex === 0 ? "primary.400" : "grey"}
                borderBottom={tabIndex === 0 ? "2px solid #693FF3" : null}
                onClick={() => setTabIndex(0)}
                cursor="pointer"
              >
                <Text>Informações básicas</Text>
              </Box>
              <Box
                py="4"
                mr="30px"
                color={tabIndex === 1 ? "primary.400" : "grey"}
                borderBottom={tabIndex === 1 ? "2px solid #693FF3" : null}
                onClick={() => setTabIndex(1)}
                cursor="pointer"
              >
                <Text>Detalhes</Text>
              </Box>
            </Flex>
          </Box>

          {tabIndex === 0 && (
            <>
              <Box
                boxShadow="sm"
                w="full"
                mt="5px"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Flex direction="column">
                  <Flex
                    ml="2"
                    direction="column"
                    maxW="600px"
                    fontWeight="semibold"
                  >
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="md" fontWeight={700} mr="2">
                        Nome da Promoção
                      </Text>
                      <Info text=" O nome da Oferta não é visível para os compradores" />
                      {/* <MdOutlineInfo color="grey" size="14px" /> */}
                      {/* <Text
                        fontSize="10px"
                        color="grey"
                        ml="1"
                        fontWeight={300}
                      >
                        O nome da Oferta não é visível para os compradores
                      </Text> */}
                    </Flex>
                    <Input
                      value={titulo}
                      variant={"customGrey"}
                      placeholder="Ex: Celular + capinha"
                      onChange={(e) => {
                        setTitulo(e.target.value);
                      }}
                    />
                  </Flex>
                </Flex>
              </Box>

              <Box
                boxShadow="sm"
                w="full"
                mt="5"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Flex direction="column">
                  <Flex
                    ml="2"
                    direction="column"
                    maxW="600px"
                    fontWeight="semibold"
                  >
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="md" fontWeight={700} mr="2">
                        Tempo da Promoção
                      </Text>
                      <Info text=" O período da promoção deve ser inferior a 180 dias." />
                      {/* <Text
                        fontSize="10px"
                        color="grey"
                        ml="1"
                        fontWeight={300}
                      >
                        O período da promoção deve ser inferior a 180 dias.
                      </Text> */}
                    </Flex>

                    <Flex align="center">
                      <Input
                        fontWeight={500}
                        size="sm"
                        type={"date"}
                        maxW="140px"
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e.target.value);
                        }}
                      />
                      <Box
                        mx="2"
                        w={"18px"}
                        h="2px"
                        backgroundColor="grey"
                        borderRadius="4px"
                      />
                      <Input
                        fontWeight={500}
                        size="sm"
                        type={"date"}
                        maxW="140px"
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e.target.value);
                        }}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </>
          )}

          {tabIndex === 1 && (
            <Box
              paddingTop={"12px"}
              paddingBottom={"4px"}
              fontWeight={"700"}
              fontSize={"22px"}
            >
              <Flex justify="space-between">
                <Flex direction="column" pb={"18px"}>
                  <Text fontSize="md" fontWeight="700">
                    Produtos
                  </Text>

                  <Text fontSize="xs" fontWeight={500}>
                    Adicione o produto que você deseja colocar em promoção
                  </Text>
                </Flex>
                <Button
                  w="200px"
                  variant="purple"
                  backgroundColor="primary.400"
                  color="white"
                  onClick={() => {
                    onOpen();
                  }}
                >
                  + Adicionar Produto
                </Button>
              </Flex>
            </Box>
          )}

          {tabIndex === 1 && (
            <PromocaoProductList
              produtos={selectedProducts}
              onChange={(produto) => {
                let produtos = selectedProducts.map((p) => {
                  if (p.variante._id === produto.variante._id) {
                    return produto;
                  }
                  return p;
                });

                setSelectedProducts(produtos);
              }}
              onRemove={(p) => {
                setSelectedProducts(
                  selectedProducts.filter(
                    (x) => x.variante._id !== p.variante._id
                  )
                );
              }}
            />
          )}
          <Flex direction="row" mt="5" justifyContent="space-between">
            <Flex />
            <Flex>
              <Button
                mr="5"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  if (tabIndex === 0) {
                    let isValid = dateRangeIsValid({
                      first: startDate,
                      last: endDate,
                      maxDays: 180,
                    });

                    if (
                      isValid &&
                      startDate.length === 10 &&
                      endDate.length === 10
                    ) {
                      setTabIndex(1);
                    } else {
                      toast({
                        title: "Erro",
                        description:
                          "Verifique as datas de início e fim da promoção",
                        status: "error",
                        duration: 5000,
                        isClosable: true,
                      });
                    }

                    return;
                  }

                  if (selectedProducts.length > 0) {
                    const body = {
                      nome: titulo,
                      data_inicio: startDate,
                      data_fim: endDate,
                      produtos: selectedProducts.map((e) => e._id),
                      imagem: selectedProducts[0].imagens.at(0),
                      descontos: selectedProducts.map((p) => ({
                        produto_id: p._id,
                        variante_id: p.variante._id,
                        desconto_reais: Number(p.desconto_reais),
                        desconto_porcentagem: Number(p.desconto_porcentagem),
                        estoque:
                          Number(p.estoque) === 0 ? null : Number(p.estoque),
                        limite:
                          Number(p.limite) === 0 ? null : Number(p.limite),
                        cliques: 0,
                        vendas: 0,
                        ativo: p.ativo,
                      })),
                    };

                    const repo = new PromocaoRepository({ token });
                    if (requestInProgress) {
                      toast({
                        title: "Por favor, aguarde!",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                      return;
                    }
                    setRequestInProgress(true);

                    if (params.id) {
                      const res = await repo.alterar({
                        ...body,
                        id: params.id,
                      });

                      setRequestInProgress(false);
                      if (res.status === 200) {
                        toast({
                          title: "Promoção editada com sucesso",
                          status: "success",
                          duration: 9000,
                          isClosable: true,
                        });

                        navigate(-1);
                      } else {
                        toast({
                          title: res.data["error"],
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                      }

                      return;
                    }

                    const res = await repo.cadastrar(body);
                    setRequestInProgress(false);
                    if (res.status === 200) {
                      toast({
                        title: "Promoção cadastrada com sucesso",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });

                      navigate(-1);
                    } else {
                      toast({
                        title: res.data["error"],
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  }
                }}
                backgroundColor="primary.400"
                color="white"
              >
                {tabIndex === 0 ? "Próximo" : "Salvar"}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </PageScaffold>
      <ProductsSelectorDialog
        isOpen={isOpen}
        onClose={onClose}
        currentProducts={selectedProducts}
        onSelect={(p) => {
          setSelectedProducts(p);
          onClose();
        }}
      />
    </>
  );
}
