import {
  Box,
  Flex,
  Grid,
  Image,
  Spinner,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  Divider,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageScaffold from "../../components/PageScaffold";
import useAuthToken from "../../hooks/useAuthToken";
import OfertaRelampagoRepository from "../../repositories/ofertas_relampago_repository";
import FilterBox from "./components/FilterBox";
import OfertasList from "./components/OfertasList";
import backAsset from "../../assets/back.png";
import { MdCircle, MdShowChart } from "react-icons/md";
import { is } from "date-fns/locale";

export default function OfertaRelampagoPage() {
  const token = useAuthToken();
  const repo = new OfertaRelampagoRepository({ token });
  const navigate = useNavigate();

  const [ofertas, setOfertas] = useState([]);
  const [filters, setFilters] = useState({});

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    repo.consultar(filters).then((response) => {
      setOfertas(response.data);
      setIsLoading(false);
    });
  }, [filters]);

  return (
    <PageScaffold>
      <Box w={"99%"} margin={"0 auto"}>
        <Box
          paddingTop={"32px"}
          paddingBottom={"24px"}
          fontWeight={"700"}
          fontSize={"22px"}
        >
          <Flex direction={"row"} alignItems={"center"}>
            <Image
              cursor={"pointer"}
              src={backAsset}
              height={"15px"}
              alt="Voltar"
              onClick={() => {
                window.history.back();
              }}
            />
            <Box width={"10px"} />
            <span>Ofertas Relâmpago</span>
          </Flex>
        </Box>

        {/* <Grid templateColumns="repeat(4, 1fr)" gap={2} mt="2">
          <Infos
            title="Vendas"
            value="R$ 10223,43"
            description="7 dias anteriores 0%"
          />
          <Infos
            title="Pedidos"
            value="R$ 4843,47"
            description="7 dias anteriores 0%"
          />
          <Infos
            title="Compradores"
            value="234"
            description="7 dias anteriores 0%"
          />
          <Infos
            title="Taxa de cliques"
            value="40%"
            description="7 dias anteriores 0%"
          />
        </Grid> */}
        <Flex
          direction={"row"}
          alignItems={"center"}
          justifyContent="space-between"
          px="4"
        >
          <Flex direction="column" maxW="50%">
            <Text fontSize="md" fontWeight="bold" color="#4e4e4e">
              Lista de Ofertas
            </Text>
            <Text fontSize="xs" fontWeight={500}>
              Crie suas próprias Ofertas por tempo limitado na página da sua
              loja para aumentar as suas vendas. Saiba mais.
            </Text>
          </Flex>
          <Flex direction="row">
            <Link to={"/estatisticas"} mr="2">
              <Button
                onClick={() => {
                  navigate("/estatisticas");
                }}
                width={"140px"}
                marginLeft={"15px"}
                backgroundColor="#232544"
                color={"white"}
                _hover={{ opacity: "0.8" }}
              >
                <Flex direction="row" align="center">
                  <MdShowChart size={20} color="cyan" />
                  <Text mx="2" fontSize="xs">
                    Métricas
                  </Text>
                  <Divider h="10px" mr="2" orientation="vertical" />
                  <MdCircle size={15} color="cyan" />
                </Flex>
              </Button>
            </Link>
            <Box w={"10px"} />

            <Button
              backgroundColor="primary.400"
              color={"white"}
              _hover={{ opacity: "0.8" }}
              colorScheme="purple"
              onClick={() => {
                navigate("/criar-oferta-relampago");
              }}
            >
              Criar Relâmpago
            </Button>
          </Flex>
        </Flex>
        <Box
          boxShadow="sm"
          width={"100%"}
          minHeight={"88vh"}
          borderRadius={"8px"}
          marginTop={"5"}
          backgroundColor={"white"}
        >
          <FilterBox
            onFilterChange={(f) => {
              setFilters(f);
            }}
          />

          {isLoading && (
            <Flex
              w={"100%"}
              height={"400px"}
              justify={"center"}
              align={"center"}
              alignItems={"center"}
            >
              <Spinner
                thickness="3.5px"
                speed="0.65s"
                emptyColor="gray.200"
                color="#7E5EFA"
                size="lg"
              />
            </Flex>
          )}

          {!isLoading && (
            <OfertasList
              data={ofertas}
              onReset={() => {
                repo.consultar().then((response) => {
                  setOfertas(response.data);
                });
              }}
            />
          )}
        </Box>
      </Box>
    </PageScaffold>
  );
}
