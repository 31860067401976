import { Flex, Text, Button, Image, Box } from "@chakra-ui/react";

import { Tr, Td } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useAuthToken from "../../../hooks/useAuthToken";
import LembretesRepository from "../../../repositories/lembretes_repository";

export default function LembretesTr({ data }) {
  console.log("Data");
  console.log(data);
  const { produto_principal, produto_adicional, produtos_adicionais } = data;
  const navigate = useNavigate();

  const token = useAuthToken();

  const repo = new LembretesRepository({ token });
  ///TODO: Alterar para limite de uso

  return (
    <>
      <Tr key={data._id}>
        <Td>
          <Text fontSize="xs">{data.nome}</Text>
        </Td>

        <Td>
          <Text fontSize="xs">{data.cupons[0]?.tipo}</Text>
        </Td>
        <Td>
          {data.cupons[0]?.desconto_porcentagem ? (
            <Text fontSize="xs">{data.cupons[0]?.desconto_porcentagem}%</Text>
          ) : (
            <Text fontSize="xs">R$ {data.cupons[0]?.desconto_reais}</Text>
          )}
        </Td>
        <Td>
          <Text fontSize="xs">{data.cupons[0]?.quantidade}</Text>
        </Td>

        <Td>
          <Text fontSize="xs">{data.status}</Text>
        </Td>
        <Td>
          <Text fontSize="xs">
            {data.data_agendamento
              ? new Date(data.data_agendamento).toLocaleDateString()
              : new Date(data.createdAt).toLocaleDateString()}
          </Text>
        </Td>
        <Td>
          <Flex direction="column">
            <Button
              disabled={
                data.status === "Cancelado" || data.status === "Finalizado"
              }
              onClick={async () => {
                const res = await repo.cancelar({
                  id: data._id,
                });

                if (res.status === 200) {
                  window.location.reload();
                }
              }}
              size="sm"
              mt="0.5"
            >
              Cancelar
            </Button>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
