import { Flex, Select, Text, Checkbox, Image, Button } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import CorreiosImg from "../../../assets/correios.png";
import useEstabelecimento from "../../../hooks/useEstabelecimento";

export default function TabGeracaoDocumento({
  pedidos = [],
  onProductsChange,
}) {
  const [currentFilter, setCurrentFilter] = useState("all");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const estabelecimento = useEstabelecimento();

  const filteredPedidos =
    pedidos &&
    pedidos.filter((pedido) => {
      if (currentFilter === "all") {
        return true;
      }

      if (currentFilter === "correios") {
        return (
          pedido.envio.logistic_provider_name.toLowerCase().trim() ===
          "correios"
        );
      }

      if (currentFilter === "jadlog") {
        return (
          pedido.envio.logistic_provider_name.toLowerCase().trim() === "jadlog"
        );
      }
    });

  useEffect(() => {
    console.log(selectedProducts);
    onProductsChange(selectedProducts);
  }, [selectedProducts]);

  if (!pedidos) return null;

  return (
    <Flex direction={"column"}>
      <Flex direction="row" my="2" align="center">
        <Text mr="5" ml={"5"} fontWeight={"700"}>
          Filtrar pedidos por:
        </Text>
        <Select
          variant={"filled"}
          value={currentFilter}
          fontWeight={"700"}
          w="200px"
          onChange={(e) => {
            setCurrentFilter(e.target.value);
          }}
        >
          <option value="all">Todos</option>
          <option value="correios">Correios</option>
          <option value="jadlog">JadLog</option>
        </Select>

        {/* {estabelecimento.dados_fiscais?.cadastro_regular && (
          <Button
            variant="outline"
            ml="5"
            borderWidth="thin"
            colorScheme="purple"
          >
            Informar NF-e em massa
          </Button>
        )} */}
      </Flex>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>
                <Checkbox
                  key={pedidos._id}
                  onChange={() => {
                    if (selectedProducts.length === filteredPedidos.length) {
                      setSelectedProducts([]);
                    } else {
                      setSelectedProducts(filteredPedidos);
                    }
                  }}
                  isChecked={
                    selectedProducts?.length === filteredPedidos?.length
                  }
                />
              </Th>

              <Th>Produtos</Th>
              <Th>ID do pedido</Th>
              <Th>Comprador</Th>
              <Th>Opção de envio</Th>
              <Th>Status do pedido</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {filteredPedidos &&
              filteredPedidos.map((pedido) => {
                return (
                  <Tr key={pedido._id}>
                    <Td>
                      <Checkbox
                        isChecked={selectedProducts.some(
                          (e) => e._id === pedido._id
                        )}
                        onChange={() => {
                          if (
                            selectedProducts.some((e) => e._id === pedido._id)
                          ) {
                            setSelectedProducts(
                              selectedProducts.filter(
                                (e) => e._id !== pedido._id
                              )
                            );
                          } else {
                            setSelectedProducts([...selectedProducts, pedido]);
                          }
                        }}
                      />
                    </Td>
                    <Td>
                      <Image
                        h="70px"
                        objectFit="contain"
                        src={pedido.itens.at(0).produto_img}
                      />
                    </Td>
                    <Td>
                      <Text fontSize={"sm"}>#{pedido.numero_pedido}</Text>
                    </Td>
                    <Td>
                      <Text fontSize={"sm"}>{pedido.usuario_nome}</Text>
                    </Td>
                    <Td>
                      <Image
                        w={"80px"}
                        h={"30px"}
                        objectFit={"contain"}
                        src={CorreiosImg}
                        mx="5"
                      />
                    </Td>
                    <Td>{pedido.eventos.at(-1).titulo}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
