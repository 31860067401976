import { Box, Text, Flex } from "@chakra-ui/react";
import Chart from "react-apexcharts";

const ChartSection = ({ data }) => {
  if (!data) {
    return <></>;
  }

  return (
    <Flex
      maxW={"90vw"}
      margin={"0 auto"}
      backgroundColor={"white"}
      borderRadius={"6px"}
      shadow={"md"}
      marginTop={"50px"}
      direction={"column"}
    >
      <Flex>
        <Text
          padding={"24px"}
          paddingRight={"0px"}
          color={"black"}
          fontWeight={"bold"}
          fontSize={"20px"}
        >
          Gráfico
        </Text>
        <Text
          padding={"24px"}
          paddingLeft={"6px"}
          fontWeight={"bold"}
          fontSize={"20px"}
          color={"#693FF3"}
        >
          Total de Vendas
        </Text>
      </Flex>

      <Chart
        width={"100%"}
        height={"300px"}
        options={{
          tooltip: {
            theme: "dark",
          },
          chart: {
            id: "area",
            foreColor: "#ccc",
            toolbar: {
              autoSelected: "pan",
              show: false,
            },
          },
          colors: ["#7f52fb"],
          xaxis: {
            categories: data.horarios,
          },
          fill: {
            type: "gradient",
            gradient: {
              type: "vertical",
              shadeIntensity: 0.5,
              gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
              inverseColors: true,
              opacityFrom: 0.6,
              opacityTo: 0.2,
              stops: [0, 50, 100],
              colorStops: [],
            },
          },
          yaxis: {
            labels: {
              formatter: function (value) {
                return Intl.NumberFormat("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                }).format(value);
              },
            },
          },
          grid: {
            borderColor: "#F5F5F5",
          },
          dataLabels: {
            enabled: false,
          },

          stroke: {
            curve: "smooth",

            width: 4,
          },
        }}
        series={data.dados}
        type="area"
      />
    </Flex>
  );
};

export default ChartSection;
