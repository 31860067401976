import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import AddImageButtonComponent from "../../components/AddImageButtonComponent";
import PageScaffold from "../../components/PageScaffold";

import { useDisclosure } from "@chakra-ui/react";
import { useRef, useContext } from "react";
import { AuthContext } from "../../contexts/authContext";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdArrowBack } from "react-icons/md";
import useEstabelecimento from "../../hooks/useEstabelecimento";

const opcoes = [
  "Itaú",
  "Bradesco",
  "Caixa Econômica",
  "Banco do Brasil",
  "Santander",
  "Banrisul",
  "Sicredi",
  "Sicoob",
  "Inter",
  "BRB",
  "Via Credi/Civia Cooperativa",
  "Neon",
  "Votorantim",
  "Banco Letsbank S.A",
  "Nubank",
  "Pagseguro",
  "Banco Original",
  "Safra",
  "Modal",
  "Banestes",
  "Unicred",
  "Money Plus",
  "Mercantil do Brasil",
  "JP Morgan",
  "Gerencianet Pagamentos do Brasil",
  "Banco C6",
  "BS2",
  "Banco Topazio",
  "Uniprime",
  "Stone",
  "Banco Daycoval",
  "Rendimento",
  "Banco do Nordeste",
  "Citibank",
  "PJBank",
  "Cooperativa Central de Credito Noroeste Brasileiro",
  "Uniprime Norte do Paraná",
  "Global SCM",
  "Next",
  "Cora",
  "Mercado Pago",
  "Banco da Amazonia",
  "BNP Paribas Brasil",
  "Juno",
  "Cresol",
  "BRL Trust DTVM",
  "Banco Banese",
  "Banco BTG Pactual",
  "Banco Omni",
  "Acesso Soluções de Pagamento",
  "CCR de São Miguel do Oeste",
  "Polocred",
  "Ótimo",
  "Banco Ribeirão Preto",
  "ASAAS IP",
  "Banco Pan",
  "Neon",
  "VORTX DTVM LTDA",
  "Banco BMG",
  "Pefisa",
  "J17 - SCD S/A",
  "Credisan",
  "Pinbank",
  "XP Investimentos",
  "Crefisa",
  "Singulare",
  "SUMUP SCD S.A.",
  "Banco ABC Brasil",
  "BCO LETSBANK S.A.",
  "HR Digital Sociedade de Crédito Direto S.A",
];

export default function ConfiguracoesBancariasPage() {
  const context = useContext(AuthContext);
  const estabelecimento = useEstabelecimento();
  const toast = useToast();
  const navigate = useNavigate();

  const [contaBancaria, setContaBancaria] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const repo = new VendedoresRepository({
    token: context.currentToken,
  });

  useEffect(() => {
    if (contaBancaria) return;

    repo.consultarContaBancaria().then((res) => {
      if (res.status === 200) {
        setContaBancaria(res.data);
      }
    });
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const nomeCompletoRef = useRef();
  const documentoRef = useRef();
  const nomeBancoRef = useRef();
  const numeroAgenciaRef = useRef();
  const numeroContaRef = useRef();
  const nomeResp = useRef();
  const cpfResp = useRef();

  return (
    <>
      <PageScaffold showSideBar={true}>
        <Box w={"100%"} margin={"0 auto"}>
          <Box
            paddingTop={"30px"}
            paddingBottom={"4px"}
            fontWeight={"700"}
            fontSize={"22px"}
          >
            <Flex direction="column">
              <Flex direction="row" align="center">
                <MdArrowBack
                  cursor="pointer"
                  size="25px"
                  onClick={() => {
                    navigate(-1);
                  }}
                />
                <Box w="5px" />
                <span>Informações bancárias</span>
              </Flex>

              <Text fontSize="14px" fontWeight={500}>
                Adicione suas informações bancárias para receber seus lucros da
                Deshopnise
              </Text>
            </Flex>
          </Box>

          <Box width={"100%"} borderRadius={"8px"} marginTop={"5px"}>
            <Flex direction={"row"}>
              {contaBancaria && contaBancaria.conta ? (
                <Box
                  onClick={() => {
                    onOpen();
                    setTimeout(() => {
                      numeroAgenciaRef.current.value = contaBancaria.agencia;
                      numeroContaRef.current.value = contaBancaria.conta;
                      nomeBancoRef.current.value = contaBancaria.banco;
                      nomeCompletoRef.current.value = contaBancaria.nome_legal;
                      documentoRef.current.value = contaBancaria.documento;
                    }, 100);
                  }}
                  w={"280px"}
                  h="40"
                  backgroundColor={"primary.400"}
                  boxSizing="border-box"
                  borderRadius="8px"
                  my="5"
                  cursor="pointer"
                  // onClick={onOpen}
                  mr="5"
                >
                  <Flex
                    justifyContent="center"
                    alignItems="start"
                    direction="column"
                    h="full"
                    px="10"
                    color="white"
                  >
                    <Text fontSize="xs" fontWeight="semibold">
                      {"Nome"}
                    </Text>
                    <Text fontSize="xs">{contaBancaria.nome_legal}</Text>
                    <Text fontSize="xs" fontWeight="semibold">
                      {"Tipo"}
                    </Text>
                    <Text fontSize="xs">{"Conta bancária"}</Text>
                    <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                      <Flex direction="column">
                        <Text fontSize="xs" fontWeight="semibold">
                          {"Agencia"}
                        </Text>
                        <Text fontSize="xs">
                          {contaBancaria.agencia}{" "}
                          {contaBancaria.agencia_digito ?? ""}
                        </Text>
                      </Flex>
                      <Flex direction="column">
                        <Text fontSize="xs" fontWeight="semibold">
                          {"Conta"}
                        </Text>
                        <Text fontSize="xs">
                          {contaBancaria.conta}{" "}
                          {contaBancaria.conta_digito ?? ""}
                        </Text>
                      </Flex>
                    </Grid>
                  </Flex>
                </Box>
              ) : (
                <AddImageButtonComponent
                  title={"Adicionar Conta"}
                  width={"280px"}
                  onClick={onOpen}
                />
              )}
            </Flex>
          </Box>
        </Box>
      </PageScaffold>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Text>Conta Bancária</Text>
              <Box height="20px" />
              <Box p="2" border="2px" borderColor="red.300" borderRadius="4">
                <Text fontWeight="normal" fontSize="xs">
                  Atenção: A conta bancária deve estar registrada sob o mesmo
                  CPF ou CNPJ utilizado no seu cadastro de vendedor. Para contas
                  poupanças, só daremos suporte aos bancos ...
                </Text>
              </Box>
            </AlertDialogHeader>

            <AlertDialogBody>
              <Input
                placeholder="Nome completo"
                variant="filled"
                fontSize={"sm"}
                mt="2"
                ref={nomeCompletoRef}
              />
              <Input
                placeholder={
                  estabelecimento.tipo_cadastro === "pessoa_fisica"
                    ? "CPF"
                    : "CNPJ"
                }
                variant="filled"
                fontSize={"sm"}
                mt="2"
                ref={documentoRef}
              />

              <Select
                placeholder="Selecione o banco"
                variant="filled"
                fontSize={"sm"}
                mt="2"
                ref={nomeBancoRef}
              >
                {opcoes.map((opcao) => (
                  <option value={opcao}>{opcao}</option>
                ))}
              </Select>
              <Grid templateColumns="3fr 3fr" gap={2} mt="2">
                <Input
                  placeholder="Número da Agência"
                  variant="filled"
                  ref={numeroAgenciaRef}
                  fontSize={"sm"}
                />
                <Input
                  placeholder="Número da Conta"
                  variant="filled"
                  ref={numeroContaRef}
                  fontSize={"sm"}
                />
                {/* <Input
                  placeholder="Digito da Agência"
                  variant="filled"
                  ref={digitoAgenciaRef}
                  fontSize={"sm"}
                /> */}
              </Grid>
              {estabelecimento.tipo_cadastro !== "pessoa_fisica" && (
                <Grid templateColumns="3fr 2fr" gap={2} mt="2">
                  <Input
                    placeholder="Nome do Responsável"
                    variant="filled"
                    ref={nomeResp}
                    fontSize={"sm"}
                  />
                  <Input
                    placeholder="CPF do Responsável"
                    variant="filled"
                    ref={cpfResp}
                    fontSize={"sm"}
                  />
                </Grid>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button
                colorScheme="purple"
                onClick={() => {
                  const body = {
                    nome_legal: nomeCompletoRef.current.value,
                    documento: documentoRef.current.value,
                    banco: nomeBancoRef.current.value,
                    agencia: numeroAgenciaRef.current.value,
                    // agencia_digito: digitoAgenciaRef.current.value,
                    conta: numeroContaRef.current.value,
                    // conta_digito: digitoContaRef.current.value,
                    nome_responsavel: nomeResp.current?.value,
                    cpf_responsavel: cpfResp.current?.value,
                  };

                  console.log(body);

                  repo
                    .atualizarContaBancaria(body)
                    .then((res) => {
                      if (res.status === 200) {
                        setContaBancaria(res.data);

                        toast({
                          title: "Conta bancária atualizada com sucesso",
                          status: "success",
                          duration: 9000,
                          isClosable: true,
                        });
                        onClose();
                      } else {
                        toast({
                          title: res.data.error,
                          status: "error",
                          duration: 9000,
                          isClosable: true,
                        });
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
                ml={3}
              >
                Salvar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
