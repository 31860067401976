import {
  Box,
  Flex,
  Grid,
  Text,
  Input,
  Heading,
  Button,
  Divider,
} from "@chakra-ui/react";

export default function TabCadastro({ isPj, loja, onOpenPjForm, save }) {
  if (loja == null) {
    return <></>;
  }
  return (
    <Box>
      <Grid templateColumns="repeat(2, 1fr)" gap={2} mt="2">
        <Flex direction="column">
          <Text>Nome</Text>
          <Input
            defaultValue={loja.nome}
            variant="filled"
            size="lg"
            mb="2"
            onChange={(e) => {
              loja.nome = e.target.value;
            }}
          />
        </Flex>
        <Flex direction="column">
          <Text>{isPj ? "CNPJ" : "CPF"}</Text>
          <Input
            defaultValue={loja.documento}
            variant="filled"
            disabled
            size="lg"
            mb="2"
            // onChange={(e) => {
            //   loja.responsavel.cpf = e.target.value;
            // }}
          />
        </Flex>
        <Flex direction="column">
          <Text>Email</Text>
          <Input
            defaultValue={loja.email}
            variant="filled"
            size="lg"
            mb="2"
            onChange={(e) => {
              loja.email = e.target.value;
            }}
          />
        </Flex>
        <Flex direction="column">
          <Text>Telefone</Text>
          <Flex mb="2" align="center">
            <Input
              variant="filled"
              placeholder="+55"
              size="lg"
              p="0"
              textAlign="center"
              w="60px"
            />

            <Divider orientation="vertical" mx="0.5" h="20px" />

            <Input
              defaultValue={loja.responsavel?.telefone}
              variant="filled"
              placeholder="Digite o seu telefone"
              size="lg"
              onChange={(e) => {
                loja.responsavel.telefone = e.target.value;
              }}
            />
          </Flex>
        </Flex>
        <Flex direction="column">
          <Text>Endereço</Text>
          <Input
            disabled
            defaultValue={
              loja.endereco &&
              loja.endereco.rua +
                ", " +
                loja.endereco.numero +
                ", " +
                loja.endereco.bairro +
                ", " +
                loja.endereco.cidade +
                " - " +
                loja.endereco.estado
            }
            variant="filled"
            size="lg"
            mb="2"
            onChange={(e) => {
              loja.endereco_responsavel = e.target.value;
            }}
          />
        </Flex>
      </Grid>
      <Box w="full" align="end" mt="5">
        {!isPj && (
          <Button colorScheme="purple" size="sm" mr="2" onClick={onOpenPjForm}>
            Mudar conta para CNPJ
          </Button>
        )}
        <Button
          colorScheme="purple"
          size="sm"
          onClick={() => {
            save(loja);
          }}
        >
          Salvar
        </Button>
      </Box>
    </Box>
  );
}
