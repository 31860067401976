import { Box, Flex, Text, Button, Image } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import { MdChat } from "react-icons/md";
import Formatters from "../../../utils/formatters";
import ProdutoTile from "./ProdutoTile";

export default function TabProdutos({ produtos }) {
  return (
    <Flex direction={"column"}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Detalhes</Th>
              <Th>Variações</Th>
              <Th>Preço</Th>
              <Th>Estoque</Th>
              <Th>Vendas</Th>
              <Th>Ação</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {produtos.map((p) => (
              <ProdutoTile key={p._id} data={p} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
