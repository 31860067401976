import React, { useContext, useEffect } from "react";
import { Box, Button, Flex, Image } from "@chakra-ui/react";

import LoginBackground from "./../../assets/completar-cad.png";

import StepperComponent from "./components/Stepper";
import CompletedComponent from "./components/Completed";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import TipoLojaComponente from "./components/TipoLoja";
import useAuthToken from "../../hooks/useAuthToken";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import { AuthContext } from "../../contexts/authContext";

export default function CompletarCadastroPage() {
  const estabelecimento = useEstabelecimento();
  const [showTipoLoja, setShowTipoLoja] = React.useState(true);
  const authContext = useContext(AuthContext);
  const [TipoLoja, setTipoLoja] = React.useState(null);
  const token = useAuthToken();

  useEffect(() => {
    if (localStorage.getItem(`show_tipo_loja_${estabelecimento._id}`)) {
      setShowTipoLoja(false);
    }
  }, []);

  if (!estabelecimento.pre_cadastro) {
    return (
      <Box h={"100vh"} background={"#232544"}>
        <Flex direction={"column"} pt={"10"}>
          <CompletedComponent />

          <Flex align={"center"} justifyContent={"center"}>
            <Button
              onClick={() => {
                window.location.href = "/";
              }}
              bg={"#683FF3"}
              color={"white"}
              mt={"5"}
              w={"450px"}
              borderRadius={"8px"}
              h={"45px"}
              fontWeight={"700"}
              _hover={{
                bg: "#4C378F",
              }}
            >
              COMEÇAR
            </Button>
          </Flex>
        </Flex>
      </Box>
    );
  }

  return (
    <>
      <Flex direction="row" h="100vh" alignItems="center">
        <Image
          display={{ base: "none", md: "block" }}
          w="35%"
          h="full"
          fit="cover"
          src={LoginBackground}
        />
        <Flex w={"100%"} direction="column" px="5">
          {showTipoLoja ? (
            <TipoLojaComponente
              tipoLoja={TipoLoja}
              setTipoLoja={(tipoLoja) => {
                setTipoLoja(tipoLoja);
              }}
              onCompleted={() => {
                const repo = new VendedoresRepository({ token });

                repo
                  .completarCadastroTipoLoja({
                    tipo_loja: TipoLoja,
                  })
                  .then((e) => {
                    authContext.refreshEstabelecimento();
                    setShowTipoLoja(false);
                    localStorage.setItem(
                      `show_tipo_loja_${estabelecimento._id}`,
                      false
                    );
                  });
              }}
            />
          ) : (
            <StepperComponent />
          )}
        </Flex>
      </Flex>
    </>
  );
}
