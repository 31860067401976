import {
  Button,
  Flex,
  Text,
  Divider,
  Center,
  Box,
  Image,
  Checkbox,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import Formatters from "../../../utils/formatters";

function SubItens({ img, nome, preco, precoPromocional }) {
  return (
    <Flex justify="space-between" w="250px" mb="5px">
      <Checkbox />
      <Image width="45px" height="45px" mr="2" src={img} />
      <Flex direction="column">
        <Text
          fontSize="10px"
          w="130px"
          fontWeight="semibold"
          noOfLines={2}
          my="5px"
          lineHeight="1"
        >
          {nome}
        </Text>
        <Text fontSize="10px" noOfLines={2} lineHeight="1">
          {preco}
        </Text>

        <Text
          fontSize="11px"
          noOfLines={2}
          color="#00BB2D"
          fontWeight={600}
          lineHeight="1.2"
        >
          {precoPromocional}
        </Text>
      </Flex>
    </Flex>
  );
}

const Preview = ({ produtosPrincipais, produtosAdicionais, descontos }) => {
  const [selectedSubItens, setSelectedSubItens] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  useEffect(() => {
    if (produtosPrincipais.length > 0) {
      setSelectedItem(produtosPrincipais[0]);
    }
  }, [produtosPrincipais]);

  if (!produtosPrincipais || produtosPrincipais.length === 0) return <></>;
  if (!produtosAdicionais || produtosAdicionais.length === 0) return <></>;

  const getFinalPrice = () => {
    if (selectedItem?.length === 0) return 0;

    if (selectedSubItens?.length === 0) {
      return (
        selectedItem?.variante.preco_promocional || selectedItem?.variante.preco
      );
    }
    const produtoPrinicialPreco =
      selectedItem?.variante.preco_promocional || selectedItem?.variante.preco;

    const produtosSecundariosPreco = selectedSubItens.reduce((acc, item) => {
      const desconto = descontos.find((x) => x.produto_id === item._id);

      const variante = item.variante;

      let valorPromocional;

      if (desconto) {
        if (desconto.desconto_porcentagem) {
          valorPromocional = variante.preco_promocional || variante.preco;
          valorPromocional =
            valorPromocional -
            valorPromocional * (desconto.desconto_porcentagem / 100);
        } else {
          valorPromocional = variante.preco_promocional || variante.preco;
          valorPromocional = valorPromocional - desconto.desconto_reais;
        }
      }

      return acc + valorPromocional;
    }, 0);

    return produtoPrinicialPreco + produtosSecundariosPreco;
  };

  if (!selectedItem) {
    return <></>;
  }

  return (
    <Flex direction="column" maxW="800px">
      <Flex direction="row" justify="space-between" mt="5">
        <Flex w="30%" direction="column" align="start">
          <Flex fontWeight="bold">Produto Principal</Flex>
          {produtosPrincipais?.map((produto) => (
            <Box
              p="4"
              cursor="pointer"
              border={selectedItem?.id === produto.id ? "2px" : "0px"}
              mt="2"
              borderRadius="8"
              borderColor={selectedItem === produto ? "primary.400" : "white"}
              onClick={() => {
                setSelectedItem(produto);
              }}
            >
              <Flex direction="row" align="center">
                <Image
                  width="40px"
                  height="40px"
                  mr="2"
                  src={produto.imagens.at(0)}
                />
                <Text fontSize="md" fontWeight="semibold" noOfLines={2}>
                  {produto.titulo}
                </Text>
              </Flex>
            </Box>
          ))}
        </Flex>
        <Flex w="65%" direction="column">
          <Flex fontWeight="bold">Compre Também</Flex>
          <Flex direction="row">
            <Box p="4" pl="0" height="220px">
              <Image mr="2" src={selectedItem?.imagens.at(0)} />
              <Text
                fontSize="13px"
                w="190px"
                fontWeight="semibold"
                noOfLines={2}
                my="5px"
                lineHeight="1"
              >
                {selectedItem?.titulo}
              </Text>
              {selectedItem?.variante.preco_promocional !== null ? (
                <>
                  <Text fontSize="10px" w="190px" noOfLines={2} lineHeight="1">
                    De {Formatters.money(selectedItem?.variante.preco)}
                  </Text>
                  <Text
                    fontSize="md"
                    w="190px"
                    noOfLines={2}
                    color="#00BB2D"
                    fontWeight={600}
                    lineHeight="1.2"
                  >
                    Por{" "}
                    {Formatters.money(selectedItem?.variante.preco_promocional)}
                  </Text>{" "}
                </>
              ) : (
                <>
                  <Text
                    fontSize="md"
                    w="190px"
                    noOfLines={2}
                    color="#00BB2D"
                    fontWeight={600}
                    lineHeight="1.2"
                  >
                    {Formatters.money(selectedItem?.variante.preco)}
                  </Text>
                </>
              )}
            </Box>

            <Box p="4" pl="0">
              <Flex direction="column">
                {produtosAdicionais?.map((produto) => {
                  const desconto = descontos.find(
                    (x) => x.produto_id === produto._id
                  );

                  let valorPromocional;

                  if (desconto) {
                    if (desconto.desconto_porcentagem) {
                      valorPromocional =
                        produto.variante.preco_promocional ||
                        produto.variante.preco;
                      valorPromocional =
                        valorPromocional -
                        valorPromocional *
                          (desconto.desconto_porcentagem / 100);
                    } else {
                      valorPromocional =
                        produto.variante.preco_promocional ||
                        produto.variante.preco;
                      valorPromocional =
                        valorPromocional - desconto.desconto_reais;
                    }
                  }

                  return (
                    <Flex
                      justify="space-between"
                      w="400px"
                      alignItems={"center"}
                    >
                      <Checkbox
                        defaultChecked={false}
                        checked={selectedSubItens.includes(produto)}
                        onChange={(c) => {
                          if (c.target.checked) {
                            setSelectedSubItens([...selectedSubItens, produto]);
                            return;
                          }
                          setSelectedSubItens(
                            selectedSubItens.filter(
                              (x) => x._id !== produto._id
                            )
                          );
                        }}
                      />
                      <Flex w="30px" />
                      <Image
                        width="130px"
                        height="130px"
                        mr="2"
                        src={produto.variante.imagem ?? produto.imagens.at(0)}
                      />
                      <Flex direction="column">
                        <Text
                          fontSize="14px"
                          w="150px"
                          fontWeight="semibold"
                          noOfLines={2}
                          my="5px"
                          lineHeight="1"
                        >
                          {produto.titulo}
                        </Text>

                        {desconto && (
                          <>
                            <Text
                              fontSize="12px"
                              w="190px"
                              noOfLines={2}
                              lineHeight="1"
                            >
                              De {Formatters.money(produto.variante.preco)}
                            </Text>
                            <Text
                              fontSize="md"
                              w="190px"
                              noOfLines={2}
                              color="#00BB2D"
                              fontWeight={600}
                              lineHeight="1.2"
                            >
                              Por {Formatters.money(valorPromocional)}
                            </Text>{" "}
                          </>
                        )}

                        {!desconto && (
                          <Text
                            fontSize="md"
                            w="190px"
                            noOfLines={2}
                            color="#00BB2D"
                            fontWeight={600}
                            lineHeight="1.2"
                          >
                            {Formatters.money(produto.variantes[0].preco)}
                          </Text>
                        )}
                      </Flex>
                    </Flex>
                  );
                })}
              </Flex>
            </Box>

            <Center height="300px">
              <Divider orientation="vertical" />
            </Center>

            <Box
              p="4"
              pl="0"
              w="190px"
              ml="30px"
              display={"flex"}
              alignItems={"center"}
            >
              <Flex direction="column">
                <Flex
                  direction="row"
                  fontSize="xs"
                  fontWeight="600"
                  w="190px"
                  mb="2"
                >
                  <Text color="primary.400" mr="2px" fontSize={"16px"}>
                    {selectedSubItens.length ?? 0} itens{"  "}
                  </Text>
                  <Text fontSize={"16px"}> selecionados</Text>
                </Flex>
                <Text fontSize="13px" w="210px" noOfLines={2} lineHeight="1">
                  De{" "}
                  {Formatters.money(
                    selectedItem?.variantes.at(0).preco +
                      selectedSubItens.reduce(
                        (acc, item) => acc + item.variantes.at(0).preco,
                        0
                      )
                  )}
                </Text>

                <Flex direction="row">
                  <Text
                    fontSize="19px"
                    noOfLines={2}
                    fontWeight={600}
                    lineHeight="1.2"
                  >
                    por
                  </Text>
                  <Flex w="5px" />
                  <Text
                    noOfLines={2}
                    fontWeight={700}
                    fontSize="19px"
                    lineHeight="1.2"
                  >
                    {Formatters.money(getFinalPrice())}
                  </Text>
                </Flex>
                <Flex direction="row" fontSize="12px" fontWeight="600" mb="2">
                  <Text color="orange" mr="2px">
                    Você ganhou
                  </Text>
                  <Text>
                    {" "}
                    {Formatters.money(
                      selectedItem.variante.preco_promocional ||
                        selectedItem.variante.preco +
                          selectedSubItens.reduce((prev, curr) => {
                            return prev + curr.variante.preco;
                          }, 0) -
                          getFinalPrice()
                    )}{" "}
                    de desconto
                  </Text>
                </Flex>
                <Button
                  fontSize={"18px"}
                  w={"280px"}
                  height={"50px"}
                  colorScheme="purple"
                >
                  Compre Agora
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Preview;
