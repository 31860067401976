import { Box, Flex, Text, Image, Input } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

import { MdDelete } from "react-icons/md";
import Formatters from "../../../utils/formatters";

export default function TabPremiosRaspadinha({
  products,
  onRemove,
  onChangeProducts,
  disabled,
}) {
  return (
    <Flex direction={"column"}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Produtos</Th>
              <Th>Preço atual</Th>
              <Th>Estoque</Th>
              <Th>Disponivel para prêmio</Th>
              <Th>Valor desconto</Th>
              <Th>Ação</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {products.map((p) => (
              <Tr key={p._id}>
                <Td>
                  <Flex direction={"column"}>
                    <Flex maxW={"250px"} align="center">
                      <Image
                        w={"80px"}
                        objectFit={"contain"}
                        src={p.imagens[0]}
                      />
                      <Box w={"40px"} />
                      <Flex direction={"column"}>
                        <Text
                          fontSize={"sm"}
                          whiteSpace={"normal"}
                          noOfLines={2}
                        >
                          {p.titulo}
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </Td>
                <Td>
                  {p.variantes[0].preco_promocional ? (
                    <Flex direction={"column"}>
                      <Text fontSize={"sm"}>
                        De {Formatters.money(p.variantes[0].preco)}
                      </Text>
                      <Text fontSize={"sm"} color="green.400">
                        por {Formatters.money(p.variantes[0].preco_promocional)}
                      </Text>
                    </Flex>
                  ) : (
                    <Text fontSize={"sm"}>
                      {Formatters.money(p.variantes[0].preco)}
                    </Text>
                  )}
                </Td>
                <Td>
                  <Flex direction={"column"}>
                    <Text fontSize={"sm"}>{p.variantes[0].estoque}</Text>
                  </Flex>
                </Td>
                <Td>
                  <Input
                    disabled={disabled}
                    textAlign={"center"}
                    variant={"filled"}
                    value={p.quantidade}
                    w="100px"
                    placeholder="0"
                    fontWeight={700}
                    onChange={(e) => {
                      p.quantidade = Number(e.target.value);
                      onChangeProducts(products);
                    }}
                  />
                </Td>
                <Td>
                  <Flex direction={"column"} w="100px" pt={"15px"}>
                    <Input
                      disabled={disabled}
                      textAlign={"center"}
                      variant={"filled"}
                      placeholder="0%"
                      value={p.desconto}
                      fontWeight={700}
                      onChange={(e) => {
                        p.desconto = Number(e.target.value);
                        onChangeProducts(products);
                      }}
                    />
                    <Text textAlign={"center"} fontSize={"sm"}>
                      {Formatters.money(
                        (p.variantes[0].preco_promocional ||
                          p.variantes[0].preco) *
                          (p.desconto / 100)
                      ) ?? "R$ 0,00"}
                    </Text>
                  </Flex>
                </Td>
                <Td>
                  {!disabled && (
                    <MdDelete
                      size="20"
                      cursor="pointer"
                      onClick={() => {
                        onRemove(p._id);
                      }}
                    />
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
