import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";

import { MdCalendarToday } from "react-icons/md";
import useAuthToken from "../../hooks/useAuthToken";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import PageScaffold from "../../components/PageScaffold";
import TabCuponsLembreteCarrinho from "./components/tabCuponsLembreteCarrinho";
import { useRef, useState, useEffect } from "react";
import CuponsSelectorDialog from "../../components/CuponsSelectorDialog";
import LembretesRepository from "../../repositories/lembretes_repository";

import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CupomDeDescontoRepository from "../../repositories/cupom_de_desconto_repository";
import backAsset from "../../assets/back.png";

export default function NovoLembreteCarrinhoPage() {
  const navigate = useNavigate();
  const token = useAuthToken();
  const toast = useToast();
  const repo = new CupomDeDescontoRepository({ token });
  const lembreteRepo = new LembretesRepository({ token });
  const estabelecimento = useEstabelecimento();
  const [selectedCupons, setSelectedCupons] = useState([]);
  const nomeRef = useRef();
  const dataRef = useRef();
  const mensagemRef = useRef();
  const [agendado, setAgendado] = useState("agora");
  const [allCupons, setAllCupons] = useState([]);

  const [requestInProgress, setRequestInProgress] = useState(false);

  const [inputUpdated, setInputUpdated] = useState(false);

  useEffect(() => {}, [inputUpdated]);

  useEffect(() => {
    fetchCupons();
  }, []);

  const fetchCupons = async () => {
    let data = {};

    data["vendedor_id"] = estabelecimento._id;
    repo.consultar(data).then((res) => {
      if (res.status === 200) {
        setAllCupons(res.data);
      }
    });
  };

  const [isOpen, setIsOpen] = useState(false);
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const removeOne = (c) => {
    setSelectedCupons(selectedCupons.filter((cupom) => cupom._id !== c._id));
  };

  const saveLembrete = async () => {
    let data = {
      vendedor_id: estabelecimento._id,
      nome: nomeRef.current.value,
      data_agendamento: dataRef?.current?.value,
      mensagem: mensagemRef.current.value,
      cupom: selectedCupons.map((c) => c._id),
    };
    if (requestInProgress) {
      toast({
        title: "Por favor, aguarde!",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setRequestInProgress(true);

    lembreteRepo.cadastrar(data).then((res) => {
      setRequestInProgress(false);
      if (res.status === 200) {
        toast({
          title: "Lembrete cadastrado com sucesso",
          status: "success",
        });
        navigate("/lembrete-carrinho");
      } else {
        toast({
          title: res.data.error,
          status: "error",
        });
      }
    });
  };
  return (
    <>
      <PageScaffold showSideBar={true} showChat={false}>
        <Box
          paddingTop={"32px"}
          paddingBottom={"5px"}
          fontWeight={"700"}
          fontSize={"22px"}
        >
          <Flex direction={"row"} alignItems={"center"}>
            <Image
              cursor={"pointer"}
              src={backAsset}
              height={"15px"}
              alt="Voltar"
              onClick={() => {
                window.history.back();
              }}
            />
            <Box width={"10px"} />
            <span>Criar Lembrete</span>
          </Flex>
        </Box>
        <Flex w="full" direction="column" py="5" pb="0">
          <Box boxShadow="sm" w="full" borderRadius="8px" p="5" bgColor="white">
            <Text fontSize="md" fontWeight={"bold"}>
              Informações Básicas
            </Text>
            <Text fontSize="sm" noOfLines="2" mt="5" fontWeight="medium">
              Nome do lembrete no chat
            </Text>
            <InputGroup mt="2" w="300px" size="sm">
              <Input
                variant="customGrey"
                w="230px"
                size="sm"
                ref={nomeRef}
                maxlength="60"
                onChange={(e) => {
                  setInputUpdated(!inputUpdated);
                }}
              />
              <InputRightElement
                children={
                  <Text fontSize="xs">
                    {nomeRef?.current?.value?.length}/60
                  </Text>
                }
                w="70px"
              />
            </InputGroup>

            <Flex h={"10px"} />
            <Text fontSize="sm" noOfLines="2" mt="2" fontWeight="medium">
              Horário do lembrete
            </Text>
            <RadioGroup
              onChange={(v) => {
                setAgendado(v);
              }}
              value={agendado}
              size="sm"
              fontSize="xs"
            >
              <Stack direction="row">
                <Radio checked={agendado === "agora"} value={"agora"}>
                  Enviar agora
                </Radio>
                <Radio checked={agendado === "agendado"} value={"agendado"}>
                  Agendar envio
                </Radio>
              </Stack>
            </RadioGroup>
            <Flex h={"10px"} />
            {agendado === "agendado" && (
              <InputGroup mt="2" w="300px" size="sm">
                <InputLeftAddon children={<MdCalendarToday />} />
                <Input type="date" placeholder="01/01/2023" ref={dataRef} />
              </InputGroup>
            )}
            <Flex h={"50px"} />
          </Box>

          <Box
            boxShadow="sm"
            w="full"
            borderRadius="8px"
            p="5"
            mt="5"
            bgColor="white"
          >
            <Heading fontSize="md">Conteúdo do lembrete</Heading>
            <Text fontSize="xs" noOfLines="2" mt="5">
              Mensagem do lembrete do chat
            </Text>
            <Textarea
              variant="filled"
              placeholder="Digite aqui uma mensagem ..."
              w="60%"
              size="sm"
              ref={mensagemRef}
              mt="4"
            />
            <Flex h={"50px"} />
          </Box>

          {/* <Box
            boxShadow="sm"
            w="full"
            borderRadius="8px"
            p="5"
            mt="5"
            bgColor="white"
          >
            <Heading fontSize="md">Adicionar cupom</Heading>
            <Text fontSize="xs" noOfLines="2">
              Envie seu lembrete com um cupom de desconto para aumentar suas
              chances de venda.
            </Text>
            <Button
              backgroundColor="primary.400"
              color="white"
              size="sm"
              w="150px"
              mt="2"
              onClick={onOpen}
            >
              + Adicionar cupom
            </Button>
          </Box> */}

          <Box
            boxShadow="sm"
            w="full"
            borderRadius="8px"
            p="5"
            mt="5"
            bgColor="white"
          >
            <Heading fontSize="md">Adicionar Cupom</Heading>
            <Flex h={"10px"} />
            <Text fontSize="xs" noOfLines="2">
              Envie com o seu lembrete um cupom de desconto para aumentar as
              suas chances de venda.
            </Text>
            <Flex h={"10px"} />
            <TabCuponsLembreteCarrinho
              allCupons={allCupons}
              selectedCupons={selectedCupons}
              removeOne={removeOne}
            />
          </Box>

          <Box w="full" mt="5" borderRadius="8px" p="5" align="end">
            <Button ml="2">Cancelar</Button>
            <Button
              ml="2"
              bgColor="primary.400"
              color="white"
              onClick={saveLembrete}
            >
              Enviar
            </Button>
          </Box>
          <Flex h={"50px"} />
        </Flex>
      </PageScaffold>
      <CuponsSelectorDialog
        isOpen={isOpen}
        onSelect={(p) => {
          console.log(p);
          setSelectedCupons(p);

          onClose();
        }}
        onClose={() => {
          console.log("tapped");
          onClose();
        }}
      />
    </>
  );
}
