import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";

export default function VideoPopUp({ onOpen, onClose, isOpen }) {
  const cancelRef = useRef();

  return (
    <AlertDialog
      size={"xl2"}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent maxWidth={"690px"}>
          <AlertDialogBody size={"xl2"}>
            <Box height={"20px"} />
            <iframe
              src="https://player.vimeo.com/video/889312039?h=b14c5f5ea7"
              width="640"
              height="360"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              size={"lg"}
              colorScheme="primary"
              width={"100%"}
              onClick={() => {
                onClose();
              }}
            >
              Quero começar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
