import PageScaffold from "../../../components/PageScaffold";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Text,
  useToast,
} from "@chakra-ui/react";

import EstabelecimentoRepository from "../../../repositories/estabelecimentos_repository";
import { AuthContext } from "../../../contexts/authContext";
import { useContext, useState, useEffect, useRef } from "react";
import { MdCloud, MdCloudUpload, MdUpload } from "react-icons/md";
import { useFilePicker } from "use-file-picker";
import useEstabelecimento from "../../../hooks/useEstabelecimento";
import VendedoresRepository from "../../../repositories/vendedores_repositories";

function dataURItoBlob(dataURI) {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);

  // create a view into the buffer
  var ia = new Uint8Array(ab);

  // set the bytes of the buffer to the correct values
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  // write the ArrayBuffer to a blob, and you're done
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export default function TabConfiguracoesFiscais({ isPj }) {
  const toast = useToast();
  const context = useContext(AuthContext);
  const repo = new VendedoresRepository({ token: context.currentToken });
  const loja = useEstabelecimento();

  const [ie, setIe] = useState("");
  const [serie, setSerie] = useState("");
  const [senha, setSenha] = useState("");

  useEffect(() => {
    setSerie(loja?.dados_fiscais?.serie_nfe);
    setIe(loja?.dados_fiscais?.inscricao_estadual);
  }, []);

  const [uploadFile, file] = useFilePicker({
    readAs: "DataURL",
    multiple: false,
    limitFilesConfig: { max: 2 },
    maxFileSize: 3, // in megabytes
  });

  const setupEnotas = async () => {
    const response = await repo.atualizarEnotas({
      inscricao_estadual: ie,
      serie_nfe: serie,
    });

    if (response.status === 200) {
      toast({
        description: "Emissor NFE configurado com sucesso",
        status: "success",
      });
    } else {
      toast({
        description: response.data.error,
        status: "error",
      });
    }
  };

  const saveFormData = async () => {
    if (!isPj) {
      return;
    }

    await setupEnotas();

    const formData = new FormData();

    formData.append("vendendor_id", context.firebaseUser.uid);
    formData.append("serie", serie);
    formData.append("ie", ie);

    if (file.filesContent.length === 0) {
      toast({
        description: "Selecione um arquivo",
        status: "error",
      });
      return;
    }
    formData.append(
      "arquivo",
      dataURItoBlob(file.filesContent[0].content),
      file.filesContent[0].name
    );
    formData.append("senha", senha);

    const certificadoResponse = await repo.cadastrarCertificado(formData);
    if (certificadoResponse.status === 200) {
      toast({
        description: "Dados alterados com sucesso",
        status: "success",
      });
    } else {
      let error = certificadoResponse?.data.error;

      toast({
        description: error ?? "Erro não identificado",
        status: "error",
      });
    }
  };

  const [alterarTipoConta, setAlterarTipoConta] = useState(false);

  const toogleTipoContaForm = () => {
    setAlterarTipoConta(!alterarTipoConta);
  };

  return (
    <Flex w={"100%"} margin={"0 0"} direction={"row"} justify="space-between">
      <Box width={"100%"} borderRadius={"8px"} marginTop={"5px"}>
        {!isPj && (
          <Text pb={"20px"} fontWeight={"bold"} color={"red"}>
            Não é possível configurar o Emissor NF-e para CPF. Migre para CNPJ.
          </Text>
        )}

        <Flex direction="column" opacity={isPj ? 1 : 0.5}>
          <Text>Inscrição Estadual</Text>
          <Input
            variant="filled"
            size="lg"
            readOnly={!isPj}
            value={ie}
            onChange={(e) => {
              setIe(e.target.value);
            }}
            w="190px"
            borderRadius="4"
          />
          <Text mt="1">Série NFE</Text>
          <Input
            variant="filled"
            size="lg"
            value={serie}
            readOnly={!isPj}
            onChange={(e) => {
              setSerie(e.target.value);
            }}
            w="60px"
            borderRadius="4"
          />
          <Text mt="1">Certificado</Text>
          <InputGroup
            size="sm"
            w="300px"
            variant="filled"
            onClick={isPj ? uploadFile : null}
          >
            <Input
              readOnly="true"
              size="lg"
              value={
                file.filesContent[0]?.name ?? loja.dados_fiscais?.razao_social
              }
              onChange={(e) => {}}
              mr="2"
              borderRadius="4"
            />
            <InputRightElement mr="5" pt="13px">
              <MdCloudUpload cursor="pointer" color="grey" size="30px" />
            </InputRightElement>
          </InputGroup>
          <Text fontSize="10px">
            Valido até:{" "}
            {loja?.dados_fiscais?.validade_certificado ?? "Não preenchido"}
          </Text>
          <Text mt="1">Senha do Certificado</Text>
          <Input
            variant="filled"
            size="lg"
            readOnly={!isPj}
            value={senha}
            onChange={(e) => {
              setSenha(e.target.value);
            }}
            w="280px"
            borderRadius="4"
          />
          <Flex direction="row" align="center" mt="1">
            <Checkbox isChecked={loja.dados_fiscais?.simples_nacional} mr="2" />
            <Text>Optante simples nacional</Text>
          </Flex>
          <Box w="full" align="end" mt="5">
            <Button
              colorScheme="purple"
              size="sm"
              onClick={async () => {
                saveFormData();
              }}
            >
              Salvar
            </Button>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
}
