import { buildHttpClient } from "../services/api";
import axios from "axios";

class VendedoresRepository {
  constructor({ token }) {
    this.http = buildHttpClient(token);

    this.http.interceptors.request.use(
      function (config) {
        if (!config.headers.token) {
          throw new axios.Cancel("");
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  }

  cadastrar(params) {
    return this.http.post("/vendedores/cadastrar", params);
  }

  alterar(params) {
    return this.http.post("/vendedores/alterar", params);
  }

  migrarCnpj(params) {
    return this.http.post("/vendedores/migrar-cnpj", params);
  }

  consultar(params) {
    return this.http.post("/vendedores/consultar", params);
  }

  cadastrarCertificado(params) {
    return this.http.post("/vendedores/upload-certificado", params);
  }

  atualizarContaBancaria(params) {
    return this.http.post("/vendedores/atualizar-conta-bancaria", params);
  }

  consultarContaBancaria(params) {
    return this.http.post("/vendedores/consultar-conta-bancaria", params);
  }

  consultarDashboard(params) {
    return this.http.post("/vendedores/consultar-dashboard", params);
  }

  atualizarEnotas(params) {
    return this.http.post("/vendedores/cadastrar-nfe", params);
  }

  consultarSaldo(params) {
    return this.http.post("/vendedores/consultar-saldo", params);
  }

  sacarSaldo(params) {
    return this.http.post("/vendedores/sacar-saldo", params);
  }

  consultarCarrinhosAbandonados(params) {
    return this.http.post(
      "/vendedores/consultar-carrinhos-abandonados",
      params
    );
  }

  completarCadastroPerfil(params) {
    return this.http.post("/vendedores/completar-cadastro-perfil", params);
  }

  completarCadastroEndereco(params) {
    return this.http.post("/vendedores/completar-cadastro-endereco", params);
  }

  completarCadastroTelefone(params) {
    return this.http.post("/vendedores/completar-cadastro-telefone", params);
  }

  completarCadastroTipoLoja(params) {
    return this.http.post("/vendedores/completar-cadastro-tipo-loja", params);
  }
}

export default VendedoresRepository;
