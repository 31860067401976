import React, { useEffect } from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  Select,
  Text,
  Button,
  useToast,
  Image,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdClose, MdFilter } from "react-icons/md";
import filterIcon from "../../../assets/filtro.svg";

export default function FilterBox({ openFilters, onChange, setTab, filters }) {
  const toast = useToast();
  const [text, setText] = useState("");
  const [category, setCategory] = useState("");
  const [minStock, setMinStock] = useState("");
  const [maxStock, setMaxStock] = useState("");
  const [minSales, setMinSales] = useState("");
  const [maxSales, setMaxSales] = useState("");
  const [stockIsValid, setStockIsValid] = useState(true);
  const [salesIsValid, setSalesIsValid] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);

  const fetchWithFilters = async () => {
    onChange({
      texto_busca: text,
      filtros: {
        categoria_id: "",
        categoria: category,
        estoque_min: minStock,
        estoque_max: maxStock,
        vendas_min: minSales,
        vendas_max: maxSales,
      },
    });
  };
  const clearFilters = async () => {
    setText("");
    setCategory("");
    setMinStock("");
    setMaxStock("");
    setMinSales("");
    setMaxSales("");

    onChange({
      texto_busca: "",
      filtros: {
        categoria_id: "",
        categoria: "",
        estoque_min: "",
        estoque_max: "",
        vendas_min: "",
        vendas_max: "",
      },
    });
  };

  let filtro = filters?.filtros;
  return (
    <Box
      boxShadow="sm"
      width={"100%"}
      borderRadius={"8px"}
      marginTop={"5px"}
      padding={"16px"}
      py="8px"
      backgroundColor={"white"}
    >
      <Flex padding={"8px 0px"} align="center" justify="space-between">
        <Flex w="max-content" align="center">
          <Box
            py="8px"
            pl="5px"
            backgroundColor="#E4E4E4"
            borderRadius="4px"
            mr="10px"
            fontWeight={"semibold"}
          >
            <Flex
              justify="space-between"
              align="center"
              px="2"
              cursor="pointer"
            >
              <Flex direction="row" align="center" onClick={openFilters}>
                <Image
                  mr={"2"}
                  width={"17px"}
                  src={filterIcon}
                  onClick={openFilters}
                />{" "}
                <Text ml="2px">Filtrar</Text>
                <Box w="20px" />
                <Flex direction="column" fontSize="8px" color="#693FF3">
                  {filtro?.estoque_min !== "" && (
                    <Text>
                      Estoque mínimo{": "}
                      {filtro?.estoque_min}
                    </Text>
                  )}

                  {filtro?.estoque_max !== "" && (
                    <Text>
                      Estoque máximo{": "}
                      {filtro?.estoque_max}
                    </Text>
                  )}

                  {filtro?.vendas_min !== "" && (
                    <Text>
                      Vendas mínimo{": "}
                      {filtro?.vendas_min}
                    </Text>
                  )}

                  {filtro?.vendas_max !== "" && (
                    <Text>
                      Vendas máximo{": "}
                      {filtro?.vendas_max}
                    </Text>
                  )}
                </Flex>
                <Box w="5px" />
              </Flex>
              <MdClose
                cursor="pointer"
                color="#693FF3"
                onClick={() => {
                  clearFilters();
                  setTab(0);
                }}
              />
            </Flex>
          </Box>
          <Input
            w="300px"
            type="text"
            fontWeight={500}
            placeholder="Buscar produto..."
            value={text}
            onChange={(e) => setText(e.target.value)}
            variant={"filled"}
          />
          <Select
            mx="2"
            w="130px"
            variant={"filled"}
            fontWeight={700}
            value={currentTab}
            onChange={(e) => {
              setTab(e.target.value);
              setCurrentTab(e.target.value);
            }}
          >
            <option value="0">Todos</option>
            <option value="1">Ativos</option>
            <option value="2">Pendentes</option>
            <option value="3">Esgotados</option>
            <option value="4">Denuncias</option>
            <option value="5">Inativos</option>
            <option value="6">Rascunhos</option>
          </Select>
        </Flex>

        <Flex direction="row" align="center">
          <Button
            w="140px"
            marginLeft={"15px"}
            backgroundColor="primary.400"
            color={"white"}
            _hover={{ opacity: "0.8" }}
            onClick={fetchWithFilters}
          >
            Buscar
          </Button>
          <Button
            onClick={clearFilters}
            w="140px"
            marginLeft={"15px"}
            variant={"outline"}
          >
            Redefinir
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
