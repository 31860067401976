import {
  Box,
  Circle,
  Divider,
  Flex,
  Heading,
  Link,
  Text,
} from "@chakra-ui/react";
import { MdCircle } from "react-icons/md";
import useAuthToken from "../../../hooks/useAuthToken";
import AdminRepository from "../../../repositories/admin_repository";
import { useState } from "react";
import { useEffect } from "react";

function Tile({ title, description, date, link }) {
  return (
    <Link
      target="_blank"
      href={link}
      _hover={{
        textDecoration: "none",
        color: "#693FF3 !important",
      }}
    >
      <Flex direction="column">
        <Flex direction="row" align="center">
          <MdCircle color="#693FF3" />
          <Text fontSize="14px" fontWeight="bold" ml="5px">
            {title}
          </Text>
        </Flex>
        <Flex ml="5px" />
        <Text
          ml="21px"
          mb={"2px"}
          mt={"2px"}
          fontSize="14px"
          fontWeight={"600"}
          opacity={"0.7"}
        >
          {description}
        </Text>
        <Text ml="21px" fontSize="12px" fontWeight={"700"} opacity={"0.8"}>
          {new Intl.DateTimeFormat("pt-BR", {
            year: "numeric",
            month: "long",
            day: "numeric",
          }).format(new Date(date))}
        </Text>
        <Flex my="2" />
      </Flex>
    </Link>
  );
}

export default function BoxAvisosDeshopnise() {
  const token = useAuthToken();
  const repo = new AdminRepository({ token });
  const [avisos, setAvisos] = useState([]);

  useEffect(() => {
    repo.consultarAvisos().then((res) => {
      if (res.status === 200) {
        setAvisos(res.data.reverse());
      }
    });
  }, []);

  return (
    <Box
      boxShadow="sm"
      height={"84vh"}
      borderRadius={"8px"}
      mt="2"
      backgroundColor={"white"}
      p="5"
    >
      <Heading fontSize="18px">Central Deshopnise</Heading>
      <Box overflowX={"hidden"} height={"77vh"} mt={"20px"}>
        {avisos.map((a) => (
          <Tile
            key={a.id}
            title={a.title}
            description={a.description}
            date={a.date}
            link={a.url}
          />
        ))}
      </Box>
    </Box>
  );
}
