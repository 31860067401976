import { Box, Button, Flex, Text, Divider, useToast } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Formatters from "../../../utils/formatters";
import Dinero from "dinero.js";
import { Link } from "react-router-dom";
import useAuthToken from "../../../hooks/useAuthToken";
import VendedoresRepository from "../../../repositories/vendedores_repositories";
import { MdChevronRight } from "react-icons/md";
import SaqueDialog from "../../../components/SaqueDialog";

export default function SaldoCard({ data, onRefresh }) {
  const [saldoDisponivel, setSaldoDisponivel] = useState("R$ 0,00");
  const [saldoPendente, setSaldoPendente] = useState("R$ 0,00");
  const [saqueDisponivel, setSaqueDisponivel] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data && data.saldo_disponivel) {
      if (data.saldo_disponivel > 0) {
        setSaldoDisponivel(Formatters.money(data.saldo_disponivel / 100));
        setSaqueDisponivel(data.pode_sacar);
      }

      if (data.saldo_pendente > 0) {
        setSaldoPendente(Formatters.money(data.saldo_pendente / 100));
      }
    }
  }, [data]);

  return (
    <Box
      boxShadow="sm"
      width={"100%"}
      borderRadius={"8px"}
      padding={"16px"}
      pt={"0px"}
      pl="0px"
    >
      <SaqueDialog
        onSaqueDone={() => {
          setIsOpen(false);
          onRefresh();
        }}
        data={data}
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
      <Flex direction={"row"} alignItems={"start"}>
        <Flex direction={"column"} pl="0px">
          <Text fontWeight={500}>Saldo da carteira</Text>
          <Text fontSize={"2xl"} fontWeight={700}>
            {saldoDisponivel}
          </Text>
          <Button
            size="sm"
            w={"60px"}
            mt={"10px"}
            _hover={
              !saqueDisponivel
                ? { backgroundColor: "primary.500" }
                : { backgroundColor: "primary.400" }
            }
            backgroundColor={"primary.400"}
            color="white"
            // disabled={!saqueDisponivel}
            onClick={async () => {
              setIsOpen(true);
              // const res = await repo.sacarSaldo();
              // if (res.status === 200) {
              //   alert("Saque solicitado com sucesso");
              // } else {
              //   alert(res.data.error);
              // }
            }}
          >
            Sacar
          </Button>
        </Flex>
        <Box w={"40px"} />

        <Divider
          borderColor={"#eaeaea"}
          height={"100px"}
          opacity={0.9}
          orientation="vertical"
        />
        <Box w={"40px"} />

        <Flex direction={"column"}>
          <Text fontWeight={500}>Saldo a receber</Text>
          <Text fontSize={"2xl"} fontWeight={700}>
            {saldoPendente}
          </Text>
        </Flex>
        <Box w={"40px"} />
        <Divider
          borderColor={"#eaeaea"}
          height={"100px"}
          opacity={0.9}
          orientation="vertical"
        />
        <Box w={"40px"} />
        <Link to={"/config-bancarias"}>
          <Flex
            direction={"row"}
            alignItems="center"
            justify="space-between"
            border="1px"
            borderRadius="8px"
            borderColor="primary.400"
            p="4px"
          >
            <Flex direction={"column"} p={"10px"}>
              <Text fontWeight={"medium"}>Conta bancária</Text>
              <Text fontSize="10px" color="primary.400" fontWeight="bold">
                Adicionar conta bancária
              </Text>
            </Flex>
            <MdChevronRight color="#693FF3" size="25px" />
          </Flex>
        </Link>
      </Flex>
    </Box>
  );
}
