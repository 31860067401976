import { Flex, Heading, Text, Input, Textarea, Box } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useFilePicker } from "use-file-picker";
import AddImageButtonComponent from "../../../components/AddImageButtonComponent";
import ImageUploader from "../helpers/ImageUploader";
import ImageCropDialog from "../ImageCropDialog";

export default function InformacoesBase({ value, onChange }) {
  const [cropping, setCropping] = useState(null);

  const [openFileSelector, { plainFiles }] = useFilePicker({
    readAs: "DataURL",
    accept: "Images/*",
    multiple: true,
    limitFilesConfig: { max: 5 },
    maxFileSize: 20, // in megabytes
  });

  const [openDescricaoFileSelector, { plainFiles: descriptionPlainFiles }] =
    useFilePicker({
      readAs: "DataURL",
      accept: "Images/*",
      multiple: true,
      limitFilesConfig: { max: 5 },
      maxFileSize: 20, // in megabytes
    });

  useEffect(() => {
    if (!plainFiles) return;

    if (plainFiles.length > 0) {
      setCropping(plainFiles[0]);
    }

    return;
    plainFiles.forEach((file) => {
      ImageUploader.upload(file, { compress: true }).then((url) => {
        onChange((anuncio) => ({
          ...anuncio,
          imagens: [...anuncio.imagens, url],
        }));
      });
    });
  }, [plainFiles]);

  useEffect(() => {
    descriptionPlainFiles.forEach((file) => {
      ImageUploader.upload(file, { compress: false }).then((url) => {
        onChange((anuncio) => ({
          ...anuncio,
          imagens_descricao: [...anuncio.imagens_descricao, url],
        }));
      });
    });
  }, [descriptionPlainFiles]);

  return (
    <Flex direction="column">
      <Box
        boxShadow="sm"
        w="full"
        borderRadius="8px"
        p="5"
        mt="5px"
        py="1"
        bgColor="white"
        pb={"20px"}
      >
        <ImageCropDialog
          isOpen={cropping !== null}
          file={cropping}
          onSave={(blob) => {
            console.log({ blob, cropping });

            blob["name"] = cropping.name;

            ImageUploader.upload(blob, { compress: true }).then((url) => {
              onChange((anuncio) => ({
                ...anuncio,
                imagens: [...anuncio.imagens, url],
              }));
            });
            setCropping(null);
          }}
          onClose={() => {
            setCropping(null);
          }}
        />
        <Flex direction="row">
          {value.imagens.map((file, index) => {
            return (
              <AddImageButtonComponent
                key={index}
                showCapaLabel={index === 0}
                onClick={openFileSelector}
                borderStyle="solid"
                borderColor="primary.400"
                borderWidth="2px"
                onDelete={() => {
                  onChange((anuncio) => ({
                    ...anuncio,
                    imagens: value.imagens.filter((_, i) => i !== index),
                  }));
                }}
                file={file}
              />
            );
          })}
          {value.imagens.length < 5 && (
            <AddImageButtonComponent onClick={openFileSelector} />
          )}
        </Flex>
      </Box>
      <Box
        boxShadow="sm"
        w="full"
        mt="5px"
        borderRadius="8px"
        p="5"
        bgColor="white"
      >
        <Flex direction="column" maxW={"1000px"}>
          <Flex direction="column" maxW="800px">
            <Text fontWeight="semibold">
              Nome do {value.tipo === "produto" ? "Produto" : "Serviço"}
            </Text>
            <Input
              fontSize={"sm"}
              variant="customGrey"
              size="lg"
              mb="2"
              value={value.titulo}
              onChange={(e) => {
                onChange((anuncio) => ({
                  ...anuncio,
                  titulo: e.target.value,
                }));
              }}
            />
          </Flex>
          <Flex direction="column" maxW="800px">
            <Text fontWeight="semibold">
              Descrição do {value.tipo === "produto" ? "Produto" : "Serviço"}
            </Text>
            <Textarea
              fontSize={"sm"}
              variant="customGrey"
              size="lg"
              minH={"350px"}
              mb="2"
              maxLength="10000"
              value={value.descricao}
              onChange={(e) => {
                onChange((anuncio) => ({
                  ...anuncio,
                  descricao: e.target.value,
                }));
              }}
            />
          </Flex>
          <Flex direction="column" maxW="70%">
            <Text fontWeight="semibold">Imagem da descrição (opcional)</Text>

            <Flex my="5" direction="row">
              {value.imagens_descricao.map((file, index) => {
                return (
                  <AddImageButtonComponent
                    key={index}
                    onClick={openFileSelector}
                    onDelete={() => {
                      onChange((anuncio) => ({
                        ...anuncio,
                        imagens_descricao: value.imagens_descricao.filter(
                          (_, i) => i !== index
                        ),
                      }));
                    }}
                    file={file}
                  />
                );
              })}
              {value.imagens_descricao.length < 5 && (
                <AddImageButtonComponent onClick={openDescricaoFileSelector} />
              )}
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
}
