import React from "react";
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Text,
  Button,
} from "@chakra-ui/react";
import { useRef } from "react";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";
import { set } from "date-fns";

export default function FilterBox({
  onFilterChange = () => {},
  setTab,
  currentTab,
  numeroPedidos,
}) {
  const [currentFilterKey, setCurrentFilterKey] =
    React.useState("usuario_nome");
  const [currentFilterValue, setCurrentFilterValue] = React.useState("");

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  return (
    <Box
      boxShadow="sm"
      width={"100%"}
      borderRadius={"8px"}
      marginTop={"5px"}
      padding={"16px"}
      backgroundColor={"white"}
    >
      <Flex direction="column" overflow="auto">
        <Text px="15px" fontWeight={800}>
          {numeroPedidos} Pedidos
        </Text>
        <Flex padding={"8px 0px"} align="center" justify="space-between">
          <Flex direction="row" alignItems="center">
            <Select
              variant={"customGrey"}
              mx="2"
              w="150px"
              fontWeight={700}
              defaultValue={currentTab}
              value={currentTab}
              onChange={(e) => {
                setTab(e.target.value);
              }}
            >
              <option value="0">Todos</option>
              <option value="1">A Enviar</option>
              <option value="2">Enviados</option>
              <option value="3">Concluídos</option>
              <option value="4">Cancelados</option>
              <option value="5">Devolução</option>
            </Select>
            <Box w="20px" />
            <Flex w="300px">
              <Input
                type="text"
                fontWeight={500}
                placeholder="Digite o nome, Id ou PLP"
                w="300px"
                value={currentFilterValue}
                onChange={(e) => {
                  setCurrentFilterValue(e.target.value);
                }}
                variant={"filled"}
              />
            </Flex>
            <Box w="20px" />
            <Select
              variant={"filled"}
              mx="2"
              w="140px"
              fontWeight={700}
              value={currentTab}
              onChange={(e) => {
                setTab(e.target.value);
              }}
            >
              <option value="todos">Todos</option>
              <option value="a_processar">A processar</option>
              <option value="processado">Processado</option>
            </Select>
          </Flex>

          <Flex direction="row" alignItems="center">
            <CustomDateRangePicker
              currentRange={[startDate, endDate]}
              onChange={({ start, end }) => {
                setStartDate(start);
                setEndDate(end);
              }}
              size="md"
            />

            {/* <Input
              variant={"customGrey"}
              fontWeight={700}
              size="sm"
              type={"date"}
              ref={startDateRef}
              isInvalid={!rangeValido}
              w="125px"
              onChange={() => {
                setRangeValido(
                  dateRangeIsValid({
                    first: startDateRef.current.value,
                    last: endDateRef.current.value,
                  })
                );
              }}
            />
            <Flex h="31px" bgColor="#F9F9F9" display="flex" alignItems="center">
              <Text>-</Text>
            </Flex>
            <Input
              variant={"customGrey"}
              fontWeight={700}
              size="sm"
              type={"date"}
              ref={endDateRef}
              isInvalid={!rangeValido}
              w="125px"
              onChange={() => {
                setRangeValido(
                  dateRangeIsValid({
                    first: startDateRef.current.value,
                    last: endDateRef.current.value,
                  })
                );
              }}
            /> */}
            <Button
              width={"140px"}
              marginLeft={"15px"}
              backgroundColor="primary.400"
              color={"white"}
              _hover={{ opacity: "0.8" }}
              onClick={() => {
                let key;

                if (isNaN(Number(currentFilterValue))) {
                  key = "usuario_nome";
                } else {
                  key = "numero_pedido";
                }

                onFilterChange({
                  [key]: currentFilterValue,
                  data_inicio: startDate,
                  data_fim: endDate,
                });
              }}
            >
              Buscar
            </Button>
            <Button
              onClick={() => {
                onFilterChange({});
                setCurrentFilterValue("");
                setCurrentFilterKey("usuario_nome");

                setStartDate(null);
                setEndDate(null);
              }}
              width={"140px"}
              marginLeft={"15px"}
              variant={"outline"}
            >
              Redefinir
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
