import PageScaffold from "../../components/PageScaffold";
import {
  Box,
  Flex,
  Tabs,
  TabPanel,
  TabPanels,
  Heading,
  Text,
  useToast,
  Divider,
  Center,
  Button,
} from "@chakra-ui/react";
import InformacoesAlteracaoTitularidade from "./Components/BoxAvisoAlteracaoTitularidade";
import TabCadastro from "./Components/TabCadastro";
import TabPessoaJuridica from "./Components/TabPessoaJuridica";
import { AuthContext } from "../../contexts/authContext";
import { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import ConfiguracoesFiscaisPage from "./Components/TabConfiguracoesFiscais";
import PageHeader from "../../components/PageHeader";
import useEstabelecimento from "../../hooks/useEstabelecimento";

export default function Configuracao() {
  const estabelecimento = useEstabelecimento();
  const [params] = useSearchParams();
  const toast = useToast();
  const context = useContext(AuthContext);
  const repo = new VendedoresRepository({ token: context.currentToken });
  const [loja, setLoja] = useState(null);
  const [isPj, setIsPj] = useState(false);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (params.get("target") == "configuracoes-loja") {
      setTab(1);
    }
    //params.get("target")
    //setTab();

    fetchLojaData();
  }, [params]);

  const fetchLojaData = async (params) => {
    let data = {};
    if (params != null) {
      data = params;
    }
    data["firebase_id"] = context.firebaseUser.uid;

    repo.consultar(data).then((res) => {
      if (res.status === 200) {
        setLoja(res.data);
        setIsPj(res.data.tipo_cadastro !== "pessoa_fisica");
      }
    });
  };

  const saveData = async (loja) => {
    repo.alterar(loja).then((res) => {
      if (res.status === 200) {
        toast({
          description: "Dados alterados com sucesso",
          status: "success",
        });
      } else {
        toast({
          description: "Houve um erro ao alterar os dados",
          status: "sucess",
        });
      }
    });
  };

  const [alterarTipoConta, setAlterarTipoConta] = useState(false);

  const toogleTipoContaForm = () => {
    setAlterarTipoConta(!alterarTipoConta);
  };

  if (estabelecimento.status === "bloqueado") {
    return (
      <PageScaffold showSideBar={true}>
        <Center h={"90vh"}>
          <Text fontWeight={"600"}>Sua conta foi bloqueada</Text>
        </Center>
      </PageScaffold>
    );
  }

  return (
    <PageScaffold showSideBar={true}>
      <Flex direction="column">
        <PageHeader
          title="Configurações"
          subtitle="Informações básicas e fiscais da sua loja"
        />

        <Box paddingBottom={"4px"} fontWeight={"600"} fontSize={"16px"}>
          {loja?.pre_cadastro && (
            <Flex direction={"column"}>
              <Text fontWeight={"bold"}>
                Faça a ativação da sua conta para configurar sua Loja.
              </Text>
              <Box mt="8px">
                <Button
                  colorScheme="purple"
                  backgroundColor={"#009E45"}
                  onClick={() => {
                    window.location.href = "/completar-cadastro";
                  }}
                >
                  Ativar conta
                </Button>
              </Box>
            </Flex>
          )}

          {/* <span>Meus Envios</span> */}
          {!loja?.pre_cadastro && (
            <Flex direction="row" mb="5" fontWeight={"bold"}>
              <Box
                py="4"
                mr="60px"
                color={tab === 0 ? "primary.400" : "grey"}
                borderBottom={tab === 0 ? "2px solid #693FF3" : null}
                onClick={() => {
                  setAlterarTipoConta(false);
                  setTab(0);
                }}
                cursor="pointer"
              >
                <Text>Informações de cadastro</Text>
              </Box>
              <Box
                py="4"
                mr="60px"
                color={tab === 1 ? "primary.400" : "grey"}
                borderBottom={tab === 1 ? "2px solid #693FF3" : null}
                onClick={() => {
                  setAlterarTipoConta(false);
                  setTab(1);
                }}
                cursor="pointer"
              >
                <Text>Configurações de NFe</Text>
              </Box>
            </Flex>
          )}
        </Box>
        {!loja?.pre_cadastro && (
          <Flex
            w={"100%"}
            margin={"0 0"}
            direction={"row"}
            justify="space-between"
          >
            {/* FORMULÁRIO DE ALTERAÇÃO DE CONTA PARA CNPJ */}
            {alterarTipoConta && (
              <Box
                boxShadow="sm"
                width={"80%"}
                minHeight={"90vh"}
                borderRadius={"8px"}
                marginTop={"5px"}
                backgroundColor={"white"}
                mr="5"
                px="4"
              >
                <Box
                  paddingTop={"12px"}
                  paddingBottom={"4px"}
                  fontSize={"22px"}
                >
                  <Heading fontSize="22px">Migrar Loja para CNPJ</Heading>
                  <Text fontSize="xs">
                    Defina as informações PJ da sua loja
                  </Text>
                </Box>
                <Divider my="2" />
                <TabPessoaJuridica
                  loja={loja}
                  save={saveData}
                  onCancel={toogleTipoContaForm}
                />
              </Box>
            )}
            {/* BOX DE INFORMAÇÃO DE AVISOS SOBRE DOCUMNETOS NECESSÁRIOS*/}
            {alterarTipoConta ? <InformacoesAlteracaoTitularidade /> : null}
            {/* FORMULÁRIOS DE INFORMAÇÕES BÁSICAS E CONFIGURAÇÕES DE PESSOA FÍSICA*/}
            {!alterarTipoConta && (
              <Box
                boxShadow="sm"
                width={"70%"}
                minW={"700px"}
                borderRadius={"8px"}
                marginTop={"5px"}
                backgroundColor={tab < 3 ? "white" : null}
              >
                {tab < 3 && (
                  <Box
                    paddingTop={"12px"}
                    paddingBottom={"4px"}
                    px="5"
                    fontSize={"22px"}
                  >
                    <Heading fontSize="22px">
                      {" "}
                      {tab === 0
                        ? "Perfil da Loja"
                        : tab === 1
                        ? "Informações do cadastro"
                        : "Configurações de NFe"}
                    </Heading>
                    <Text fontSize="xs">
                      Informações do perfil da sua loja.
                    </Text>
                  </Box>
                )}
                {tab < 3 && <Divider my="2" />}
                <Tabs
                  index={tab}
                  onChange={(index) => {
                    setTab(index);
                  }}
                >
                  <TabPanels>
                    <TabPanel>
                      <TabCadastro
                        isPj={isPj}
                        loja={loja}
                        onOpenPjForm={toogleTipoContaForm}
                        save={saveData}
                      />
                    </TabPanel>
                    <TabPanel>
                      <ConfiguracoesFiscaisPage isPj={isPj} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            )}
          </Flex>
        )}
      </Flex>
    </PageScaffold>
  );
}
