import { useToast } from "@chakra-ui/react";
import FirebaseAuthService from "../services/firebase_auth_service";

import React, { useState, createContext, useEffect } from "react";
import VendedoresRepository from "../repositories/vendedores_repositories";
import { useNavigate } from "react-router-dom";

export const AuthContext = createContext();

const AuthProvider = ({ children, onAppReady }) => {
  const firebaseAuthService = new FirebaseAuthService();

  const toast = useToast();

  const [currentToken, setCurrentToken] = useState(null);
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [hasPhone, setHasPhone] = useState(false);

  const [estabelecimento, setEstabelecimento] = useState(null);

  useEffect(() => {
    firebaseAuthService.auth.onAuthStateChanged((user) => {
      console.log({ user });
      if (user) {
        setFirebaseUser(user);
        setCurrentToken(user.accessToken);

        loadEstabelecimento(user.uid, user.accessToken).then(() => {
          // setHasPhone(!!user.phoneNumber);

          onAppReady(true);
        });
      } else {
        setFirebaseUser(null);
        setEstabelecimento(null);
        onAppReady(false);
      }
    });

    setInterval(() => {
      firebaseAuthService.auth.currentUser?.getIdToken(true).then((token) => {
        setCurrentToken(token);
      });
    }, 1000 * 60 * 5);
  }, []);

  const refreshEstabelecimento = async () => {
    await loadEstabelecimento(firebaseUser.uid, firebaseUser.accessToken);
  };

  const signInEmailAndPassword = async ({ email, password }) => {
    if (!email) {
      toast({
        description: "Campo e-mail não preenchido",
        status: "error",
      });
      return;
    }

    if (!password) {
      // toast({
      //   description: "Campo senha não preenchido",
      //   status: "error",
      // });

      return;
    }

    try {
      const res = await firebaseAuthService.signInEmailAndPassword({
        email,
        password,
      });

      setFirebaseUser(res.user);

      await loadEstabelecimento(res.user.uid, res.user.accessToken);

      return res;
    } catch (err) {
      console.log(err);
      toast({
        description: firebaseAuthService.getError(err.code),
        status: "error",
      });
    }
  };

  const sendResetPasswordMail = async ({ email }) => {
    if (!email) {
      toast({
        description: "Digite seu E-mail",
        status: "error",
      });
      return;
    }

    try {
      await firebaseAuthService.sendPasswordEmail({ email });
      toast({
        description: "E-mail enviado!",
        status: "success",
      });
      return true;
    } catch (err) {
      toast({
        description: firebaseAuthService.getError(err.code),
        status: "error",
      });
    }
  };

  const loadEstabelecimento = async (uid, token) => {
    const repo = new VendedoresRepository({
      token: token || currentToken,
    });

    const res = await repo.consultar({
      firebase_id: uid,
    });

    if (res.status === 200) {
      setEstabelecimento(res.data);
    }
  };

  const createEstabelecimento = async ({ nome, email, senha }) => {
    try {
      const res = await firebaseAuthService.createUserEmailAndPassword({
        email,
        password: senha,
      });

      const idToken = await res.user.accessToken;

      if (res.user) {
        const repo = new VendedoresRepository({
          token: idToken,
        });

        const resMongo = await repo.cadastrar({
          nome,
          email,
        });

        if (resMongo.status === 200) {
          loadEstabelecimento(res.user.uid, idToken);
        } else {
          await firebaseAuthService.deleteFirebaseUser(firebaseUser);
        }
      }

      return res;
    } catch (err) {
      toast({
        description:
          firebaseAuthService.getError(err.code) ?? "Algo estranho aconteceu",
        status: "error",
      });

      return false;
    }
  };

  const signOut = (source) => {
    console.log({ source });
    localStorage.clear();
    // router.replace("/");
    return firebaseAuthService.auth.signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        signInEmailAndPassword,
        createEstabelecimento,
        loadEstabelecimento,
        firebaseUser,
        estabelecimento,
        currentToken,
        sendResetPasswordMail,
        signOut,
        hasPhone,
        refreshEstabelecimento,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
