import React from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Divider,
  Link,
  Select,
  InputLeftElement,
  Image,
  Input,
  InputGroup,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdCircle, MdShowChart } from "react-icons/md";
import CustomDateRangePicker from "../../../components/CustomDateRangePicker";

import searchImg from "../../../assets/pesquisar.svg";

export default function FilterBox({ onFilterChange = () => {}, onReset }) {
  const navigate = useNavigate();

  const [status, setStatus] = React.useState("todos");
  const [name, setName] = React.useState("");

  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  return (
    <Box width={"100%"} borderRadius={"8px"} marginTop={"5px"} padding={"15px"}>
      <Flex padding={"8px 0px"} justify="space-between">
        <InputGroup spacing="24px" width={"280px"}>
          <InputLeftElement
            children={<Image src={searchImg} boxSize="17px" />}
          />
          <Input
            type="text"
            fontWeight={700}
            placeholder="Buscar por nome..."
            value={name}
            fontSize={"15px"}
            w="250px"
            onChange={(e) => {
              setName(e.target.value);
            }}
            variant={"filled"}
          />
        </InputGroup>

        <Flex direction="row">
          <Flex align="center">
            <Text fontSize="sm" fontWeight={500} w="60px">
              Período
            </Text>
            <Box w={"10px"} />
            <CustomDateRangePicker
              currentRange={[startDate, endDate]}
              onChange={({ start, end }) => {
                setStartDate(start);
                setEndDate(end);
              }}
            />
          </Flex>
          <Box w={"60px"} />
          <Flex align="center">
            <Text fontSize="sm" fontWeight={500} w="60px">
              Status
            </Text>

            <Select
              mx="2"
              w="180px"
              variant={"filled"}
              fontWeight={"700"}
              value={status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option value="todos">Todos</option>
              <option value="Em andamento">Em andamento</option>
              <option value="Agendado">Agendado</option>
              <option value="Finalizado">Finalizado</option>
            </Select>
          </Flex>
          <Button
            width={"140px"}
            marginLeft={"15px"}
            backgroundColor="primary.400"
            color={"white"}
            _hover={{ opacity: "0.8" }}
            onClick={() => {
              onFilterChange({
                nome: name,
                status: status === "todos" ? null : status,
                data_inicio: startDate,
                data_fim: endDate,
              });
            }}
          >
            Buscar
          </Button>
          <Button
            onClick={() => {
              onFilterChange({});
              setName("");
              setStatus("todos");
              setStartDate(null);
              setEndDate(null);

              onReset();
            }}
            width={"140px"}
            marginLeft={"15px"}
            variant={"outline"}
          >
            Redefinir
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
