import {
  Box,
  Flex,
  Text,
  Divider,
  Checkbox,
  ModalBody,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
  Input,
  Select,
  useToast,
  CloseButton,
  Spacer,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useState } from "react";
import { NumericFormat } from "react-number-format";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../contexts/authContext";
import VendedoresRepository from "../../../repositories/vendedores_repositories";
import { MdArrowForwardIos } from "react-icons/md";
import { ShipImage } from "../../DetalhesPedido/components/ShipCard";
import useEstabelecimento from "../../../hooks/useEstabelecimento";

export default function FreteDialog({
  isOpen,
  onClose,

  fretes,
}) {
  const [selected, setSelected] = useState(null);

  const loja = useEstabelecimento();

  useEffect(() => {}, []);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalOverlay>
        <ModalContent>
          <ModalHeader fontSize="lg" fontWeight="bold" pt={"40px"}>
            <CloseButton
              onClick={() => {
                onClose(selected);
              }}
              position="absolute"
              right="20px"
              top="20px"
            />
            <Text>Opções de envio</Text>

            <Text fontSize={"12px"} mt={"5px"} mb={"5px"}>
              Selecione uma das opções abaixo para envio do seu produto ao
              destino:
            </Text>
          </ModalHeader>

          <ModalBody overflowY="scroll">
            <Flex
              cursor={"pointer"}
              borderLeft={"6px solid #232544"}
              justifyContent={"center"}
              align={"center"}
              mb={"15px"}
            >
              <Link
                style={{
                  display: "flex",
                }}
                to={"/meus-enderecos"}
              >
                <Text
                  fontSize={"13px"}
                  mt={"3px"}
                  mb={"3px"}
                  noOfLines={1}
                  p={"0px 6px"}
                  fontWeight={"600"}
                >
                  {loja.endereco.rua +
                    ", " +
                    loja.endereco.numero +
                    ", " +
                    loja.endereco.bairro +
                    ", " +
                    loja.endereco.cidade +
                    " - " +
                    loja.endereco.estado +
                    " - " +
                    loja.endereco.cep}
                </Text>

                <Spacer />

                <MdArrowForwardIos />
              </Link>
            </Flex>

            {fretes.map((f) => (
              <Flex
                key={f.delivery_method_id}
                bg={"#F9F9F9"}
                p={"10px 12px"}
                borderRadius={"5px"}
                mt={"10px"}
                mb={"10px"}
                align={"center"}
              >
                <Checkbox
                  size="lg"
                  colorScheme="purple"
                  value={
                    selected &&
                    selected.delivery_method_id === f.delivery_method_id
                  }
                  onChange={(v) => {
                    setSelected(f);
                  }}
                />

                <Box w={"16px"} />

                <ShipImage
                  height={"25px"}
                  objectFit={"contain"}
                  data={{
                    envio: {
                      ...f,
                    },
                  }}
                />
                <Text
                  pt={"15px"}
                  pl={"10px"}
                  style={{
                    fontSize: "13px",
                  }}
                >
                  {f.description}
                </Text>

                <Spacer />

                <Text fontWeight={"700"} fontSize={"15px"}>
                  R$ {f.final_shipping_cost}
                </Text>
              </Flex>
            ))}

            <Text fontWeight={"500"} fontSize={"12px"} pt={"10px"}>
              A taxa de frete acima é um valor padrão. O valor exato do custo
              será calculado no momento da compra baseando-se no endereço do
              comprador e do vendedor. Para promoções ativas de frete grátis,
              essa taxa não será considerada.
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="purple"
              backgroundColor={"#693FF3"}
              variant="solid"
              onClick={() => {
                onClose(selected);
              }}
              mb={"10px"}
            >
              Pronto
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
