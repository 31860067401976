import React, { useState, createContext, useEffect, useContext } from "react";

import ChatsRepository from "../repositories/chat_repository";
import { AuthContext } from "./authContext";
import useEstabelecimento from "../hooks/useEstabelecimento";

export const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [allChats, setAllChatsData] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [unreadAmount, setUnreadAmount] = useState(0);
  const estabelecimento = useEstabelecimento();

  const authContext = useContext(AuthContext);
  const chatRepo = new ChatsRepository({ token: authContext.currentToken });

  const openChat = async () => {
    setIsChatOpen(true);
  };

  const closeChat = async () => {
    setIsChatOpen(false);
    setActiveChat(null);
  };

  const loadAllChats = async () => {
    const res = await chatRepo.consultar({
      estabelecimento_id: estabelecimento._id,
    });

    if (res.status === 200) {
      setAllChatsData(res.data);
    }

    return res.data;
  };

  const loadChat = async ({ chat_id }) => {
    const res = await chatRepo.consultar({
      estabelecimento_id: estabelecimento._id,
      chat_id,
    });

    if (res.status === 200) {
      setActiveChat(res.data);
    }
  };

  const openChatWith = async ({ usuario_id }) => {
    if (!estabelecimento) {
      alert("Faça login para enviar mensagens");
      // toast_service.error("Faça login para enviar mensagens");

      return;
    }

    const exists = allChats.find((e) => e.usuario_id === usuario_id);

    setIsChatOpen(true);

    if (exists) {
      setActiveChat(exists);
    } else {
      const res = await chatRepo.novo({
        estabelecimento_id: estabelecimento._id,
        usuario_id,
      });
      await loadAllChats();

      setTimeout(() => {
        setActiveChat(res.data);
      }, 1000);
    }
  };

  const sendMessage = async ({ chat_id, conteudo }) => {
    const res = await chatRepo.enviar({
      chat_id,
      autor_id: estabelecimento._id,
      conteudo,
    });

    if (res.status === 200) {
      loadChat({ estabelecimento_id: estabelecimento._id, chat_id });
    }
  };

  const blockUser = async ({ chatObject }) => {
    if (chatObject.bloqueado_por === estabelecimento._id) {
      return;
    }

    await chatRepo.bloquear({
      chat_id: chatObject._id,
      autor_id: estabelecimento._id,
    });

    loadChat({ chat_id: chatObject._id });
  };

  const unBlockUser = async ({ chatObject }) => {
    if (chatObject.bloqueado_por !== estabelecimento._id) {
      return;
    }

    await chatRepo.desbloquear({
      chat_id: chatObject._id,
      autor_id: estabelecimento._id,
    });

    loadChat({ chat_id: chatObject._id });
  };

  const reportUser = async () => {};
  const viewUserProfile = async () => {};

  useEffect(() => {
    if (!estabelecimento) {
      return;
    }

    loadAllChats();

    const interval = setInterval(() => {
      if (!authContext.firebaseUser) {
        loadAllChats();
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [estabelecimento]);

  useEffect(() => {
    if (allChats) {
      const amountUnreads = allChats.reduce((acc, curr) => {
        const amount = curr.mensagens?.filter(
          (e) => !e.lido && e.autor_id !== estabelecimento._id
        ).length;

        return acc + amount;
      }, 0);

      setUnreadAmount(amountUnreads);
    }

    if (activeChat && allChats) {
      const chat = allChats.find((c) => c._id === activeChat._id);

      if (chat) {
        if (chat.mensagens.length > activeChat.mensagens.length) {
          loadChat({
            estabelecimento_id: estabelecimento._id,
            chat_id: activeChat._id,
          });
        }
      }
    }
  }, [allChats, activeChat]);

  return (
    <ChatContext.Provider
      value={{
        setIsChatOpen,
        isChatOpen,
        openChat,
        closeChat,
        allChats,
        activeChat,
        unreadAmount,
        setActiveChat,
        loadAllChats,
        loadChat,
        blockUser,
        unBlockUser,
        sendMessage,
        reportUser,
        viewUserProfile,
        openChatWith,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
