import { Box, Flex, Image, Text } from "@chakra-ui/react";
import Formatters from "../../../../utils/formatters";

function MessageLeft(msg) {
  return (
    <Flex direction="column" mt="2" px="10px">
      <Box p="2" borderRadius="8" backgroundColor="#F5F5F5" w="max-content">
        <Text fontSize="xs" fontWeight={500} w="200px">
          {msg.mensagem}
        </Text>
      </Box>
      {msg.anexos.length > 0 && (
        <Image
          width="200px"
          src={msg.anexos[0].url}
          onClick={() => {
            window.open(msg.anexos[0].url, "", "height=800px, width=800px");
          }}
        />
      )}

      <Text
        fontSize="10px"
        color="gray.500"
        mt="1px"
        noOfLines={8}
        wordBreak="break-all"
        w="150px"
        overflow="hidden"
      >
        {Formatters.date(new Date(msg.createdAt))}
      </Text>
    </Flex>
  );
}

function MessageRight(msg) {
  return (
    <Flex direction="column" mt="2" align="flex-end" mr="5px">
      <Box p="2" borderRadius="8" backgroundColor="primary.400" w="max-content">
        {msg.anexos.length > 0 && (
          <Image
            width="200px"
            src={msg.anexos[0].url}
            onClick={() => {
              window.open(msg.anexos[0].url, "", "height=800px, width=800px");
            }}
          />
        )}
        <Text
          fontSize="xs"
          color="white"
          noOfLines={8}
          wordBreak="break-all"
          w="200px"
          fontWeight={500}
          overflow="hidden"
        >
          {msg.mensagem}
        </Text>
      </Box>
      <Text fontSize="10px" color="gray.500" mt="1px">
        {Formatters.date(new Date(msg.createdAt))}
      </Text>
    </Flex>
  );
}

export default function Messages({ messages, loggedUser, messagesEnd }) {
  return (
    <Flex direction="column" overflowY="scroll" px="5px" ref={messagesEnd}>
      {messages.map((message) => {
        if (loggedUser === message.autor_id) {
          return MessageRight(message);
        } else {
          return MessageLeft(message);
        }
      })}
    </Flex>
  );
}
