import { Flex, Text } from "@chakra-ui/react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import Formatters from "../../../utils/formatters";

export default function TabCuponsRaspadinha({ cupons }) {
  return (
    <Flex direction={"column"}>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Nome/Código</Th>
              <Th>Tipo de cupom</Th>
              <Th>Desconto(R$)</Th>
              <Th>Quantidade</Th>
              <Th>Status</Th>
              <Th>Período</Th>
            </Tr>
          </Thead>
          <Tbody fontWeight={600}>
            {cupons.map((c) => {
              return (
                <Tr key={c._id}>
                  <Td>
                    <Text
                      fontSize={"sm"}
                      whiteSpace={"normal"}
                      noOfLines={2}
                      w="250"
                    >
                      {c.nome}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={"sm"}
                      whiteSpace={"normal"}
                      noOfLines={2}
                      w="250"
                    >
                      {c.tipo}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={"sm"}
                      whiteSpace={"normal"}
                      noOfLines={2}
                      w="250"
                    >
                      {c.desconto_porcentagem
                        ? c.desconto_porcentagem + "%"
                        : Formatters.money(c.desconto_valor)}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={"sm"}
                      whiteSpace={"normal"}
                      noOfLines={2}
                      w="250"
                    >
                      {c.quantidade}
                    </Text>
                  </Td>
                  <Td>
                    <Text
                      fontSize={"sm"}
                      whiteSpace={"normal"}
                      noOfLines={2}
                      w="250"
                    >
                      {c.ativo ? "Ativo" : "Inativo"}
                    </Text>
                  </Td>
                  <Td>
                    <Flex direction={"column"}>
                      <Text fontSize={"sm"}>
                        {new Date(c.data_inicio).toLocaleDateString()}
                      </Text>
                      <Text fontSize={"sm"}>
                        {new Date(c.data_fim).toLocaleDateString()}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
}
