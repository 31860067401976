import { Container, Box, Flex } from "@chakra-ui/react";
import React from "react";
import AppBarComponent from "../AppBarComponent";
import SideBarComponent from "../SideBarComponent";
import Chat from "../Chat";

export default function PageScaffold({
  children,
  showSideBar = true,
  showAppBar = true,
  useDarkerBackground = false,
  showWhiteLogo = false,
  disableContentPadding = false,
  showChat = true,
  onOpenVideo,
}) {
  return (
    <Container
      maxW="100vw"
      paddingInline={"0px"}
      overflow="hidden"
      overflowX="auto"
      h="100vh"
    >
      <Box display={"flex"} flexDirection={"row"} bg={"#E4E4E4"}>
        {showSideBar && <SideBarComponent />}
        {showChat && <Chat />}

        <Flex direction="column" w="100vw">
          {showAppBar && (
            <AppBarComponent
              useDarkerBackground={useDarkerBackground}
              showWhiteLogo={showWhiteLogo}
              onOpenVideo={onOpenVideo}
            />
          )}
          <Flex
            w={disableContentPadding ? "100%" : "95%"}
            margin={"0 auto"}
            flexDirection={"column"}
            overflow="scroll"
            h="100vh"
          >
            <Flex direction="column">
              {children}
              <Box h="50px" />
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </Container>
  );
}
