import {
  Box,
  Flex,
  Grid,
  Heading,
  Select,
  Text,
  Input,
} from "@chakra-ui/react";
import { useEffect, useImperativeHandle } from "react";

import { useRef } from "react";
import useEstabelecimento from "../../../hooks/useEstabelecimento";

export default function InformacoesFiscaisNovoProduto({ value, onChange }) {
  const estabelecimento = useEstabelecimento();

  return (
    <Box
      boxShadow="sm"
      w="full"
      mt="5px"
      borderRadius="8px"
      p="5"
      bgColor="white"
    >
      <Heading fontSize="md">Informações fiscais</Heading>
      <Text fontSize="xs" noOfLines="2">
        Para utilizar o emissor de NF-e da Deshopnise, todos os campos abaixo
        são obrigatórios. Aconselhamos que entre em contato com o seu contador
        para preencher essas informações. Para mais informações, fique aqui.
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        <Flex direction="row" maxW="65%" align="center" mt="2">
          <Text fontSize="sm" mr="2" minW="35%">
            NCM
          </Text>
          <Input
            onChange={(event) => {
              onChange({ ...value, ncm: event.target.value });
            }}
            placeholder="Informe"
            size="md"
            variant={"filled"}
            value={value?.ncm}
          />
        </Flex>
        <Flex direction="row" maxW="65%" align="center" mt="2">
          <Text fontSize="sm" mr="2" minW="35%">
            Origem
          </Text>
          <Select
            size="md"
            variant={"filled"}
            fontWeight={500}
            placeholder="Selecione"
            onChange={(event) => {
              onChange({ ...value, origem: event.target.value });
            }}
            value={value?.origem}
          >
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </Select>
        </Flex>
        <Flex direction="row" maxW="65%" align="center" mt="2">
          <Text fontSize="sm" mr="2" minW="35%">
            CEST
          </Text>
          <Input
            onChange={(event) => {
              onChange({ ...value, cest: event.target.value });
            }}
            placeholder="Informe"
            size="md"
            variant={"filled"}
            value={value?.cest}
          />
        </Flex>
        <Flex direction="row" maxW="65%" align="center" mt="2">
          {estabelecimento.dados_fiscais?.simples_nacional ? (
            <>
              <Text fontSize="sm" mr="2" minW="35%">
                CSOSN
              </Text>
              <Select
                size="md"
                variant={"filled"}
                fontWeight={500}
                placeholder="Selecione"
                onChange={(event) => {
                  onChange({
                    ...value,
                    csosn_cst: event.target.value,
                  });
                }}
                value={value?.csosn_cst}
              >
                <option value="101">101</option>
                <option value="102">102</option>
                <option value="103">103</option>
                <option value="201">201</option>
                <option value="202">202</option>
                <option value="203">203</option>
                <option value="300">300</option>
                <option value="400">400</option>
                <option value="500">500</option>
                <option value="900">900</option>
              </Select>
            </>
          ) : (
            <>
              <Text fontSize="sm" mr="2" minW="35%">
                CST
              </Text>
              <Select
                size="md"
                variant={"filled"}
                fontWeight={500}
                placeholder="Selecione"
                onChange={(event) => {
                  onChange({
                    ...value,
                    csosn_cst: event.target.value,
                  });
                }}
                value={value?.csosn_cst}
              >
                <option value="00">00</option>
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="41">41</option>
                <option value="50">50</option>
                <option value="51">51</option>
                <option value="60">60</option>
                <option value="70">70</option>
                <option value="90">90</option>
              </Select>
            </>
          )}
        </Flex>
        <Flex direction="row" maxW="65%" align="center" mt="2">
          <Text fontSize="sm" mr="2" minW="35%">
            CFOP (Mesmo Estado)
          </Text>
          <Select
            size="md"
            variant={"filled"}
            fontWeight={500}
            placeholder="Selecione"
            onChange={(event) => {
              onChange({ ...value, cfop: event.target.value });
            }}
            value={value?.cfop}
          >
            <option value="5101">5101</option>
            <option value="5102">5102</option>
            <option value="5401">5401</option>
            <option value="5405">5405</option>
          </Select>
        </Flex>
        <Flex direction="row" maxW="65%" align="center" mt="2">
          <Text fontSize="sm" mr="2" minW="35%">
            CFOP (Outro Estado)
          </Text>
          <Select
            size="md"
            variant={"filled"}
            fontWeight={500}
            placeholder="Selecione"
            onChange={(event) => {
              onChange({ ...value, cfop_externo: event.target.value });
            }}
            value={value?.cfop_externo}
          >
            <option value="6107">6107</option>
            <option value="6108">6108</option>
            <option value="6401">6401</option>
            <option value="6404">6404</option>
          </Select>
        </Flex>

        <Flex direction="row" maxW="65%" align="center" mt="2">
          <Text fontSize="sm" mr="2" minW="35%">
            UN Medida
          </Text>
          <Select
            size="md"
            variant={"filled"}
            fontWeight={500}
            placeholder="Selecione"
            onChange={(event) => {
              onChange({ ...value, unid_medida: event.target.value });
            }}
            value={value?.unid_medida}
          >
            <option value="UNID">UNIDADE</option>
            <option value="PC">PEÇA</option>
            <option value="SACO">SACO</option>
            <option value="ROLO">ROLO</option>
            <option value="AMPOLA">AMPOLA</option>
            <option value="BALDE">BALDE</option>
            <option value="BANDEJ">BANDEJA</option>
            <option value="BARRA">BARRA</option>
            <option value="BISNAG">BISNAGA</option>
            <option value="BLOCO">BLOCO</option>
            <option value="BOBINA">BOBINA</option>
            <option value="CAPS">CAPSULA</option>
            <option value="CART">CARTELA</option>
            <option value="CENTO">CENTO</option>
            <option value="CJ">CONJUNTO</option>
            <option value="CM">CENTIMETRO</option>
            <option value="CM2">CENTIMETRO QUADRADO</option>
            <option value="CX">CAIXA</option>
            <option value="CX2">CAIXA COM 2 UNIDADES</option>
            <option value="CX3">CAIXA COM 3 UNIDADES</option>
            <option value="CX5">CAIXA COM 5 UNIDADES</option>
            <option value="CX10">CAIXA COM 10 UNIDADES</option>
            <option value="CX15">CAIXA COM 15 UNIDADES</option>
            <option value="CX20">CAIXA COM 20 UNIDADES</option>
            <option value="CX25">CAIXA COM 25 UNIDADES</option>
            <option value="CX50">CAIXA COM 50 UNIDADES</option>
            <option value="CX100">CAIXA COM 100 UNIDADES</option>

            <option value="DISP">DISPLAY</option>
            <option value="DUZIA">DUZIA</option>
            <option value="EMBAL">EMBALAGEM</option>
            <option value="FARDO">FARDO</option>
            <option value="FOLHA">FOLHA</option>
            <option value="FRASCO">BALDE</option>
            <option value="GALAO">GALÃO</option>
            <option value="GF">GARRAFA</option>

            <option value="GRAMAS">GRAMAS</option>
            <option value="JOGO">JOGO</option>
            <option value="KG">QUILOGRAMA</option>
            <option value="KIT">KIT</option>
            <option value="LATA">LATA</option>
            <option value="LITRO">LITRO</option>

            <option value="M">Metro</option>
            <option value="M2">METRO QUADRADO</option>
            <option value="M3">METRO CÚBICO</option>
            <option value="MILHEI">MILHEIRO</option>
            <option value="ML">MILILITRO</option>
          </Select>
        </Flex>
      </Grid>
    </Box>
  );
}
