import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import { useNavigate } from "react-router-dom";

import PromocaoAsset from "../../assets/marketing/promocao.png";
import PromocaoBgAsset from "../../assets/marketing/promocao-bg.png";
import OfertaComboAsset from "../../assets/marketing/ofertacombo.png";
import OfertaComboBgAsset from "../../assets/marketing/ofertacombo-bg.png";
import OfertaRelampagoAsset from "../../assets/marketing/ofertarelampago.png";
import OfertaRelampagoBgAsset from "../../assets/marketing/ofertarelampago-bg.png";
import RaspadinhaAsset from "../../assets/marketing/raspadinha.png";
import RaspadinhaBgAsset from "../../assets/marketing/raspadinha-bg.png";
import CupomAsset from "../../assets/marketing/cupom.png";
import CupomBgAsset from "../../assets/marketing/cupom-bg.png";
import ProdutosAsset from "../../assets/marketing/produtos.png";
import ProdutosBgAsset from "../../assets/marketing/produtos-bg.png";
import LembreteAsset from "../../assets/marketing/lembrete.png";
import LembreteBgAsset from "../../assets/marketing/lembrete-bg.png";
import TutorialAsset from "../../assets/marketing/tutorial.png";
import TutorialBgAsset from "../../assets/marketing/tutorial-bg.png";

import useEstabelecimento from "../../hooks/useEstabelecimento";
import { useToast } from "@chakra-ui/react";
import useAuthToken from "../../hooks/useAuthToken";
import VendedoresRepository from "../../repositories/vendedores_repositories";
import { useEffect, useState } from "react";

function MarketingButton({
  backgroundImage,
  iconAsset,
  title,
  description,
  description2,
  onClick,
}) {
  return (
    <Box
      cursor={"pointer"}
      onClick={onClick}
      background={"white"}
      padding={"25px"}
      w={"25vw"}
      maxW={"400px"}
      borderRadius={"8px"}
    >
      <Flex direction="row" align="center">
        <Flex
          backgroundImage={backgroundImage}
          backgroundPosition={"center"}
          backgroundSize={"cover"}
          w={"70px"}
          h={"70px"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Flex w={"70px"} h={"70px"}>
            <Image p={"11px"} src={iconAsset} objectFit={"contain"} />
          </Flex>
        </Flex>

        <Box w={"20px"} />
        <Flex direction={"column"}>
          <Text fontWeight={"bold"}>{title}</Text>
          <Text fontSize={"14px"} fontWeight={"500"}>
            {description}
            {description2 && (
              <>
                <br />
                {description2}
              </>
            )}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}

export default function CentralMarketingPage() {
  const estabelecimento = useEstabelecimento();
  const token = useAuthToken();
  const [quantidadeCarrinhosAbandonados, setQuantidadeCarrinhosAbandonados] =
    useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const repo = new VendedoresRepository({ token });

    repo.consultarCarrinhosAbandonados().then((response) => {
      console.log(response.data);

      if (response.status === 200) {
        setQuantidadeCarrinhosAbandonados(response.data.quantidade);
      }
    });
  }, []);

  if (estabelecimento.status === "bloqueado") {
    return (
      <PageScaffold showSideBar={true}>
        <Center h={"90vh"}>
          <Text fontWeight={"600"}>Sua conta foi bloqueada</Text>
        </Center>
      </PageScaffold>
    );
  }

  return (
    <PageScaffold showSideBar={true}>
      <Flex direction={"column"} mt="20px">
        <Box
          paddingTop={"12px"}
          paddingBottom={"4px"}
          fontWeight={"700"}
          fontSize={"22px"}
        >
          <Flex direction="column">
            <span>Central de Marketing</span>

            <Text fontSize="14px" fontWeight={"medium"}>
              Bem-Vindo(a) a central de Marketing, conheça diversas ferramentas
              para aumentar suas vendas na Deshopnise.
            </Text>
          </Flex>
        </Box>
        <Flex
          direction={"column"}
          lineHeight="20px"
          w="85%"
          maxW={"1200px"}
          mt="20px"
        >
          <Grid
            direction={"row"}
            wrap={"wrap"}
            spacing="12px"
            templateColumns="repeat(3, 1fr)"
            gap={"10px"}
          >
            <MarketingButton
              title={"Promoções"}
              description={
                "Crie descontos em seus produtos por tempo limitado."
              }
              iconAsset={PromocaoAsset}
              backgroundImage={PromocaoBgAsset}
              onClick={() => {
                navigate("/promocoes");
              }}
            />

            <MarketingButton
              title={"Oferta Combo"}
              description={"Ofereça mais opções em conjunto com seu anuncio."}
              iconAsset={OfertaComboAsset}
              backgroundImage={OfertaComboBgAsset}
              onClick={() => {
                navigate("/oferta-combo");
              }}
            />

            <MarketingButton
              title={"Oferta Relâmpago"}
              description={
                "Crie ofertas por tempo limitado e aumente o engajamento."
              }
              iconAsset={OfertaRelampagoAsset}
              backgroundImage={OfertaRelampagoBgAsset}
              onClick={() => {
                navigate("/oferta-relampago");
              }}
            />

            <MarketingButton
              title={"Raspadinha"}
              description={"Crie raspadinhas premiadas para seus clientes"}
              iconAsset={RaspadinhaAsset}
              backgroundImage={RaspadinhaBgAsset}
              onClick={() => {
                navigate("/raspadinha");
              }}
            />

            <MarketingButton
              title={"Cupons"}
              description={"Crie cupons exclusivos de produtos e de sua loja. "}
              iconAsset={CupomAsset}
              backgroundImage={CupomBgAsset}
              onClick={() => {
                navigate("/cupons");
              }}
            />

            <MarketingButton
              title={"Produtos em alta"}
              description={"Veja os produtos mais vendidos na Deshopnise"}
              iconAsset={ProdutosAsset}
              backgroundImage={ProdutosBgAsset}
              onClick={() => {
                navigate("/mais-vendidos");
              }}
            />

            <MarketingButton
              title={"Lembrete no Chat"}
              description={
                "Lembre os compradores dos produtos de seus carrinhos."
              }
              description2={
                "Carrinhos abandonados: " + quantidadeCarrinhosAbandonados
              }
              iconAsset={LembreteAsset}
              backgroundImage={LembreteBgAsset}
              onClick={() => {
                navigate("/lembrete-carrinho");
              }}
            />

            <MarketingButton
              title={"Video Aulas"}
              description={"Saiba como utilizar as ferramentas de marketing."}
              iconAsset={TutorialAsset}
              backgroundImage={TutorialBgAsset}
              onClick={() => {
                window.location.href =
                  "https://www.central.deshopnise.com.br/cursos-deshopnise";
              }}
            />
          </Grid>
        </Flex>
      </Flex>
    </PageScaffold>
  );
}
