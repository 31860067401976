import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import ProdutosTr from "./components/ProdutosTr";
import ProductsSelectorDialog from "../../components/ProductsSelectorDialog";
import { useState } from "react";
import CalendarPickerDialog from "./components/CalendarComponent/CalendarPickerDialog";
import useAuthToken from "../../hooks/useAuthToken";
import OfertaRelampagoRepository from "../../repositories/ofertas_relampago_repository";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import AnunciosRepository from "../../repositories/anuncios_repository";
import { MdArrowBack, MdOutlineInfo } from "react-icons/md";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import DeshopFlashProductList from "../../components/DeshopFlashProductList";

function Info({ text }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <InfoOutlineIcon
        cursor={"pointer"}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        fontSize={"14px"}
      />
      {isOpen && (
        <Box position={"relative"}>
          <Text
            backgroundColor={"white"}
            borderRadius={"4px"}
            padding={"10px"}
            minW={"200px"}
            shadow={"md"}
            position={"absolute"}
            fontSize={"12px"}
            fontWeight={"500"}
            color={"black"}
            zIndex={4}
          >
            {text}
          </Text>
        </Box>
      )}
    </>
  );
}

export default function NovaOfertaRelampagoPage() {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [oferta, setOferta] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const onOpenCalendar = () => {
    setIsOpenCalendar(true);
    console.log("tapped");
  };
  const onCloseCalendar = () => setIsOpenCalendar(false);

  const token = useAuthToken();
  const repo = new OfertaRelampagoRepository({ token });
  const toast = useToast();
  const navigate = useNavigate();

  const [requestInProgress, setRequestInProgress] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      loadRelampago(params.id);
      setIsEditMode(true);
    }
  }, []);

  const loadRelampago = async (id) => {
    const res = await repo.consultar({ id });

    if (res.status === 200) {
      const pRepo = new AnunciosRepository({ token });

      const res2 = await Promise.all(
        res.data.produtos.map(async (p) => {
          const res = await pRepo.consultar({
            id: p,
          });

          return res.data;
        })
      );

      setSelectedProducts(
        res2.map((e) => {
          const desconto = res.data.descontos.find(
            (d) => d.produto_id === e._id
          );

          const variante = e.variantes.find(
            (v) => v._id === desconto.variante_id
          );

          return {
            ...e,
            cliques: desconto.cliques,
            desconto_porcentagem: desconto.desconto_porcentagem,
            desconto_reais: desconto.desconto_reais,
            estoque: desconto.estoque,
            limite: desconto.limite,
            vendas: desconto.vendas,
            variante,
            ativo: desconto.ativo,
          };
        })
      );
      setOferta(res.data);
      setSelectedDate({
        start: new Date(res.data.data_inicio),
        end: new Date(res.data.data_fim),
      });
    }
  };

  return (
    <>
      <PageScaffold showChat={false}>
        <Box w={"99%"} margin={"0 auto"}>
          <Box py={"5"} fontWeight={"700"} fontSize={"22px"}>
            <Flex direction="row" align="center">
              <MdArrowBack
                cursor="pointer"
                size="25px"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <Box w="5px" />
              <span>Criar Relâmpago</span>
            </Flex>
          </Box>

          <Box paddingBottom={"4px"} fontWeight={"600"} fontSize={"16px"}>
            {/* <span>Meus Envios</span> */}
            <Flex direction="row" mb="2">
              <Box
                py="4"
                mr="30px"
                color={tabIndex === 0 ? "primary.400" : "grey"}
                borderBottom={tabIndex === 0 ? "2px solid #693FF3" : null}
                onClick={() => setTabIndex(0)}
                cursor="pointer"
              >
                <Text>Informações básicas</Text>
              </Box>
              <Box
                py="4"
                mr="30px"
                color={tabIndex === 1 ? "primary.400" : "grey"}
                borderBottom={tabIndex === 1 ? "2px solid #693FF3" : null}
                onClick={() => setTabIndex(1)}
                cursor="pointer"
              >
                <Text>Detalhes</Text>
              </Box>
            </Flex>
          </Box>
          {tabIndex === 0 && (
            <Box
              boxShadow="sm"
              w="full"
              mt="5px"
              borderRadius="8px"
              p="5"
              bgColor="white"
            >
              <Flex direction="column">
                <Flex
                  ml="2"
                  direction="column"
                  maxW="600px"
                  fontWeight="semibold"
                >
                  <Flex direction="row" align="center" mb="2">
                    <Text fontSize="md" fontWeight={700} mr="2">
                      Tempo da oferta
                    </Text>
                    <Info text=" A oferta tem uma duração de 2 horas" />
                  </Flex>

                  <Input
                    w={"250px"}
                    value={
                      selectedDate
                        ? `${selectedDate.start.toLocaleDateString()}  ${selectedDate.start.toLocaleTimeString(
                            [],
                            { hour: "numeric", minute: "numeric" }
                          )} - ${selectedDate.end.toLocaleTimeString([], {
                            hour: "numeric",
                            minute: "numeric",
                          })}`
                        : "Selecionar"
                    }
                    readOnly={true}
                    fontWeight={"700"}
                    onClick={onOpenCalendar}
                  />
                </Flex>
              </Flex>
            </Box>
          )}

          {tabIndex === 1 && (
            <Box boxShadow="sm" mt="1" borderRadius="8px">
              <Flex direction="column">
                <Flex direction="row" justify="space-between" my="2">
                  <Flex direction="column">
                    <Text fontSize="md" fontWeight="700">
                      Produtos da Oferta Relâmpago
                    </Text>
                    <Text fontSize="xs" mr="2" fontWeight={500}>
                      Um comprador pode comprar no máximo 100 produtos
                      principais para a mesma oferta no combo.
                    </Text>
                  </Flex>
                  <Button
                    w="200px"
                    backgroundColor="primary.400"
                    color="white"
                    onClick={onOpen}
                  >
                    + Adicionar Produto
                  </Button>
                </Flex>
                <DeshopFlashProductList
                  produtos={selectedProducts}
                  onChange={(produto) => {
                    let produtos = selectedProducts.map((p) => {
                      if (p.variante._id === produto.variante._id) {
                        return produto;
                      }
                      return p;
                    });

                    setSelectedProducts(produtos);
                  }}
                  onRemove={(p) => {
                    setSelectedProducts(
                      selectedProducts.filter(
                        (x) => x.variante._id !== p.variante._id
                      )
                    );
                  }}
                />

                {/* <Produtos
                  descontos={oferta?.descontos}
                  produtos={selectedProducts}
                  onChange={(produtos) => {
                    setSelectedProducts(produtos);
                  }}
                /> */}
              </Flex>
            </Box>
          )}
          <Flex direction="row" mt="5" justifyContent="space-between">
            <Flex />
            <Flex>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                mr="5"
              >
                Cancelar
              </Button>
              <Button
                onClick={async () => {
                  if (tabIndex === 0) {
                    setTabIndex(1);
                    return;
                  }
                  const body = {
                    produtos: [...new Set(selectedProducts.map((p) => p._id))],
                    data_inicio: selectedDate.start,
                    data_fim: selectedDate.end,
                    descontos: selectedProducts.map((p) => ({
                      produto_id: p._id,
                      variante_id: p.variante._id,
                      desconto_reais: Number(p.desconto_reais),
                      desconto_porcentagem: Number(p.desconto_porcentagem),
                      estoque:
                        Number(p.estoque) === 0 ? null : Number(p.estoque),
                      limite: Number(p.limite) === 0 ? null : Number(p.limite),
                      cliques: 0,
                      vendas: 0,
                      ativo: p.ativo,
                    })),
                  };
                  if (requestInProgress) {
                    toast({
                      title: "Por favor, aguarde!",
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                    });
                    return;
                  }
                  setRequestInProgress(true);

                  if (isEditMode) {
                    const res = await repo.alterar({
                      ...body,
                      _id: oferta._id,
                    });
                    setRequestInProgress(false);

                    toast({
                      title: "Oferta relâmpago criada com sucesso",
                      status: "success",
                      duration: 9000,
                      isClosable: true,
                    });

                    if (res.status === 200) {
                      toast({
                        title: "Oferta relâmpago alterada com sucesso",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });

                      navigate(-1);
                    } else {
                      toast({
                        title: res.data["error"],
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  } else {
                    const res = await repo.cadastrar(body);

                    if (res.status === 200) {
                      toast({
                        title: "Oferta relâmpago criada com sucesso",
                        status: "success",
                        duration: 9000,
                        isClosable: true,
                      });

                      navigate(-1);
                    } else {
                      toast({
                        title: res.data["error"],
                        status: "error",
                        duration: 9000,
                        isClosable: true,
                      });
                    }
                  }
                }}
                backgroundColor="primary.400"
                color="white"
              >
                {tabIndex === 0 ? "Próximo" : "Salvar"}
              </Button>
            </Flex>
          </Flex>
        </Box>
      </PageScaffold>
      <ProductsSelectorDialog
        currentProducts={selectedProducts}
        isOpen={isOpen}
        onSelect={(p) => {
          setSelectedProducts(p);

          onClose();
        }}
        onClose={() => {
          onClose();
        }}
      />
      <CalendarPickerDialog
        isOpen={isOpenCalendar}
        onCancel={() => {
          onCloseCalendar();
        }}
        onSelect={(p) => {
          setSelectedDate(p);
          onCloseCalendar();
        }}
      />
    </>
  );
}
