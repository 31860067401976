import PageScaffold from "../../components/PageScaffold";
import {
  Box,
  Flex,
  Grid,
  Table,
  TableContainer,
  Tbody,
  Image,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import FilterBox from "./components/FilterBox";
import ProdutosTr from "./components/ProdutosTr";
import { useEffect } from "react";
import PromocaoRepository from "../../repositories/promocao_repository";
import useAuthToken from "../../hooks/useAuthToken";
import { useState } from "react";
import backAsset from "../../assets/back.png";
import CancelamentoDialog from "../../components/CancelamentoDialog";
import { fi } from "date-fns/locale";

function Produtos({ produtos, onClose }) {
  const token = useAuthToken();
  const repo = new PromocaoRepository({ token });

  return (
    <Box
      boxShadow="sm"
      width={"100%"}
      borderRadius={"8px"}
      marginTop={"1px"}
      padding={"16px"}
      backgroundColor={"white"}
    >
      <Flex direction={"column"}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th textTransform="none" fontSize="14px">
                  Nome
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Produtos
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Desconto
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Vendas
                </Th>
                <Th textTransform="none" fontSize="14px">
                  QTD Acessos
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Status
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Período
                </Th>
                <Th textTransform="none" fontSize="14px">
                  Ação
                </Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={600}>
              {produtos.map((c) => (
                <ProdutosTr
                  key={c._id}
                  data={c}
                  onClose={() => {
                    onClose(c._id);
                  }}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
}

export default function PromocoesPage() {
  const token = useAuthToken();

  const promoRepo = new PromocaoRepository({ token });

  const [data, setData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [filters, setFilters] = useState({});

  const fetch = async () => {
    setLoaded(false);
    promoRepo.consultar(filters).then((res) => {
      setData(res.data);
      setLoaded(true);
    });
  };

  useEffect(() => {
    fetch();
  }, [filters]);

  return (
    <>
      <PageScaffold>
        <Box w={"99%"} margin={"0 auto"}>
          <Box
            paddingTop={"32px"}
            paddingBottom={"24px"}
            fontWeight={"700"}
            fontSize={"22px"}
          >
            <Flex direction={"row"} alignItems={"center"}>
              <Image
                cursor={"pointer"}
                src={backAsset}
                height={"15px"}
                alt="Voltar"
                onClick={() => {
                  window.history.back();
                }}
              />
              <Box width={"10px"} />
              <span>Minhas Promoções</span>
            </Flex>
          </Box>

          <FilterBox
            onFilterChange={(props) => {
              setFilters(props);
            }}
            onReset={() => {
              promoRepo.consultar().then((res) => {
                setData(res.data);
              });
            }}
          />
          {loaded && (
            <Produtos
              produtos={data}
              onClose={(id) => {
                setIsOpen(true);
                setSelectedId(id);
              }}
            />
          )}
        </Box>
      </PageScaffold>
      <CancelamentoDialog
        customMsg="Ao encerrar não será possível reverter a ação, deseja continuar?"
        isOpen={isOpen}
        onClose={async (sair) => {
          console.log(data);
          if (sair && data) {
            await promoRepo.cancelar({
              id: selectedId,
            });
            window.location.reload();
          }
          setIsOpen(false);
        }}
      />
    </>
  );
}
