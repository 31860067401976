import {
  Box,
  Button,
  Flex,
  Input,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import PageScaffold from "../../components/PageScaffold";
import ProdutosTr from "./components/ProdutosTr";
import ProductsSelectorDialog from "../../components/ProductsSelectorDialog";
import { useState } from "react";
import Preview from "./components/Previa";
import OfertasComboRepository from "../../repositories/ofertas_combo_repository";
import useAuthToken from "../../hooks/useAuthToken";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import dateRangeIsValid from "../../utils/date_comparator";
import { MdArrowBack } from "react-icons/md";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import ProdutosPrincipaisList from "./components/ProdutosPrincipaisList";
import ProdutosSecundariosList from "./components/ProdutosSecundariosList";
import AnunciosRepository from "../../repositories/anuncios_repository";

function Produtos({
  produtos,
  isProdutoAdicional = false,
  onSetDesconto,
  descontos,
  onRemove,
}) {
  return (
    <Box
      boxShadow="sm"
      width={"100%"}
      borderRadius={"8px"}
      marginTop={"1px"}
      padding={"16px"}
      backgroundColor={"white"}
    >
      <Flex direction={"column"}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Produto</Th>
                <Th>Preço</Th>
                {isProdutoAdicional && <Th>Desconto</Th>}
                <Th>Estoque</Th>
                <Th>Ação</Th>
              </Tr>
            </Thead>
            <Tbody fontWeight={600}>
              {produtos.map((c) => (
                <ProdutosTr
                  key={c._id}
                  data={c}
                  isProdutoAdicional={isProdutoAdicional}
                  onSetDesconto={onSetDesconto}
                  descontos={descontos}
                  onRemove={onRemove}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
    </Box>
  );
}

function Info({ text }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <InfoOutlineIcon
        cursor={"pointer"}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        fontSize={"14px"}
      />
      {isOpen && (
        <Box position={"relative"}>
          <Text
            backgroundColor={"white"}
            borderRadius={"4px"}
            padding={"10px"}
            minW={"200px"}
            shadow={"md"}
            position={"absolute"}
            fontSize={"12px"}
            fontWeight={"500"}
            color={"black"}
            zIndex={4}
          >
            {text}
          </Text>
        </Box>
      )}
    </>
  );
}

export default function NovaOfertaComboPage() {
  const token = useAuthToken();
  const repo = new OfertasComboRepository({ token });
  const pRepo = new AnunciosRepository({ token });

  const toast = useToast();

  const [isOpenMain, setIsOpenMain] = useState(false);
  const [isOpenAditional, setIsOpenAditional] = useState(false);
  const onOpenMain = () => setIsOpenMain(true);
  const onOpenAditional = () => setIsOpenAditional(true);
  const onClose = () => {
    setIsOpenMain(false);
    setIsOpenAditional(false);
  };

  const [tabIndex, setTabIndex] = useState(0);
  const [nome, setNome] = useState("");
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [limiteCompra, setLimiteCompra] = useState("");
  const [produtosPrincipais, setProdutosPrincipais] = useState([]);
  const [produtosAdicionais, setProdutosAdicionais] = useState([]);
  const [descontos, setDescontos] = useState([]);
  const navigate = useNavigate();

  const [requestInProgress, setRequestInProgress] = useState(false);

  const params = useParams();

  useEffect(() => {
    if (params.id) {
      loadCombo(params.id);
    }
  }, []);

  const loadCombo = async (id) => {
    const res = await repo.consultar({ id });

    if (res.status === 200) {
      // setPromocao(res.data);
      setNome(res.data.nome);
      setDataInicio(new Date(res.data.data_inicio).toISOString().slice(0, 10));
      setDataFim(new Date(res.data.data_fim).toISOString().slice(0, 10));

      setDescontos(res.data.descontos);
      setLimiteCompra(res.data.limite_compras);

      const resProdutosPrincipais = await Promise.all(
        res.data.produtos_principais.map(async (p) => {
          const res = await pRepo.consultar({
            id: p,
          });

          return res.data;
        })
      );

      setProdutosPrincipais(
        resProdutosPrincipais.map((e) => ({
          ...e,
          variante: e.variantes.at(0),
        }))
      );

      const resProdutosAdicionais = await Promise.all(
        res.data.produtos_adicionais.map(async (p) => {
          const res = await pRepo.consultar({
            id: p,
          });

          return res.data;
        })
      );

      setProdutosAdicionais(
        resProdutosAdicionais.map((e) => {
          const desconto = res.data.descontos.find(
            (d) => d.produto_id === e._id
          );

          const variante = e.variantes.find(
            (v) => v._id === desconto.variante_id
          );

          return {
            ...e,
            cliques: desconto.cliques,
            desconto_porcentagem: desconto.desconto_porcentagem,
            desconto_reais: desconto.desconto_reais,
            estoque: desconto.estoque,
            limite: desconto.limite,
            vendas: desconto.vendas,
            variante,
            ativo: desconto.ativo,
          };
        })
      );
    }
  };

  return (
    <>
      <PageScaffold showChat={false}>
        <Box w={"99%"} margin={"0 auto"}>
          <Box py={"5"} fontWeight={"700"} fontSize={"22px"}>
            <Flex direction="row" align="center">
              <MdArrowBack
                cursor="pointer"
                size="25px"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <Box w="5px" />
              <span>
                {params.id ? "Atualizar " : " Criar "}
                Oferta Combo
              </span>
            </Flex>
          </Box>
          <Box paddingBottom={"4px"} fontWeight={"600"} fontSize={"16px"}>
            {/* <span>Meus Envios</span> */}
            <Flex direction="row" mb="2">
              <Box
                py="4"
                mr="30px"
                color={tabIndex === 0 ? "primary.400" : "grey"}
                borderBottom={tabIndex === 0 ? "2px solid #693FF3" : null}
                onClick={() => setTabIndex(0)}
                cursor="pointer"
              >
                <Text>Informações básicas</Text>
              </Box>
              <Box
                py="4"
                mr="30px"
                color={tabIndex === 1 ? "primary.400" : "grey"}
                borderBottom={tabIndex === 1 ? "2px solid #693FF3" : null}
                onClick={() => setTabIndex(1)}
                cursor="pointer"
              >
                <Text>Detalhes</Text>
              </Box>
            </Flex>
          </Box>
          {tabIndex === 0 && (
            <Flex direction="column">
              <Box
                boxShadow="sm"
                w="full"
                mt="5px"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Flex direction="column">
                  <Flex
                    ml="2"
                    direction="column"
                    maxW="600px"
                    fontWeight="semibold"
                  >
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="sm" fontWeight={700} mr="2">
                        Nome da oferta
                      </Text>
                      <Info text="O nome da Oferta não é visível para os compradores" />
                    </Flex>
                    <Input
                      placeholder="Ex: Celular + capinha"
                      variant={"customGrey"}
                      value={nome}
                      onChange={(e) => {
                        setNome(e.target.value);
                      }}
                    />
                  </Flex>
                </Flex>
              </Box>
              <Box
                boxShadow="sm"
                w="full"
                mt="5"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Flex direction="column">
                  <Flex
                    ml="2"
                    direction="column"
                    maxW="600px"
                    fontWeight="semibold"
                  >
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="sm" fontWeight={700} mr="2">
                        Tempo da oferta
                      </Text>
                      <Info text="A data de término deve ser depois da data de início por pelo menos 1 dia." />
                    </Flex>

                    <Flex align="center">
                      <Input
                        fontWeight={500}
                        size="sm"
                        type={"date"}
                        maxW="140px"
                        value={dataInicio}
                        onChange={(e) => {
                          let isValid = dateRangeIsValid({
                            first: e.target.value,
                            last: dataFim,
                            maxDays: 180,
                          });

                          if (isValid) {
                            setDataInicio(e.target.value);
                          } else {
                            toast({
                              title: "Erro",
                              description:
                                "Verifique as datas de início e fim da promoção",
                              status: "error",
                              duration: 5000,
                              isClosable: true,
                            });
                          }
                        }}
                      />
                      <Box
                        mx="2"
                        w={"18px"}
                        h="2px"
                        backgroundColor="grey"
                        borderRadius="4px"
                      />
                      <Input
                        fontWeight={500}
                        size="sm"
                        type={"date"}
                        maxW="140px"
                        value={dataFim}
                        onChange={(e) => {
                          let isValid = dateRangeIsValid({
                            first: dataInicio,
                            last: e.target.value,
                            maxDays: 180,
                          });

                          if (isValid) {
                            setDataFim(e.target.value);
                          } else {
                            toast({
                              title: "Erro",
                              description:
                                "Verifique as datas de início e fim da promoção",
                              status: "error",
                              duration: 5000,
                              isClosable: true,
                            });
                          }
                        }}
                      />
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
              {/* <Box
                boxShadow="sm"
                w="full"
                mt="5"
                borderRadius="8px"
                p="5"
                bgColor="white"
              >
                <Flex direction="column">
                  <Flex
                    ml="2"
                    direction="column"
                    maxW="600px"
                    fontWeight="semibold"
                  >
                    <Flex direction="row" align="center" mb="2">
                      <Text fontSize="sm" fontWeight={700} mr="2">
                        Limite de compra
                      </Text>
                      <Info
                        text="Número máximo de produtos do combo que podem ser
                        comprados."
                      />
                    </Flex>
                    <Input
                      value={limiteCompra}
                      onChange={(e) => {
                        setLimiteCompra(e.target.value);
                      }}
                      w="60px"
                    />
                  </Flex>
                </Flex>
              </Box> */}
            </Flex>
          )}
          {tabIndex === 1 && (
            <Flex direction="column" mt="14px">
              <Flex direction="row" justify="space-between">
                <Flex direction="column">
                  <Text fontSize="md" fontWeight="700">
                    Produtos principais
                  </Text>
                  <Text fontSize="xs" mr="2" fontWeight={500}>
                    Um comprador pode comprar no máximo 100 produtos principais
                    para a mesma oferta no combo.
                  </Text>
                </Flex>
                <Button
                  w={"200px"}
                  backgroundColor="primary.400"
                  color="white"
                  onClick={onOpenMain}
                >
                  Adicionar Produto
                </Button>
              </Flex>
              <ProdutosPrincipaisList
                produtos={produtosPrincipais}
                onChange={(produto) => {
                  let produtos = produtosPrincipais.map((p) => {
                    if (p.variante._id === produto.variante._id) {
                      return produto;
                    }
                    return p;
                  });

                  setProdutosPrincipais(produtos);
                }}
                onRemove={(p) => {
                  setProdutosPrincipais(
                    produtosPrincipais.filter(
                      (x) => x.variante._id !== p.variante._id
                    )
                  );
                }}
              />

              <Flex direction="column" mt="42px">
                <Flex direction="row" justify="space-between">
                  <Flex direction="column">
                    <Text fontSize="md" fontWeight="700">
                      Produtos Adicionais
                    </Text>
                    <Text fontSize="xs" mr="2" fontWeight={500}>
                      Um comprador pode comprar no máximo 100 produtos
                      principais para a mesma oferta no combo.
                    </Text>
                  </Flex>
                  <Button
                    w={"200px"}
                    backgroundColor="primary.400"
                    color="white"
                    onClick={onOpenAditional}
                  >
                    Adicionar Produto
                  </Button>
                </Flex>
              </Flex>

              <ProdutosSecundariosList
                produtos={produtosAdicionais}
                onChange={(produto) => {
                  let produtos = produtosAdicionais.map((p) => {
                    if (p.variante._id === produto.variante._id) {
                      return produto;
                    }
                    return p;
                  });

                  setProdutosAdicionais(produtos);
                }}
                onRemove={(p) => {
                  setProdutosAdicionais(
                    produtosAdicionais.filter(
                      (x) => x.variante._id !== p.variante._id
                    )
                  );
                }}
              />
              {/* 
              <Box
                boxShadow="sm"
                w="full"
                mt="5"
                borderRadius="8px"
                p="5"
                pt="1"
                bgColor="white"
              >
                <Flex direction="column" maxW="800px">
                  <Produtos
                    produtos={produtosAdicionais}
                    isProdutoAdicional={true}
                    descontos={descontos}
                    onSetDesconto={(id, porcentagem) => {
                      const desconto = descontos.find((d) => d.id === id);
                      if (desconto) {
                        desconto.porcentagem = porcentagem;
                        setDescontos(descontos);
                      } else {
                        setDescontos([...descontos, { id, porcentagem }]);
                      }
                      console.log(descontos);
                    }}
                    onRemove={(v) => {
                      setProdutosAdicionais(
                        produtosAdicionais.filter((p) => p._id !== v._id)
                      );
                    }}
                  />
                </Flex>
              </Box> */}

              <Flex direction="column" mt="42px">
                <Flex direction="row" justify="space-between">
                  <Flex direction="column">
                    <Text fontSize="md" fontWeight="700">
                      Prévia do produto
                    </Text>
                    <Text fontSize="xs" mr="2" fontWeight={500}>
                      Você pode aproveitar essa fução para vender mais produtos,
                      dando aos usuários descontos.
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Box
                boxShadow="sm"
                w="full"
                mt="5"
                borderRadius="8px"
                p="5"
                pt="1"
                bgColor="white"
                minH={"150px"}
              >
                <Preview
                  produtosPrincipais={produtosPrincipais}
                  produtosAdicionais={produtosAdicionais}
                  descontos={produtosAdicionais.map((p) => ({
                    produto_id: p._id,
                    variante_id: p.variante._id,
                    desconto_reais: Number(p.desconto_reais),
                    desconto_porcentagem: Number(p.desconto_porcentagem),
                    estoque: Number(p.estoque) === 0 ? null : Number(p.estoque),
                    limite: Number(p.limite) === 0 ? null : Number(p.limite),
                    cliques: 0,
                    vendas: 0,
                    ativo: p.ativo,
                  }))}
                />
              </Box>
            </Flex>
          )}
          <Flex justifyContent={"end"} mt="5">
            <Button
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancelar
            </Button>
            <Flex w="10px" />
            <Button
              onClick={async () => {
                if (tabIndex === 0) {
                  setTabIndex(1);
                  return;
                }

                const body = {
                  nome,
                  produtos_principais: produtosPrincipais.map((p) => p._id),
                  produtos_adicionais: produtosAdicionais.map((p) => p._id),
                  data_inicio: dataInicio,
                  data_fim: dataFim,
                  descontos: produtosAdicionais.map((p) => ({
                    produto_id: p._id,
                    variante_id: p.variante._id,
                    desconto_reais: Number(p.desconto_reais),
                    desconto_porcentagem: Number(p.desconto_porcentagem),
                    estoque: Number(p.estoque),
                    limite: Number(p.limite),
                    cliques: 0,
                    vendas: 0,
                    ativo: p.ativo,
                  })),
                };

                if (requestInProgress) {
                  toast({
                    title: "Por favor, aguarde!",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                  return;
                }
                setRequestInProgress(true);

                if (params.id) {
                  const res = await repo.alterar({
                    id: params.id,
                    ...body,
                  });
                  setRequestInProgress(false);
                  if (res.status === 200) {
                    toast({
                      title: "Oferta Combo atualizada com sucesso",
                      status: "success",
                      duration: 9000,
                      isClosable: true,
                    });

                    navigate(-1);
                  } else {
                    toast({
                      title: res.data["error"],
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  }
                  return;
                }

                const res = await repo.cadastrar(body);
                setRequestInProgress(false);
                if (res.status === 200) {
                  toast({
                    title: "Oferta Combo cadastrada com sucesso",
                    status: "success",
                    duration: 9000,
                    isClosable: true,
                  });

                  navigate(-1);
                } else {
                  toast({
                    title: res.data["error"],
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                  });
                }
              }}
              background={"primary.400"}
              color={"white"}
            >
              {params.id ? "Atualizar" : tabIndex === 0 ? "Próximo" : "Salvar"}
            </Button>
          </Flex>
        </Box>
      </PageScaffold>
      <ProductsSelectorDialog
        isOpen={isOpenMain || isOpenAditional}
        blockProducts={produtosPrincipais}
        onSelect={(p) => {
          if (isOpenMain) {
            setProdutosPrincipais(p);
          } else {
            setProdutosAdicionais(p);
          }

          setDescontos([]);

          onClose();
        }}
        onClose={() => {
          onClose();
        }}
      />
    </>
  );
}
