import {
  getStorage,
  ref,
  uploadString,
  uploadBytes,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import FirebaseAuthService from "./firebase_auth_service";
import { v4 as uuidv4 } from "uuid";

class FirebaseStorageService {
  handleUpload = async (dir, files) => {
    let urls = [];
    const storage = getStorage(FirebaseAuthService.app);
    if (!files) {
      throw new Error("Nenhuma imagem foi carregada");
    }

    for await (let file of files) {
      if (!dir.includes("foto_usuario/")) {
        dir += "/" + uuidv4() + "." + file.name.split(".").at(-1);
      }

      const storageRef = ref(storage, `${dir}`);
      await uploadBytesResumable(storageRef, file);
      let url = await getDownloadURL(storageRef);
      urls.push(url);
    }

    return urls;
  };

  handleBlobUpload = async (file) => {
    const storage = getStorage(FirebaseAuthService.app);
    if (!file) {
      throw new Error("Nenhuma imagem foi carregada");
    }

    const dir = "/" + uuidv4() + "." + file.name.split(".").at(-1);

    const storageRef = ref(storage, `${dir}`);

    await uploadBytes(storageRef, file, "data_url");

    const url = await getDownloadURL(storageRef);

    return url;
  };
}
export default FirebaseStorageService;
