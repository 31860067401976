import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/prime.css";
import ptbr from "react-date-object/locales/gregorian_pt_br";
import { Input, InputGroup, InputLeftElement, Image } from "@chakra-ui/react";
import icon from "../assets/calendario.png";
import { useRef } from "react";

const bypassTimezoneParse = (string) => {
  if (!string) return null;

  return new Date(string.replace("Z", ""));
};

export default function CustomDateRangePicker({
  onChange,
  minDate,
  maxDate,
  currentRange,
}) {
  const ref = useRef(null);

  const range =
    currentRange && currentRange.length > 1
      ? [
          bypassTimezoneParse(currentRange[0]),
          bypassTimezoneParse(currentRange[1]),
        ]
      : null;

  return (
    <DatePicker
      ref={ref}
      render={<CustomRangeInput />}
      dateSeparator=" - "
      range
      buttons={true}
      value={range}
      hideOnScroll
      rangeHover
      className="rmdp-prime"
      minDate={minDate}
      maxDate={maxDate}
      locale={ptbr}
      format="DD/MM/YYYY"
      onChange={(dates) => {
        if (!dates) return;

        if (dates.length > 1) {
          const [start, end] = dates;

          onChange({
            start: start.format("YYYY-MM-DDT00:00:00.000Z"),
            end: end.format("YYYY-MM-DDT23:59:59.000Z"),
          });

          ref.current?.closeCalendar();
        }
      }}
    />
  );
}

function CustomRangeInput({ onFocus, value, separator }) {
  let values = value.split(separator);
  let from = values[0] || "";
  let to = values[1] || "";

  value = from && to ? from + " - " + to : from;

  return (
    <InputGroup>
      <Input
        variant={"filled"}
        fontWeight={700}
        size={"md"}
        placeholder="Selecionar período"
        color={"black"}
        _placeholder={{ color: "black" }}
        onFocus={onFocus}
        value={value}
        w="250px"
        readOnly
        fontSize={"15px"}
      />
      <InputLeftElement cursor={"pointer"} onClick={onFocus}>
        <Image src={icon} w="15px" alt="Calendário" />
      </InputLeftElement>
    </InputGroup>
  );
}
