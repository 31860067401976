import React from "react";
import { Box, Flex, Heading, Image } from "@chakra-ui/react";

import logo from "../../assets/logo.png";

export default function Page404() {
  return (
    <Flex
      width="full"
      align="center"
      maxWidth="1440px"
      margin={"0 auto"}
      justifyContent="center"
      direction="column"
      py="5"
    >
      <Image
        src={logo}
        maxWidth="350px"
        style={{ filter: "invert(1) grayscale(1)" }}
        objectFit="contain"
        alt="Deshopnise"
        mt="10"
        mb="5"
        px="5"
      />

      <Box height={"50px"} />

      <Box maxWidth="500px" margin={"auto"}>
        <Box height={"50px"} />
        <Heading fontSize={"20px"}>404</Heading>
        <Heading fontSize={"20px"}>Pagina não encontrada</Heading>
      </Box>
    </Flex>
  );
}
