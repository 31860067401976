import useAuthToken from "../hooks/useAuthToken";
import { useState, useEffect, useContext } from "react";
import {
  Box,
  Flex,
  Image,
  Text,
  Divider,
  Checkbox,
  ModalBody,
  ModalFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Button,
} from "@chakra-ui/react";
import CupomDeDescontoRepository from "../repositories/cupom_de_desconto_repository";
import useEstabelecimento from "../hooks/useEstabelecimento";

export default function CuponsSelectorDialog({
  onSelect,
  isOpen,
  onClose,
  currentProducts = [],
}) {
  const token = useAuthToken();
  const estabelecimento = useEstabelecimento();
  const repo = new CupomDeDescontoRepository({ token });

  const [allCupons, setAllCupons] = useState([]);
  const [selectedCupons, setSelectedCupons] = useState([]);

  useEffect(() => {
    fetchCupons();
  }, [isOpen]);

  const fetchCupons = async () => {
    let data = {};

    data["vendedor_id"] = estabelecimento._id;
    repo.consultar(data).then((res) => {
      if (res.status === 200) {
        setAllCupons(res.data);
      }
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      height="300px"
      // leastDestructiveRef={cancelRef}
      //  onClose={onClose}
    >
      <ModalOverlay>
        <ModalContent height="700px">
          <ModalHeader fontSize="lg" fontWeight="bold">
            <Text> Seleção de cupons</Text>
            <Text fontWeight="normal" fontSize="xs">
              Selecione os cupons relacionados.
            </Text>
          </ModalHeader>

          <ModalBody overflowY="scroll">
            {allCupons.map((p) => {
              p.isChecked = currentProducts.find((cp) => cp._id === p._id);

              return (
                <Flex direction="row" justify="space-between">
                  <Box w="30px"></Box>
                  <Text fontSize={"sm"} fontWeight="semibold" w="20%">
                    nome
                  </Text>
                  <Text fontSize={"sm"} fontWeight="semibold" w="20%">
                    tipo
                  </Text>
                  <Text fontSize={"sm"} fontWeight="semibold" w="20%">
                    Código
                  </Text>
                  <Text fontSize={"sm"} fontWeight="semibold" w="20%">
                    Qtd produtos
                  </Text>
                </Flex>
              );
            })}

            <Divider my="2" />
            {allCupons.map((p) => {
              p.isChecked = currentProducts.find((cp) => cp._id === p._id);

              return (
                <Flex align="center" justify="space-between">
                  <Checkbox
                    onChange={(v) => {
                      p.isChecked = v.target.checked;
                    }}
                    mr="5"
                  />
                  <Text fontSize={"sm"} w="20%">
                    {p.nome}
                  </Text>
                  <Text fontSize={"sm"} w="20%">
                    {" "}
                    {p.tipo}
                  </Text>
                  <Text fontSize={"sm"} w="20%">
                    {" "}
                    {p.codigo}
                  </Text>
                  <Text fontSize={"sm"} w="20%">
                    {p.produtos.length}
                  </Text>
                </Flex>
              );
            })}
          </ModalBody>

          <ModalFooter>
            <Button onClick={onClose}>Cancelar</Button>
            <Button
              colorScheme="purple"
              onClick={() => {
                onSelect(allCupons.filter((p) => p.isChecked));
                allCupons.forEach((p) => (p.isChecked = false));
              }}
              ml={3}
            >
              Salvar
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
