import { Flex, Text, Box } from "@chakra-ui/react";

function TabButton({ children, onClick, active = false }) {
  return (
    <Flex
      cursor={"pointer"}
      direction={"column"}
      align={"center"}
      pr={"25px"}
      onClick={onClick}
    >
      <Text
        opacity={active ? "1" : "0.4"}
        fontSize={"19px"}
        fontWeight={"bold"}
      >
        {children}
      </Text>

      {active && (
        <Box
          borderRadius={"2px"}
          height={"4px"}
          backgroundColor={"#693FF3"}
          width={"115%"}
        ></Box>
      )}
    </Flex>
  );
}

export default TabButton;
