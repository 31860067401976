import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import Formatters from "../../utils/formatters";

export default function TimelineComponent(props) {
  const { logo, title, date, description, color, index, arrLength } = props;
  const textColor = useColorModeValue("gray.700", "white.300");
  const bgIconColor = useColorModeValue("white.300", "gray.700");

  return (
    <Flex alignItems="center" minH="78px" justifyContent="start" h="100%">
      <Flex direction="column" h="100%" minH="78px">
        {logo && (
          <Icon
            as={logo}
            bg={bgIconColor}
            color={color}
            h={"30px"}
            w={"26px"}
            pe="6px"
            zIndex="1"
            position="relative"
            right={document.documentElement.dir === "rtl" ? "-10px" : ""}
            left={document.documentElement.dir === "rtl" ? "" : "-9px"}
          />
        )}

        {!logo && (
          <Box
            borderRadius={"50%"}
            background={index === 0 ? color : "grey"}
            h={"10px"}
            w={"10px"}
            ml={"5px"}
            mt={"6px"}
            mb={"9px"}
            zIndex="1"
            position="relative"
            right={document.documentElement.dir === "rtl" ? "-10px" : ""}
            left={document.documentElement.dir === "rtl" ? "" : "-9px"}
          />
        )}

        <Box
          w="2px"
          bg={color}
          h={index === arrLength - 1 ? "0px" : "100%"}
          minH={index === arrLength - 1 ? "0px" : "45px"}
        />
      </Flex>
      <Flex direction="column" justifyContent="flex-start" h="100%" minH="78px">
        <Text
          fontSize="sm"
          color={!logo && index === 0 ? color : textColor}
          fontWeight="bold"
        >
          {title}
        </Text>
        <Text fontSize="xs" color="gray.900" fontWeight="500">
          {description}
        </Text>
        <Text fontSize="10px" color="gray.500" fontWeight="normal">
          {Formatters.date(new Date(date))}
        </Text>
      </Flex>
    </Flex>
  );
}
