import {
  Box,
  Flex,
  Grid,
  Heading,
  Select,
  Text,
  Input,
} from "@chakra-ui/react";
import CustomSelect from "../../../components/CustomSelect";

export default function Especificacoes({ source, anuncio, onChange }) {
  if (!source) {
    return <></>;
  }

  if (source.length === 0) {
    return <></>;
  }

  const specs = source.flatMap((e) => e.propriedades);

  if (!Array.isArray(specs) || specs.length === 0) {
    return <></>;
  }

  return (
    <Box
      boxShadow="sm"
      w="full"
      mt="5px"
      borderRadius="8px"
      p="5"
      bgColor="white"
    >
      <Heading fontSize="md">Especificação</Heading>
      <Text fontSize="xs" noOfLines="2">
        Preencha mais atributos para anunciar a exposição do seu produto.
      </Text>
      <Grid templateColumns="repeat(2, 1fr)" gap={2}>
        {specs.map((e) => {
          return (
            <Flex
              key={e.titulo}
              direction="row"
              maxW="500px"
              w={"500px"}
              align="center"
              justify={"end"}
              mt="2"
            >
              <Text fontSize="sm" mr="5" w="200px" textAlign={"end"}>
                {e.titulo}
              </Text>

              <CustomSelect
                options={e.opcoes.map((a) => ({
                  label: a,
                  value: a,
                }))}
                onChange={(v) => {
                  onChange({
                    [e.titulo]: v.value,
                  });
                }}
                selectedOption={{
                  ...(anuncio?.especificacoes &&
                    Object.keys(anuncio?.especificacoes).length > 0 && {
                      label: anuncio?.especificacoes[e.titulo] ?? "",
                      value: anuncio?.especificacoes[e.titulo] ?? "",
                    }),
                }}
              />
            </Flex>
          );
        })}
      </Grid>
    </Box>
  );
}
