import React, { useEffect, useRef, useState } from "react";
import PageScaffold from "../../components/PageScaffold";

import {
  Box,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Flex,
  Text,
  Input,
  Select,
  Button,
} from "@chakra-ui/react";
import SaldoCard from "./components/SaldoCard";
import CustomTabButtonComponent from "../../components/CustomTabButtonComponent";
import useAuthToken from "../../hooks/useAuthToken";
import PedidosRepository from "../../repositories/pedidos_repository";
import useEstabelecimento from "../../hooks/useEstabelecimento";
import { useSearchParams } from "react-router-dom";
import dateRangeIsValid from "../../utils/date_comparator";
import { MdCalendarToday } from "react-icons/md";
import PageHeader from "../../components/PageHeader";

import CustomDateRangePicker from "../../components/CustomDateRangePicker";
import CarteiraRepository from "../../repositories/carteira_repository";
import TransacoesList from "./components/TransacoesList";

export default function Lucros() {
  const token = useAuthToken();
  const loja = useEstabelecimento();

  const repo = new CarteiraRepository({ token });

  const [filters, setFilters] = useState({
    data_inicio: "",
    data_fim: "",
    status: "",
    tipo: "",
  });

  const [transacoes, setTransacoes] = useState([]);
  const [resumo, setResumo] = useState(null);

  useEffect(() => {
    fetchSaldo();
  }, []);

  useEffect(() => {
    fetchTransactionList();
  }, [filters]);

  const fetchSaldo = async () => {
    const res = await repo.resumo();

    if (res.status === 200) {
      setResumo(res.data);
    }
  };

  const fetchTransactionList = async () => {
    const _filters = new URLSearchParams(filters);

    const res = await repo.consultar(_filters);

    if (res.status === 200) {
      setTransacoes(res.data);
    }
  };

  return (
    <PageScaffold>
      <PageHeader
        title={"Carteira Deshopnise"}
        subtitle={"Acompanhe seus lucros e seu saldo atual."}
      />

      {loja?.pre_cadastro && (
        <Flex direction={"column"}>
          <Text fontWeight={"bold"}>
            Faça a ativação da sua conta para configurar sua Loja.
          </Text>
          <Box mt="8px">
            <Button
              colorScheme="purple"
              backgroundColor={"#009E45"}
              onClick={() => {
                window.location.href = "/completar-cadastro";
              }}
            >
              Ativar conta
            </Button>
          </Box>
        </Flex>
      )}

      {!loja?.pre_cadastro && (
        <Box>
          <Text fontSize="xs" fontWeight="medium" paddingBottom={"4px"}></Text>

          <SaldoCard
            data={resumo}
            onRefresh={() => {
              setTimeout(() => {
                fetchSaldo();
                fetchTransactionList();
              }, 1500);
            }}
          />

          <Box
            boxShadow="sm"
            width={"100%"}
            minHeight={"88vh"}
            borderRadius={"8px"}
            marginTop={"5px"}
            backgroundColor={"white"}
          >
            <Flex
              direction={"row"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              padding={"15px 30px"}
            >
              <CustomDateRangePicker
                onChange={({ start, end }) => {
                  setFilters({ ...filters, data_inicio: start, data_fim: end });
                }}
                size="md"
              />

              <Select
                variant={"filled"}
                mx="2"
                w="280px"
                fontWeight={700}
                value={filters.status || null}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    status: e.target.value === "Todos" ? "" : e.target.value,
                  });
                }}
              >
                <option value={null}>Todos</option>
                <option value={"pendente"}> Pagamentos a receber</option>
                <option value={"disponivel"}> Pagamentos disponíveis</option>
                <option value={"concluido"}> Pagamentos recebidos</option>
                <option value={"cancelado"}> Pagamentos cancelados</option>
              </Select>
            </Flex>
            <TransacoesList data={transacoes} />
          </Box>
        </Box>
      )}
    </PageScaffold>
  );
}
