import { Box, Flex, Text } from "@chakra-ui/react";
import { MdAccessTime } from "react-icons/md";
import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Formatters from "../../../utils/formatters";

function StatusCard({ data }) {
  const etapaEnvio =
    data.eventos.at(-1).id === "pagamento_aprovado" ||
    data.eventos.at(-1).id === "preparando_pedido";

  return (
    <Box
      p={"25px"}
      boxShadow="sm"
      maxWidth={"600px"}
      borderRadius={"10px"}
      marginTop={"5px"}
      backgroundColor={"white"}
    >
      {etapaEnvio && (
        <Box
          background={"#663BFF"}
          width={"100%"}
          p={"15px"}
          borderRadius={"8px"}
        >
          <Flex justifyContent={"flex-start"} alignItems={"center"}>
            <MdAccessTime size={"35px"} color={"white"} />
            <Box width={"15px"} />
            <Text color={"white"} fontWeight={"500"} width={"58%"}>
              Envie seu produto até dia{" "}
              {data.prazo_maximo_envio
                ? new Date(data.prazo_maximo_envio).toLocaleDateString()
                : Formatters.addDays(
                    new Date(data.createAt),
                    4
                  ).toLocaleDateString()}{" "}
              e faça bonito na fita da clientela.
            </Text>
          </Flex>
        </Box>
      )}

      <Text fontWeight={700} fontSize={"26px"} padding={"20px 0px"}>
        {data.eventos.at(-1).titulo}
      </Text>

      <VerticalTimeline
        layout="1-column-left"
        lineColor="#09BE34"
        className="vertical-timeline-properties"
      >
        {data.eventos.map((e) => (
          <VerticalTimelineElement
            iconStyle={{
              background: "#09BE34",
              scale: "0.6",
            }}
            contentArrowStyle={{ height: "0px !important" }}
          >
            <Box>
              <Box fontSize={"19px"} fontWeight={"700"}>
                {e.titulo.toUpperCase()}
              </Box>
              <Box fontSize={"15px"} fontWeight={"500"}>
                {e.descricao}
              </Box>
              <Flex alignItems={"center"} opacity={"0.8"}>
                <MdAccessTime />

                <Box fontSize={"15px"} fontWeight={"500"} paddingStart={"5px"}>
                  {Formatters.date(new Date(e.data))}
                </Box>
              </Flex>
            </Box>
          </VerticalTimelineElement>
        ))}
        {/* <VerticalTimelineElement
          iconStyle={{
            background: "#09BE34",
            scale: "0.6",
          }}
          contentArrowStyle={{ height: "0px !important" }}
        >
          <Box fontSize={"19px"} fontWeight={"700"} opacity={0.7}>
            Em preparação
          </Box>
        </VerticalTimelineElement> */}
        {/* <VerticalTimelineElement
          iconStyle={{
            background: "#09BE34",
            scale: "0.6",
          }}
          contentArrowStyle={{ height: "0px !important" }}
        >
          <Box>
            <Box fontSize={"19px"} fontWeight={"700"}>
              Pedido enviado
            </Box>
            <Box fontSize={"15px"} fontWeight={"500"}>
              Seu pedido foi enviado!
            </Box>
            <Flex alignItems={"center"} opacity={"0.8"}>
              <MdAccessTime />

              <Box fontSize={"15px"} fontWeight={"500"} paddingStart={"5px"}>
                19/03/2033 às 19:00
              </Box>
            </Flex>
          </Box>
        </VerticalTimelineElement> */}
        {/* <VerticalTimelineElement
          iconStyle={{
            background: "#09BE34",
            scale: "0.6",
          }}
          contentArrowStyle={{ height: "0px !important" }}
        >
          <Box>
            <Box fontSize={"19px"} fontWeight={"700"}>
              A caminho
            </Box>
            <Box fontSize={"15px"} fontWeight={"500"}>
              Seu pacote está a caminho!
            </Box>
            <Flex alignItems={"center"} opacity={"0.8"}>
              <MdAccessTime />

              <Box fontSize={"15px"} fontWeight={"500"} paddingStart={"5px"}>
                19/03/2033 às 19:00
              </Box>
            </Flex>
          </Box>
        </VerticalTimelineElement> */}
        {/* <VerticalTimelineElement
          iconStyle={{
            background: "#09BE34",
            scale: "0.6",
          }}
          contentArrowStyle={{ height: "0px !important" }}
        >
          <Box>
            <Box fontSize={"19px"} fontWeight={"700"}>
              Pedido entregue
            </Box>
            <Flex alignItems={"center"} opacity={"0.8"}>
              <MdAccessTime />

              <Box fontSize={"15px"} fontWeight={"500"} paddingStart={"5px"}>
                19/03/2033 às 19:00
              </Box>
            </Flex>
          </Box>
        </VerticalTimelineElement> */}
      </VerticalTimeline>
    </Box>
  );
}

export default StatusCard;
