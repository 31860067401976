import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Grid,
  Heading,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useState, useRef, useEffect } from "react";
import { MdDelete, MdAdd } from "react-icons/md";
import TabVariacoesNovoProduto from "./TabVariacoesNovoProduto";
import { v4 as uuidv4 } from "uuid";

export default function VariacoesNovoProduto({
  tipo,
  value,
  onChange,
  onClose,
  canSwitch,
}) {
  const toast = useToast();

  const [prop1, setProp1] = useState({
    active: true,

    label: "",
    opcoes: [],
  });
  const [prop2, setProp2] = useState({
    active: false,
    label: "",
    opcoes: [],
  });

  const [variacoes, setVariacoes] = useState([]);

  useEffect(() => {
    setVariacoes(value);

    const keys = value.reduce((acc, variacao) => {
      return [...new Set([...acc, ...Object.keys(variacao.propriedades)])];
    }, []);

    keys.forEach((key, index) => {
      if (index === 0) {
        setProp1({
          ...prop1,
          label: key,
          opcoes: [
            ...new Set(value.map((variacao) => variacao.propriedades[key])),
          ],
        });
      }

      if (index === 1) {
        setProp2({
          ...prop2,
          active: true,
          label: key,
          opcoes: [
            ...new Set(value.map((variacao) => variacao.propriedades[key])),
          ],
        });
      }
    });
  }, []);

  const gerarVariacoes = (p1, p2) => {
    const data = [];

    console.log({ p1, p2 });

    if (!p2.active) {
      p1.opcoes.forEach((opcao1) => {
        if (!opcao1) return;

        const sku = [p1.label, opcao1]
          .join("")
          .toUpperCase()
          .replaceAll(" ", "");

        const current = value.filter((e) => e.sku === sku);

        if (current.length > 0) {
          data.push(current[0]);
          return;
        }

        console.log(current);

        data.push({
          preco: 0,
          estoque: 3,
          sku: sku,
          propriedades: {
            [p1.label]: opcao1,
          },
        });
      });

      setVariacoes(data);
      onChange(data);

      return;
    } else {
      p1.opcoes.forEach((opcao1) => {
        if (!opcao1) return;

        p2.opcoes.forEach((opcao2) => {
          if (!opcao2) return;

          const sku = [opcao1, opcao2]
            .join("")
            .toUpperCase()
            .replaceAll(" ", "");

          const current = value.filter((e) => e.sku === sku);

          if (current.length > 0) {
            data.push(current[0]);
            return;
          }

          console.log(current);

          data.push({
            preco: 0,
            estoque: 3,
            sku: sku,
            propriedades: {
              [p1.label]: opcao1,
              [p2.label]: opcao2,
            },
          });
        });
      });

      console.log(data);

      setVariacoes(data);
      onChange(data);
    }
  };

  return (
    <Box
      boxShadow="sm"
      w="full"
      mt="5px"
      borderRadius="8px"
      p="5"
      bgColor="white"
    >
      <Heading fontSize="md">Variações do {tipo}</Heading>
      <Text fontSize="xs" noOfLines="2">
        Complete: 0/2 Preencha mais atributos para anunciar a exposição do seu
        {" " + tipo}.
      </Text>
      <Flex direction="row" mt="5" w="60%">
        <Text fontSize="sm" fontWeight="medium" w="100px">
          Variação 1
        </Text>
        <Box
          backgroundColor="whitesmoke"
          ml="5"
          p="5"
          borderRadius="8px"
          minW="490px"
        >
          <Flex direction="row" align="center">
            <Text fontWeight={500} w={"65px"} mr="3" fontSize={"12px"}>
              Nome
            </Text>
            <Input
              mr="2"
              onChange={(e) => {
                setProp1({
                  ...prop1,
                  label: e.target.value,
                });

                gerarVariacoes(
                  {
                    ...prop1,
                    label: e.target.value,
                  },
                  prop2
                );
              }}
              variant={"filled"}
              placeholder="Exemplo: Cor"
              value={prop1.label}
            />
            <MdDelete
              onClick={() => {
                onClose();
              }}
              size="30px"
              cursor="pointer"
              color="grey"
            />
          </Flex>

          <Flex direction={"column"}>
            {prop1.opcoes.map((opcao, index) => {
              return (
                <VarianteOptions
                  key={index}
                  item={opcao}
                  onChange={(text) => {
                    if (!text || opcao != "") return;

                    const opcoes = [...prop1.opcoes, text].filter(
                      (e) => e !== ""
                    );

                    setProp1({
                      ...prop1,
                      opcoes,
                    });

                    setTimeout(() => {
                      gerarVariacoes(
                        {
                          ...prop1,
                          opcoes,
                        },
                        prop2
                      );
                    }, 500);
                  }}
                  onRemove={(item) => {
                    let opcoes = prop1.opcoes.filter((opcao) => opcao !== item);

                    let v = variacoes.filter(
                      (variacao) => variacao.propriedades[prop1.label] !== item
                    );

                    setProp1({
                      ...prop1,
                      opcoes,
                    });

                    setTimeout(() => {
                      gerarVariacoes(
                        {
                          ...prop1,
                          opcoes,
                        },
                        prop2,
                        v
                      );
                    }, 1000);
                  }}
                />
              );
            })}
          </Flex>
          <Box
            onClick={() => {
              if (prop1.label === "") {
                toast({
                  title: "Preencha o nome da variação",
                  status: "error",
                  duration: 3000,
                  isClosable: true,
                });
                return;
              }

              setProp1({
                ...prop1,
                opcoes: [...prop1.opcoes, ""],
              });
            }}
            borderStyle="dashed"
            borderColor="primary.400"
            borderWidth="1px"
            boxSizing="border-box"
            borderRadius="6px"
            my="5"
            ml="60px"
            cursor="pointer"
            justifyContent="center"
            align="center"
          >
            <Flex
              justifyContent="center"
              align="center"
              direction="row"
              color="primary.400"
              h="10"
            >
              <MdAdd size="20" m="5 auto" />
              <Text fontSize="xs" fontWeight="semibold">
                Adicionar opção
              </Text>
            </Flex>
          </Box>
        </Box>
      </Flex>

      <Flex direction="row" mt="5" w="60%">
        <Text fontSize="sm" fontWeight="medium" w="100px">
          Variação 2
        </Text>

        {!prop2.active && (
          <Box
            ml="5"
            borderStyle="dashed"
            borderColor="primary.400"
            borderWidth="1px"
            boxSizing="border-box"
            borderRadius="6px"
            cursor="pointer"
            w="590px"
          >
            <Flex
              justifyContent="center"
              align="center"
              direction="row"
              color="primary.400"
              h="10"
              onClick={() => {
                setProp2({
                  ...prop2,
                  active: true,
                });
              }}
            >
              <MdAdd size="20" m="5 auto" />
              <Text fontSize="xs" fontWeight="semibold">
                Habilitar variações 2
              </Text>
            </Flex>
          </Box>
        )}

        {prop2.active && (
          <Box
            backgroundColor="whitesmoke"
            ml="5"
            p="5"
            borderRadius="8px"
            minW="490px"
          >
            <Flex direction="row" align="center">
              <Text fontWeight={500} w={"65px"} mr="3" fontSize={"13px"}>
                Nome
              </Text>

              <Input
                mr="2"
                onChange={(e) => {
                  setProp2({
                    ...prop2,
                    label: e.target.value,
                  });

                  gerarVariacoes(prop1, {
                    ...prop2,
                    label: e.target.value,
                  });
                }}
                variant={"filled"}
                placeholder="Exemplo: Cor"
                value={prop2.label}
              />
              <MdDelete
                onClick={() => {
                  setProp2({
                    opcoes: [],
                    label: "",
                    active: false,
                  });

                  setTimeout(() => {
                    gerarVariacoes(prop1, {
                      opcoes: [],
                      label: "",
                      active: false,
                    });
                  }, 500);
                }}
                size="30px"
                cursor="pointer"
                color="grey"
              />
            </Flex>

            <Flex direction={"column"}>
              {prop2.opcoes.map((opcao, index) => {
                return (
                  <VarianteOptions
                    key={index}
                    index={index}
                    item={opcao}
                    onChange={(text) => {
                      if (!text || opcao != "") return;

                      const opcoes = [...prop2.opcoes, text].filter(
                        (e) => e !== ""
                      );

                      setProp2({
                        ...prop2,
                        opcoes,
                      });

                      setTimeout(() => {
                        gerarVariacoes(prop1, {
                          ...prop2,
                          opcoes,
                        });
                      }, 500);
                    }}
                    onRemove={(item) => {
                      let opcoes = prop2.opcoes.filter(
                        (opcao) => opcao !== item
                      );

                      setProp2({
                        ...prop2,
                        opcoes,
                      });

                      setTimeout(() => {
                        gerarVariacoes(prop1, {
                          ...prop2,
                          opcoes,
                        });
                      }, 500);
                    }}
                  />
                );
              })}
            </Flex>
            <Box
              onClick={() => {
                if (prop1.label === "") {
                  toast({
                    title: "Preencha o nome da variação",
                    status: "error",
                    duration: 3000,
                    isClosable: true,
                  });
                  return;
                }
                setProp2({
                  ...prop2,
                  opcoes: [...prop2.opcoes, ""],
                });
              }}
              borderStyle="dashed"
              borderColor="primary.400"
              borderWidth="1px"
              boxSizing="border-box"
              borderRadius="6px"
              my="5"
              ml="60px"
              cursor="pointer"
              justifyContent="center"
              align="center"
            >
              <Flex
                justifyContent="center"
                align="center"
                direction="row"
                color="primary.400"
                h="10"
              >
                <MdAdd size="20" m="5 auto" />
                <Text fontSize="xs" fontWeight="semibold">
                  Adicionar opção
                </Text>
              </Flex>
            </Box>
          </Box>
        )}
      </Flex>

      {variacoes.length > 0 && (
        <Flex direction="row" w="80%" align="start" mt="5">
          <Text fontSize="sm" fontWeight="medium" w="100px">
            Lista de variações
          </Text>
          <TabVariacoesNovoProduto
            variacoes={variacoes}
            onChange={(v) => {
              setVariacoes(v);
              onChange(v);
            }}
          />
        </Flex>
      )}
    </Box>
  );
}

const VarianteOptions = ({ item, onChange, onRemove }) => {
  const ref = useRef(null);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onChange(ref.current.value);

      ref.current.blur();
    }
  };

  return (
    <Flex direction="row" align="center" mt="2" key={item}>
      <Text fontWeight={500} w={"70px"} mr="2" fontSize={"12px"}>
        Opção
      </Text>
      <Input
        ref={ref}
        onKeyDown={handleKeyDown}
        onBlur={(e) => {
          onChange(ref.current.value);
        }}
        defaultValue={item}
        placeholder="Exemplo: Vermelho"
        mr="2"
        variant={"filled"}
      />
      <MdDelete
        onClick={() => {
          onRemove(item);
        }}
        size="30px"
        cursor="pointer"
        color="grey"
      />
    </Flex>
  );
};
