import { Box, Heading, Text, Button } from "@chakra-ui/react";
import Formatters from "../../../utils/formatters";

const StatusHeader = ({ pedido, divergencia, callback }) => {
  return (
    <Box
      boxShadow="sm"
      w="full"
      mt="5px"
      borderRadius="8px"
      p="5"
      bgColor="white"
    >
      {divergencia?.status === "aguardando_devolucao" && (
        <>
          <Heading fontSize="sm">Solicitação de Devolução</Heading>

          <Text fontSize="sm" fontWeight={"500"} pt={"5px"}>
            Aguardando resposta do comprador(a) : Foi enviado a solicitação de
            devolução para o comprador. O comprador(a) terá até 5 dias úteis
            para envio do produto, após recebe-lo verifique se o produto está em
            condições aceitáveis, caso contrário, você pode criar uma disputa.
            Após recebimento responda em até 48 horas.
          </Text>
        </>
      )}

      {divergencia?.status === "aguardando_resposta_deshopnise" && (
        <>
          <Heading fontSize="sm">Disputa em análise</Heading>

          <Text fontSize="sm" fontWeight={"500"} pt={"5px"}>
            A Deshopnise está analisando a disputa.
          </Text>
        </>
      )}

      {divergencia?.status === "aguardando_resposta_vendedor" && (
        <>
          <Heading fontSize="sm">Solicitação de devolução ou reembolso</Heading>

          <Text fontSize="sm" fontWeight={"500"}>
            <br />
            O(A) comprador(a) <b>{pedido?.usuario_nome}</b> solicitou um pedido
            de Devolução/Reembolso. Acompanhe abaixo as informações para tomada
            de ação. Responda até
            <b>
              {" "}
              {Formatters.date(
                new Date(divergencia?.data_limite_resposta_vendedor)
              )}
            </b>
            , caso contrário o reembolso será liberado para o(a) comprador(a).
          </Text>
          <Box w="full" align="end" mt="5">
            <Button
              backgroundColor="#693FF3"
              color="white"
              size="sm"
              mr="2"
              onClick={() => {
                callback("aprovar_devolucao");
              }}
            >
              Aceitar devolução
            </Button>
            <Button
              onClick={() => {
                callback("aprovar_reembolso");
              }}
              backgroundColor="#693FF3"
              color="white"
              size="sm"
              mr="2"
            >
              Reembolso
            </Button>
            <Button
              colorScheme="blackAlpha"
              size="sm"
              onClick={() => {
                callback("iniciar_disputa");
              }}
            >
              Disputa
            </Button>
          </Box>
        </>
      )}

      {(divergencia?.status === "reembolso_aprovado" ||
        divergencia?.status === "reembolso_aprovado_deshopnise") && (
        <>
          <Heading fontSize="sm">Reembolso enviado com sucesso</Heading>

          <Text fontSize="sm" fontWeight={"500"} pt={"5px"}>
            A solicitação do comprador foi aprovado com sucesso.
          </Text>
        </>
      )}

      {divergencia?.status === "negada_automaticamente" && (
        <>
          <Heading fontSize="sm">
            Reembolso/Devolução negado automaticamente
          </Heading>

          <Text fontSize="sm" fontWeight={"500"} pt={"5px"}>
            A solicitação foi cancelada por falta de resposta do comprador.
          </Text>
        </>
      )}

      {divergencia?.status === "aprovada_automaticamente" && (
        <>
          <Heading fontSize="sm">Reembolso aprovado automaticamente</Heading>

          <Text fontSize="sm" fontWeight={"500"} pt={"5px"}>
            A solicitação foi aprovada por falta de resposta do vendedor.
          </Text>
        </>
      )}

      {divergencia?.status === "disputa_negada" && (
        <>
          <Heading fontSize="sm">Reembolso/Devolução negada</Heading>

          <Text fontSize="sm" fontWeight={"500"} pt={"5px"}>
            A Deshopise negou a solicitação de reembolso/devolução.
          </Text>
        </>
      )}

      {divergencia?.status === "disputa_cancelada" && (
        <>
          <Heading fontSize="sm">Reembolso/Devolução cancelada</Heading>

          <Text fontSize="sm" fontWeight={"500"} pt={"5px"}>
            O comprador cancelou a solicitação de reembolso/devolução.
          </Text>
        </>
      )}

      {divergencia?.status === "disputa_encerrada" && (
        <>
          <Heading fontSize="sm">Reembolso/Devolução encerrado</Heading>

          <Text fontSize="sm" fontWeight={"500"} pt={"5px"}>
            A disputa foi encerrada.
          </Text>
        </>
      )}

      {divergencia?.status === "devolucao_concluida" && (
        <>
          <Heading fontSize="sm">Pedido de Reembolso aberto</Heading>

          <Text fontSize="sm" fontWeight={"500"}>
            <br />
            Aguardando sua resposta: O comprador enviou o(s) item(s) para o seu
            endereço; Se você recebe-lo nas condições esperadas, confirme o
            reembolso, caso contrário, você pode criar uma disputa. Responda até
            <b>
              {" "}
              {Formatters.date(
                new Date(divergencia?.data_limite_resposta_vendedor)
              )}
            </b>
            , caso contrário o reembolso será liberado para o(a) comprador(a).
          </Text>
          <Box w="full" align="end" mt="5">
            <Button
              onClick={() => {
                callback("aprovar_reembolso");
              }}
              backgroundColor="#693FF3"
              color="white"
              size="sm"
              mr="2"
            >
              Reembolso
            </Button>
            <Button
              colorScheme="blackAlpha"
              size="sm"
              onClick={() => {
                callback("abrir_disputa");
              }}
            >
              Disputa
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default StatusHeader;
