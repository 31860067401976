import {
  Box,
  Heading,
  Text,
  Flex,
  Card,
  CardBody,
  Button,
} from "@chakra-ui/react";
import useEstabelecimento from "../../../../hooks/useEstabelecimento";

import { useContext, useState } from "react";
import PerfilComponent from "../Perfil";
import EnderecoComponent from "../Endereco";
import CarteiraComponent from "../Carteira";
import TelefoneComponent from "../Telefone";
import TipoLojaComponent from "../TipoLoja";

import { AuthContext } from "../../../../contexts/authContext";

function StepperItemComponent({
  isEnabled,
  isSelected,
  isCompleted,
  title,
  description,
  onClick,
  number,
}) {
  return (
    <Card
      cursor={isEnabled ? "pointer" : "not-allowed"}
      shadow={"none"}
      borderRadius={"10px"}
      maxW="md"
      border={isSelected ? "3px solid #683FF3" : undefined}
      onClick={onClick}
      direction={"row"}
      alignItems={"center"}
      mb={"5px"}
    >
      <Flex
        ml={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        w={"50px"}
        h={"50px"}
        borderRadius={"50%"}
        background={"#D4C7FF"}
        fontSize={"28px"}
        fontWeight={"700"}
      >
        {number}
      </Flex>

      <CardBody opacity={isEnabled ? "100%" : "50%"}>
        <Heading fontSize={"17px"} align="start" mb={"2px"}>
          {title}
        </Heading>
        <Text
          fontSize={"13px"}
          fontWeight={"500"}
          align="start"
          lineHeight={"17px"}
          noOfLines={[3, 4]}
        >
          {description}
          {isCompleted && (
            <Text fontWeight={"700"} color={"#02AA80"}>
              {" "}
              Concluído
            </Text>
          )}
        </Text>
      </CardBody>
    </Card>
  );
}

export default function StepperComponent() {
  const estabelecimento = useEstabelecimento();
  const authContext = useContext(AuthContext);
  const isPj = estabelecimento?.tipo_cadastro !== "pessoa_fisica";
  const [step, setStep] = useState(0);
  const [focusedStep, setFocusedStep] = useState(1);

  if (step) {
    if (step === 1)
      return (
        <PerfilComponent
          data={estabelecimento}
          onCompleted={() => {
            setFocusedStep(2);
            setStep(null);
            authContext.refreshEstabelecimento();
          }}
        />
      );
    if (step === 2)
      return (
        <EnderecoComponent
          data={estabelecimento}
          onCompleted={() => {
            setFocusedStep(3);
            setStep(null);
            authContext.refreshEstabelecimento();
          }}
        />
      );
    if (step === 3)
      return (
        <CarteiraComponent
          data={estabelecimento}
          onCompleted={() => {
            setFocusedStep(4);
            setStep(null);
            authContext.refreshEstabelecimento();
          }}
        />
      );
    if (step === 4)
      return (
        <TelefoneComponent
          data={estabelecimento}
          onCompleted={() => {
            setFocusedStep(5);
            setStep(null);
            authContext.refreshEstabelecimento();
          }}
        />
      );
  }

  return (
    <Box
      style={{
        zoom: "1.1",
      }}
      width="full"
      my="5"
      mt="20"
      align={"center"}
    >
      <Flex
        justifyContent={"center"}
        w={"450px"}
        align="center"
        direction="column"
      >
        <StepperItemComponent
          isSelected={focusedStep === 1}
          isEnabled={true}
          isCompleted={!!estabelecimento?.documento}
          title={`Informações do ${isPj ? "CNPJ" : "CPF"}`}
          description={`Preencha as informações do seu ${
            isPj ? "CNPJ" : "CPF"
          } para liberar o acesso a todas as funções da plataforma.`}
          number={1}
          onClick={() => {
            setFocusedStep(1);
          }}
        />
        <StepperItemComponent
          isSelected={focusedStep === 2}
          isEnabled={!!estabelecimento?.documento}
          isCompleted={!!estabelecimento?.endereco?.cep}
          title={"Informações de endereço"}
          description={
            "Preencha as informações do endereço do seu negócio para validarmos o seu cadastro."
          }
          number={2}
          onClick={() => {
            setFocusedStep(2);
          }}
        />
        <StepperItemComponent
          isSelected={focusedStep === 3}
          isEnabled={!!estabelecimento?.endereco?.cep}
          isCompleted={!!estabelecimento?.conta_bancaria_valida}
          title={"Adicionar conta bancária"}
          description={
            "Adicione suas informações bancárias para receber o lucro de suas vendas."
          }
          number={3}
          onClick={() => {
            setFocusedStep(3);
          }}
        />
        <StepperItemComponent
          isSelected={focusedStep === 4}
          isCompleted={!!estabelecimento?.telefone}
          isEnabled={!!estabelecimento?.conta_bancaria_valida}
          title={"Validação do seu telefone"}
          description={
            "Valide seu número de telefone para deixar sua conta totalmente segura."
          }
          number={4}
          onClick={() => {
            setFocusedStep(4);
          }}
        />
      </Flex>

      <Button
        onClick={() => {
          setStep(focusedStep);
        }}
        bg={"#683FF3"}
        color={"white"}
        mt={"2"}
        w={"450px"}
        borderRadius={"8px"}
        h={"45px"}
        fontWeight={"700"}
        _hover={{
          bg: "#4C378F",
        }}
      >
        COMEÇAR
      </Button>
    </Box>
  );
}
